import {Injectable} from '@angular/core';
import * as SSM from 'aws-sdk/clients/ssm';
import {NodeEnv, Stage, STAGE_MAPPINGS, isValidNodeEnv} from '../../kanso-common/core/constants/environment.constants';

export interface ParameterStoreVariables {
  name: string;
  application?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ParameterStoreService {
  private readonly NODE_ENV: NodeEnv;
  private readonly stage: Stage;
  private readonly ssm: SSM;

  constructor() {
    try {
      const nodeEnv = sessionStorage.getItem('NODE_ENV');
      if (!isValidNodeEnv(nodeEnv)) {
        console.warn(`Invalid NODE_ENV: ${nodeEnv}, defaulting to 'development'`);
        this.NODE_ENV = 'development';
      } else {
        this.NODE_ENV = nodeEnv;
      }

      this.stage = this.getStage();
      this.ssm = this.initializeSSM();
    } catch (err) {
      const error = `Failed constructing ParameterStore: ${err instanceof Error ? err.message : 'Unknown error'}`;
      console.error(error);
      throw new Error(error);
    }
  }

  /**
   * Helper function that returns the stage the site is currently running in
   * @param environment Environment value from ConfigEnv
   * @returns {Stage} Either 'dev', 'qa', 'training' or 'prod'
   */
  public getStage(): Stage {
    const matchedStage = STAGE_MAPPINGS.find(mapping => this.NODE_ENV === mapping.keyword);

    if (!matchedStage) {
      console.warn(`No stage mapping found for NODE_ENV: ${this.NODE_ENV}, using development`);
      return 'dev';
    }

    return matchedStage.stage;
  }

  private initializeSSM(): SSM {
    try {
      return new SSM();
    } catch (err) {
      const error = `Failed to initialize SSM client: ${err instanceof Error ? err.message : 'Unknown error'}`;
      console.error(error);
      throw new Error(error);
    }
  }

  /**
   * Function short circuits to return the sessionStorage entry for the given variable or completes the AWS handshake to pull
   * the property from the AWS Parameter Store under the given parameter name.
   *
   * Currently the class handles articulating the parameter name by using the provided environment variables:
   * - stage (prod or dev derived from the database connection string. defaults to 'dev' if no condition matches explicitly)
   * - Application (defaults to 'doorways')
   * - parameter (provided string from developer)
   *
   * @param application {string} Kanso Application
   * @param parameter {string} Parameter Store Entry
   * @returns {string | null} Value of Parameter Store Entry or Null If Parameter Is Not Present
   */
  private async getParameter(application: string, parameter: string): Promise<string | null> {
    try {
      const storedValue = sessionStorage.getItem(parameter);
      if (storedValue) {
        return storedValue;
      }

      const {
        Parameter: {Value},
      }: SSM.GetParameterResult = await this.ssm.getParameter({Name: `/${application}/${this.stage}/${parameter}`}).promise();

      sessionStorage.setItem(parameter, Value);
      return Value;
    } catch (err) {
      console.error(
        `Error fetching parameter: ${application}, ${this.stage}, ${parameter}`,
        err instanceof Error ? err.message : 'Unknown error'
      );
      return null;
    }
  }

  /**
   * Helper function that returns parameter store values set in session storage or fetched from AWS
   *
   * @param parameters {{ name: string, application?: string }[]} Parameter Store variables wanted to be fetched and saved
   * @returns {string | null} AWS Parameter Store Entries (if applicable)
   */
  public async fetchAndSetParameters(parameters: ParameterStoreVariables[]): Promise<(string | null)[]> {
    return await Promise.all(parameters.map(async parameter => this.getParameter(parameter.application || 'doorways', parameter.name)));
  }

  /**
   * Helper function that returns parameter store values set in session storage
   *
   * @param parameter {string} Parameter Store variable to fetch and save
   * @param application {string} Kanso Application (defaults to 'doorways')
   * @returns {(string|null)} String or null (null if parameter is not found in Parameter Store)
   */
  public async fetchAndSetParameter(parameter: string, application = 'doorways'): Promise<string | null> {
    return this.getParameter(application, parameter);
  }

  /**
   * Helper function that clears the session storage when invoked.
   * Allowing the end user to quickly fetch updated parameter store values from AWS in case of emergency
   */
  public refreshParameterStoreCache(): void {
    sessionStorage.clear();
  }
}
