export class CustomFile {
  public id?: string;
  public tags: string[] = [];
  public departments: string[] = [];
  public file: File;
  public fileId?: string;

  constructor(file: File) {
    this.file = file;
    this.id = generateRandomId(6);
  }
}

function generateRandomId(length = 8): string {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
