/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {from, Subscription} from 'rxjs';
import * as moment from 'moment';
import {BaseService} from '../../kanso-common/core/service';
import {ErrorPopupComponent} from '../error-popup/error-popup.component';
import {PICActionsDataService} from './exported-actions-data-service';
import {IExportedActionsBatch} from './exported-actions-interface';
import {IExportedBatchDetails, IExportedForms, IExportedPicFile} from './exported-actions-details-interface';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-exported-actions-details',
  templateUrl: './exported-actions-details.component.html',
  styleUrls: ['./exported-actions-details.component.scss'],
})
export class ExportedActionsDetailsComponent implements OnInit, OnDestroy {
  @Input() currentPicBatch: IExportedActionsBatch;
  private subscriptions: Subscription = new Subscription();
  exportedDataSet: MatTableDataSource<IExportedForms[]>;
  exportedActionsDetails: IExportedBatchDetails;

  showFilter = false;
  errorMessage: string;
  loading = true;
  showPanelView: any = 'exported-actions-details';
  loggedInUser: string;
  picBatchFile: string;
  picSummaryReportLink: string;
  picBatchExportDate: string;
  errorLocation: string;
  exportedFileName: string;

  displayedColumns: string[] = ['Household', 'Action', 'Effective Date', 'Created On Date'];
  exportedActionsDataService: any;

  constructor(
    private picActionsDataService: PICActionsDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dialog: MatDialog,
    private baseService: BaseService
  ) {
    this.matIconRegistry.addSvgIconLiteral(
      'filter-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Filter_Icon" data-name="Filter Icon" xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14"><defs><style>.cls-1 {fill: #00aad4;}</style></defs><g><rect class="cls-1" x="5" width="6" height="6"/><rect id="Rectangle_9_copy" data-name="Rectangle 9 copy" class="cls-1" x="13" y="2" width="8" height="2"/><rect id="Rectangle_9_copy_2" data-name="Rectangle 9 copy 2" class="cls-1" y="2" width="3" height="2"/></g><g id="Group_1_copy" data-name="Group 1 copy"><rect class="cls-1" x="10" y="8" width="6" height="6"/><rect id="Rectangle_9_copy-2" data-name="Rectangle 9 copy" class="cls-1" y="10" width="8" height="2"/><rect id="Rectangle_9_copy_2-2" data-name="Rectangle 9 copy 2" class="cls-1" x="18" y="10" width="3" height="2"/></g></svg>'
      )
    );
    this.matIconRegistry.addSvgIconLiteral(
      'download-icon-blue',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Download_Icon" data-name="Download Icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#5CA3D4"><g><rect fill="none" height="24" width="24"/><path d="M12,4c4.41,0,8,3.59,8,8s-3.59,8-8,8s-8-3.59-8-8S7.59,4,12,4 M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10 c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2L12,2z M13,12l0-4h-2l0,4H8l4,4l4-4H13z"/></g></svg>'
      )
    );
    this.matIconRegistry.addSvgIconLiteral(
      'download-icon',
      this.domSanitizer.bypassSecurityTrustHtml(
        '<svg id="Download_Icon" data-name="Download Icon" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffa500"><g><rect fill="none" height="24" width="24"/><path d="M12,4c4.41,0,8,3.59,8,8s-3.59,8-8,8s-8-3.59-8-8S7.59,4,12,4 M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10 c5.52,0,10-4.48,10-10C22,6.48,17.52,2,12,2L12,2z M13,12l0-4h-2l0,4H8l4,4l4-4H13z"/></g></svg>'
      )
    );
  }

  async ngOnInit() {
    this.subscriptions.add(
      from(await this.picActionsDataService.getExportedActionsDetails(this.currentPicBatch.picBatchId)).subscribe({
        next: exportedActionsDetailsData => {
          this.exportedActionsDetails = exportedActionsDetailsData;
          this.exportedDataSet = new MatTableDataSource(this.exportedActionsDetails.exportedForms);
          this.exportedFileName = this.exportedActionsDetails.exportedFileName;
          this.picSummaryReportLink = this.builtPICBatchItemLink();
          this.loading = false;
        },
        error: err => {
          this.errorMessage = err;
          this.errorPopup();
        },
      })
    );
  }

  builtPICBatchItemLink(): string {
    const reportingSubDomain = this.baseService.getReportingSubDomain();
    return `${window.location.protocol}//${window.location.host
      .replace(':3000', '')
      .replace('.app', '')
      .replace('.hdsdoorways', `.${reportingSubDomain}.hdsdoorways`)}/reports/Community/All.Site.Community.PIC%20Batch%20Itemization?c=${
      this.picActionsDataService.customerId
    }&s=${this.picActionsDataService.siteId}&batchId=${this.currentPicBatch.picBatchId}`;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  async getExportedBatchById(batchId) {
    this.getCurrentUsersLogInCookie();
    const batchRequest = {
      batchId: batchId,
      user: '',
    };

    batchRequest.user = this.loggedInUser;
    console.log('batch request', batchRequest);

    this.subscriptions.add(
      from(await this.picActionsDataService.getExportedBatchById(batchRequest)).subscribe({
        next: (data: any) => {
          const downloadUrl = window.URL.createObjectURL(data);
          const link = document.createElement('a');

          link.href = downloadUrl;
          link.download = this.exportedFileName;

          link.click();
        },
        error: err => {
          this.errorMessage = err;
          console.log('error message: ', this.errorMessage);
          this.errorPopup();
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  errorPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {errorMessage: this.errorLocation};
    this.dialog.open(ErrorPopupComponent, dialogConfig);
  }
}
