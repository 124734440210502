import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatHorizontalStepper} from '@angular/material/stepper';
import moment from 'moment';
import {CoreService} from 'src/app/core/service/core.service';
import {stepInfo} from 'src/app/custom/accounting/service/accounting-models';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {FeatureConfigurationService} from 'src/app/shared/services';
import {VoucherService} from '../vouchers-services/vouchers.service';
import {AccountingService} from 'src/app/custom/accounting/service/accounting.service';
import {debounceTime, distinctUntilChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {VendorTypes} from 'src/app/custom/housing-core/services/housing-models';
import {vendorNode} from 'src/app/custom/vendor/vendor-model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-vouchers-issue-wizard',
  templateUrl: './vouchers-issue-wizard.component.html',
  styleUrls: ['./vouchers-issue-wizard.component.scss'],
})
export class VouchersIssueWizardComponent implements GlobalModalChild<any>, OnInit, AfterViewInit {
  @ViewChild('stepper') stepper: MatHorizontalStepper;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  enableSetup2: boolean;
  documentId = null;
  filteredUnitOptions;
  filteredHaVendorOptions$: Observable<vendorNode[]>;
  formIsRequired = false;
  fundingprogram = null;
  fundingPrograms: any[];
  fundingProjects: any[];
  globalModalParent: GlobalModalParent;
  householdId;
  issueVoucherAction = 'issuance of voucher';
  has58ValidationErrors = false;
  landlordName = '';
  loggedInUser: string;
  minDate: Date = new Date();
  programs: any[];
  siteId: string;
  submitted = false;
  stepWizardInfo: stepInfo[] = [
    {stepHeader: 'Issue Voucher', stepTitle: 'Issue Voucher'},
    {stepHeader: 'Form', stepTitle: 'Form'},
  ];
  templateId: string;
  userPermissions = [];
  voucherForm: FormGroup;
  voucherSpecialTypeList: string[];
  voucherTypeList: string[];
  voucherPortabilityTypeList: string[];

  currentFormTemplateId: {unit: any; household: string; action: string; templateId: string};
  recentlyCreatedForm: any;
  actionRequest: {
    currentAction: {
      action: string;
      effectiveDate: string;
      unit: any;
      voucherBedroomSize: number;
      createdOn: string;
      createdBy: string;
      term: {
        details: {
          household: string;
          effectiveDate: string;
          enhancedVoucherToggle: boolean;
          enhancedMinimumRentValue: number;
        };
      };
    };
    additionalActions: any;
    rentCalc: any;
    siteId: string;
    templateId: string;
    household: string;
  };

  constructor(
    private accountingService: AccountingService,
    private cd: ChangeDetectorRef,
    private coreService: CoreService,
    private featureConfigService: FeatureConfigurationService,
    private formBuilder: FormBuilder,
    private voucherService: VoucherService,
    private vendorService: VendorService
  ) {
    this.voucherTypeList = ['Tenant Based', 'Project Based', 'Homeownership'];
    this.voucherPortabilityTypeList = ['Not Applicable', 'Port In', 'Port Out'];
    this.voucherSpecialTypeList = [
      'NONE',
      'DHAPK',
      'DVIKE',
      'FUPF',
      'FUPY',
      'FYITPV',
      'KATHU',
      'LIT',
      'MS5',
      'MTO',
      'NED',
      'NHT',
      'PHRR',
      'RADPH',
      'RADMR',
      'RADRP',
      'RADRS',
      'ROC',
      'ROSS',
      'TCU',
      'VASH',
    ];
  }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    this.checkConfiguration();
    this.siteId = sessionStorage.getItem('SITEID');

    this.voucherForm = this.formBuilder.group({
      fundingProgram: null,
      fundingProject: null,
      voucherType: this.voucherTypeList[0],
      specialType: this.voucherSpecialTypeList[0],
      voucherNumber: null,
      portabilityType: this.voucherPortabilityTypeList[0],
      authorizedBedrooms: null,
      issuedOn: new Date(
        moment()
          .startOf('day')
          .toDate()
      ),
      expiresOn: null,
      issuedDays: null,
      unit: null,
      enhancedVoucher: false,
      protectionVoucher: false,
      enhancedMinimumRent: null,
      portabilityVendor: null,
    });
    this.fundingPrograms = this.programs;

    this.filteredHaVendorOptions$ = this.voucherForm.controls.portabilityVendor.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(value => value && value.length >= 3),
      distinctUntilChanged(),
      switchMap((value: string) => this.vendorService.getVendorsV2(50, '', 'forward', value, VendorTypes.Housingauthority))
    );
  }

  vendorSelectDisplay(option: vendorNode): string {
    return option?.name || '';
  }

  removeVendor() {
    this.voucherForm.patchValue({['portabilityVendor']: null});
  }

  refetchUnits() {
    // only start searching if the string length is greater or equal to 3
    if (this.voucherForm.value.unit.length >= 3) {
      this.voucherService.getUnits(this.siteId, this.voucherForm.value.unit).subscribe((result: any) => {
        this.filteredUnitOptions = result.data.units.edges;
        this.filteredUnitOptions = this.filteredUnitOptions.filter(x => x.node.landlordId);
      });
    }
  }

  async fetchLandlord(landlordId) {
    if (this.enableSetup2) {
      await this.vendorService.getSelectedVendor(landlordId).subscribe((result: any) => {
        this.getLandlordName(result);
      });
    } else {
      await this.voucherService.getLandlordInfo(landlordId).subscribe((result: any) => {
        this.getLandlordName(result);
      });
    }
  }

  getLandlordName(landlord) {
    if (landlord.companyName) {
      this.landlordName = landlord.companyName;
    } else {
      this.landlordName = `${landlord.firstName} ${landlord.lastName}`;
    }
  }

  getOptionText(option) {
    if (!option) {
      return '';
    }
    let returnAddress = option.node.streetAddress;
    if (option.node.apartmentNumber != null) {
      returnAddress += ` Apt: ${option.node.apartmentNumber}`;
    }
    if (option.node.unitNumber != null) {
      returnAddress += ` Unit: ${option.node.unitNumber}`;
    }

    return returnAddress;
  }

  removeUnit() {
    this.voucherForm.patchValue({['unit']: null});
  }

  checking58ValidationErrors(event) {
    this.has58ValidationErrors = event;
  }

  createdDocumentId(event) {
    this.documentId = event.documentId;
  }

  getProjects() {
    this.voucherForm.value.fundingProject = null;
    const selectedProgram = this.voucherForm.value?.fundingProgram;
    this.fundingProjects = selectedProgram.projects;
  }

  preFillVoucherNumber(): void {
    const projectId = this.voucherForm.value.fundingProject.id;
    this.voucherService.getVoucherTotalInProject(projectId).subscribe((result: any) => {
      this.voucherForm.controls.voucherNumber.setValue(
        `${this.voucherForm.value.fundingProgram.programCode}-${this.voucherForm.value.fundingProject.projectCode}-${result.data
          .voucherCountByProject.count + 1}`
      );
    });
  }

  updateIssuedDays() {
    this.minDate = new Date(this.voucherForm.controls.issuedOn.value);
    if (typeof this.voucherForm.controls.issuedOn.value == 'undefined') {
      return;
    }
    if (typeof this.voucherForm.controls.expiresOn.value == 'undefined') {
      return;
    }

    const date1 = moment(this.voucherForm.controls.issuedOn.value);
    const date2 = moment(this.voucherForm.controls.expiresOn.value);

    if (date1.isSameOrAfter(date2)) {
      return;
    }
    const diff = date2.diff(date1, 'days');
    this.voucherForm.controls.issuedDays.setValue(diff);
  }

  updateExpiredDate() {
    if (typeof this.voucherForm.controls.issuedOn.value == 'undefined') {
      return;
    }
    if (typeof this.voucherForm.controls.expiresOn.value == 'undefined') {
      return;
    }

    const issued = moment(this.voucherForm.controls.issuedOn.value);
    issued.add(this.voucherForm.controls.issuedDays.value, 'days');
    this.voucherForm.controls.expiresOn.setValue(new Date(issued.format('MM/DD/YYYY')));
  }

  async getActionForms(): Promise<void> {
    const actionFormQuery = {
      action: this.issueVoucherAction,
      program: this.voucherForm.value?.fundingProgram.id,
      project: this.voucherForm.value?.fundingProject.id,
    };
    await new Promise<void>((resolve, reject) => {
      this.accountingService.getActionForms(actionFormQuery).subscribe(res => {
        //Action forms are not used by all clients
        if (res.statusCode !== 200) {
          resolve();
        } else if (res.statusCode === 200) {
          // Only one form is displayed currently, choosing 58 form specifically
          const actionForm58Config = res.body.find(form => form.formName == 'HUD 50058');
          if (actionForm58Config) {
            this.templateId = actionForm58Config.docReferenceId;
          }
          resolve();
        } else {
          this.coreService.displayError(
            `Error retrieving action form configuration: ${res.message}`,
            null,
            'Error retrieving action form configuration'
          );
          reject(new Error(`Error retrieving action form configuration: ${res.message}`));
        }
      });
    });
  }

  setActionRequest() {
    const formattedEffDate = moment(this.voucherForm.value.issuedOn).format('YYYY/MM/DD');
    this.actionRequest = {
      currentAction: {
        action: this.issueVoucherAction,
        effectiveDate: formattedEffDate,
        unit: this.voucherForm.value.unit ? this.voucherForm.value.unit.node.id : null,
        voucherBedroomSize: this.voucherForm.value.authorizedBedrooms,
        createdOn: moment(new Date()).toISOString(),
        createdBy: this.loggedInUser,
        term: {
          details: {
            household: this.householdId,
            effectiveDate: formattedEffDate,
            enhancedVoucherToggle: this.voucherForm.value.enhancedVoucher,
            enhancedMinimumRentValue: this.voucherForm.value.enhancedMinimumRent,
          },
        },
      },
      additionalActions: null,
      rentCalc: null,
      siteId: this.siteId,
      templateId: this.templateId,
      household: this.householdId,
    };
  }

  setCurrentFormTemplateId() {
    this.currentFormTemplateId = {
      unit: this.voucherForm.value.unit ? this.voucherForm.value.unit.node.id : null,
      household: this.householdId,
      action: this.issueVoucherAction,
      templateId: this.templateId,
    };
  }

  async savePendingForm() {
    try {
      const customerId = sessionStorage.getItem('CUSTOMERID');
      const url = sessionStorage.getItem('FORMS_SERVICE_URI');
      const secret = sessionStorage.getItem('FORMS_SERVICE_API_KEY');

      const urlAndSecret = {
        url: url + `api/forms/tic/${this.siteId}/${this.householdId}`,
        secret,
      };

      fetch(urlAndSecret.url, {
        method: 'post',
        headers: {
          'x-api-key': urlAndSecret.secret,
          'Content-Type': 'application/json',
          'x-site-id': this.siteId,
          'x-customer-id': customerId,
        },
        body: JSON.stringify({
          dataDocumentId: this.documentId,
          templateId: this.actionRequest.templateId,
          data: '',
          metadata: {
            user: this.actionRequest.currentAction.createdBy,
            action: this.actionRequest.currentAction.action,
            unitId: this.actionRequest.currentAction.unit,
            requestedDocumentName: await this.constructDocumentName(),
            actionId: null,
          },
        }),
      }).then((response: Response) => {
        if (!response.ok) {
          throw new Error('There was an error saving issuance of voucher');
        }
      });
    } catch (error) {
      console.log('There was an error saving issuance of voucher', error);
    }
  }

  constructDocumentName() {
    const templateName = 'HUD-50058';
    const action = this.actionRequest.currentAction.action.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });

    let todaysDate: Date | string = new Date();
    let todaysMonth = (1 + todaysDate.getMonth()).toString();
    todaysMonth = parseInt(todaysMonth) < 10 ? '0' + todaysMonth : todaysMonth;
    let todaysDay = todaysDate.getDate().toString();
    todaysDay = parseInt(todaysDay) < 10 ? '0' + todaysDay : todaysDay;
    todaysDate = `${todaysMonth}-${todaysDay}-${todaysDate.getFullYear()}`;

    return `${todaysDate}-${templateName}-${action}`;
  }

  async goToNextStep(): Promise<void> {
    const nextStep = this.stepper.selectedIndex + 1;
    if (nextStep == 1) {
      this.stepper.selectedIndex = nextStep;
      if (this.voucherForm.value.voucherType == 'Tenant Based') {
        await this.getActionForms();
        if (!this.templateId) {
          this.savePendingForm();
        } else {
          this.recentlyCreatedForm = null;
          this.setCurrentFormTemplateId();
          this.setActionRequest();
          this.formIsRequired = true; // Initiates form prefill
        }
      }
    } else {
      if (this.voucherForm.value.voucherType == 'Tenant Based') {
        this.savePendingForm();
      }
      this.updateFunction(this.voucherForm.value);
      this.globalModalParent.closePopup();
    }
  }

  onCancelIssueVoucher(): void {
    this.globalModalParent.closePopup();
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  onCheckDisableNext(stepper: MatHorizontalStepper) {
    if (stepper.selectedIndex === 0) {
      if (!this.voucherForm.valid) {
        return true;
      }
    }
    return false;
  }

  checkConfiguration() {
    this.featureConfigService.getConfig('enableNewSetup').subscribe(
      configObject => {
        this.enableSetup2 = configObject.response.value;
      },
      error => {
        console.log('there was an error checking setup 2.0 configuration', error);
      }
    );
  }
}
