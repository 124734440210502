/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {ParameterStoreService, ParameterStoreVariables} from 'src/app/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {
  responseObject,
  responseVendorData,
  vendorData,
  vendorNode,
  vendorEdges,
  qqlPageInfo,
  VendorLedgerPayoutHistory,
  VendorSortField,
} from './vendor-model';
import {FetchResult} from '@apollo/client/link/core/types';
import {UUID} from 'aws-sdk/clients/fsx';
import {
  IVendor,
  IVendorAch,
  ILandlordAddress,
  Vendor,
  VendorAch,
  VendorTypes,
  VendorUpdateCommand,
  VendorAddressUpdateCommand,
  VendorAddressCreateCommand,
  VendorCreateCommand,
  VendorAchUpdateCommand,
  VendorAchCreateCommand,
  VendorNote,
} from '../housing-core/services/housing-models';
import {isEmpty, groupBy, sumBy} from 'lodash';
import {ComplexSaveResult, Note} from 'src/app/core/service/core-models';
import _ from 'lodash';
import {CoreService} from 'src/app/core/service/core.service';
import {VendorTransaction} from '../accounting/service/accounting-models';
import {UtilityAllowance} from 'src/app/kanso-common/core/service/core-models';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import {InvariantError} from '@apollo/client/utilities/globals';
import {Interface} from 'readline';
import {ICON_REGISTRY_PROVIDER_FACTORY} from '@angular/material/icon';
import {getApolloCacheMemoryInternals} from '@apollo/client/utilities/caching/getMemoryInternals';

type vendorModel = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  payTo: string;
  vendorType: string;
  companyName: string;
  phoneNumber: string;
  emailAddress: string;
  taxId: string;
  externalReferenceId: string;
  paymentPreference: string;
  addresses: [];
  notes: [];
  vendorAch: IVendorAch;
  phaCode: string;
};
type vendorDetailEdges = {
  node: vendorModel;
};
type vendorDetail = {
  __typename: string;
  edges: vendorDetailEdges[];
  pageInfo: qqlPageInfo;
};
type responseVendorDetail = {
  vendors: vendorDetail;
  loading: boolean;
  networkStatus: number;
};
type responseLandlordDetail = {
  landlords: vendorDetail;
  loading: boolean;
  networkStatus: number;
};
type responseVendorObject = {
  data: responseVendorDetail;
  loading: boolean;
  networkStatus: number;
};
type responseLandlordObject = {
  data: responseLandlordDetail;
  loading: boolean;
  networkStatus: number;
};
type vendorOverview = {
  __typename: string;
  name: string;
  vendorType: string;
  address: string;
  vendorId: string;
  cursor: any;
};

enum VendorType {
  Landlord = 0,
  Utility = 1,
  Other = 2,
  Maintenance = 3,
  Housingauthority = 4,
}

export enum PaymentPreference {
  ACH = 0,
  Check = 1,
  Other = 2,
}

export type vendorPayableViewObject = {
  __typename: string;
  vendorId: string;
  vendorName: string;
  vendorType: number;
  currentCharges: number;
  numberOfTransactions: number;
  lastPayment: number;
  balanceToBePaid: number;
  details?: any;
  expanded?: boolean;
  selected?: boolean;
  indeterminate?: boolean;
  paymentPreference?: number;
};

export type PayableObjectWizardView = Pick<vendorPayableViewObject, 'vendorName' | 'balanceToBePaid' | 'lastPayment'>;

export type PayableReviewObjectView = {
  type: string;
  numberofPayments: number;
  amountToBePaid: number;
};

export interface BatchHistoryData {
  batchId: number;
  date: string;
  numberOfPayments: number;
  amount: number;
  createdBy: string;
  posted: boolean;
}

export interface PayablesDetail {
  payTo: string;
  lastPaymentAmount: number;
  balanceToBePaid: number;
}

export interface PostedOn {
  date: Date;
  description: string;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface VoidOn {
  transaction: VendorTransaction;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface IdEmmitter {
  id: string | number;
  processType: ProcessType;
}
export enum ProcessType {
  Payables = 0,
  Transaction = 1,
}
export interface IDataResponse {
  status: string;
  failureReason?: string;
}
export interface IUpdateVendorDataResponse {
  updateVendor: IVendorDataResponse;
}
export interface ICreateVendorDataResponse {
  createVendor: IVendorDataResponse;
}
export interface IVendorDataResponse extends IDataResponse {
  affectedEntity: IVendor;
}
export interface IVendorAddressDataResponse extends IDataResponse {
  affectedEntity: ILandlordAddress;
}
export interface IUpdateVendorAddressDataResponse {
  updateVendorAddress: IVendorAddressDataResponse;
}
export interface ICreateVendorAddressDataResponse {
  createVendorAddress: IVendorAddressDataResponse;
}
export interface IVendorAchDataResponse extends IDataResponse {
  affectedEntity: IVendorAch;
}
export interface IUpdateVendorAchDataResponse {
  updateVendorAch: IVendorAchDataResponse;
}
export interface ICreateVendorAchDataResponse {
  createVendorAch: IVendorAchDataResponse;
}

@Injectable()
export class VendorService extends BaseService {
  private vendorDataSubject = new Subject<any>();
  private vendorNoteSubject = new BehaviorSubject<VendorNote[]>([]);
  apollo: Apollo;
  _http: any;
  constructor(public http: HttpClient, public coreService: CoreService, private apolloProvider: Apollo) {
    super(http);
    this.apollo = this.apolloProvider;
    const vendorHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    const existingOccupancyBase = this.apollo.use('occupancy');
    if (!existingOccupancyBase) {
      this.apollo.create(
        {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...vendorHeaders}},
        'occupancy'
      );
    }
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...vendorHeaders}},
      'vendor'
    );
  }
  envDat = {
    siteId: sessionStorage.getItem('SITEID'),
    customerId: sessionStorage.getItem('CUSTOMERID'),
  };
  sendVendor(vendor: Vendor) {
    this.vendorDataSubject.next(vendor);
  }

  getVendor() {
    return this.vendorDataSubject.asObservable();
  }

  sendVendorNotes(vendorNote: VendorNote[], isUpdated: boolean) {
    let notes;
    this.vendorNoteSubject.subscribe(result => {
      notes = result;
    });
    if (isUpdated) {
      this.vendorNoteSubject.next(vendorNote);
    } else {
      if (vendorNote.length > notes.length) {
        this.vendorNoteSubject.next(vendorNote);
      } else {
        this.vendorNoteSubject.next(notes);
      }
    }
  }

  //// move these functions when the change of 2574 come in
  getAllUtilityAllowances<T>(siteId: string, customerId: string, searchText: string): Observable<UtilityAllowance[]> {
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryUtilityAllowances(searchText),
        variables: {
          siteId,
          customerId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((reponse: any) => {
          if (reponse.data.utilityAllowance.length == 0 && searchText == '') {
            const data = [
              {
                id: null,
                siteId: null,
                customerId: null,
                name: 'There are no unit types setup.',
              },
            ];
            return data;
          }
          return reponse.data.utilityAllowance;
        })
      );
  }

  getUtilityAllowance<T>(utilityAllowanceId: string): Observable<UtilityAllowance> {
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryUtilityAllowance(),
        variables: {
          utilityAllowanceId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((reponse: any) => {
          if (reponse.data.utilityAllowance.length > 0) {
            return reponse.data.utilityAllowance[0];
          } else {
            return null;
          }
        })
      );
  }

  private buildUtilityWhereClause(filter: string) {
    let returnValue = '';
    if (filter.length > 0) {
      returnValue = 'where: { or: [';
      returnValue += `{ name: { contains: "${filter}"}} ]}`;
    }
    return returnValue;
  }

  queryUtilityAllowances(searchText: any) {
    let whereFilter;
    if (searchText && typeof searchText == 'string') {
      whereFilter = this.buildUtilityWhereClause(searchText.trim());
    } else {
      whereFilter = this.buildUtilityWhereClause('');
    }
    return gql`
      query UtilityAllowance {
        utilityAllowance(order: [{name: ASC}], ${whereFilter}) {
          id
          customerId
          siteId
          name
          createdOn
          createdBy
          modifiedOn
          modifiedBy
        }
      }
    `;
  }

  queryUtilityAllowance() {
    return gql`
      query UtilityAllowance($utilityAllowanceId: UUID) {
        utilityAllowance(where: {id: {eq: $utilityAllowanceId}}) {
          id
          customerId
          siteId
          name
          createdOn
          createdBy
          modifiedOn
          modifiedBy
        }
      }
    `;
  }

  getVendorNotes() {
    return this.vendorNoteSubject.asObservable();
  }

  getVendorTransactions<T>(siteId: string, vendorId: UUID): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorTransactions(),
        variables: {
          siteId,
          vendorId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            data.push(edge.node);
          }
          return [data, response.pageInfo];
        })
      );
  }
  getVendorRecurringTransactions<T>(vendorId: string): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorRecurringTransactions(),
        variables: {
          vendorId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorRecurringTransactions.edges) {
            data.push(edge);
          }
          return [data, response.data.vendorRecurringTransactions.pageInfo];
        })
      );
  }
  getProgram<T>(siteId: string, programId: string): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryPrograms(),
        variables: {
          siteId,
          programId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.programs.edges) {
            data.push(edge.node);
          }
          return response.data.programs.edges;
        })
      );
  }
  getVendorAccounts<T>(siteId: string, vendorId: UUID): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorAccounts(),
        variables: {
          siteId,
          vendorId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorAccounts.edges) {
            data.push(edge.node);
          }
          return [response.data.vendorAccounts.edges, response.data.vendorAccounts.pageInfo];
        })
      );
  }
  getVendorAccountSpecificTransactions<T>(
    siteId: string,
    vendorId: UUID,
    vendorAccountId: UUID,
    cursor: string,
    direction: string,
    pageSize: number
  ): Observable<any> {
    const data = [];
    let pageInfo: qqlPageInfo;
    let totalCount: number;
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorAccountSpecificTransactions(cursor, direction, pageSize),
        variables: {
          siteId,
          vendorId,
          vendorAccountId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            data.push(edge.node);
          }
          pageInfo = response.data.vendorTransactions.pageInfo;
          totalCount = response.data.vendorTransactions.totalCount;
          return [data, pageInfo, totalCount];
        })
      );
  }
  getVendorBatchPayments<T>(siteId: string, vendorId: UUID, batchId: number): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorBatchPayment(),
        variables: {
          siteId,
          vendorId,
          batchId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  getVendorTransactionsWithDateRange<T>(siteId: string, vendorId: UUID, minDate: Date, maxDate: Date): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorTransactionsWithDateRange(),
        variables: {
          siteId,
          vendorId,
          minDate,
          maxDate,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            data.push(edge.node);
          }
          return [data, response.pageInfo];
        })
      );
  }
  getVendorAccountSpecificTransactionsWithDateRange<T>(
    siteId: string,
    vendorId: UUID,
    vendorAccountId: UUID,
    minDate: Date,
    maxDate: Date,
    cursor: string,
    direction: string,
    pageSize: number
  ): Observable<any> {
    const data = [];
    let pageInfo: qqlPageInfo;
    let totalCount: number;
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorAccountSpecificTransactionsWithDateRange(cursor, direction, pageSize),
        variables: {
          siteId,
          vendorId,
          vendorAccountId,
          minDate,
          maxDate,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            data.push(edge.node);
          }
          pageInfo = response.data.vendorTransactions.pageInfo;
          totalCount = response.data.vendorTransactions.totalCount;
          return [data, pageInfo, totalCount];
        })
      );
  }
  /* getVendorLedgerHistoricalPayout(siteId: string, vendorId: string, cursor: string, direction: string, pageSize: number) {
    const data: VendorLedgerPayoutHistory[] = [];
    let pageInfo: qqlPageInfo;
    let totalCount: number;
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorLedgerHistoricalPayoutView(cursor, direction, pageSize),
        variables: {
          siteId,
          vendorId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorLedgerHistoricalPayoutView.edges) {
            data.push(edge.node);
          }
          pageInfo = response.data.vendorLedgerHistoricalPayoutView.pageInfo;
          totalCount = response.data.vendorLedgerHistoricalPayoutView.totalCount;
          return {data, pageInfo, totalCount};
        })
      );
  }*/
  getSubledgerChargeTypes<T>(siteId: string, id: string): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.querySubledgerChargeTypes(),
        variables: {
          siteId,
          id,
        },
      })
      .pipe(
        map((response: any) => {
          return [response];
        })
      );
  }
  //Check Here
  getVendorLedgerHistoricalPayout(siteId: string, vendorId: string, cursor: string, direction: string, pageSize: number) {
    const data: VendorLedgerPayoutHistory[] = [];
    let pageInfo: qqlPageInfo;
    let totalCount: number;
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorLedgerHistoricalPayoutView(cursor, direction, pageSize),
        variables: {
          siteId,
          vendorId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorLedgerHistoricalPayoutView.edges) {
            data.push(edge.node);
          }
          pageInfo = response.data.vendorLedgerHistoricalPayoutView.pageInfo;
          totalCount = response.data.vendorLedgerHistoricalPayoutView.totalCount;
          return {data, pageInfo, totalCount};
        })
      );
  }

  getVendorPayablesView<T>(siteId: string, filter, programFilter, preferenceFilter): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorPayablesView(filter, programFilter, preferenceFilter),
        fetchPolicy: 'no-cache',
        variables: {
          siteId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.payableVendorView.edges) {
            const viewObject: vendorPayableViewObject = {
              ...edge.node,
              balanceToBePaid: edge.node.currentCharges,
              details: null,
              expanded: false,
            };
            data.push(viewObject);
          }
          return [data];
        })
      );
  }

  getVendorPayablesTransactions<T>(siteId: string, vendorId: UUID): Observable<any> {
    let data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorPayablesTransactions(),
        fetchPolicy: 'no-cache',
        variables: {
          siteId,
          vendorId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.vendorTransactions.edges) {
            const transactionObject = {
              ...edge.node,
              tenantName: edge.node.headLastName ? `${edge.node.headLastName}, ${edge.node.headFirstName}` : 'Vacant',
            };
            data.push(transactionObject);
          }
          data = _.sortBy(data, ['tenantName', 'postedOn']);
          return [data, response.pageInfo];
        })
      );
  }

  getVendorPayablesHistoricBatches<T>(siteId: string, cursor: string, direction: string, pageSize: number): Observable<any> {
    const data = [];
    let pageInfo: qqlPageInfo;
    let totalCount: number;
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorPayablesHistoricBatches(cursor, direction, pageSize),
        fetchPolicy: 'no-cache',
        variables: {
          siteId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.payableHistoricBatches.edges) {
            data.unshift(edge.node);
          }
          pageInfo = response.data.payableHistoricBatches.pageInfo;
          totalCount = response.data.payableHistoricBatches.totalCount;
          return {data, pageInfo, totalCount};
        })
      );
  }

  getVendorChecks<T>(siteId: string): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorChecks(),
        variables: {
          siteId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          if (response.data.vendorTransactions.edges.length > 0) {
            for (const edge of response.data.vendorTransactions.edges) {
              data.push(+edge.node.checkNumber);
            }
          }
          return data;
        })
      );
  }

  getBatchedTransactionsWithVendorNames<T>(siteId: string, batchId: number): Observable<any> {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryBatchedTransactionsWithVendorNames(),
        fetchPolicy: 'no-cache',
        variables: {
          siteId,
          batchId,
        },
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.batchedTransactionsWithVendorNames.edges) {
            data.push(edge.node);
          }
          const groupedData = _.chain(data)
            .groupBy('paymentPreference')
            .sortBy('vendorName')
            .map((value) => ({
              paymentPreference: PaymentPreference[value[0].paymentPreference],
              totalAmount: _.sumBy(value, 'amount'),
              numberOfPayments: value.length,
              transactions: value.map(t => ({
                payTo: t.vendorName,
                vendorId: t.vendorId,
                lastPaymentAmount: t.prevAmount,
                balanceToBePaid: t.amount,
                checkNumber: t.checkNumber,
                postedOn: t.postedOn,
                voidedOn: t.voidedOn,
                sequence: t.sequence,
                isOrganization: t.isOrganization,
              })),
            }))

            .value();
          return groupedData;
        })
      );
  }

  queryVendorPayablesView(filter: string[], programFilter: string[], preferenceFilter: string[]) {
    const whereType = [];
    for (const typeString of filter) {
      switch (typeString) {
        case VendorType[VendorType.Landlord]:
          whereType.push(`{vendorType: {eq: ${VendorType.Landlord}}}`);
          break;
        case VendorType[VendorType.Utility]:
          whereType.push(`{vendorType: {eq: ${VendorType.Utility}}}`);
          break;
        case VendorType[VendorType.Maintenance]:
          whereType.push(`{vendorType: {eq: ${VendorType.Maintenance}}}`);
          break;
        case VendorType[VendorType.Other]:
          whereType.push(`{vendorType: {eq: ${VendorType.Other}}}`);
          break;
        case VendorType[VendorType.Housingauthority]:
          whereType.push(`{vendorType: {eq: ${VendorType.Housingauthority}}}`);
          break;
        default:
          break;
      }
    }
    const whereProgram = [];
    for (const program of programFilter) {
      whereProgram.push(`{programId: {eq: "${program}"}},`);
    }
    const wherePreference = [];
    for (const preference of preferenceFilter) {
      switch (preference) {
        case PaymentPreference[PaymentPreference.ACH]:
          wherePreference.push(`{paymentPreference: {eq: ${PaymentPreference.ACH}}}`);
          break;
        case PaymentPreference[PaymentPreference.Check]:
          wherePreference.push(`{paymentPreference: {eq: ${PaymentPreference.Check}}}`);
          break;
        case PaymentPreference[PaymentPreference.Other]:
          wherePreference.push(`{paymentPreference: {eq: ${PaymentPreference.Other}}}`);
          break;
        default:
          break;
      }
    }
    const whereFilter = `{
      siteId: {eq: $siteId},
      and:[
            { or: [${whereType}] },
            { or: [${whereProgram}] },
            { or: [${wherePreference}] },
          ]
    }`;
    return gql`
      query PayableVendorView($siteId: String) {
        payableVendorView(first: 200, where: ${whereFilter}) {
          edges {
            cursor
            node {
              vendorId
              vendorName
              vendorType
              siteId
              paymentPreference
              currentCharges
              numberOfAccounts
              numberOfTransactions
              lastPayment
              programId
            }
          }
        }
      }
    `;
  }

  queryVendorPayablesTransactions() {
    return gql`
      query QueryVendorTransactions($siteId: String, $vendorId: UUID) {
        vendorTransactions(
          first: 50
          where: {siteId: {eq: $siteId}, vendorId: {eq: $vendorId}, batchId: {eq: null}, isPayment: {neq: true}, voidedOn: {eq: null}}
        ) {
          edges {
            node {
              id
              vendorAccountId
              amount
              checkNumber
              ppuCode
              headFirstName
              headLastName
              createdBy
              createdOn
              postedOn
              postedBy
              subLedgerMappingName
              chargeTypeName
              checkNumber
              sequence
              vendorAccountName
              voidedBy
              voidedOn
              vendorId
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }

  queryVendorChecks() {
    return gql`
      query VendorTransactions($siteId: String) {
        vendorTransactions(where: {siteId: {eq: $siteId}, checkNumber: {neq: null}}) {
          edges {
            node {
              id
              checkNumber
            }
          }
        }
      }
    `;
  }

  queryVendorPayablesHistoricBatches(cursor = '', direction = 'forward', pageSize: number) {
    const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
    const cursorFilter = isEmpty(cursor) ? '' : filter;
    const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
    return gql`
      query PayableHistoricBatches($siteId: String) {
        payableHistoricBatches(
            where: {siteId: {eq: $siteId}}
            ${pageSizeFilter},
            ${cursorFilter}
          ) {
          edges {
            node {
              batchId
              siteId
              customerId
              createdOn
              numberOfTransactions
              amount
              createdBy
              postedOn
            }
            cursor
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          totalCount
        }
      }
    `;
  }

  queryBatchedTransactionsWithVendorNames() {
    return gql`
      query BatchedTransactionsWithVendorNames($siteId: String, $batchId: Long) {
        batchedTransactionsWithVendorNames(where: {siteId: {eq: $siteId}, batchId: {eq: $batchId}}) {
          edges {
            node {
              id
              batchId
              siteId
              customerId
              vendorId
              vendorName
              amount
              prevAmount
              paymentPreference
              checkNumber
              postedOn
              createdOn
              createdBy
              voidedOn
              sequence
              isOrganizationInt
              isOrganization
            }
          }
        }
      }
    `;
  }

  postPayableTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation PostPayable($command: PostPayableCommandInput!) {
          postPayable(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  voidPayableTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation VoidPayableTransactions($command: VoidPayableTransactionCommandInput!) {
          voidPayableTransactions(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  voidVendorTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation VoidVendorTransaction($command: VoidVendorTransactionCommandInput!) {
          voidVendorTransaction(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  queryPrograms() {
    return gql`
      query QueryPrograms($programId: String) {
        programs(first: 1, where: {id: {eq: $programId}}) {
          edges {
            cursor
            node {
              id
              name
              programType
              programCode
            }
          }
        }
      }
    `;
  }

  queryVendorTransactions() {
    return gql`
      query QueryVendorTransactions($vendorId: UUID) {
        vendorTransactions(first: 50, where: {vendorId: {eq: $vendorId}, postedOn: {neq: null}}) {
          edges {
            node {
              id
              programId
              projectId
              accountDefinitionId
              vendorAccountId
              amount
              checkNumber
              ppuCode
              headFirstName
              headLastName
              createdBy
              createdOn
              postedOn
              postedBy
              subLedgerMappingName
              chargeTypeName
              checkNumber
              sequence
              batchId
              isPayment
              vendorAccountName
              programName
              projectName
              voidedBy
              voidedOn
              vendorId
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }
  queryVendorTransactionsWithDateRange() {
    return gql`
      query QueryVendorTransactionsDateRange($siteId: String, $vendorId: UUID, $minDate: DateTime, $maxDate: DateTime) {
        vendorTransactions(first: 50, where: {siteId: {eq: $siteId}, vendorId: {eq: $vendorId}, postedOn: {gte: $minDate, lte: $maxDate}}) {
          edges {
            node {
              id
              programId
              projectId
              accountDefinitionId
              vendorAccountId
              amount
              headFirstName
              headLastName
              createdBy
              createdOn
              postedOn
              postedBy
              subLedgerMappingName
              chargeTypeName
              checkNumber
              programName
              projectName
              voidedBy
              voidedOn
              vendorId
              transactionGroupId
              ppuCode
              sequence
              batchId
              isPayment
              vendorAccountName
              hold
              holdDescription
              householdId
              checkNumber
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }
  queryVendorRecurringTransactions() {
    return gql`
      query QueryVendorRecurringTransactions($vendorId: String) {
        vendorRecurringTransactions(first: 50, where: {vendorId: {eq: $vendorId}}) {
          edges {
            node {
              siteId
              id
              name
              startOn
              completedOn
              vendorId
              vendorAccountId
              subledgerMappingId
              subledgerMappingName
              chargeTypeId
              chargeTypeName
              description
              portReimbursements
              householdId
              voucherNumber
              voucherSpecialType
              totalAmount
              contractRent
              tenantPortion
              hapAmount
              urpAmount
              adminAmount
              hardToHouseAmount
              fssEscrowAmount
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              archivedOn
              archivedBy
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }
  queryVendorAccounts() {
    return gql`
      query QueryVendorAccounts($siteId: String, $vendorId: UUID) {
        vendorAccounts(first: 50, where: {siteId: {eq: $siteId}, vendorId: {eq: $vendorId}}) {
          edges {
            node {
              id
              programId
              projectId
              accountDefinitionId
              subLedgerMappingId
              createdBy
              createdOn
              vendorId
              displayName
              name
              deletedOn
              unitId
              unit {
                ppuCode
                streetAddress
                city
                state
                postalCode
                apartmentNumber
                unitStatusChangedEvents {
                  household {
                    headOfHouseholdId
                    lastActionName
                    effectiveMoveInDate
                    members {
                      id
                      firstName
                      lastName
                      middleName
                    }
                  }
                }
              }
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }
  queryVendorAccountSpecificTransactions(cursor = '', direction = 'forward', pageSize: number) {
    const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
    const cursorFilter = isEmpty(cursor) ? '' : filter;
    const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
    return gql`
      query QueryVendorTransactions($siteId: String, $vendorAccountId: UUID) {
        vendorTransactions(where: {
          siteId: {eq: $siteId},
          vendorAccountId: {eq: $vendorAccountId}, 
          postedOn: {neq: null}
        },
        ${pageSizeFilter},
        ${cursorFilter}
        ) {
          edges {
            node {
              id
              programId
              projectId
              accountDefinitionId
              vendorAccountId
              amount
              checkNumber
              ppuCode
              headFirstName
              headLastName
              createdBy
              createdOn
              postedOn
              postedBy
              subLedgerMappingName
              chargeTypeName
              checkNumber
              sequence
              batchId
              isPayment
              vendorAccountName
              programName
              projectName
              voidedBy
              voidedOn
              vendorId
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `;
  }
  queryVendorBatchPayment() {
    return gql`
      query QueryVendorTransactions($siteId: String, $vendorId: UUID, $batchId: Long) {
        vendorTransactions(
          first: 50
          where: {siteId: {eq: $siteId}, vendorId: {eq: $vendorId}, batchId: {eq: $batchId}, isPayment: {eq: false}}
        ) {
          edges {
            node {
              id
              accountDefinitionId
              vendorAccountId
              amount
              checkNumber
              ppuCode
              headFirstName
              headLastName
              checkNumber
              sequence
              batchId
              isPayment
              vendorAccountName
              voidedBy
              voidedOn
              vendorId
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }
  queryVendorAccountSpecificTransactionsWithDateRange(cursor = '', direction = 'forward', pageSize: number) {
    const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
    const cursorFilter = isEmpty(cursor) ? '' : filter;
    const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
    return gql`
      query QueryVendorSpecificTransactionsDateRange(
        $siteId: String
        $vendorId: UUID
        $vendorAccountId: UUID
        $minDate: DateTime
        $maxDate: DateTime
      ) {
        vendorTransactions(
          where: {
            siteId: {eq: $siteId}
            vendorId: {eq: $vendorId}
            vendorAccountId: {eq: $vendorAccountId}
            postedOn: {gte: $minDate, lte: $maxDate}
          },
          ${pageSizeFilter},
          ${cursorFilter}
        ) {
          edges {
            node {
              id
              programId
              projectId
              accountDefinitionId
              vendorAccountId
              amount
              headFirstName
              headLastName
              createdBy
              createdOn
              postedOn
              postedBy
              subLedgerMappingName
              chargeTypeName
              checkNumber
              programName
              projectName
              voidedBy
              voidedOn
              vendorId
              transactionGroupId
              ppuCode
              sequence
              batchId
              isPayment
              vendorAccountName
              hold
              holdDescription
              householdId
              checkNumber
              description
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `;
  }
  querySubledgerChargeTypes() {
    return gql`
      query SubledgerMappings($siteId: String, $id: String) {
        subledgerMappings(where: {siteId: {eq: $siteId}, id: {eq: $id}}) {
          nodes {
            name
            subledgerChargeGroups {
              chargeGroup {
                id
                name
                chargeTypes {
                  name
                  id
                  chargeGroupId
                }
              }
            }
          }
        }
      }
    `;
  }

  queryVendorLedgerHistoricalPayoutView(cursor = '', direction = 'forward', pageSize: number) {
    const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
    const cursorFilter = isEmpty(cursor) ? '' : filter;
    const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
    return gql`
      query VendorLedgerHistoricalPayoutView(
        $vendorId: UUID
      ) {
        vendorLedgerHistoricalPayoutView(
          where: {
            vendorId: { eq: $vendorId },
          }
          ${pageSizeFilter},
          ${cursorFilter}
          ) {
            edges {
              node {
                  vendorId
                  batchId
                  chargeTypeName
                  postedOn
                  createdOn
                  checkNumber
                  siteId
                  customerId
                  totalAmount
                  numberOfTransactions
              }
              cursor
          }
          pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
          }
          totalCount
        }
      }
    `;
  }

  getVendorsV2(
    pageSize = 10,
    cursor = '',
    direction = 'forward',
    searchText = '',
    vendorType: VendorTypes = null
  ): Observable<vendorNode[]> {
    try {
      const data: vendorNode[] = [];
      const gqlQuery = this.searchVendors(pageSize, cursor, direction);
      return this.apollo
        .use('vendor')
        .query({
          query: this.searchVendors(pageSize, cursor, direction, searchText, vendorType),
        })
        .pipe(
          map((response: responseObject) => {
            for (const edge of response.data.vendors.edges) {
              try {
                const rawVendor = edge.node as vendorNode;
                let eachVendor: vendorNode = {
                  id: rawVendor.id,
                  vendorType: rawVendor.vendorType as VendorTypes,
                  firstName: rawVendor.firstName,
                  lastName: rawVendor.lastName,
                  companyName: rawVendor.companyName,
                  address: this.vendorAddress(rawVendor.addresses[0]),
                  externalReferenceId: rawVendor.externalReferenceId,
                  __typename: rawVendor.__typename,
                  name: isEmpty(rawVendor.companyName) ? `${rawVendor.firstName} ${rawVendor.lastName}` : rawVendor.companyName,
                  addresses: rawVendor.addresses,
                };
                eachVendor = {...rawVendor, ...eachVendor};
                data.push(eachVendor);
              } catch (error) {
                console.log(error);
              }
            }
            return this.coreService.sortByProperty(data, 'name');
          })
        );
    } catch (error) {
      console.log(error);
    }
  }

  getVendorV2(vendorId: UUID): Observable<vendorNode> {
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorDetails(),
        variables: {
          vendorId,
        },
      })
      .pipe(
        map((response: responseObject) => {
          const rawVendor = response.data.vendors.edges[0].node as vendorNode;
          let vendor: vendorNode = {
            id: rawVendor.id,
            vendorType: rawVendor.vendorType as VendorTypes,
            firstName: rawVendor.firstName,
            lastName: rawVendor.lastName,
            companyName: rawVendor.companyName,
            address: this.vendorAddress(rawVendor.addresses[0]),
            externalReferenceId: rawVendor.externalReferenceId,
            __typename: rawVendor.__typename,
            name: isEmpty(rawVendor.companyName) ? `${rawVendor.firstName} ${rawVendor.lastName}` : rawVendor.companyName,
            addresses: rawVendor.addresses,
          };
          vendor = {...rawVendor, ...vendor};
          return vendor;
        })
      );
  }

  getVendors<T>(
    pageSize = 10,
    cursor = '',
    direction = 'forward',
    searchText = '',
    vendorType: VendorTypes = null,
    sortField: VendorSortField = VendorSortField.Name,
    skipCache
  ): Observable<any> {
    try {
      const data = [];
      let pageInfo: any;
      let totalCount: number;
      const gqlQuery = this.searchVendors(pageSize, cursor, direction, searchText, vendorType, sortField);
      return this.apollo
        .use('vendor')
        .query({
          query: this.searchVendors(pageSize, cursor, direction, searchText, vendorType, sortField),
          fetchPolicy: skipCache ? 'network-only' : 'cache-first',
        })
        .pipe(
          map((response: responseObject) => {
            for (const edge of response.data.vendors.edges) {
              try {
                const rawVendor = edge.node as vendorNode;
                let eachVendor: vendorNode = {
                  id: rawVendor.id,
                  vendorType: rawVendor.vendorType as VendorTypes,
                  firstName: rawVendor.firstName,
                  lastName: rawVendor.lastName,
                  companyName: rawVendor.companyName,
                  address: this.vendorAddress(rawVendor.addresses[0]),
                  externalReferenceId: rawVendor.externalReferenceId,
                  __typename: rawVendor.__typename,
                  name: isEmpty(rawVendor.companyName) ? `${rawVendor.firstName} ${rawVendor.lastName}` : rawVendor.companyName,
                  addresses: rawVendor.addresses,
                };
                eachVendor = {...rawVendor, ...eachVendor};
                data.push(eachVendor);
              } catch (error) {
                console.log(error);
              }
            }
            pageInfo = response.data.vendors.pageInfo;
            totalCount = response.data.vendors.totalCount;
            return {data, pageInfo, totalCount};
          })
        );
    } catch (error) {
      console.log(error);
    }
  }

  vendorAddress(vendorAddressData: ILandlordAddress): string {
    return !vendorAddressData
      ? ''
      : `${vendorAddressData?.streetAddress} ${vendorAddressData?.unit} ${vendorAddressData?.city} ${vendorAddressData?.state} ${vendorAddressData?.postalCode}`.trim();
  }

  async saveVendor(vendorToSave: Vendor, vendorExisting?: Vendor): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: vendorExisting ?? null,
    };
    let addressResponse = _.clone(response);
    let achResponse = _.clone(response);

    if (vendorExisting) {
      //check for dirty vendor
      const vendorDirty = !this.coreService.areEqual(vendorToSave, vendorExisting);
      if (vendorDirty) {
        const command = this.buildUpdateVendorMutation(vendorToSave, vendorExisting);
        const result = await this.updateVendor(command).toPromise();
        if ((result.data as IUpdateVendorDataResponse).updateVendor.status !== 'SUCCESS') {
          response.success = false;
          response.errorMessage = `${(result.data as IUpdateVendorDataResponse).updateVendor.failureReason}\n`;
          return response;
        } else {
          response.affectedEntity = (result.data as IUpdateVendorDataResponse).updateVendor.affectedEntity;
          response.affectedEntity.addresses = vendorExisting.addresses;
          response.affectedEntity.vendorAch = vendorExisting.vendorAch;
          response.affectedEntity.notes = vendorExisting.notes;
          response.affectedEntity.attributeValues = vendorExisting.attributeValues;
        }
      }
      const vendorAddressDirty = !this.coreService.areEqual(vendorToSave.addresses[0], vendorExisting.addresses[0]);
      if (vendorAddressDirty) {
        addressResponse = await this.SaveVendorAddress(vendorToSave.addresses[0], vendorExisting.addresses[0]);
        if (!addressResponse.success) {
          return addressResponse;
        } else {
          try {
            response.affectedEntity.addresses[0] = addressResponse.affectedEntity;
          } catch (error) {
            console.log(error);
          }
        }
      }

      const vendorAchDirty = !this.coreService.areEqual(vendorToSave.vendorAch, vendorExisting.vendorAch);
      if (vendorAchDirty) {
        achResponse = await this.SaveVendorAch(vendorToSave.vendorAch, vendorExisting.vendorAch);
        if (!achResponse.success) {
          return achResponse;
        } else {
          response.affectedEntity.vendorAch = achResponse.affectedEntity;
        }
      }
    } else {
      //create new vendor
      const command = this.buildCreateVendorMutation(vendorToSave);
      const result = await this.createVendor(command).toPromise();
      if ((result.data as ICreateVendorDataResponse).createVendor.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as ICreateVendorDataResponse).createVendor.failureReason}\n`;
        return response;
      } else {
        response.affectedEntity = (result.data as ICreateVendorDataResponse).createVendor.affectedEntity;
        response.affectedEntity.addresses = [];
        response.affectedEntity.vendorAch = new VendorAch();
      }
      if (vendorToSave.addresses.length && vendorToSave.addresses[0].streetAddress.trim().length) {
        vendorToSave.addresses[0].vendorId = response.affectedEntity.id;
        addressResponse = await this.SaveVendorAddress(vendorToSave.addresses[0]);
        if (!addressResponse.success) {
          return addressResponse;
        } else {
          response.affectedEntity.addresses[0] = addressResponse.affectedEntity;
        }
      }
      if (vendorToSave.vendorAch.accountNumber.length) {
        vendorToSave.vendorAch.vendorId = response.affectedEntity.id;
        achResponse = await this.SaveVendorAch(vendorToSave.vendorAch);
        if (!achResponse.success) {
          return achResponse;
        } else {
          response.affectedEntity.vendorAch = achResponse.affectedEntity;
        }
      }
    }
    return response;
  }

  private async SaveVendorAddress(addressToSave: ILandlordAddress, addressExisting?: ILandlordAddress): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    if (addressExisting && addressExisting.id != '') {
      //update existing

      const command = this.buildUpdateVendorAddressMutation(addressToSave, addressExisting);
      const result = await this.updateVendorAddress(command).toPromise();
      if ((result.data as IUpdateVendorAddressDataResponse).updateVendorAddress.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as IUpdateVendorAddressDataResponse).updateVendorAddress.failureReason}\n`;
      } else {
        response.affectedEntity = (result.data as IUpdateVendorAddressDataResponse).updateVendorAddress.affectedEntity;
      }
    } else {
      //create new address
      const command = this.buildCreateVendorAddressMutation(addressToSave);
      const result = await this.createVendorAddress(command).toPromise();
      if ((result.data as ICreateVendorAddressDataResponse).createVendorAddress.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as ICreateVendorAddressDataResponse).createVendorAddress.failureReason}`;
        response.errorMessage =
          response.errorMessage == 'undefined'
            ? 'Street Address, City, State and Zip are all required address fields'
            : response.errorMessage;
      } else {
        response.affectedEntity = (result.data as ICreateVendorAddressDataResponse).createVendorAddress.affectedEntity;
      }
    }
    return response;
  }

  private async SaveVendorAch(achToSave: IVendorAch, achExisting?: IVendorAch): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };

    if (achExisting && achExisting.id) {
      const command = this.buildUpdateVendorAchMutation(achToSave, achExisting);
      const result = await this.updateVendorAch(command).toPromise();
      if ((result.data as IUpdateVendorAchDataResponse).updateVendorAch.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as IUpdateVendorAchDataResponse).updateVendorAch.failureReason}\n`;
      } else {
        response.affectedEntity = new VendorAch((result.data as IUpdateVendorAchDataResponse).updateVendorAch.affectedEntity);
      }
    } else {
      const command = this.buildCreateVendorAchMutation(achToSave);
      const result = await this.createVendorAch(command).toPromise();
      if ((result.data as ICreateVendorAchDataResponse).createVendorAch.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as ICreateVendorAchDataResponse).createVendorAch.failureReason}`;
        response.errorMessage =
          response.errorMessage == 'undefined'
            ? 'Street Address, City, State and Zip are all required address fields'
            : response.errorMessage;
      } else {
        response.affectedEntity = new VendorAch((result.data as ICreateVendorAchDataResponse).createVendorAch.affectedEntity);
      }
    }
    return response;
  }

  private buildCreateVendorMutation(vendorToSave: IVendor): VendorCreateCommand {
    const vendorToSaveKeys = Object.keys(vendorToSave);
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();

    const command: VendorCreateCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      createdBy: currentuser,
      paymentPreference: vendorToSave.paymentPreference,
      vendorType: vendorToSave.vendorType,
    };

    // any prop that does  not match add to command object
    for (const key of vendorToSaveKeys) {
      // Check if the property is of type 'object' or 'array'
      const isObject = this.coreService.isObject(vendorToSave[key]);
      const isArray = Array.isArray(vendorToSave[key]);
      if (vendorToSave[key] && !isObject && !isArray) {
        command[key] = vendorToSave[key];
      }
    }
    return command;
  }

  private buildUpdateVendorMutation(vendorToSave: IVendor, vendorExisting: IVendor): VendorUpdateCommand {
    const vendorToSaveKeys = Object.keys(vendorToSave);
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();

    const command: VendorUpdateCommand = {
      id: vendorToSave.id,
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      modifiedBy: currentuser,
    };

    // any prop that does  not match add to command object
    for (const key of vendorToSaveKeys) {
      // Check if the property is of type 'object' or 'array'
      const isObject = this.coreService.isObject(vendorToSave[key]) || this.coreService.isObject(vendorExisting[key]);
      const isArray = Array.isArray(vendorToSave[key]);
      if (isArray || isObject) {
        //console.log(`skipping: ${key} existing value:${vendorExisting[key]} new value:${vendorToSave[key]}` )
        continue; // Skip properties that are objects or arrays
      }
      // console.log(`key: ${key}`)
      // console.log(`existing value:${vendorExisting[key]}`)
      // console.log(`new value:${vendorToSave[key]}`)
      if (!Object.prototype.hasOwnProperty.call(vendorExisting, key) || vendorToSave[key] !== vendorExisting[key]) {
        const commandKey = key;
        command[commandKey] = vendorToSave[key];
      }
    }
    return command;
  }

  private buildUpdateVendorAddressMutation(addressToSave: ILandlordAddress, addressExisting: ILandlordAddress): VendorAddressUpdateCommand {
    const addressToSaveKeys = Object.keys(addressToSave);
    const addressExistingKeys = Object.keys(addressExisting);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: VendorAddressUpdateCommand = {
      id: addressToSave.id,
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
    };

    // any prop that does  not match add to command object
    for (const key of addressToSaveKeys) {
      // Check if the property is of type 'object' or 'array'
      const isObject = this.coreService.isObject(addressToSave[key]) || this.coreService.isObject(addressExisting[key]);
      const isArray = Array.isArray(addressToSave[key]);
      if (isArray || isObject) {
        //console.log(`skipping: ${key} existing value:${addressExisting[key]} new value:${addressToSave[key]}` )
        continue; // Skip properties that are objects or arrays
      }
      // console.log(`key: ${key}`)
      // console.log(`existing value:${addressExisting[key]}`)
      // console.log(`new value:${addressToSave[key]}`)
      if (!Object.prototype.hasOwnProperty.call(addressExisting, key) || addressToSave[key] !== addressExisting[key]) {
        const commandKey = key;
        command[commandKey] = addressToSave[key];
      }
    }
    return command;
  }

  private buildCreateVendorAddressMutation(addressToSave: ILandlordAddress): VendorAddressCreateCommand {
    const command: VendorAddressCreateCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      vendorId: addressToSave.vendorId,
      streetAddress: addressToSave.streetAddress,
      city: addressToSave.city,
      state: addressToSave.state,
      postalCode: addressToSave.postalCode,
    };
    if (addressToSave.unit != '') {
      command.unit = addressToSave.unit;
    }
    return command;
  }

  private buildUpdateVendorAchMutation(achToSave: IVendorAch, achExisting: IVendorAch): VendorAchUpdateCommand {
    const achToSaveKeys = Object.keys(achToSave);
    const achExistingKeys = Object.keys(achExisting);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: VendorAchUpdateCommand = {
      id: achToSave.id,
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      consumerAcct: achToSave.consumerAcct,
      checkingAcct: achToSave.checkingAcct,
      modifiedBy: currentuser,
    };

    // any prop that does  not match add to command object
    for (const key of achToSaveKeys) {
      // Check if the property is of type 'object' or 'array'
      const isObject = this.coreService.isObject(achToSave[key]) || this.coreService.isObject(achExisting[key]);
      const isArray = Array.isArray(achToSave[key]);
      if (isArray || isObject) {
        //console.log(`skipping: ${key} existing value:${achExisting[key]} new value:${achToSave[key]}` )
        continue; // Skip properties that are objects or arrays
      }
      // console.log(`key: ${key}`)
      // console.log(`existing value:${achExisting[key]}`)
      // console.log(`new value:${achToSave[key]}`)
      if (!Object.prototype.hasOwnProperty.call(achExisting, key) || achToSave[key] !== achExisting[key]) {
        const commandKey = key;
        command[commandKey] = achToSave[key];
      }
    }
    return command;
  }

  private buildCreateVendorAchMutation(achToSave: IVendorAch): VendorAchCreateCommand {
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: VendorAchCreateCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      vendorId: achToSave.vendorId,
      accountNumberDisplay: achToSave.accountNumberDisplay,
      accountNumber: achToSave.accountNumber,
      dataKey: achToSave.dataKey,
      dataVector: achToSave.dataVector,
      routingNumber: achToSave.routingNumber,
      consumerAcct: achToSave.consumerAcct,
      checkingAcct: achToSave.checkingAcct,
      createdBy: currentuser,
    };
    return command;
  }

  getVendorsList<T>(): Observable<any> {
    try {
      const data = [];
      let pageInfo: any;
      return this.apollo
        .use('vendor')
        .query({
          query: gql`
            query SearchVendors {
              vendors {
                edges {
                  node {
                    id
                    firstName
                    lastName
                    companyName
                    vendorType
                  }
                  cursor
                }
              }
            }
          `,
        })
        .pipe(
          map((response: responseObject) => {
            for (const edge of response.data.vendors.edges) {
              const eachVendor = edge.node as vendorNode;
              data.push(eachVendor);
            }
            pageInfo = response.data.vendors.pageInfo;
            return {data, pageInfo};
          })
        );
    } catch (error) {
      console.log(error);
    }
  }
  createVendor<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation createVendorMutation($command: CreateVendorCommandInput!) {
          createVendor(command: $command) {
            commandName
            status
            affectedEntity {
              id
              firstName
              lastName
              companyName
              name
              emailAddress
              phoneNumber
              taxId
              externalReferenceId
              vendorType
              paymentPreference
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
              payTo
              phaCode
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  updateVendor<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation updateVendorMutation($command: UpdateVendorCommandInput!) {
          updateVendor(command: $command) {
            commandName
            status
            failedOn
            failureReason
            affectedEntity {
              id
              firstName
              lastName
              companyName
              name
              emailAddress
              phoneNumber
              taxId
              externalReferenceId
              vendorType
              paymentPreference
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
              payTo
              phaCode
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  createVendorAddress<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation createVendorAddressMutation($command: CreateVendorAddressCommandInput!) {
          createVendorAddress(command: $command) {
            commandName
            status
            affectedEntity {
              id
              unit
              streetAddress
              city
              state
              postalCode
              vendorId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  updateVendorAddress<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation UpdateVendorAddress($command: UpdateVendorAddressCommandInput) {
          updateVendorAddress(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              streetAddress
              unit
              city
              state
              postalCode
              vendorId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateVendorAch<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation UpdateVendorAch($command: UpdateVendorAchCommandInput) {
          updateVendorAch(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              accountNumberDisplay
              dataKey
              dataVector
              routingNumber
              consumerAcct
              checkingAcct
              customerId
              siteId
              vendorId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createVendorAch<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation CreateVendorAch($command: CreateVendorAchCommandInput) {
          createVendorAch(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              accountNumberDisplay
              dataKey
              dataVector
              routingNumber
              consumerAcct
              checkingAcct
              customerId
              siteId
              vendorId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createVendorAttributeValues<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation CreateVendorAttributeValue($command: CreateVendorAttributeValueCommandInput) {
          createVendorAttributeValue(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              textValue
              booleanValue
              dateTimeValue
              entityInstanceId
              entityAttributeId
              siteId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateVendorAttributeValues<T>(valueCommand: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation UpdateVendorAttributeValue($valueCommand: UpdateVendorAttributeValueCommandInput) {
          updateVendorAttributeValue(valueCommand: $valueCommand) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              textValue
              booleanValue
              dateTimeValue
              entityAttributeId
              entityInstanceId
              siteId
            }
          }
        }
      `,
      variables: {
        valueCommand,
      },
    });
  }

  createVendorNote<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation createVendorNoteMutation($command: CreateVendorNoteCommandInput!) {
          createVendorNote(command: $command) {
            commandName
            status
            affectedEntity {
              id
              body
              category
              vendorId
              createdOn
              createdBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  updateVendorNote<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation updateVendorNoteMutation($command: UpdateVendorNoteCommandInput!) {
          updateVendorNote(command: $command) {
            commandName
            status
            affectedEntity {
              id
              body
              category
              vendorId
              createdOn
              createdBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  deleteVendorNote<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation deleteVendorNoteMutation($command: DeleteVendorNoteCommandInput!) {
          deleteVendorNote(command: $command) {
            status
            issuedOn
            affectedEntity {
              id
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  getSelectedVendor(vendorId: string) {
    switch (vendorId.length) {
      case 36:
        return this.getVendorByGuid(vendorId); //Is a GUId
      case 24:
        return this.getVendorByMongoId(vendorId); //Is a Mongo Id
    }
  }
  async setEnvironmentParameters(parameterStoreService: ParameterStoreService, parameters: ParameterStoreVariables[]): Promise<string[]> {
    const fetchedParameters = await parameterStoreService.fetchAndSetParameters(parameters);
    return fetchedParameters;
  }
  createVendorTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation CreateVendorTransaction($command: CreateVendorTransactionCommandInput!) {
          createVendorTransaction(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              siteId
              customerId
              vendorId
              sequence
              vendorAccountId
              vendorAccountName
              accountDefinitionId
              amount
              programId
              programName
              projectId
              projectName
              householdId
              headFirstName
              headLastName
              subLedgerMappingId
              subLedgerMappingName
              chargeGroupId
              chargeGroupName
              chargeTypeId
              chargeTypeName
              checkNumber
              description
              transactionGroupId
              voidedOn
              voidedBy
              postedOn
              postedBy
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              ppuCode
              hold
              holdDescription
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  deletePayableMutation<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation DeletePayable($command: DeletePayableCommandInput) {
          deletePayable(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  private getVendorByMongoId<T>(landlordId: string) {
    const data = [];
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryLandlords(),
        variables: {
          landlordId,
        },
      })
      .pipe(
        map((response: responseLandlordObject) => {
          data.push(response.data.landlords.edges[0].node);
          return data[0];
        })
      );
  }

  private getVendorByGuid<T>(vendorId: UUID): Observable<any> {
    return this.apollo
      .use('vendor')
      .query({
        query: this.queryVendorDetails(),
        variables: {
          vendorId,
        },
      })
      .pipe(
        map((response: responseVendorObject) => {
          try {
            const vendor: IVendor = new Vendor(response.data.vendors.edges[0].node);
            return vendor;
          } catch (error) {
            console.log(error);
          }
        })
      );
  }

  createPayableMutation<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation CreatePayable($command: CreatePayableCommandInput) {
          createPayable(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  private buildVendorWhereClause(filter: string, vendorType: VendorTypes = null) {
    let returnValue = '';
    if (filter && filter.length > 0) {
      returnValue = 'where: { or: [';
      returnValue += `{ companyName: { contains: "${filter}" } },`;
      returnValue += `{ firstName: { contains: "${filter}" } },`;
      returnValue += `{ lastName: { contains: "${filter}" } },`;
      returnValue += `{ addresses: { some: {or: [{ streetAddress: { contains: "${filter}" }}, {unit: { contains: "${filter}" }},{city: { contains: "${filter}"}}, {state: { contains: "${filter}" }}, {postalCode: { contains: "${filter}" }} ]  } } }`;
      returnValue += vendorType ? `] vendorType: { eq:${vendorType.toUpperCase()}}}` : ']}';
    } else if (vendorType) {
      returnValue = `where: { vendorType: { eq:${vendorType.toUpperCase()}}}`;
    }
    return returnValue;
  }

  private buildSortClause(sortField) {
    let returnValue = ' order: [{ ';
    switch (sortField) {
      case VendorSortField.Name:
        returnValue += 'name: ASC}]';
        break;
      case VendorSortField.Type:
        returnValue += 'vendorType: ASC }]';
        break;
    }
    return returnValue;
  }

  private searchVendors(
    pageSize: number,
    cursor = '',
    direction = 'forward',
    searchText = '',
    vendorType: VendorTypes = null,
    sortField: VendorSortField = VendorSortField.Name
  ) {
    try {
      const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
      const cursorFilter = isEmpty(cursor) ? '' : filter;
      const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;
      const whereFilter = this.buildVendorWhereClause(searchText, vendorType);
      const sortClause = this.buildSortClause(sortField);
      // vendorType: { eq: LANDLORD }
      return gql`
      query SearchVendors {
        vendors(${pageSizeFilter} ${cursorFilter} ${whereFilter} ${sortClause}) {
          edges {
            node {
              id
              companyName
              firstName
              lastName
              name
              vendorType         
              addresses {
                streetAddress
                unit
                city
                state
                postalCode
              }     
              externalReferenceId
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `;
    } catch (error) {
      console.log(error);
    }
  }

  private queryVendorDetails() {
    return gql`
      query QueryVendorDetails($vendorId: UUID) {
        vendors(first: 1, where: {id: {eq: $vendorId}}) {
          edges {
            node {
              id
              firstName
              lastName
              payTo
              emailAddress
              vendorType
              paymentPreference
              companyName
              taxId
              phoneNumber
              externalReferenceId
              siteId
              createdOn
              createdBy
              phaCode
              notes {
                id
                body
                category
                vendorId
                createdOn
                createdBy
                deletedOn
                deletedBy
              }
              addresses {
                id
                streetAddress
                unit
                city
                state
                postalCode
                vendorId
              }
              attributeValues {
                id
                textValue
                booleanValue
                dateTimeValue
                entityInstanceId
                entityInstanceIdAsGuid
                entityAttributeId
                attribute {
                  id
                  attributeType
                  name
                  isActive
                  isRequired
                }
              }
              vendorAch {
                id
                accountNumberDisplay
                dataKey
                dataVector
                routingNumber
                consumerAcct
                checkingAcct
              }
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }

  private queryLandlords() {
    return gql`
      query QueryLandlords($siteId: String, $landlordId: String) {
        landlords(first: 1, where: {id: {eq: $landlordId}, siteId: {eq: $siteId}}) {
          edges {
            node {
              id
              firstName
              lastName
              companyName
              taxId
              phoneNumber
              externalReferenceId
              emailAddress
              paymentPreference
              units {
                id
                streetAddress
                city
                streetAddress
                postalCode
              }
              addresses {
                id
                streetAddress
                city
                state
                postalCode
              }
              notes {
                id
                body
              }
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `;
  }

  public getVendorNotesByGuid<T>(siteId: string, vendorId: UUID): Observable<any> {
    try {
      let vendorNotes,
        filteredVendorNotes = [];
      return this.apollo
        .use('vendor')
        .query({
          query: this.queryVendorNotes(),
          variables: {
            siteId,
            vendorId,
          },
        })
        .pipe(
          map((response: responseVendorObject) => {
            vendorNotes = response.data.vendors.edges[0].node.notes;
            filteredVendorNotes = vendorNotes
              .filter(note => note.deletedOn === null)
              .sort((a, b) => {
                return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
              });

            return filteredVendorNotes;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }

  public queryVendorNotes() {
    try {
      return gql`
        query QueryVendorNotes($siteId: String, $vendorId: UUID) {
          vendors(where: {siteId: {eq: $siteId}, id: {eq: $vendorId}}) {
            edges {
              node {
                id
                notes {
                  id
                  body
                  category
                  vendorId
                  createdOn
                  createdBy
                  deletedOn
                  deletedBy
                }
              }
            }
          }
        }
      `;
    } catch (error) {
      console.log(error);
    }
  }

  createVendorRecurringTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation CreateVendorRecurringTransaction($command: CreateVendorRecurringTransactionCommandInput!) {
          createVendorRecurringTransaction(command: $command) {
            commandName
            status
            failedOn
            failureReason
            affectedEntity {
              id
              name
              startOn
              completedOn
              vendorId
              vendorAccountId
              subledgerMappingId
              subledgerMappingName
              chargeTypeId
              chargeTypeName
              description
              portReimbursements
              householdId
              voucherNumber
              voucherSpecialType
              totalAmount
              contractRent
              tenantPortion
              hapAmount
              urpAmount
              adminAmount
              hardToHouseAmount
              fssEscrowAmount
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              archivedOn
              archivedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateVendorRecurringTransaction<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('vendor').mutate({
      mutation: gql`
        mutation UpdateVendorRecurringTransaction($command: UpdateVendorRecurringTransactionCommandInput!) {
          updateVendorRecurringTransaction(command: $command) {
            commandName
            status
            failedOn
            failureReason
            affectedEntity {
              id
              name
              startOn
              completedOn
              vendorId
              vendorAccountId
              subledgerMappingId
              subledgerMappingName
              chargeTypeId
              chargeTypeName
              description
              portReimbursements
              householdId
              voucherNumber
              voucherSpecialType
              totalAmount
              contractRent
              tenantPortion
              hapAmount
              urpAmount
              adminAmount
              hardToHouseAmount
              fssEscrowAmount
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              archivedOn
              archivedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  public getHouseholdMemberById<T>(siteId: string, householdId: string): Observable<any> {
    try {
      return this.apollo
        .use('occupancy')
        .query({
          query: this.queryHouseholdMemberById(),
          variables: {
            siteId,
            householdId,
          },
        })
        .pipe(
          map((response: any) => {
            const householdMember = response.data.householdMembers.edges[0] ? response.data.householdMembers.edges[0].node : null;
            if (!householdMember) {
              return 'NA';
            }
            const householdFullName = [
              householdMember.firstName,
              householdMember.middleName,
              householdMember.lastName,
              householdMember.suffix,
            ]
              .filter(part => part)
              .join(' ');
            return householdFullName;
          })
        );
    } catch (error) {
      console.log(error);
    }
  }

  public queryHouseholdMemberById() {
    try {
      return gql`
        query HouseholdMembers($siteId: String, $householdId: String) {
          householdMembers(where: {siteId: {eq: $siteId}, householdId: {eq: $householdId}, relationCode: {eq: "H"}}) {
            edges {
              cursor
              node {
                id
                firstName
                lastName
                middleName
                suffix
              }
            }
          }
        }
      `;
    } catch (error) {
      console.log(error);
    }
  }
}
