import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl} from '@angular/forms';
import {Component, Injector, OnInit, QueryList, ViewChildren} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {PostAdjustmentComponent} from './post-adjustment/post-adjustment.component';
import {PayablesNotesComponent} from './payables-notes/payables-notes.component';
import {PayablesWizardComponent} from './payables-wizard/payables-wizard.component';
import {VendorService, vendorPayableViewObject} from '../../vendor/vendor.service';
import {AccountingService} from '../service/accounting.service';
import {PayablesNotes, payableWizardModal} from '../service/accounting-models';
import {PaymentDetail, VendorDetail} from '../../vendor/vendor-model';
import {CoreService} from 'src/app/core/service/core.service';
import {ProgramsService} from 'src/app/shared/services/programs.service';
import * as moment from 'moment';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('openClose', [
      state(
        'open',
        style({
          left: '0px',
          top: '190px',
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      state(
        'closed',
        style({
          left: '-1300px',
          top: '190px',
          opacity: 0,
          backgroundColor: '#fff',
          zIndex: 2,
        })
      ),
      transition('open => closed', [animate('1s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class PayoutsComponent implements OnInit {
  customerId: string;
  siteId: string;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<VendorDetail>>;

  displayedColumns: string[] = [
    'select',
    'chevron',
    'Pay To',
    '# of Accounts',
    'Last Payable Amt',
    'Total Balance',
    'Balance to be Paid',
    'Notes',
  ];
  innerDisplayedColumns: string[] = ['select', 'Name', 'transactionId', 'Last Payed Date', 'Payment Type', 'Amount'];
  loading = true;
  innerLoading = true;
  busyText: string;
  showPage = 'new-batch';
  currentBatchId: number;
  currentBatchCreatedBy: string;
  currentBatchCreatedOn: string;
  dataSource: MatTableDataSource<vendorPayableViewObject>;
  expandedElement: vendorPayableViewObject | null;
  expandedPanel = true;
  allTransactions = true;
  allVendorTransactions = true;
  checkboxMessage: string;
  disabledNavButtonClass = 'disabled-icon';
  pagePreviousClass = 'navButton-previous';
  baseClass = 'mat-icon notranslate material-icons mat-icon-no-color';
  pageSize = new FormControl('10');
  totalCount: number;
  pageCount: number;
  viewPage: number;
  currentPage = 1;
  vendorPayables;
  programList;
  programs = new FormControl('');
  showFilters = false;
  payableTypes = new FormControl('');
  payableTypeList: string[] = ['Landlord', 'Utility', 'Maintenance', 'Other'];
  paymentPreference = new FormControl('');
  paymentPreferenceList: string[] = ['ACH', 'Check', 'Other'];
  hasNextPage = false;
  hasPreviousPage = false;
  initialSumOfTransactionObject = [];
  loggedInUser;
  pageLimit = 200;

  payablesChecksLink: string;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  // eslint-disable-next-line
  constructor(
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    private vendorService: VendorService,
    private accountingService: AccountingService,
    private coreService: CoreService,
    private programService: ProgramsService
  ) {}

  ngOnInit() {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.setBusy('Loading');
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    this.getProgramsData();
    this.payableTypes.setValue(this.payableTypeList);
    this.paymentPreference.setValue(this.paymentPreferenceList);
    this.getPayable();
  }

  getPayable = () => {
    this.vendorService
      .getVendorPayablesView(this.siteId, this.payableTypes.value, this.programs.value, this.paymentPreference.value)
      .subscribe(
        result => {
          this.allTransactions = true;
          this.vendorPayables = result[0];
          for (const data of this.vendorPayables) {
            this.initialSumOfTransactionObject.push({
              vendorId: data.vendorId,
              numberOfTransactions: data.numberOfTransactions,
              balanceToBePaid: data.balanceToBePaid,
            });
          }
          this.getPage(null);
          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.coreService.displayError('there was error getting paybles!');
        }
      );
  };

  getProgramsData() {
    this.setBusy('Loading Units');
    this.programService.getPrograms().subscribe(results => {
      this.programList = results;
      const defaultProgram = [];
      for (const program of this.programList) {
        defaultProgram.push(program.id);
      }
      this.programs.setValue(defaultProgram);
    });
  }

  getPage(direction?) {
    if (this.vendorPayables.length > 50) {
      switch (direction) {
        case 'forward':
          this.currentPage++;
          this.setDataTable();
          break;
        case 'backward':
          this.currentPage--;
          this.setDataTable();
          break;
        default:
          this.setDataTable();
          break;
      }
      if (this.currentPage == 1) {
        this.hasPreviousPage = false;
      } else {
        this.hasPreviousPage = true;
      }
      if (this.vendorPayables.length / (this.currentPage * this.pageLimit) > 1) {
        this.hasNextPage = true;
      } else {
        this.hasNextPage = false;
      }
    } else {
      this.setDataTable();
    }
  }

  setDataTable() {
    const pagedData = this.vendorPayables.slice((this.currentPage - 1) * this.pageLimit, this.currentPage * this.pageLimit);
    this.dataSource = new MatTableDataSource(pagedData);
  }

  selectAllTransactions(selected: boolean) {
    this.allTransactions = selected;
    if (this.dataSource == null) {
      return;
    }
    this.dataSource.filteredData.forEach(payee => {
      payee.selected = !selected;
      payee.indeterminate = false;
      if (payee.details) {
        payee.details.forEach(vendor => {
          vendor.selected = !selected;
        });
      } else {
        this.vendorService.getVendorPayablesTransactions(this.siteId, payee.vendorId).subscribe(results => {
          payee.details = results[0];
          for (const transaction of payee.details) {
            if (selected == false) {
              transaction.selected = true;
            } else {
              transaction.selected = false;
            }
          }
        });
      }
      if (selected == false) {
        payee.numberOfTransactions = 0;
        payee.balanceToBePaid = 0;
      } else {
        const inittialObjectValue = this.initialSumOfTransactionObject.find(obj => obj.vendorId == payee.vendorId);
        payee.numberOfTransactions = inittialObjectValue.numberOfTransactions;
        payee.balanceToBePaid = inittialObjectValue.balanceToBePaid;
      }
    });
  }

  updateOuterCheckbox(payToRow) {
    this.dataSource.filteredData.forEach(payee => {
      if (payToRow == payee.vendorId) {
        payee.selected = !payee.selected;
        if (payee.indeterminate == true) {
          payee.indeterminate = false;
        }
        if (payee.details) {
          payee.details.forEach(vendor => {
            vendor.selected = payee.selected;
          });
        } else {
          this.vendorService.getVendorPayablesTransactions(this.siteId, payee.vendorId).subscribe(results => {
            payee.details = results[0];
            for (const transaction of payee.details) {
              transaction.selected = true;
            }
          });
        }
        if (payee.selected == true) {
          payee.numberOfTransactions = 0;
          payee.balanceToBePaid = 0;
        } else {
          const inittialObjectValue = this.initialSumOfTransactionObject.find(obj => obj.vendorId == payee.vendorId);
          payee.numberOfTransactions = inittialObjectValue.numberOfTransactions;
          payee.balanceToBePaid = inittialObjectValue.balanceToBePaid;
        }
      }
    });
    this.allTransactions = this.dataSource.filteredData != null && this.dataSource.filteredData.every(t => !t.selected);
  }

  updateInnerSelected(payeeTransaction) {
    //this inner case when the dataildata is ready
    this.dataSource.filteredData.forEach(payee => {
      if (payee.details) {
        payee.details.forEach(vendor => {
          if (vendor.sequence === payeeTransaction) {
            vendor.selected = !vendor.selected;
            payee.indeterminate =
              payee.details != null && !payee.details.every(t => t.selected) && payee.details.filter(t => t.selected == true).length > 0;
            payee.selected = payee.details != null && !payee.details.every(t => !t.selected);
            this.allTransactions = this.dataSource.filteredData != null && this.dataSource.filteredData.every(t => !t.selected);
            if (vendor.selected == true) {
              payee.balanceToBePaid -= vendor.amount;
              payee.numberOfTransactions--;
            } else {
              payee.balanceToBePaid += vendor.amount;
              payee.numberOfTransactions++;
            }
          }
        });
      }
    });
  }

  someInnerSelected() {
    if (this.dataSource == null) {
      return false;
    }
    return this.dataSource.filteredData.filter(t => !t.selected).length > 0 && !this.allVendorTransactions;
  }

  someSelected(): boolean {
    if (this.dataSource == null) {
      return false;
    }
    if (this.allTransactions) {
      this.checkboxMessage = 'Click to Deselect All';
    } else {
      this.checkboxMessage = 'Click to Select All';
    }
    return this.dataSource.filteredData.filter(t => !t.selected).length > 0 && !this.allTransactions;
  }

  toggleRow(element) {
    element.expanded = true;
    if (element.expanded && !element.details) {
      this.vendorService.getVendorPayablesTransactions(this.siteId, element.vendorId).subscribe(results => {
        element.details = results[0];
        for (const transaction of element.details) {
          if (element.selected == undefined) {
            transaction.selected = false;
          }
        }
        this.innerLoading = false;
        // eslint-disable-next-line
        element.details
          ? this.expandedElement === element
            ? (this.expandedPanel = !this.expandedPanel)
            : (this.expandedElement = element)
          : null;
      });
    } else {
      this.innerLoading = false;
      // eslint-disable-next-line
      element.details
        ? this.expandedElement === element
          ? (this.expandedPanel = !this.expandedPanel)
          : (this.expandedElement = element)
        : null;
    }
  }

  showFilterToggle() {
    this.showFilters = !this.showFilters;
  }

  resetFilters() {
    this.showFilters = false;
    this.payableTypes.setValue(this.payableTypeList);
    const defaultProgram = [];
    for (const program of this.programList) {
      defaultProgram.push(program._id);
    }
    this.programs.setValue(defaultProgram);
    this.getPayable();
  }

  applyFilters() {
    this.showFilters = false;
    this.getPayable();
  }

  navButtonClass(button: string) {
    switch (button) {
      case 'previous':
        return !this.hasPreviousPage
          ? `${this.baseClass} ${this.disabledNavButtonClass} ${this.pagePreviousClass}`
          : `${this.baseClass} ${this.pagePreviousClass}`;
      case 'next':
        return !this.hasNextPage ? `${this.baseClass} ${this.disabledNavButtonClass}` : `${this.baseClass}`;
      default:
        return '';
    }
  }

  goPage(page) {
    switch (page) {
      case 'previous':
        this.getPage('backward');
        break;
      case 'next':
        this.getPage('forward');
        break;
      default:
        break;
    }
  }

  sumAmount(paymentDetail: MatTableDataSource<vendorPayableViewObject>, property: string): number {
    return paymentDetail.filteredData.reduce((sum: number, obj) => sum + obj[property], 0);
  }

  sumAbsoluteValue(paymentDetail: MatTableDataSource<vendorPayableViewObject>, property: string): number {
    return this.getAbsoluteValue(paymentDetail.filteredData.reduce((sum: number, obj) => sum + obj[property], 0));
  }

  getAbsoluteValue(number) {
    return this.coreService.getAbsoluteValue(number);
  }

  getNavClass(page: string): string {
    return page === this.showPage ? 'nav-active' : '';
  }

  onPayableNavigate(page: string): void {
    const previousPage = this.showPage;
    this.showPage = page;
    if (page === 'new-batch' && previousPage !== 'new-batch') {
      this.setBusy('Loading');
      this.getPayable();
    }
  }

  setCurrentBatchId(data): void {
    console.log(data, 395);
    this.currentBatchCreatedBy = data.createdBy;
    this.currentBatchCreatedOn = moment(data.createdOn).format('MMM DD, YYYY');
    this.currentBatchId = data.batchId;
  }

  onPayment(selectedPayment: VendorDetail): void {
    this.globalModalService.openModal<PaymentDetail>(
      PostAdjustmentComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {landLord: this.expandedElement.vendorId, vendorDetail: selectedPayment}
    );
  }

  onOpenNotes(element: vendorPayableViewObject): void {
    this.globalModalService.openModal<PayablesNotes>(
      PayablesNotesComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {landLord: element.vendorName, vendorId: element.vendorId}
    );
  }

  onOpenWizard(): void {
    this.globalModalService.openModal<payableWizardModal>(
      PayablesWizardComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        payableObject: this.dataSource.filteredData,
        updateFunction: this.onCreatePayable,
      }
    );
  }

  onCreatePayable = (transactionIds: string[], checkNumber: number) => {
    const createPayableCommand = {
      siteId: this.siteId,
      customerId: this.customerId,
      vendorTransactionIds: transactionIds,
      createdBy: this.loggedInUser,
      checkNumber: checkNumber ? checkNumber.toString() : '0',
    };
    this.setBusy('Processing Payable');
    this.vendorService.createPayableMutation(createPayableCommand).subscribe(
      (result: any) => {
        if (result.data.createPayable.status == 'SUCCESS') {
          this.getPayable();
          this.loading = false;
        } else {
          this.coreService.displayError('There was an error processing payable');
          this.loading = false;
        }
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was an error processing payable');
        this.loading = false;
      }
    );
  };

  setBusy(message: string) {
    this.loading = true;
    this.busyText = message;
  }

  onCheckDisableProcessPayble() {
    const nonAllSelected = this.dataSource.filteredData.filter(t => !t.selected).length == 0;
    const nonAllInnerSelected = this.dataSource.filteredData.filter(t => t.indeterminate).length == 0;
    if (nonAllSelected) {
      if (!nonAllInnerSelected) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  onNavigateVendorURL(id) {
    window.location.href = `/vendors/${id}/details`;
  }
}
