import {Component, OnInit, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {GlobalModalChild, GlobalModalParent} from '../../../../kanso-common/core/service/globalmodal.service';
import {CommunityAnnouncement, CommunityAnnouncementModal} from 'src/app/shared/services/integrations-service';

@Component({
  selector: 'app-community-announcements',
  templateUrl: './community-announcements.component.html',
  styleUrls: ['./community-announcements.component.scss'],
})
export class CommunityAnnouncementsComponent implements GlobalModalChild<CommunityAnnouncementModal>, CommunityAnnouncementModal {
  communityAnnouncement: CommunityAnnouncement;
  operation: string;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  globalModalParent: GlobalModalParent;
  createMode = false;
  editMode = false;
  deleteMode = false;
  constructor() {
    console.log('works', this.communityAnnouncement);
  }
  ngOnInit(): void {
    this.checkOperation();
  }

  checkOperation() {
    switch (this.operation) {
      case 'create':
        this.createMode = true;
        break;
      case 'edit':
        this.editMode = true;
        break;
      case 'delete':
        this.deleteMode = true;
        break;
      default:
        break;
    }
  }

  saveAnnouncement() {
    this.updateFunction(this.communityAnnouncement);
    this.closeDialog();
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }
}
