<section class="tabs__panel layout-column">
    <h2>Features</h2>    
</section>

<section class="tabs__panel layout-column featureList">
    <div *ngIf="error" class="layout-row">
        <div class="spinnerPadding">{{errorMsg}}</div>
    </div>
    <busy-notice
        [isBusy]="this.isBusy"
        [busyText]="this.busyText" 
        headerText="KANSO">
    </busy-notice>

    <div *ngIf="!error && !isBusy" class="tableContainer">
        <table mat-table class="kanso-table altRows">
            <thead>
                <tr class="centerData">
                    <th>Name</th>
                    <th>Category</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tr class="centerData" *ngFor="let config of this.featureConfigurations, let $index=index">
                <td>{{config.name}}</td>
                <td>{{config.domain}}</td>
                <td>{{config.description}}</td>
                <td>
                    <div *ngIf="(config.type === 'select') && (config.subdomain === 'selectTelerikCheckReport')">
                        <div *ngIf="checkReportLoading" class="layout-row">
                            <mat-spinner diameter="25"></mat-spinner> 
                            <div class="spinnerPadding">...Loading Check Reports</div>
                        </div>
                        <div *ngIf="!checkReportLoading" >
                            <mat-form-field  appearance="outline">
                                <mat-label *ngIf="config.value">Selected Check Report</mat-label>
                                <mat-label *ngIf="!config.value">{{selectMessage}}</mat-label>
                                <mat-select matInput ngModel 
                                    [(ngModel)]="config.value"  
                                    (selectionChange)="configChange($event, config)">
                                    <mat-option *ngFor="let report of payableReports" 
                                        [(value)]= "report.fullName"
                                        >{{report.name}}</mat-option>
                                    <mat-option [value]="null"> Remove Selection</mat-option>
                                </mat-select>
                            </mat-form-field> 
                        </div>
                    </div>
                    <div *ngIf="(config.type === 'select') && (config.subdomain === 'selectTelerikGLReport')" >
                        <div *ngIf="gLReportLoading" class="layout-row">
                            <mat-spinner diameter="25"></mat-spinner> 
                            <div class="spinnerPadding">...Loading General Ledger Reports</div>
                        </div>
                        <div *ngIf="!gLReportLoading" >
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="config.value">Selected GL Report</mat-label>
                                <mat-label *ngIf="!config.value">{{selectMessage}}</mat-label>
                                <mat-select matInput ngModel 
                                    [ngModel]="config.value"  
                                    (selectionChange)="configChange($event, config)">
                                    <mat-option *ngFor="let glreport of generalLedgerReports" 
                                        [value]="glreport.fullName"
                                        >{{glreport.name}}</mat-option>
                                    <mat-option [value]="null"> Remove Selection</mat-option>
                                </mat-select>
                            </mat-form-field> 
                        </div>
                    </div>
                    <div *ngIf="(config.subdomain === 'sageIntacct')">
                        <div class="togglePadding">
                            <mat-slide-toggle *ngIf="config.type === 'toggle'"
                                (change)="configChange($event, config)"
                                [checked]="config.value">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div *ngIf="config.subdomain === 'stripeInt'">
                        <div class="togglePadding">
                            <mat-slide-toggle *ngIf="config.type === 'toggle'"
                                (change)="configChange($event, config)"
                                [checked]="config.value">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div *ngIf="config.subdomain === 'amerindUnitFields'">
                        <div class="togglePadding">
                            <mat-slide-toggle *ngIf="config.type === 'toggle'"
                                (change)="configChange($event, config)"
                                [checked]="config.value">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <div *ngIf="config.subdomain === 'PicExport'">
                        <div class="togglePadding">
                            <mat-slide-toggle *ngIf="config.type === 'toggle'"
                                (change)="configChange($event, config)"
                                [checked]="config.value">
                            </mat-slide-toggle>
                        </div>
                    </div>
          
                    <!-- feature configurations below this have been added for examples but they are not completed and can be deleted once real toggles and multi select have been created -->
                    <div *ngIf="config.domain === 'Maintenance'">
                        <div class="togglePadding">
                            <mat-slide-toggle *ngIf="config.type === 'toggle'"
                                (change)="configChange($event, config)"
                                [checked]="config.value">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <mat-form-field *ngIf="(config.type === 'select') && 
                                            !(config.subdomain === 'selectTelerikCheckReport') &&
                                            !(config.subdomain === 'selectTelerikGLReport')" 
                                            appearance="outline"> 
                            <mat-label *ngIf="config.value">Selected Value</mat-label>
                            <mat-label *ngIf="!config.value">{{selectMessage}}</mat-label>
                            <mat-select [(value)] ="config.value"
                                        (selectionChange)="configChange($event, config)">
                                <mat-option *ngFor="let option of config.configurationsOptions"
                                    [value]="option.display">{{option.display}}</mat-option>
                                <mat-option [value]="null"> Remove Selection</mat-option>
                            </mat-select> 
                    </mat-form-field>
                    <mat-form-field *ngIf="config.type === 'multi-select'" appearance="outline">
                        <mat-label *ngIf="config.value">Selected</mat-label>
                        <mat-label *ngIf="!config.value">{{selectMessage}}</mat-label>
                            <mat-select matInput ngModel     
                                [ngModel]="configurationsValues"  
                                [compareWith]="compareValues"
                                (selectionChange)="changedMultiSelect($event, config)"
                                multiple>
                                <mat-option *ngFor="let option of config.configurationsOptions" 
                                    [value]="option">{{option.display}}
                                </mat-option>
                            </mat-select> 
                    </mat-form-field>
                </td>
            </tr>
        </table>    
    </div>
</section>
