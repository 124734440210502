import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {Observable, Subject, pipe} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {FetchResult} from '@apollo/client/link/core/types';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import {CoreService} from 'src/app/core/service/core.service';
import {AuthorityACH, VendorACH, VendorData} from 'src/app/custom/accounting/service/nacha-model';

export interface CreateAuthorityAchCommand {
  bankTokenDisplay: string;
  bankToken: string;
  dataKey: string;
  dataVector: string;
  odfiRoutingNumber: string;
  odfiName: string;
  createdBy: string;
  customerId: string;
  siteId: string;
  achDescription: string;
  achNameOnAccount?: string;
}

export interface UpdateAuthorityAchCommand {
  id: string;
  bankTokenDisplay: string;
  bankToken: string;
  dataKey: string;
  dataVector: string;
  odfiRoutingNumber: string;
  odfiName: string;
  customerId: string;
  siteId: string;
  achDescription: string;
  achNameOnAccount?: string;
  modifiedBy: string;
}

export class AchService extends BaseService {
  apollo: Apollo;
  _http: any;
  constructor(public http: HttpClient, public coreService: CoreService, private apolloProvider: Apollo) {
    super(http);
    this.apollo = this.apolloProvider;
    const commonHeaders: any = {
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };

    const achHeaders: any = {...commonHeaders, ...{'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY')}};
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...achHeaders}},
      'achService'
    );
    const kimonoHeaders: any = {...commonHeaders, ...{'x-api-key': sessionStorage.getItem('KIMONO_SERVICE_API_KEY')}};
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('KIMONO_SERVICE_URI') + '/kimono/graphql', headers: {...kimonoHeaders}},
      'kimono'
    );
  }

  getAuthorityAch<T>(): Observable<AuthorityACH> {
    const data = [];
    return this.apollo
      .use('achService')
      .query({
        query: this.queryHousingAuthorityAchs(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          return response.data.housingAuthorityAchs.edges[0]?.node;
        })
      );
  }

  getEncryptedAuthorityAch<T>(authorityId: string): Observable<any> {
    return this.apollo
      .use('kimono')
      .query({
        query: this.queryAuthorityAch(),
        variables: {
          authorityId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          return response.data.authorityData.edges[0];
        })
      );
  }

  getVendorACHData<T>(idList: string[]): Observable<VendorACH[]> {
    return this.apollo
      .use('nacha')
      .query({
        query: this.queryVendorAchsWithIds(),
        fetchPolicy: 'no-cache',
        variables: {
          ids: idList,
        },
      })
      .pipe(
        map((response: any) => {
          const data = [];
          for (const edge of response.data.vendorAchs.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  getEncryptedVendorsData(idList: string[]): Observable<VendorData[]> {
    return this.apollo
      .use('kimono')
      .query({
        query: this.queryVendorDataWithIds(),
        fetchPolicy: 'no-cache',
        variables: {
          ids: idList,
        },
      })
      .pipe(
        map((response: any) => {
          const data = [];
          for (const edge of response.data.vendorData.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  queryVendorAchsWithIds() {
    return gql`
      query VendorAchs($ids: [UUID!]!) {
        vendorAchs(where: {vendorId: {in: $ids}}) {
          edges {
            node {
              id
              accountNumberDisplay
              dataKey
              dataVector
              routingNumber
              consumerAcct
              checkingAcct
              customerId
              siteId
              vendorId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      }
    `;
  }

  private queryVendorDataWithIds() {
    return gql`
      query VendorData($ids: [UUID!]!) {
        vendorData(where: {id: {in: $ids}}) {
          edges {
            node {
              id
              accountNumberData
              siteId
              customerId
              createdBy
              createdOn
              modifiedBy
              modifiedOn
            }
          }
        }
      }
    `;
  }

  queryHousingAuthorityAchs() {
    return gql`
      query HousingAuthorityAchs {
        housingAuthorityAchs {
          edges {
            cursor
            node {
              id
              bankTokenDisplay
              dataKey
              dataVector
              odfiRoutingNumber
              odfiName
              achDescription
              achNameOnAccount
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      }
    `;
  }

  queryAuthorityAch() {
    return gql`
      query AuthorityData($authorityId: UUID!) {
        authorityData(where: {id: {eq: $authorityId}}) {
          edges {
            node {
              id
              tokenData
            }
          }
        }
      }
    `;
  }

  createAuthorityAch<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('achService').mutate({
      mutation: gql`
        mutation createAuthorityAch($command: CreateAuthorityAchCommandInput!) {
          createAuthorityAch(command: $command) {
            commandName
            status
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              bankTokenDisplay
              dataKey
              dataVector
              odfiRoutingNumber
              odfiName
              achDescription
              achNameOnAccount
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateAuthorityAch<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('achService').mutate({
      mutation: gql`
        mutation updateAuthorityAch($command: UpdateAuthorityAchCommandInput!) {
          updateAuthorityAch(command: $command) {
            commandName
            status
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              bankTokenDisplay
              dataKey
              dataVector
              odfiRoutingNumber
              odfiName
              achDescription
              achNameOnAccount
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
              deletedOn
              deletedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
}
