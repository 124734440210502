import {HttpClient, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Observable, throwError, EMPTY} from 'rxjs';
import {Injectable} from '@angular/core';
import {ReportingService} from './reporting.service';
import {STAGE_MAPPINGS} from '../constants/environment.constants';

@Injectable({providedIn: 'root'})
export class BaseService {
  apiPath: string;
  secret: string;
  NODE_ENV: string;
  stage: string;
  siteId: string;
  customerId: string;
  header: any;

  constructor(public http: HttpClient) {
    this.apiPath = sessionStorage.getItem('BASE_SERVICE_URI');
    this.secret = sessionStorage.getItem('BASE_SERVICE_API_KEY');
    this.siteId = sessionStorage.getItem('SITEID');
    this.customerId = sessionStorage.getItem('CUSTOMERID');
    this.NODE_ENV = sessionStorage.getItem('NODE_ENV');
    this.stage = this.getStageContext();
    this.setServiceHeaders();
  }

  public getStageContext(): string {
    if (!this.NODE_ENV) {
      console.warn('NODE_ENV is not set, defaulting to prod stage');
      return 'prod';
    }

    const matchedStage = STAGE_MAPPINGS.find(mapping => this.NODE_ENV === mapping.keyword);
    return matchedStage?.stage || 'prod';
  }

  public getReportingSubDomain(): string {
    return ReportingService.getInstance().getReportingSubDomain();
  }

  private setServiceHeaders(): void {
    const cookies = decodeURIComponent(document.cookie).split(';');
    const jwt = cookies.filter(x => x.indexOf('.idToken') > 0)[0].split('=')[1];
    this.header = {
      headers: {
        'content-type': 'application/json',
        'x-api-key': this.secret,
        'x-site-id': this.siteId,
        'x-customer-id': this.customerId,
        'x-token': jwt,
      },
    };
  }

  protected handleError(error: HttpErrorResponse | any, customErrorMessage?: string) {
    let errMsg: string;
    if (error instanceof HttpErrorResponse) {
      const err = error.message || JSON.stringify(error.error);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    if (customErrorMessage) {
      errMsg = `${customErrorMessage} ${errMsg}`;
    }

    console.error(errMsg);
    const newError = Object.assign({}, error, {errorEmmitted: true});
    return throwError(newError);
  }

  checkResponse<T>(res: any) {
    try {
      return JSON.parse(JSON.stringify(res)) as T;
    } catch (error) {
      console.log(`error in checkresponse ${error}`);
    }
  }

  apiPut<T>(method: string, params?: any) {
    return this.http.put<T>(method, JSON.stringify(params), this.header).pipe(
      map(res => this.checkResponse<T>(res)),
      catchError(error => this.handleError(error))
    );
  }

  apiGet<T>(method: string): Observable<T> {
    try {
      return this.http.get<T>(method, this.header).pipe(
        map(res => this.checkResponse<T>(res)),
        catchError(error => this.handleError(error))
      ) as Observable<T>;
    } catch (error) {
      console.log(`error in get ${error}`);
    }
  }

  apiPost<T>(method: string, params?: any): Observable<T> {
    try {
      params = JSON.stringify(params);
      return this.http.post<T>(method, params, this.header).pipe(
        map(res => this.checkResponse<T>(res)),
        catchError(error => this.handleError(error))
      );
    } catch (error) {
      console.log(`Post error" ${error}`);
    }
  }

  apiDelete<T>(method: string) {
    return this.http.delete<T>(method, this.header).pipe(
      map(res => this.checkResponse<T>(res)),
      catchError(error => this.handleError(error))
    );
  }
}
