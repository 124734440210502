import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CoreService} from 'src/app/core/service/core.service';
import {BaseService} from 'src/app/kanso-common/core/service';
import {
  CreateUtilityAllowanceCommandInput,
  UpdateUtilityAllowanceCommandInput,
  CloneUtilityAllowanceCommandInput,
  DeleteUtilityAllowanceCommandInput,
  CreateUtilityAllowanceTableCommandInput,
  CreateUtilityAllowanceTableValuesCommandInput,
  DeleteUtilityAllowanceTableValueCommandInput,
  UpdateUtilityAllowanceTableValuesCommandInput,
} from './setup-utility-object';
import {
  CreateUtilityAllowanceOverrideCommand,
  DeleteUtilityAllowanceOverrideCommand,
  OverridesCommandListObject,
  UpdateUtilityAllowanceOverrideCommand,
  UtilityActionType,
  UtilityAllowance,
  UtilityAllowanceDto,
  UtilityAllowanceObject,
  UtilityAllowanceOverride,
  UtilityAllowancesTable,
  UtilityAllowancesTableDto,
  UtilityAllowancesTableObject,
  UtilityAllowancesTableOverrideValue,
  UtilityAllowancesTableValue,
} from './setup-utility-model';
import moment from 'moment';

@Injectable({providedIn: 'root'})
export class SetupUtilityAllowancesService extends BaseService {
  apollo: Apollo;
  _http: any;
  siteId: string;
  customerId: string;
  currentUser: string;
  constructor(public http: HttpClient, public coreService: CoreService, private apolloProvider: Apollo) {
    super(http);
    this.siteId = sessionStorage.getItem('SITEID');
    this.customerId = sessionStorage.getItem('CUSTOMERID');
    this.currentUser = this.coreService.getCurrentUsersLogInCookie();
    this.apollo = this.apolloProvider;
    const setupUtilityHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...setupUtilityHeaders}},
      'utility'
    );
  }

  utitlityActionCommandBuilder(actionType: number, existingObject?: any) {
    let commandObject;
    switch (actionType) {
      case UtilityActionType.addUnitType: {
        const createUnitTypeCommand = new CreateUtilityAllowanceCommandInput();
        createUnitTypeCommand.createdBy = this.currentUser;
        commandObject = createUnitTypeCommand;
        break;
      }
      case UtilityActionType.updateUnitType: {
        const updateUnitTypeCommand = new UpdateUtilityAllowanceCommandInput();
        updateUnitTypeCommand.id = existingObject.id;
        updateUnitTypeCommand.name = existingObject.name;
        updateUnitTypeCommand.modifiedBy = this.currentUser;
        commandObject = updateUnitTypeCommand;
        break;
      }
      case UtilityActionType.deleteUnitType: {
        const deleteUnitTypeCommand = new DeleteUtilityAllowanceCommandInput();
        deleteUnitTypeCommand.id = existingObject.id;
        commandObject = deleteUnitTypeCommand;
        break;
      }
      case UtilityActionType.cloneUnitType: {
        const cloneUnitTypeCommand = new CloneUtilityAllowanceCommandInput();
        cloneUnitTypeCommand.id = existingObject.id;
        cloneUnitTypeCommand.name = 'Copy of ' + existingObject.name;
        cloneUnitTypeCommand.createdBy = this.currentUser;
        commandObject = cloneUnitTypeCommand;
        break;
      }
      case UtilityActionType.addEffectiveDate: {
        const createTableCommand = new CreateUtilityAllowanceTableCommandInput();
        createTableCommand.utilityAllowanceId = existingObject.id;
        createTableCommand.createdBy = this.currentUser;
        commandObject = createTableCommand;
        break;
      }
      case UtilityActionType.addUtilityItem: {
        const createTableValuesCommand = new CreateUtilityAllowanceTableValuesCommandInput();
        createTableValuesCommand.utilityAllowanceTableId = existingObject.id;
        createTableValuesCommand.createdBy = this.currentUser;
        commandObject = createTableValuesCommand;
        break;
      }
      case UtilityActionType.editUtilityItem: {
        const updateTableValuesCommand = new UpdateUtilityAllowanceTableValuesCommandInput();
        updateTableValuesCommand.id = existingObject.id;
        updateTableValuesCommand.utilityAllowanceTableId = existingObject.utilityAllowanceTableId;
        updateTableValuesCommand.category = existingObject.category;
        updateTableValuesCommand.description = existingObject.description;
        updateTableValuesCommand.bedroom0 = existingObject.bedroom0;
        updateTableValuesCommand.bedroom1 = existingObject.bedroom1;
        updateTableValuesCommand.bedroom2 = existingObject.bedroom2;
        updateTableValuesCommand.bedroom3 = existingObject.bedroom3;
        updateTableValuesCommand.bedroom4 = existingObject.bedroom4;
        updateTableValuesCommand.bedroom5 = existingObject.bedroom5;
        updateTableValuesCommand.bedroom6 = existingObject.bedroom6;
        updateTableValuesCommand.bedroom7 = existingObject.bedroom7;
        updateTableValuesCommand.bedroom8 = existingObject.bedroom8;
        updateTableValuesCommand.modifiedBy = this.currentUser;
        commandObject = updateTableValuesCommand;
        break;
      }
      case UtilityActionType.deleteUtilityItem: {
        const deleteTableValuesCommand = new DeleteUtilityAllowanceTableValueCommandInput();
        deleteTableValuesCommand.id = existingObject.id;
        commandObject = deleteTableValuesCommand;
        break;
      }
    }
    commandObject.siteId = this.siteId;
    commandObject.customerId = this.customerId;

    return commandObject;
  }

  effectiveDateListModifier(list: UtilityAllowancesTableObject[]): UtilityAllowancesTableObject[] {
    const sortedUtilityAllowancesTableObject = list.sort((a, b) => {
      const dateA = new Date(a.effectiveDate).getTime();
      const dateB = new Date(b.effectiveDate).getTime();
      return dateB > dateA ? 1 : -1;
    });
    const modifiedUtilityAllowancesTableObject = sortedUtilityAllowancesTableObject.map((obj, index) => {
      if (index == 0) {
        obj.expectedDisplay = `${moment(obj.effectiveDate).format('MM/DD/YYYY')} (current)`;
      } else {
        obj.expectedDisplay = `${moment(obj.effectiveDate).format('MM/DD/YYYY')} - ${moment(
          sortedUtilityAllowancesTableObject[index - 1].effectiveDate
        )
          .subtract(1, 'day')
          .format('MM/DD/YYYY')}`;
      }
      return obj;
    });
    return modifiedUtilityAllowancesTableObject;
  }

  utilityArraybuilder(objArrayInput: any[], extractField1: string, extractField2?: string, doubleExtract = false): any[] {
    const expectedArray = [];
    if (objArrayInput.length > 0) {
      switch (doubleExtract) {
        case false:
          objArrayInput.map(obj => {
            if (obj[extractField1]) {
              expectedArray.push(obj[extractField1]);
            }
          });
          break;
        case true:
          objArrayInput.map(obj => {
            expectedArray.push(`${obj[extractField1] ? obj[extractField1] : ''}${obj[extractField2] ? obj[extractField2] : ''}`);
          });
          break;
      }
    }
    return expectedArray;
  }

  utilityOverrideCommandHandler(commandObject: OverridesCommandListObject) {
    const observablesArray: Observable<any>[] = [];
    if (commandObject.createCommands.length > 0) {
      for (const command of commandObject.createCommands) {
        observablesArray.push(this.createUtilityAllowanceOverrides(command));
      }
    }
    if (commandObject.updateCommands.length > 0) {
      for (const command of commandObject.updateCommands) {
        observablesArray.push(this.updateUtilityAllowanceOverrides(command));
      }
    }
    if (commandObject.deleteCommands.length > 0) {
      for (const command of commandObject.deleteCommands) {
        observablesArray.push(this.deleteUtilityAllowanceOverrides(command));
      }
    }
    if (observablesArray.length > 0) {
      return forkJoin(observablesArray).toPromise();
    } else {
      return;
    }
  }

  buildCreateUtilityOverrideCommandList(
    utov: UtilityAllowancesTableOverrideValue[],
    unitId: string
  ): CreateUtilityAllowanceOverrideCommand[] {
    const command: CreateUtilityAllowanceOverrideCommand[] = [];
    for (const u of utov) {
      if (u.selected) {
        command.push({
          unitId: unitId,
          utilityAllowanceTableId: u.utilityAllowanceTableId,
          utilityAllowanceTableValueId: u.id,
          selected: u.selected,
          siteId: this.siteId,
          customerId: this.customerId,
          createdBy: this.currentUser,
        });
      }
    }
    return command;
  }

  buildUpdateUtilityOverrideCommandList(utov: UtilityAllowanceOverride[]): UpdateUtilityAllowanceOverrideCommand[] {
    const command: UpdateUtilityAllowanceOverrideCommand[] = [];
    for (const u of utov) {
      if (u.selected) {
        command.push({
          id: u.id,
          utilityAllowanceTableId: u.utilityAllowanceTableId,
          utilityAllowanceTableValueId: u.utilityAllowanceTableValueId,
          selected: u.selected,
          siteId: this.siteId,
          customerId: this.customerId,
          modifiedBy: this.currentUser,
        });
      }
    }
    return command;
  }

  buildDeleteUtilityOverrideCommandList(utov: string[]): DeleteUtilityAllowanceOverrideCommand[] {
    const command: DeleteUtilityAllowanceOverrideCommand[] = [];
    for (const u of utov) {
      command.push({
        id: u,
        siteId: this.siteId,
        customerId: this.customerId,
      });
    }
    return command;
  }

  getAllUtilityAllowances<T>(siteId: string, customerId: string): Observable<UtilityAllowanceObject[]> {
    return this.apollo
      .use('utility')
      .query({
        query: this.queryUtilityAllowances(),
        variables: {
          siteId,
          customerId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((reponse: any) => {
          return reponse.data.utilityAllowance;
        })
      );
  }

  queryUtilityAllowances() {
    return gql`
      query UtilityAllowance {
        utilityAllowance(order: [{name: ASC}]) {
          id
          customerId
          siteId
          name
          createdOn
          createdBy
          modifiedOn
          modifiedBy
          utilityAllowanceTable {
            id
            customerId
            siteId
            utilityAllowanceId
            effectiveDate
            createdOn
            createdBy
            utilityAllowanceTableValues {
              id
              customerId
              siteId
              utilityAllowanceTableId
              category
              description
              bedroom0
              bedroom1
              bedroom2
              bedroom3
              bedroom4
              bedroom5
              bedroom6
              bedroom7
              bedroom8
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
          }
        }
      }
    `;
  }

  queryUtilityAllowance() {
    return gql`
      query UtilityAllowance($id: UUID) {
        utilityAllowance(where: {id: {eq: $id}}) {
          id
          customerId
          siteId
          name
          createdOn
          createdBy
          modifiedOn
          modifiedBy
          utilityAllowanceTable {
            id
            customerId
            siteId
            utilityAllowanceId
            effectiveDate
            createdOn
            createdBy
            utilityAllowanceTableValues {
              id
              customerId
              siteId
              utilityAllowanceTableId
              category
              description
              bedroom0
              bedroom1
              bedroom2
              bedroom3
              bedroom4
              bedroom5
              bedroom6
              bedroom7
              bedroom8
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
          }
        }
      }
    `;
  }

  getUtilityAllowance<T>(id: string): Observable<UtilityAllowanceDto> {
    return this.apollo
      .use('utility')
      .query({
        query: this.queryUtilityAllowance(),
        variables: {
          siteId: this.siteId,
          customerId: this.customerId,
          id: id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((reponse: any) => {
          const result: UtilityAllowanceDto[] = reponse.data.utilityAllowance;
          if (result.length === 1) {
            return reponse.data.utilityAllowance[0];
          } else {
            return null;
          }
        })
      );
  }

  getUtilityAllowanceOverrides<T>(unitId: string): Observable<UtilityAllowanceOverride[]> {
    return this.apollo
      .use('utility')
      .query({
        query: this.queryUtilityAllowanceOverrides(),
        variables: {
          siteId: this.siteId,
          customerId: this.customerId,
          unitId: unitId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((reponse: any) => {
          return reponse.data.utilityAllowanceOverride;
        })
      );
  }

  queryUtilityAllowanceOverrides() {
    return gql`
      query UtilityAllowanceOverride($unitId: String) {
        utilityAllowanceOverride(where: {unitId: {eq: $unitId}}) {
          id
          siteId
          customerId
          unitId
          utilityAllowanceTableId
          utilityAllowanceTableValueId
          category
          description
          selected
          createdOn
          createdBy
          modifiedOn
          modifiedBy
        }
      }
    `;
  }

  createUtilityAllowanceOverrides<T>(command: CreateUtilityAllowanceOverrideCommand) {
    try {
      return this.apollo.use('utility').mutate({
        mutation: gql`
          mutation CreateUtilityAllowanceOverride($command: CreateUtilityAllowanceOverrideCommandInput!) {
            createUtilityAllowanceOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUtilityAllowanceOverrides<T>(command: UpdateUtilityAllowanceOverrideCommand) {
    try {
      return this.apollo.use('utility').mutate({
        mutation: gql`
          mutation UpdateUtilityAllowanceOverride($command: UpdateUtilityAllowanceOverrideCommandInput!) {
            updateUtilityAllowanceOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteUtilityAllowanceOverrides<T>(command: DeleteUtilityAllowanceOverrideCommand) {
    try {
      return this.apollo.use('utility').mutate({
        mutation: gql`
          mutation DeleteUtilityAllowanceOverride($command: DeleteUtilityAllowanceOverrideCommandInput!) {
            deleteUtilityAllowanceOverride(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createUtilityAllowance<T>(command: CreateUtilityAllowanceCommandInput): Observable<UtilityAllowance> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation createUtilityAllowance($command: CreateUtilityAllowanceCommandInput!) {
              createUtilityAllowance(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  name
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.createUtilityAllowance.status == 'SUCCESS') {
              return response.data.createUtilityAllowance.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUtilityAllowance<T>(command: UpdateUtilityAllowanceCommandInput): Observable<UtilityAllowance> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation updateUtilityAllowance($command: UpdateUtilityAllowanceCommandInput!) {
              updateUtilityAllowance(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  name
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.updateUtilityAllowance.status == 'SUCCESS') {
              return response.data.updateUtilityAllowance.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteUtilityAllowance<T>(command: DeleteUtilityAllowanceCommandInput): Observable<any> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation deleteUtilityAllowance($command: DeleteUtilityAllowanceCommandInput!) {
              deleteUtilityAllowance(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.deleteUtilityAllowance.status == 'SUCCESS') {
              return response.data.deleteUtilityAllowance;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  cloneUtilityAllowance<T>(command: CloneUtilityAllowanceCommandInput): Observable<UtilityAllowance> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation cloneUtilityAllowance($command: CloneUtilityAllowanceCommandInput!) {
              cloneUtilityAllowance(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  name
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.cloneUtilityAllowance.status == 'SUCCESS') {
              return response.data.cloneUtilityAllowance.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createUtilityAllowanceTable<T>(command: CreateUtilityAllowanceTableCommandInput): Observable<UtilityAllowancesTable> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation createUtilityAllowance($command: CreateUtilityAllowanceTableCommandInput!) {
              createUtilityAllowanceTable(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  utilityAllowanceId
                  effectiveDate
                  createdOn
                  createdBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.createUtilityAllowanceTable.status == 'SUCCESS') {
              return response.data.createUtilityAllowanceTable.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createUtilityAllowanceTableValues<T>(command: CreateUtilityAllowanceTableValuesCommandInput): Observable<UtilityAllowancesTableValue> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation createUtilityAllowanceTableValues($command: CreateUtilityAllowanceTableValuesCommandInput!) {
              createUtilityAllowanceTableValues(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  utilityAllowanceTableId
                  category
                  description
                  bedroom0
                  bedroom1
                  bedroom2
                  bedroom3
                  bedroom4
                  bedroom5
                  bedroom6
                  bedroom7
                  bedroom8
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.createUtilityAllowanceTableValues.status == 'SUCCESS') {
              return response.data.createUtilityAllowanceTableValues.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateUtilityAllowanceTableValues<T>(command: UpdateUtilityAllowanceTableValuesCommandInput): Observable<UtilityAllowancesTableValue> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation updateUtilityAllowanceTableValues($command: UpdateUtilityAllowanceTableValuesCommandInput!) {
              updateUtilityAllowanceTableValues(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
                affectedEntity {
                  id
                  customerId
                  siteId
                  utilityAllowanceTableId
                  category
                  description
                  bedroom0
                  bedroom1
                  bedroom2
                  bedroom3
                  bedroom4
                  bedroom5
                  bedroom6
                  bedroom7
                  bedroom8
                  createdOn
                  createdBy
                  modifiedOn
                  modifiedBy
                }
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.updateUtilityAllowanceTableValues.status == 'SUCCESS') {
              return response.data.updateUtilityAllowanceTableValues.affectedEntity;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteUtilityAllowanceTableValues<T>(command: DeleteUtilityAllowanceTableValueCommandInput): Observable<any> {
    try {
      return this.apollo
        .use('utility')
        .mutate({
          mutation: gql`
            mutation deleteUtilityAllowanceTableValue($command: DeleteUtilityAllowanceTableValueCommandInput!) {
              deleteUtilityAllowanceTableValue(command: $command) {
                commandName
                status
                issuedOn
                acceptedOn
                succeededOn
                failedOn
                failureReason
              }
            }
          `,
          variables: {
            command,
          },
        })
        .pipe(
          map((response: any) => {
            if (response.data.deleteUtilityAllowanceTableValue.status == 'SUCCESS') {
              return response.data.deleteUtilityAllowanceTableValue;
            } else {
              return null;
            }
          })
        );
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }
}
