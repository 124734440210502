<form #unitForm="ngForm">
    <mat-card class="knso-card unit-setup-container">
        <div *ngIf="isBusy" class="layout-row spinnerPadding">
            <mat-spinner diameter="25" > </mat-spinner> 
            <div class="leftPadding">Loading unit...</div>
        </div>
        <mat-card-header *ngIf="!isBusy">
            <mat-toolbar>
                <span>{{!editMode ? 'New Unit' : editUnit.streetAddress}}</span>
                <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
        </mat-card-header>
        <mat-card-content *ngIf="!isBusy">
            <div class="mat-card-content-content">
                <div>
                    <div class="section-header chevronAlign">
                        <a class="account__details-collapse">
                            <mat-icon class="collapse-icon" 
                                [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                                (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                            </mat-icon>
                        </a>
                        <div class="headerPadding">
                            General Information
                        </div>
                    </div> 
                    <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35" *ngIf="programList && !editMode">
                                <mat-label>Program</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.program" 
                                    (selectionChange)="onProgramChange()"
                                    ngModel
                                    #program="ngModel"
                                    name="program"
                                    required>
                                    <mat-option *ngFor="let pl of programList"
                                        [value]="pl._id">
                                        <span>{{pl.name}}</span>
                                    </mat-option>
                                    <mat-error *ngIf="program.hasError('required')">
                                        <div ng-message="required">Program is required.</div>
                                    </mat-error>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-35" *ngIf="projectList && !editMode">
                                <mat-label>Project</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.project" 
                                    (selectionChange)="onProjectChange()"
                                    ngModel
                                    #project="ngModel"
                                    name="project"
                                    required>
                                    <mat-option *ngFor="let pl of projectList"
                                        [value]="pl._id">
                                        <span>{{pl.name}}</span>
                                    </mat-option>
                                    <mat-error *ngIf="project.hasError('required')">
                                        <div ng-message="required">Project is required.</div>
                                    </mat-error>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Street</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.streetAddress" 
                                    ngModel
                                    #streetAddress="ngModel"
                                    name="Street Address"
                                    required 
                                    aria-label="Street address" >
                                    <mat-error *ngIf="streetAddress.hasError('required')">
                                        <div ng-message="required">Street Address is required.</div>
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Apt #</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.apartmentNumber"
                                    ngModel 
                                    #apartmentNumber="ngModel"
                                    name="Apartment Number"
                                    aria-label="Apartment number" >
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Unit Number</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.unitNumber" 
                                    ngModel 
                                    #unitNumber="ngModel"
                                    name="Unit Number"
                                    aria-label="Unit Number">
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>City</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.city"
                                    ngModel 
                                    #city="ngModel"
                                    name="City"
                                    required 
                                    aria-label="City" >
                                    <mat-error *ngIf="city.hasError('required')">
                                        <div ng-message="required">City is required.</div>
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>State</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.state"
                                    ngModel 
                                    #state="ngModel"
                                    name="State"
                                    required 
                                    aria-label="State" >
                                <mat-error *ngIf="state.hasError('required')">
                                    <div ng-message="required">State is required.</div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Postal Code</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.postalCode" 
                                    ngModel 
                                    #postalCode="ngModel"
                                    name="Postal Code"
                                    aria-label="Postal Code"
                                    (keypress)="numericOnly($event)" 
                                    minlength="5"
                                    maxlength="5"
                                    (paste)="false"
                                    autocomplete="off"
                                    >
                                <mat-error *ngIf="postalCode.hasError('minlength')">
                                        <div ng-message="required">Minimum length of 5</div>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>+ 4</mat-label>
                                <input 
                                    matInput                             
                                    [(ngModel)]="editUnit.postalCodePlus4" 
                                    ngModel 
                                    #postalCodePlus4="ngModel"
                                    name="Postal Code Plus 4"
                                    aria-label="Zip Code plus 4"
                                    (keypress)="numericOnly($event)"
                                    minlength="4"
                                    maxlength="4"
                                    (paste)="false"
                                    autocomplete="off"
                                    >
                                <mat-error *ngIf="postalCodePlus4.hasError('minlength')">
                                    <div ng-message="required">Minimum length of 4</div>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="flex-30"  appearance="outline">
                                <mat-label>County</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.county" 
                                    ngModel 
                                    #county="ngModel"
                                    name="county"
                                    aria-label="County">
                            </mat-form-field>
                            <mat-form-field 
                                class="flex-30" 
                                appearance="outline" 
                                *ngIf="program && (program.type == 'Public Housing' || program.type == 'Assistance' || program.type == 'Home Ownership')"
                                >
                                <mat-label>Unit Real Estate Id</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.unitRealEstateId" 
                                    ngModel 
                                    #unitRealEstateId="ngModel"
                                    name="unitRealEstateId"
                                    minlength="11"
                                    maxlength="11"
                                    aria-label="Unit Real Estate Id">
                                <mat-error *ngIf="unitRealEstateId.hasError('minlength')">
                                    <div ng-message="minlength">Minimum length of 11</div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>PIC Unit Number</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.picUnitNumber"
                                    ngModel 
                                    #picUnitNumber="ngModel"
                                    name="Pic Unit Number"
                                    id="Pic Unit Number"
                                    maxlength="5"
                                    pattern="^[a-zA-Z0-9]*"
                                    >
                                    <mat-error *ngIf="picUnitNumber.hasError('maxlength')">
                                        <div ng-message="maxlength">Can not over exceed the length of 5.</div>
                                    </mat-error>
                                    <mat-error *ngIf="picUnitNumber.hasError('pattern')">
                                        <div ng-message="pattern">PIC Unit Number may not contain any special characters.</div>
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>BIN</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.bin" 
                                    ngModel 
                                    #bin="ngModel"
                                    name="Bin Number"
                                    maxlength="11"
                                    pattern="^[a-zA-Z]{2,2}-\d{2,2}-\d{5,5}"
                                    >
                                    <mat-hint>
                                        ex. format: AA-00-00000
                                    </mat-hint>
                                    <mat-error *ngIf="bin.hasError('pattern')">
                                        <div ng-message="pattern">Please enter a valid 11 characters, ex: AA-00-00000.</div>
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Property ID</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.propertyId" 
                                    ngModel 
                                    #propertyId="ngModel"
                                    name="Property Id"
                                    maxlength="50"
                                    aria-label="Property ID">
                            </mat-form-field>
                            <mat-form-field class="flex-30" appearance="outline">
                                <mat-label>Number of Bedrooms</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.numberOfBedrooms" 
                                    [compareWith]="compareValues"
                                    ngModel
                                    #numberOfBedrooms="ngModel"
                                    required
                                    name="numberOfBedrooms"
                                    >
                                    <mat-option [value]="0" id="0Bedrooms">0</mat-option>
                                    <mat-option [value]="1" id="1Bedrooms">1</mat-option>
                                    <mat-option [value]="2" id="2Bedrooms">2</mat-option>
                                    <mat-option [value]="3" id="3Bedrooms">3</mat-option>
                                    <mat-option [value]="4" id="4Bedrooms">4</mat-option>
                                    <mat-option [value]="5" id="5Bedrooms">5</mat-option>
                                    <mat-option [value]="6" id="6Bedrooms">6</mat-option>
                                    <mat-option [value]="7" id="7Bedrooms">7</mat-option>
                                    <mat-option [value]="8" id="8Bedrooms">8</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Building Name</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.buildingName" 
                                    ngModel 
                                    #buildingName="ngModel"
                                    name="Building Name"
                                    aria-label="Building Name">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Building Number</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.buildingNumber" 
                                    ngModel 
                                    #buildingNumber="ngModel"
                                    name="Building Number"
                                    maxlength="6"
                                    pattern="^[\w-_.]*$"
                                    aria-label="Building Number">
                                <mat-error *ngIf="buildingNumber.hasError('pattern'); else showHint">
                                    <div ng-message="pattern">Special charaters are not allowed.</div>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-20">
                                <mat-label>Entrance Number</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.entranceNumber" 
                                    ngModel 
                                    #entranceNumber="ngModel"
                                    name="Entrance Number"
                                    aria-label="Entrance Number">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Number of Bathrooms</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.numberOfBathrooms"                             
                                    [compareWith]="compareValues"
                                    ngModel
                                    #numberOfBathrooms="ngModel"
                                    name="Number Of Bathrooms"
                                    required
                                    >
                                    <mat-option [value]="0" id="0Bathrooms">0</mat-option>
                                    <mat-option [value]="1" id="1Bathrooms">1</mat-option>
                                    <mat-option [value]="2" id="2Bathrooms">2</mat-option>
                                    <mat-option [value]="3" id="3Bathrooms">3</mat-option>
                                    <mat-option [value]="4" id="4Bathrooms">4</mat-option>
                                    <mat-option [value]="5" id="5Bathrooms">5</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row unit-radio-area layout-align-start-center">
                            <div>
                                <mat-label>1937 Unit?</mat-label>
                                <mat-radio-group 
                                    [(ngModel)]="editUnit.is1937Unit" 
                                    class="flex-20"
                                    ngModel
                                    #is1937Unit="ngModel"
                                    name="is1937Unit">
                                    <mat-radio-button 
                                        [value]="true"
                                        [checked]="is1937Unit.value == true"
                                        >Yes </mat-radio-button>
                                    <mat-radio-button 
                                        [value]="false" 
                                        [checked]="is1937Unit.value == false"
                                        >No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div>
                                <mat-label>CAS Excluded?</mat-label>
                                <mat-radio-group 
                                    [(ngModel)]="editUnit.isCasExcluded" 
                                    class="flex-20"
                                    ngModel
                                    #isCasExcluded="ngModel"
                                    name="isCasExcluded">
                                    <mat-radio-button 
                                        [value]="true"
                                        [checked]="isCasExcluded.value == true"
                                        >Yes </mat-radio-button>
                                    <mat-radio-button 
                                        [value]="false" 
                                        [checked]="isCasExcluded.value == false"
                                        >No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div>
                                <mat-label>Handicap accessible?</mat-label>
                                <mat-radio-group 
                                    [(ngModel)]="editUnit.isHandicapAccessible" 
                                    class="flex-20"
                                    ngModel
                                    #isHandicapAccessible="ngModel"
                                    name="isHandicapAccessible">
                                    <mat-radio-button 
                                        [value]="true"
                                        [checked]="isHandicapAccessible.value == true"
                                        >Yes </mat-radio-button>
                                    <mat-radio-button 
                                        [value]="false" 
                                        [checked]="isHandicapAccessible.value == false"
                                        >No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
            
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>Converted From Program</mat-label>
                                <mat-select 
                                    name="convertedFromProgramId" 
                                    [(ngModel)]="editUnit.convertedFromProgramId" 
                                    (selectionChange)="onConvertedChanged()"
                                    aria-label="converted from which program" 
                                    id="convertedFromDropdown">
                                    <mat-option id="emptyConvertedFrom" mat-option-empty="true" [value]="null">Not Converted</mat-option>
                                    <mat-option *ngFor="let p of programList"
                                        [value]="p._id">
                                        <span>{{p.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-35" *ngIf="editUnit.convertedFromProgramId">
                                <mat-label>Converted From Project</mat-label>
                                <mat-select
                                    name="convertedFromProjectId" 
                                    [(ngModel)]="editUnit.convertedFromProjectId"
                                    aria-label="converted from which project" 
                                    id="convertedFromProjectDropdown">
                                    <mat-option id="convertedFromProject" mat-option-empty="true" [value]="null">Not Converted</mat-option>
                                    <mat-option *ngFor="let v of convertedProjectList"
                                        [value]="v._id">
                                        <span>{{v.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Date of Full Availability (DOFA)</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.dateOfFullAvailability" 
                                    ngModel 
                                    #dateOfFullAvailability="ngModel"
                                    name="dateOfFullAvailability"
                                    aria-label="Date of Full Availability (DOFA)"
                                    [matDatepicker]="dofaDate">
                                    <mat-datepicker-toggle class="kansoBlue"  matIconSuffix [for]="dofaDate"></mat-datepicker-toggle>
                                    <mat-datepicker #dofaDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>DCE Threshold Exceeded</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.dceThresholdExceeded" 
                                    ngModel 
                                    #dceThresholdExceeded="ngModel"
                                    name="dceThresholdExceeded"
                                    aria-label="Date of Full Availability (DOFA)"
                                    [matDatepicker]="dceDate">
                                <mat-datepicker-toggle class="kansoBlue"  matIconSuffix [for]="dceDate"></mat-datepicker-toggle>
                                <mat-datepicker #dceDate></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Created</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.createdOn" 
                                    [matDatepicker]="createdDatePicker"
                                    ngModel
                                    #createdOn="ngModel"
                                    name="created"
                                    >
                                    <mat-datepicker-toggle class="kansoBlue"  matIconSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #createdDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Total Development Cost (TDC)</mat-label>
                                <input 
                                    matInput
                                    type="number"
                                    [(ngModel)]="editUnit.totalDevelopmentCost" 
                                    ngModel 
                                    #totalDevelopmentCost="ngModel"
                                    name="Total Development Cost"
                                    aria-label="Total Development Cost (TDC)">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Square Footage</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.squareFootage"
                                    ngModel 
                                    #squareFootage="ngModel"
                                    name="Square Footage" 
                                    aria-label="Square Footage"
                                    type="number"
                                    >
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-30">
                                <mat-label>Year Constructed</mat-label>
                                <input 
                                    matInput 
                                    type="number"
                                    [(ngModel)]="editUnit.yearConstructed" 
                                    ngModel 
                                    #yearConstructed="ngModel"
                                    name="Year Constructed" 
                                    aria-label="Year Constructed">
                            </mat-form-field>
                        </div>
                        <!-- GraphQL Vendor Connection -->
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>Landlord</mat-label>
                                <input matInput
                                    aria-label="Landlord"
                                    [matAutocomplete]="landlordAuto"
                                    [formControl]="landlordCtrl">
                                <button mat-button *ngIf="landlordCtrl.value" matSuffix mat-icon-button (click)="clearLandlordSelection()">
                                    <mat-icon>close</mat-icon>
                                </button>                                    
                                <mat-autocomplete #landlordAuto="matAutocomplete" [displayWith]="vendorSelectDisplay" (optionSelected)="onLandlordSelected($event)">
                                    <mat-option *ngFor="let ll of filteredLandlordVendors$ | async;" 
                                        [value]="ll">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <span *ngIf="tenantCheck">Note: You cannot change landlord while unit is assigned or
                                    occupied</span>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>Utility Vendor</mat-label>
                                <input matInput
                                    aria-label="Utility Vendor"
                                    [matAutocomplete]="utilityAuto"
                                    [formControl]="utilityVendorCtrl">
                                <button mat-button *ngIf="utilityVendorCtrl.value" matSuffix mat-icon-button (click)="clearUtilitySelection()">
                                    <mat-icon>close</mat-icon>
                                </button>                                    
                                <mat-autocomplete #utilityAuto="matAutocomplete" [displayWith]="vendorSelectDisplay" (optionSelected)="onUtilityVendorSelected($event)">
                                    <mat-option *ngFor="let ll of filteredUtilityVendors$ | async;" 
                                        [value]="ll">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-autocomplete>
                                <span *ngIf="tenantCheck">Note: You cannot change Utility Vendor while unit is assigned or
                                    occupied</span>
                            </mat-form-field>
                            <div *ngIf="program" class="flex-30 utility-allowance-overrides">
                                <mat-form-field *ngIf="program.rentCalc.isUtilityAllowanceCalculations" appearance="outline" class="flex utility-allowance-dropdown">
                                    <mat-label>Unit Type</mat-label>
                                    <input
                                        matInput
                                        aria-label="Utility Allowance"
                                        [matAutocomplete]="utilityAllowanceAuto"
                                        [formControl]="utilityAllowanceCtrl"
                                        >
                                    <button mat-button *ngIf="utilityAllowanceCtrl.value" matSuffix mat-icon-button (click)="clearUtilityAllowanceSelection()">
                                        <mat-icon>close</mat-icon>
                                    </button>                                    
                                    <mat-autocomplete #utilityAllowanceAuto="matAutocomplete" [displayWith]="utilitySelectDisplay" (optionSelected)="onUtilityAllowanceSelected($event)">
                                        <mat-option
                                            *ngFor="let ul of filteredUtilityAllowances$ | async;" 
                                            [value]="ul">
                                            <span>{{ul.name}}</span>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="overrides-btn"><span *ngIf="utilityAllowanceCtrl.value" (click)="openUtilityOverrideDialog() ">{{editUnit.utilityAllowanceOverride ? 'Edit' : 'Select'}} Applicable Utilities</span></div>
                            </div>
                        </div>
                        <!-- description in SQL was map by LegalDescripton in Mongo -->
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-70">
                                <mat-label>Legal Description</mat-label>
                                <textarea 
                                    matInput 
                                    [(ngModel)]="editUnit.description"
                                    ngModel 
                                    #description="ngModel"
                                    name="Description" 
                                    aria-label="Legal Description"
                                    rows="2"
                                    >
                                </textarea>
                            </mat-form-field>
                            <div class="non-inventory-area">
                                <mat-label>Non-Inventory</mat-label>
                                <mat-radio-group 
                                    [(ngModel)]="editUnit.isNonInventoryUnit" 
                                    ngModel
                                    #isNonInventoryUnit="ngModel"
                                    name="isNonInventoryUnit">
                                    <mat-radio-button 
                                        [value]="true"
                                        [checked]="isNonInventoryUnit.value == true"
                                        >Yes </mat-radio-button>
                                    <mat-radio-button 
                                        [value]="false" 
                                        [checked]="isNonInventoryUnit.value == false"
                                        >No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <div class="section-header chevronAlign">
                        <a class="account__details-collapse">
                            <mat-icon class="collapse-icon" 
                                [ngClass]="{'collapse-icon--open' : customAttributesInfoPanelOpenState}"
                                (click)="customAttributesInfoPanelOpenState = !customAttributesInfoPanelOpenState">chevron_right
                            </mat-icon>
                        </a>
                        <div class="headerPadding">
                            Custom Attributes
                        </div>
                    </div> 
                    <mat-expansion-panel [expanded]="customAttributesInfoPanelOpenState" class="hidePanel">
                        <div *ngIf="!isBusy">
                            <div class="section-body" layout-align="start center">
                                <p *ngIf="attributeCount === 0">No custom attributes apply to this Unit</p>
                                <app-custom-attributes *ngIf="attributeCount > 0"  
                                    [entity]="'Unit'" 
                                    [editMode]="true" 
                                    [(currentCustomAttributes)]="customAttributes"
                                    [sqlData]="true"
                                    (currentCustomAttributesChange)="attributeChangeHandler($event)">
                                </app-custom-attributes>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    
                    <div class="section-header chevronAlign">
                        <a class="account__details-collapse">
                            <mat-icon class="collapse-icon" 
                                [ngClass]="{'collapse-icon--open' : monthlyChargeInfoPanelOpenState}"
                                (click)="monthlyChargeInfoPanelOpenState = !monthlyChargeInfoPanelOpenState">chevron_right
                            </mat-icon>
                        </a>
                        <div class="headerPadding">
                            Monthly Charge Calculation Information
                        </div>
                    </div>
                    
                    <mat-expansion-panel  [expanded]="monthlyChargeInfoPanelOpenState" class="hidePanel" *ngIf="editUnit.rentCalc">
                        <mat-checkbox
                            class="checkbox-padding" 
                            [(ngModel)]="editUnit.overrideRentCalc"
                            ngModel
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleOverride(editUnit.overrideRentCalc)"
                            #unitRentCalcOverride
                            [disabled]="!editUnit.rentCalc"
                            name="unitRentCalcOverride"
                            >Override Program settings
                        </mat-checkbox>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Total Tenant Payment Percent (input as a decimal, e.g. 0.3 for 30%)</mat-label>
                                <input 
                                    matInput
                                    [(ngModel)]="editUnit.rentCalc.totalTenantPaymentPercentage" 
                                    ngModel
                                    #totalTenantPaymentPercentage="ngModel"
                                    name="totalTenantPaymentPercentage"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    type="number"
                                    >
                                    <mat-hint>
                                        Percentage of adjusted income used to compute monthly charges if this project is income-based
                                    </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Allowable Dependent Deduction</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.allowableDependentDeduction" 
                                    ngModel
                                    #allowableDependentDeduction="ngModel"
                                    name="allowableDependentDeduction"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    type="number"
                                    aria-label="Allowable dependent deduction">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Allowable Elderly/Disability Deduction</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.allowableElderlyDisabilityDeduction" 
                                    ngModel
                                    #allowableElderlyDisabilityDeduction="ngModel"
                                    name="allowableElderlyDisabilityDeduction"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    type="number"
                                    aria-label="Allowable elderly deduction">
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>Age to Qualify for Elderly Deduction</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.elderlyDeductionAge"
                                    ngModel
                                    #elderlyDeductionAge="ngModel"
                                    name="elderlyDeductionAge"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    type="number" 
                                    aria-label="Elderly deduction age">
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-35">
                                <mat-label>Age to Qualify for Near Elder Status</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.nearElderAge" 
                                    ngModel
                                    #nearElderAge="ngModel"
                                    name="nearElderAge"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    type="number"
                                    aria-label="Near elder age">
                            </mat-form-field>
                        </div>
                        <div class="section-body rentCalc-radio-area layout-row layout-align-start-center">
                            <mat-label class="flex-35">Observe NAHASDA 30% Rule?</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editUnit.rentCalc.observeNahasda30Percent" 
                                class="flex-20"
                                ngModel
                                #observeNahasda30Percent="ngModel"
                                name="observeNahasda30Percent"
                                [disabled]="editUnit.overrideRentCalc == false"
                                >
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="observeNahasda30Percent.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="observeNahasda30Percent.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Calc Key</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.calcKey" 
                                    ngModel
                                    #calcKey="ngModel"
                                    name="calcKey"
                                    [disabled]="editUnit.overrideRentCalc == false"
                                    required>
                                    <mat-error *ngIf="calcKey.hasError('required')">
                                        <div ng-message="required">RentCalc Key is required.</div>
                                    </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Assistance Calc Key</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.assistanceCalcKey"
                                    ngModel
                                    #assistanceCalcKey="ngModel"
                                    name="assistanceCalcKey"
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    aria-label="Assistance Calc Key">
                            </mat-form-field>
                        </div>                                      
                        <div *ngIf="program && program.type=='Public Housing'"
                             class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Min TTP (50058 Form, 9h)</mat-label>
                                <input 
                                    matInput 
                                    [(ngModel)]="editUnit.rentCalc.minTtp"
                                    ngModel
                                    #minTtp="ngModel"
                                    name="minTtp"
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    aria-label="Minimum Total Tenant Payment">
                            </mat-form-field>
                        </div>
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Income Limits Area</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.rentCalc.incomeLimitArea" 
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    [compareWith]="compareValues"
                                    ngModel
                                    #incomeLimitArea="ngModel"
                                    name="incomeLimitArea">
                                    <mat-option *ngFor="let ll of housingAuthority.incomeLimitAreas"
                                        [value]="ll._id">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Rent Limits Area</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.rentCalc.rentLimitArea" 
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    [compareWith]="compareValues"
                                    ngModel
                                    #incomeLimitArea="ngModel"
                                    name="rentLimitArea">
                                    <mat-option *ngFor="let ll of housingAuthority.rentLimitAreas"
                                        [value]="ll._id">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>        
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Payment Standard</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.rentCalc.paymentStandard" 
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    [compareWith]="compareValues"
                                    ngModel
                                    #paymentStandard="ngModel"
                                    name="paymentStandard">
                                    <mat-option *ngFor="let ll of housingAuthority.paymentStandards"
                                        [value]="ll._id">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>                
                        </div>                       
                        <div *ngIf="program && program.type=='Tax Credit'" class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-40">
                                <mat-label>Tax Credits Area</mat-label>
                                <mat-select 
                                    [(ngModel)]="editUnit.rentCalc.taxCreditIncomeLimitArea" 
                                    [disabled]="editUnit.overrideRentCalc == false" 
                                    [compareWith]="compareValues"
                                    ngModel
                                    #incomeLimitArea="ngModel"
                                    name="incomeLimitArea">
                                    <mat-option *ngFor="let ll of housingAuthority.incomeLimitAreas"
                                        [value]="ll._id">
                                        <span>{{ll.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>    
                        
                        <div class="section-body rentCalc-radio-area layout-row layout-align-start-center">
                            <mat-label class="flex-35">Is this Project Income-Based?</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editUnit.rentCalc.incomeBased" 
                                class="flex-20"
                                ngModel
                                #incomeBased="ngModel"
                                name="incomeBased"
                                [disabled]="editUnit.overrideRentCalc == false"
                                >
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="incomeBased.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="incomeBased.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="layout-row">
                            <div flex class="charge-calc-item">
                                <h5>Admin Fee</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('adminFees')"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.adminFees" class="defined-messeage">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped"
                            *ngIf="editUnit.rentCalc.adminFees">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let f of editUnit.rentCalc.adminFees; let i = index"
                                id="adminFeeInput">
                                <td><span id="adminFeeCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="adminFeeCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="adminFeeEffectiveDateText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span *ngIf="f.value[editUnit.numberOfBedrooms] === null || f.value[editUnit.numberOfBedrooms] === undefined">N/A for this bedroom size</span>
                                    <span id="adminFeeValue">
                                        {{
                                            ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                            ? f.value[editUnit.numberOfBedrooms] 
                                            : f.value[unit.numberOfBedrooms] 
                                            ) /100 | currency
                                        }}
                                    </span>
                                </td>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;" *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('adminFees', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <a style="font-size:18px;margin-left: 10px;" *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('adminFees', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="layout-row">
                            <div flex class="charge-calc-item">
                                <h5>Minimum Rent</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('minimumCharges')"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.minimumCharges">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped"
                            *ngIf="editUnit.rentCalc.minimumCharges">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>    
                            <tr *ngFor="let f of editUnit.rentCalc.minimumCharges; let i = index"
                                id="minChargeInput">
                                <td><span id="minChargeCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="minChargeCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="minChargeEffectiveDateCreatedByText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span id="minChargeValue">
                                    {{
                                        ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                        ? f.value[editUnit.numberOfBedrooms] 
                                        : f.value[unit.numberOfBedrooms] 
                                        ) /100 | currency
                                    }}
                                    </span>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;"
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('minimumCharges', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <a style="font-size:18px;margin-left: 10px;" 
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('minimumCharges', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    
                        <div class="layout-row">
                            <div flex class="charge-calc-item">
                                <h5>Maximum Charge</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('maximumCharges')"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.maximumCharges">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped"
                            *ngIf="editUnit.rentCalc.maximumCharges">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>                            
                            </thead>
                            <tr *ngFor="let f of editUnit.rentCalc.maximumCharges; let i = index"
                                id="maxChargeInput">                                
                                <td><span id="maxChargeCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="maxChargeCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="maxChargeEffectiveDateText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span id="maxChargeValue">
                                    {{
                                        ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                        ? f.value[editUnit.numberOfBedrooms] 
                                        : f.value[unit.numberOfBedrooms] 
                                        ) /100 | currency
                                    }}
                                    </span>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;"
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('maximumCharges', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <!-- overrideProgramSettings -->
                                        <a style="font-size:18px;margin-left: 10px;" *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('maximumCharges', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    
                        <div class="layout-row">
                            <div flex class="charge-calc-item">
                                <h5>Utility Allowance</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('utilityAllowances')"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.utilityAllowances">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped" *ngIf="editUnit.rentCalc.utilityAllowances">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let f of editUnit.rentCalc.utilityAllowances; let i = index"
                                id="utilityAllowanceInput">
                                <td><span id="utilityAllowanceCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="utilityAllowanceCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="utilityAllowanceEffectiveDateText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span id="utilityAllowanceValue">
                                    {{
                                        ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                        ? f.value[editUnit.numberOfBedrooms] 
                                        : f.value[unit.numberOfBedrooms] 
                                        ) /100 | currency
                                    }}
                                    </span>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;"
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('utilityAllowances', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <a style="font-size:18px;margin-left: 10px;"
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('utilityAllowances', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    
                        <div class="layout-row">
                            <div flex class="charge-calc-item">
                                <h5>Fair Market Rent</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('fairMarketRents')" id="addBedroomFormButton"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.fairMarketRents">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped"
                            *ngIf="editUnit.rentCalc.fairMarketRents">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let f of editUnit.rentCalc.fairMarketRents; let i = index"
                                id="fairMarketInput">
                                <td><span id="fairMarketCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="fairMarketCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="fairMarketEffectiveDateText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span id="fairMarketValue">
                                    {{
                                        ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                        ? f.value[editUnit.numberOfBedrooms] 
                                        : f.value[unit.numberOfBedrooms] 
                                        ) /100 | currency
                                    }}
                                    </span></td>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;" 
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('fairMarketRents', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <a style="font-size:18px;margin-left: 10px;" 
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('fairMarketRents', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <div class="layout-row" *ngIf="isPublicHousing">                            
                            <div flex class="charge-calc-item">
                                <h5>Flat Rent</h5>
                            </div>
                            <button mat-flat-button class="hds_button" (click)="newMonthlyChargesDialog('flatRents')" id="addBedroomFormButton"
                                [disabled]="!(editUnit.overrideRentCalc)">Add</button>
                        </div>
                        <p *ngIf="!editUnit.rentCalc.flatRents">No values defined</p>
                        <table class="table table-condensed table-bordered table-striped"
                            *ngIf="editUnit.rentCalc.flatRents && isPublicHousing">
                            <thead>
                                <tr>
                                    <th>Created</th>
                                    <th>Created By</th>
                                    <th>Effective</th>
                                    <th>Value</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let f of editUnit.rentCalc.flatRents; let i = index"
                                id="fairMarketInput">
                                <td><span id="flatRentCreatedOnText">{{f.createdOn | date}}</span></td>
                                <td><span id="flatRentCreatedByText">{{f.createdBy}}</span></td>
                                <td><span id="flatRentEffectiveDateText">{{f.effectiveDate | date}}</span></td>
                                <td>
                                    <span id="flatRentValue">
                                    {{
                                        ( f.value[editUnit.numberOfBedrooms] && f.value[editUnit.numberOfBedrooms] != 0
                                        ? f.value[editUnit.numberOfBedrooms] 
                                        : f.value[unit.numberOfBedrooms] 
                                        ) /100 | currency
                                    }}
                                    </span></td>
                                <td>
                                    <div class="layout-row">
                                        <a style="font-size:18px;" 
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="openMonthlyChargesDialog('flatRents', i)"><mat-icon>edit</mat-icon>
                                        </a>
                                        <a style="font-size:18px;margin-left: 10px;" 
                                            *ngIf="editUnit.overrideRentCalc"
                                            (click)="deleteMonthlyCharge('flatRents', i)"><mat-icon>delete</mat-icon>
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </mat-expansion-panel>
                    <div *ngIf="amerindFields" class="section-header chevronAlign" [ngStyle]="{ 'pointer-events': amerindToggle ? 'auto' : 'none', 'opacity': amerindToggle ? '1' : '0.5' }">
                        <a class="account__details-collapse">
                            <mat-icon class="collapse-icon" 
                                [ngClass]="{'collapse-icon--open' : amerindFieldsInfoPanelOpenState}"
                                (click)="amerindFieldsInfoPanelOpenState = !amerindFieldsInfoPanelOpenState">chevron_right
                            </mat-icon>
                        </a>
                        <div class="headerPadding">
                            AMERIND Insurance
                        </div>
                    </div>
                    <mat-expansion-panel [expanded]="amerindFieldsInfoPanelOpenState" class="hidePanel">
                        <div class="section-body layout-row layout-align-start-center">
                            <mat-form-field appearance="outline" class="flex-35">
                              <mat-label>Building</mat-label>
                              <input matInput
                                     aria-label="Building"
                                     [matAutocomplete]="buildingAuto"
                                     [formControl]="buildingCtrl">
                              <button mat-button *ngIf="buildingCtrl.value" matSuffix mat-icon-button (click)="clearBuildingSelction()">
                                <mat-icon>close</mat-icon>
                              </button>                                    
                              <mat-autocomplete #buildingAuto="matAutocomplete" [displayWith]="displayBuilding" (optionSelected)="onBuildingSelected($event)">
                                <mat-option *ngFor="let b of filteredBuilding$ | async;" [value]="b">
                                  <span>{{b.address}} - {{b.buildingDescription}}</span>
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                    </mat-expansion-panel>
                </div>
            </div>                         
        </mat-card-content>
        <mat-card-actions *ngIf="!isBusy">   
                <button
                    mat-flat-button 
                    class="hds_button cancel" 
                    (click)="cancel()" 
                    id="cancelButton"
                    >Cancel</button>
                <button    
                    mat-flat-button 
                    class="hds_button" 
                    (click)="save()" 
                    id="saveButton"
                    [disabled]="(!unitForm.valid || unitForm.pristine) && !unitFeesDirty && !unitOverrideDelete && landlordCtrl.pristine && utilityVendorCtrl.pristine && buildingCtrl.pristine">
                    Save Unit</button>
        </mat-card-actions>    
    </mat-card>
</form>
