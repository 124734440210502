import {Component, Injector, OnInit} from '@angular/core';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {UtilityActionPopupComponent} from './utility-action-popup/utility-action-popup.component';
import {
  SortOrder,
  UtilitySortType,
  UtilityActionType,
  UtilityActionModal,
  UtilityAllowanceObject,
  UtilityAllowancesTableValue,
  UtilityAllowancesTableObject,
} from '../services/setup-utility-model';
import {
  CreateUtilityAllowanceCommandInput,
  UpdateUtilityAllowanceCommandInput,
  CloneUtilityAllowanceCommandInput,
  DeleteUtilityAllowanceCommandInput,
  CreateUtilityAllowanceTableCommandInput,
  CreateUtilityAllowanceTableValuesCommandInput,
  UpdateUtilityAllowanceTableValuesCommandInput,
  DeleteUtilityAllowanceTableValueCommandInput,
} from '../services/setup-utility-object';
import {User} from 'src/app/core/service/core-models';
import {CoreService} from 'src/app/core/service/core.service';
import {UserService} from 'src/app/shared/services';
import {BaseService} from 'src/app/kanso-common/core/service';
import {SetupUtilityAllowancesService} from '../services/setup-utility-allowances.service';
import moment from 'moment';
import {ReportingService} from 'src/app/kanso-common/core/service/reporting.service';

@Component({
  selector: 'app-utility-allowances',
  templateUrl: './utility-allowances.component.html',
  styleUrls: ['./utility-allowances.component.scss'],
})
export class UtilityAllowancesComponent implements OnInit {
  utilityAllowanceList: UtilityAllowanceObject[] = [];
  utilityAllowancesTableList: UtilityAllowancesTableObject[];
  selectedUtilityAllowance: UtilityAllowanceObject;
  selectedUtilityAllowancesTable: UtilityAllowancesTableObject;
  selectedUtilityTableValueData: UtilityAllowancesTableValue[] = [];
  utilityActionType = UtilityActionType;
  utilitySortType = UtilitySortType;
  sortOrder = SortOrder;
  selectedSortType = 0;
  categorySortOrder = 0;
  descriptionSortOrder = 0;
  isCategoryDefaultSort = false;
  isDescriptionDefaultSort = true;
  maxLength = 30;
  truncatedTextCategory = '';
  truncatedTextDescription = '';
  displayedColumns: string[] = [
    'category',
    'description',
    'studio',
    '1 Bed',
    '2 Bed',
    '3 Bed',
    '4 Bed',
    '5 Bed',
    '6 Bed',
    '7 Bed',
    '8 Bed',
    'edit',
    'delete',
  ];
  currentUser: string;
  busyText: string;
  isBusy = false;
  errorFetch = false;
  defaultPermission = 'admin || actionsOfUtilityAllowance';
  hasPermission = false;

  constructor(
    private readonly globalModalService: GlobalModalService,
    protected injector: Injector,
    private readonly coreService: CoreService,
    private readonly userService: UserService,
    private readonly baseService: BaseService,
    private readonly setupUtilityService: SetupUtilityAllowancesService
  ) {}

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      (user: User) => {
        this.hasPermission = this.userService.permissionExists(user.permissions, this.defaultPermission);
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was error when retrieving current user information!');
      }
    );
    this.activate();
  }

  activate(newId?: string, newTableId?: string, isUnitTypeChange = true) {
    this.setBusy('Loading Utility Allowances...');
    this.setupUtilityService.getAllUtilityAllowances(this.setupUtilityService.siteId, this.setupUtilityService.customerId).subscribe(
      data => {
        this.utilityAllowanceList = data;
        if (this.utilityAllowanceList.length > 0) {
          this.selectedUtilityAllowance = newId
            ? this.utilityAllowanceList.find(utilityAllowance => utilityAllowance.id == newId)
            : this.utilityAllowanceList[0];
          this.onUnitTypeChange(isUnitTypeChange);
          if (newTableId) {
            this.selectedUtilityAllowancesTable = this.utilityAllowancesTableList.find(table => table.id == newTableId);
            this.onEffectiveDateChange();
          }
        }
        this.isBusy = false;
      },
      error => {
        this.errorFetch = true;
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error getting unit types!');
      }
    );
  }

  openUtilityActionPopup(actionType?: number, inputData?: any) {
    let actionModal: UtilityActionModal;
    switch (actionType) {
      case UtilityActionType.addUnitType:
        actionModal = {
          header: 'New Unit Type',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType),
          updateFunction: this.addUnitType,
        };
        break;
      case UtilityActionType.updateUnitType:
        actionModal = {
          header: 'Edit Unit Type',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, this.selectedUtilityAllowance),
          updateFunction: this.updateUnitType,
        };
        break;
      case UtilityActionType.deleteUnitType:
        actionModal = {
          header: 'Delete Unit Type',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, this.selectedUtilityAllowance),
          updateFunction: this.deleteUnitType,
          initialObject: this.selectedUtilityAllowance,
        };
        break;
      case UtilityActionType.cloneUnitType:
        actionModal = {
          header: 'Clone Unit Type',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, this.selectedUtilityAllowance),
          updateFunction: this.cloneUnitType,
        };
        break;
      case UtilityActionType.addEffectiveDate:
        actionModal = {
          header: 'Add Effective Date',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, this.selectedUtilityAllowance),
          effectiveDateList: this.setupUtilityService.utilityArraybuilder(this.utilityAllowancesTableList, 'effectiveDate'),
          updateFunction: this.addEffectiveDate,
        };
        break;
      case UtilityActionType.addUtilityItem:
        actionModal = {
          header: 'New Utility Item',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, this.selectedUtilityAllowancesTable),
          combinationList: this.setupUtilityService.utilityArraybuilder(
            this.selectedUtilityTableValueData,
            'category',
            'description',
            true
          ),
          updateFunction: this.addUtilityItem,
        };
        break;
      case UtilityActionType.editUtilityItem:
        actionModal = {
          header: 'Edit Utility Item',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, inputData),
          combinationList: this.setupUtilityService.utilityArraybuilder(
            this.selectedUtilityTableValueData,
            'category',
            'description',
            true
          ),
          initialObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, inputData),
          updateFunction: this.editUtilityItem,
        };
        break;
      case UtilityActionType.deleteUtilityItem:
        actionModal = {
          header: 'Delete Utility Item',
          actionType: actionType,
          inputCommandObject: this.setupUtilityService.utitlityActionCommandBuilder(actionType, inputData),
          updateFunction: this.deleteUtilityItem,
        };
        break;
    }
    this.globalModalService.openModal<UtilityActionModal>(
      UtilityActionPopupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      actionModal
    );
  }

  addUnitType = (command: CreateUtilityAllowanceCommandInput) => {
    this.setBusy('Adding Unit Type...');
    this.setupUtilityService.createUtilityAllowance(command).subscribe(
      result => {
        if (result) {
          this.activate(result.id);
        } else {
          this.coreService.displayError('There was an error creating new Unit Type');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error creating new Unit Type');
      }
    );
  };

  updateUnitType = (command: UpdateUtilityAllowanceCommandInput) => {
    this.setBusy('Updating Unit Type...');
    this.setupUtilityService.updateUtilityAllowance(command).subscribe(
      result => {
        if (result) {
          this.selectedUtilityAllowance.name = result.name;
          this.rebuildUitlityAllowanceObject();
        } else {
          this.coreService.displayError('There was an error updating Unit Type');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error updating Unit Type');
      }
    );
  };

  deleteUnitType = (command: DeleteUtilityAllowanceCommandInput) => {
    this.setBusy('Deleting Unit Type...');
    this.setupUtilityService.deleteUtilityAllowance(command).subscribe(
      result => {
        if (result) {
          this.utilityAllowanceList = this.utilityAllowanceList.filter(utility => utility.id != this.selectedUtilityAllowance.id);
          if (this.utilityAllowanceList.length > 0) {
            this.selectedUtilityAllowance = this.utilityAllowanceList[0];
            this.onUnitTypeChange();
          } else {
            this.selectedUtilityAllowance = null;
            this.utilityAllowancesTableList = [];
            this.selectedUtilityTableValueData = [];
          }
        } else {
          this.coreService.displayError('There was an error deleting Unit Type');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error deleting Unit Type');
      }
    );
  };

  cloneUnitType = (command: CloneUtilityAllowanceCommandInput) => {
    this.setBusy('Cloning Unit Type...');
    this.setupUtilityService.cloneUtilityAllowance(command).subscribe(
      result => {
        if (result) {
          this.activate(result.id);
        } else {
          this.coreService.displayError('There was an error cloning Unit Type');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error cloning Unit Type');
      }
    );
  };

  addEffectiveDate = (command: CreateUtilityAllowanceTableCommandInput) => {
    this.setBusy('Adding Effective Date...');
    this.setupUtilityService.createUtilityAllowanceTable(command).subscribe(
      result => {
        if (result) {
          this.activate(result.utilityAllowanceId, result.id, false);
        } else {
          this.coreService.displayError('There was an error addding effective date');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error addding effective date');
      }
    );
  };

  addUtilityItem = (command: CreateUtilityAllowanceTableValuesCommandInput) => {
    this.setBusy('Adding Utility Item...');
    this.setupUtilityService.createUtilityAllowanceTableValues(command).subscribe(
      result => {
        if (result) {
          this.selectedUtilityTableValueData = [result, ...this.selectedUtilityTableValueData];
          this.rebuildUitlityAllowanceObject(true);
        } else {
          this.coreService.displayError('There was an error addding utility item');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error addding utility item');
      }
    );
  };

  editUtilityItem = (command: UpdateUtilityAllowanceTableValuesCommandInput) => {
    this.setBusy('Updating Utility Item...');
    this.setupUtilityService.updateUtilityAllowanceTableValues(command).subscribe(
      result => {
        if (result) {
          this.selectedUtilityTableValueData = this.selectedUtilityTableValueData.map(utilityTableValue => {
            if (result.id == utilityTableValue.id) {
              return result;
            } else {
              return utilityTableValue;
            }
          });
          this.rebuildUitlityAllowanceObject(true);
        } else {
          this.coreService.displayError('There was an error updating utility item');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error updating utility item');
      }
    );
  };

  deleteUtilityItem = (command: DeleteUtilityAllowanceTableValueCommandInput) => {
    this.setBusy('Deleting Utility Item...');
    this.setupUtilityService.deleteUtilityAllowanceTableValues(command).subscribe(
      result => {
        if (result) {
          this.selectedUtilityTableValueData = this.selectedUtilityTableValueData.filter(
            utilityTableValue => utilityTableValue.id != command.id
          );
          this.rebuildUitlityAllowanceObject(true);
        } else {
          this.coreService.displayError('There was an error deleting utility item');
        }
        this.isBusy = false;
      },
      error => {
        this.isBusy = false;
        console.log(error);
        this.coreService.displayError('There was an error deleting utility item');
      }
    );
  };

  onUnitTypeChange(isUnitTypeChange = true) {
    this.utilityAllowancesTableList = this.setupUtilityService.effectiveDateListModifier(
      this.selectedUtilityAllowance.utilityAllowanceTable
    );
    if (this.utilityAllowancesTableList.length > 0 && isUnitTypeChange) {
      this.selectedUtilityAllowancesTable = this.utilityAllowancesTableList[0];
      this.onEffectiveDateChange();
    }
  }

  onEffectiveDateChange() {
    this.selectedUtilityTableValueData = this.selectedUtilityAllowancesTable.utilityAllowanceTableValues;
    this.utilityTableValueDataSort();
  }

  sortValueChanges(sortType: number, sortOrder: number) {
    switch (sortType) {
      case UtilitySortType.category:
        this.selectedSortType = UtilitySortType.category;
        this.categorySortOrder = sortOrder;
        break;
      case UtilitySortType.description:
        this.selectedSortType = UtilitySortType.description;
        this.descriptionSortOrder = sortOrder;
        break;
    }
    this.utilityTableValueDataSort();
  }

  utilityTableValueDataSort() {
    const sortData = this.selectedUtilityTableValueData.sort((a, b) => {
      let sortTypeOutput;
      switch (this.selectedSortType) {
        case UtilitySortType.category: {
          if (this.isCategoryDefaultSort) {
            this.categorySortOrder = SortOrder.ascending;
            this.isCategoryDefaultSort = false;
          }
          switch (this.categorySortOrder) {
            case SortOrder.ascending:
              sortTypeOutput = a.category.localeCompare(b.category);
              break;
            case SortOrder.descending:
              sortTypeOutput = b.category.localeCompare(a.category);
              break;
          }
          this.isDescriptionDefaultSort = true;
          this.descriptionSortOrder = SortOrder.ascending;
          break;
        }
        case UtilitySortType.description: {
          if (this.isDescriptionDefaultSort) {
            this.descriptionSortOrder = SortOrder.ascending;
            this.isDescriptionDefaultSort = false;
          }
          let descriptionA;
          let descriptionB;
          switch (this.descriptionSortOrder) {
            case SortOrder.ascending:
              descriptionA = a.description ? a.description : 'z';
              descriptionB = b.description ? b.description : 'z';
              sortTypeOutput = descriptionA.localeCompare(descriptionB);
              break;
            case SortOrder.descending:
              descriptionA = a.description ? a.description : 'a';
              descriptionB = b.description ? b.description : 'a';
              sortTypeOutput = descriptionB.localeCompare(descriptionA);
              break;
          }
          this.isCategoryDefaultSort = true;
          this.categorySortOrder = SortOrder.ascending;
          break;
        }
      }
      return sortTypeOutput;
    });
    this.selectedUtilityTableValueData = [...sortData];
  }

  rebuildUitlityAllowanceObject(deepRebuild = false) {
    if (deepRebuild) {
      this.selectedUtilityAllowancesTable.utilityAllowanceTableValues = this.selectedUtilityTableValueData;
      this.utilityAllowancesTableList = this.utilityAllowancesTableList.map(utilityTable => {
        return utilityTable.id == this.selectedUtilityAllowancesTable.id ? this.selectedUtilityAllowancesTable : utilityTable;
      });
    }
    this.selectedUtilityAllowance.utilityAllowanceTable = this.utilityAllowancesTableList;
    this.utilityAllowanceList = this.utilityAllowanceList.map(utility => {
      return utility.id == this.selectedUtilityAllowance.id ? this.selectedUtilityAllowance : utility;
    });
  }

  navigateToUtilityReport(): void {
    try {
      if (!this.selectedUtilityAllowancesTable?.effectiveDate || !this.selectedUtilityAllowance?.name) {
        throw new Error('Missing required data for report navigation');
      }

      const reportingSubDomain = this.baseService.getReportingSubDomain();
      const effectiveDate = this.formatEffectiveDate(this.selectedUtilityAllowancesTable.effectiveDate);
      const reportUrl = this.buildReportUrl(reportingSubDomain, effectiveDate);

      window.open(reportUrl);
    } catch (error) {
      console.error('Failed to navigate to utility report:', error);
      this.coreService.displayError('Unable to open utility report. Please try again later.');
    }
  }

  private formatEffectiveDate(date: Date | string): string {
    const formattedDate = moment(date).format('M/D/YYYY');
    return `${formattedDate} 12:00:00 AM`;
  }

  private buildReportUrl(reportingSubDomain: string, effectiveDate: string): string {
    const params = {
      c: this.setupUtilityService.customerId,
      s: this.setupUtilityService.siteId,
      UnitName: this.selectedUtilityAllowance.name,
      EffectiveDate: effectiveDate,
    };

    return ReportingService.getInstance().buildReportUrl(
      window.location.host.replace(':3000', ''),
      window.location.protocol,
      '/reports/Properties/All.Site.Properties.Utility%20Allowances',
      params
    );
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  truncateTextCategory(text: string): string {
    return text.length > this.maxLength ? text.substring(0, this.maxLength) + '...' : text;
  }

  truncateTextDescription(text: string): string {
    if (text == null) {
      return '';
    }
    return text.length > this.maxLength ? text.substring(0, this.maxLength) + '...' : text;
  }
}
