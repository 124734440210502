<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span *ngIf="createMode">Create Announcement</span>
            <span *ngIf="editMode">Update Announcement</span>
            <span *ngIf="deleteMode">Delete Announcement</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>

    <mat-card-content>
        <form #postAdjustmentForm="ngForm">
            <mat-form-field class="largeInputBox" appearance="outline" *ngIf="createMode || editMode">
                <mat-label>Add New Tenant Portal Announcement:</mat-label>
                <textarea
                    matInput
                    matTextareaAutosize
                    ngModel
                    [(ngModel)]="communityAnnouncement.messageBody"
                    #body="ngModel"
                    name="messageBody"
                    id="messageBody"
                    type="text"
                    aria-label="Body"
                    required>
                </textarea>
            </mat-form-field>
            <div *ngIf="deleteMode"> 
                Do you wish to delete this announcement?
                Deleting an announcement will remove it from Tenant Portal and cannot be undone.
            </div>
            <mat-card-actions>
                <button 
                    mat-flat-button
                    (click)="closeDialog()"
                    class="hds_button cancel"
                    id="cancelButton">
                        Cancel
                </button>
                <button 
                    *ngIf="createMode || editMode"
                    mat-flat-button 
                    class="hds_button"
                    (click)="saveAnnouncement()"
                    id="Submit">
                        Save
                </button>
                <button 
                    *ngIf="deleteMode"
                    mat-flat-button 
                    class="hds_button"
                    (click)="saveAnnouncement()"
                    id="Submit">
                        Confirm
                </button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>