/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Component, OnInit, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';
import {State, Voucher, VoucherType, PortabilityType} from 'src/generated/graphql';
import * as moment from 'moment';
import {VoucherState} from '../vouchers-models/voucher';
import {Location} from '@angular/common';
import {VoucherService} from '../vouchers-services/vouchers.service';
import {GlobalAngular2ErrorHandlerComponent} from 'src/app/global-angular2-error-handler/global-angular2-error-handler.component';
import {vendorNode} from 'src/app/custom/vendor/vendor-model';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, startWith, switchMap} from 'rxjs/operators';
import {VendorTypes} from 'src/app/custom/housing-core/services/housing-models';
import {VendorService} from 'src/app/custom/vendor/vendor.service';
import _ from 'lodash';

@Component({
  selector: 'app-vouchers-addedit',
  templateUrl: './vouchers-addedit.component.html',
  styleUrls: ['./vouchers-addedit.component.scss'],
})
export class VouchersAddEditComponent implements OnInit {
  voucherForm: FormGroup;
  submitted = false;
  unitCtrl: FormControl;
  toHighlight = '';
  voucher: Voucher;
  fundingprogram = null;

  fundingPrograms: any[];
  fundingProjects: any[];
  filteredUnitOptions;
  filteredHaVendorOptions$: Observable<vendorNode[]>;
  voucherPortabilityTypeList: string[];
  VoucherType = VoucherType;
  PortabilityType = PortabilityType;
  portabilityTypeDisplay: string;
  VoucherState = VoucherState;
  voucherSpecialTypeList: string[];
  voucherTypeList: string[];
  householdId = null;
  expiredDate: string = null;
  issuedDate: string = null;
  minDate: Date = new Date();
  loggedInUser: string;
  initAddEdit: string = null;
  initLeasedState = false;
  selected = null;
  userPermissions = [];
  spinnerCheck = false;
  overlay = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<VouchersAddEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _location: Location,
    private voucherService: VoucherService,
    private vendorService: VendorService
  ) {
    this.voucherTypeList = ['Tenant Based', 'Project Based', 'Homeownership'];
    this.voucherPortabilityTypeList = ['Not Applicable', 'Port In', 'Port Out'];
    this.voucherSpecialTypeList = [
      'NONE',
      'DHAPK',
      'DVIKE',
      'FUPF',
      'FUPY',
      'FYITPV',
      'KATHU',
      'LIT',
      'MS5',
      'MTO',
      'NED',
      'NHT',
      'PHRR',
      'RADPH',
      'RADMR',
      'RADRP',
      'RADRS',
      'ROC',
      'ROSS',
      'TCU',
      'VASH',
    ];
  }

  //NOTEL this is to allow the dropdown to compare a object property to the forms simple value
  compareFn(a, b): boolean {
    return a.Id === b;
  }

  //NOTE: This is here to make sure the enum values can be compared be they string or number values.
  compareEnum(a: number, b: number): boolean {
    return a == b;
  }

  refetchUnits() {
    if (this.voucher && this.voucher?.unit) {
      this.voucher.unit.landlord = null; //we have to null the carried over landlord if they try to search
    }
    // only start searching if the string length is greater or equal to 3
    if (this.voucherForm.value.unit.length >= 3) {
      this.voucherService.getUnits(sessionStorage.getItem('SITEID'), this.voucherForm.value.unit).subscribe((result: any) => {
        this.filteredUnitOptions = result.data.units.edges;
        this.filteredUnitOptions = this.filteredUnitOptions.filter(x => x.node.landlordId);
      });
    }
  }

  getProjects() {
    this.voucherForm.value.fundingProject = null;
    let selectedProgramName = this.voucherForm.value?.fundingProgram?.node?.name;
    if (!selectedProgramName) {
      selectedProgramName = this.voucherForm.value.fundingProgram;
    }
    for (const program of this.fundingPrograms) {
      if (program.node.name === selectedProgramName) {
        this.fundingProjects = program.node.projects;
        break;
      }
    }
  }

  getOptionText(option) {
    if (!option) {
      return '';
    }
    return option.streetAddress;
  }

  ngOnInit() {
    this.userPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
    this.getCurrentUsersLogInCookie();
    //set the voucher
    this.voucher = this.data.Voucher;
    this.portabilityTypeDisplay = this.data.portabilityTypeDisplay;
    //set the householdId in case voucher dont exist
    this.householdId = this.data.houseHoldId;
    if (this.data.Init === 'new') {
      this.initAddEdit = 'new';
    } else {
      this.initAddEdit = 'edit';
      this.issuedDate = moment(this.voucher.issuedOn).format('MM-DD-YYYY');
      this.minDate = new Date(this.voucher.issuedOn);
      if (this.voucher.state == 'LEASED') {
        this.initLeasedState = true;
        this.expiredDate = moment(this.voucher.expiresOn).format('MM-DD-YYYY');
      }
    }

    this.voucherForm = this.formBuilder.group({
      fundingProgram: this.voucher?.program?.name ? this.voucher?.program?.name : null,
      fundingProject: this.voucher?.project?.name ? this.voucher?.project?.name : null,
      voucherType:
        this.voucher?.voucherType === 'TENANT_BASED'
          ? 'Tenant Based'
          : this.voucher?.voucherType === 'PROJECT_BASED'
          ? 'Project Based'
          : this.voucher?.voucherType === 'HOME_OWNERSHIP'
          ? 'Homeownership'
          : this.voucherTypeList[0],
      specialType: this.voucher?.specialType ? this.voucher?.specialType : this.voucherSpecialTypeList[0],
      voucherNumber: this.voucher?.voucherNumber ? this.voucher.voucherNumber : null,
      authorizedBedrooms: this.voucher?.authorizedBedrooms ? this.voucher.authorizedBedrooms : null,
      issuedOn: this.voucher?.issuedOn
        ? this.voucher.issuedOn
        : new Date(
            moment()
              .startOf('day')
              .toDate()
          ),
      expiresOn: this.voucher?.expiresOn
        ? new Date(
            moment(this.voucher.expiresOn)
              .add(1, 'days')
              .toDate()
          )
        : null,
      issuedDays: this.voucher?.expiresOn ? null : null,
      unit: this.voucher?.unit?.streetAddress ? this.voucher?.unit?.streetAddress : null,
      enhancedVoucher: this.voucher?.enhancedVoucher,
      protectionVoucher: this.voucher?.protectionVoucher,
      enhancedMinimumRent: this.voucher?.enhancedMinimumRent,
      portabilityType: this.voucherService.portabilityTypeConverter(this.voucher.portabilityType, 'toReadable'),
      portabilityVendor: this.voucher.portabilityVendor,
    });

    if (!_.isNil(this.voucherForm.value.portabilityVendor)) {
      this.vendorService.getVendorV2(this.voucherForm.value.portabilityVendor).subscribe(haVendor => {
        if (haVendor) {
          this.voucherForm.patchValue({portabilityVendor: haVendor});
        }
      });
    }

    this.filteredHaVendorOptions$ = this.voucherForm.controls.portabilityVendor.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter(value => value && value.length >= 3),
      distinctUntilChanged(),
      switchMap((value: string) => this.vendorService.getVendorsV2(50, '', 'forward', value, VendorTypes.Housingauthority))
    );

    //if expires on is set then update issuedDays (also means its a edit)
    if (this.voucherForm.value.expiresOn) {
      this.updateIssuedDays();
    }
    this.fundingPrograms = this.data.programs;
    //if we are editing then prefill the projects based on the program
    if (this.initAddEdit == 'edit') {
      this.getProjects();
    }
  }

  vendorSelectDisplay(option: vendorNode): string {
    return option?.name || '';
  }

  removeVendor() {
    this.voucherForm.patchValue({['portabilityVendor']: null});
  }

  onCancelClick(): void {
    this.dialogRef.close(null);
  }

  dateToDisplay(date) {
    return moment(date)
      .add(1, 'days')
      .format('MM-DD-YYYY')
      .toString();
  }

  preFillVoucherNumber(): void {
    let programCode, projectCode;
    let projectId;
    //we dont save the entire program and project object in the form, only the name, we do this for prepopulation
    for (const program of this.fundingPrograms) {
      if (this.voucherForm.value.fundingProgram === program.node.name) {
        programCode = program.node.programCode;
      }
    }
    for (const project of this.fundingProjects) {
      if (this.voucherForm.value.fundingProject === project.name) {
        projectCode = project.projectCode;
        projectId = project.id;
        break;
      }
    }

    this.voucherService.getVoucherTotalInProject(projectId).subscribe((result: any) => {
      this.voucherForm.controls.voucherNumber.setValue(`${programCode}-${projectCode}-${result.data.voucherCountByProject.count + 1}`);
    });
  }

  //there is a cookie present of the current logged in user
  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  onTerminateClick(): void {
    this.spinnerCheck = true;
    this.overlay = true;
    let command;
    if (this.voucher.state === State.Issued) {
      command = {
        voucherId: this.voucher.id,
        siteId: sessionStorage.getItem('SITEID'),
        customerId: sessionStorage.getItem('CUSTOMERID'),
        modifiedBy: this.loggedInUser,
        newState: 'TERMINATED_WITH_OUT_LEASING',
        terminatedOn: moment(new Date()).format('MM-DD-YYYY'),
      };
    } else {
      command = {
        voucherId: this.voucher.id,
        siteId: sessionStorage.getItem('SITEID'),
        customerId: sessionStorage.getItem('CUSTOMERID'),
        modifiedBy: this.loggedInUser,
        newState: 'TERMINATED',
        terminatedOn: moment(new Date()).format('MM-DD-YYYY'),
      };
    }

    this.voucherService.changeVoucherStatus(command).subscribe(
      (result: any) => {
        if (result.data.transitionVoucher.status === 'SUCCESS') {
          this.dialogRef.close();
        }
      },
      error => {
        console.log('there was an error sending the mutation', error);
      }
    );
  }
  onSubmit() {
    this.spinnerCheck = true;
    this.overlay = true;
    //set vouchertype appropiately
    if (this.voucherForm.value.voucherType === 'Tenant Based') {
      this.voucherForm.value.voucherType = 'TENANT_BASED';
    }
    if (this.voucherForm.value.voucherType === 'Project Based') {
      this.voucherForm.value.voucherType = 'PROJECT_BASED';
    }
    if (this.voucherForm.value.voucherType === 'Homeownership') {
      this.voucherForm.value.voucherType = 'HOME_OWNERSHIP';
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.voucherForm.invalid) {
      return;
    }

    let programId;
    let projectId;

    for (const program of this.fundingPrograms) {
      if (this.voucherForm.value.fundingProgram === program.node.name) {
        programId = program.node.id;
      }
    }
    if (this.voucherForm.value.fundingProject) {
      for (const project of this.fundingProjects) {
        if (this.voucherForm.value.fundingProject === project.name) {
          projectId = project.id;
        }
      }
    } else {
      projectId = this.voucher.project.id;
    }

    if (this.initAddEdit === 'new') {
      const command = {
        fundingProgramId: programId,
        siteId: sessionStorage.getItem('SITEID'),
        customerId: sessionStorage.getItem('CUSTOMERID'),
        fundingProjectId: projectId,
        voucherNumber: this.voucherForm.value.voucherNumber,
        householdId: this.householdId, //always set
        issuedOn: moment(this.voucherForm.value.issuedOn).format('MM-DD-YYYY'),
        expiresOn: moment(this.voucherForm.value.expiresOn).format('MM-DD-YYYY'),
        createdBy: this.loggedInUser ? this.loggedInUser : 'voucher form', //we need to save the user in cookies or session storage to pull this value
        authorizedBedrooms: parseInt(this.voucherForm.value.authorizedBedrooms),
        unitId: this.voucherForm.value?.unit?.node?.id,
        voucherType: this.voucherForm.value.voucherType,
        specialType: this.voucherForm.value.specialType,
        portabilityType: this.voucherService.portabilityTypeConverter(this.voucherForm.value.portabilityType, 'toConstant'),
        portabilityVendor: this.voucherForm.value.portabilityVendor,
      };

      this.voucherService.createVoucher(command).subscribe(
        (result: any) => {
          if (result.data.createVoucher.status === 'SUCCESS') {
            this.dialogRef.close();
          } else {
            console.log(result.data.createVoucher);
            this.dialog.open(GlobalAngular2ErrorHandlerComponent, {
              width: '80%',
              data: result.data.createVoucher.failureReason,
              disableClose: true,
            });
          }
        },
        error => {
          console.log('there was an error sending the mutation', error);
        }
      );
    } else {
      const command = {
        voucherId: this.voucher.id,
        fundingProgramId: programId,
        siteId: sessionStorage.getItem('SITEID'),
        customerId: sessionStorage.getItem('CUSTOMERID'),
        fundingProjectId: projectId,
        voucherNumber: this.voucherForm.value.voucherNumber,
        // issuedOn: moment(this.voucherForm.value.issuedOn).format('MM-DD-YYYY'),
        expiresOn: moment(this.voucherForm.value.expiresOn).format('MM-DD-YYYY'),
        modifiedBy: this.loggedInUser ? this.loggedInUser : 'voucher form', //we need to save the user in cookies or session storage to pull this value
        authorizedBedrooms: parseInt(this.voucherForm.value.authorizedBedrooms),
        unitId: this.voucherForm.value?.unit?.node?.id ? this.voucherForm.value?.unit?.node?.id : this.voucher?.unit?.id,
        voucherType: this.voucherForm.value.voucherType,
        specialType: this.voucherForm.value.specialType,
        enhancedVoucher: this.voucherForm.value.enhancedVoucher ?? false,
        protectionVoucher: this.voucherForm.value.protectionVoucher ?? false,
        enhancedMinimumRent: this.voucherForm.value.enhancedVoucher ? this.voucherForm.value.enhancedMinimumRent : null,
        portabilityType: this.voucherService.portabilityTypeConverter(this.voucherForm.value.portabilityType, 'toConstant'),
        portabilityVendor: this.voucherService.portabilityVendorConverter(this.voucherForm.value),
      };
      this.voucherService.editVoucher(command).subscribe(
        (result: any) => {
          if (result.data.editVoucher.status === 'SUCCESS') {
            this.dialogRef.close();
          } else {
            console.log(result.data.createVoucher);
            this.dialog.open(GlobalAngular2ErrorHandlerComponent, {
              width: '80%',
              data: result.data.createVoucher.failureReason,
              disableClose: true,
            });
          }
        },
        error => {
          console.log('there was an error sending the mutation', error);
        }
      );
    }
    this.submitted = false;
  }

  updateIssuedDays() {
    this.minDate = new Date(this.voucherForm.controls.issuedOn.value);
    if (typeof this.voucherForm.controls.issuedOn.value == 'undefined') {
      return;
    }
    if (typeof this.voucherForm.controls.expiresOn.value == 'undefined') {
      return;
    }

    const date1 = moment(this.voucherForm.controls.issuedOn.value);
    const date2 = moment(this.voucherForm.controls.expiresOn.value);

    if (date1.isSameOrAfter(date2)) {
      return;
    }
    const diff = date2.diff(date1, 'days');
    this.voucherForm.controls.issuedDays.setValue(diff);
  }

  updateExpiredDate() {
    if (typeof this.voucherForm.controls.issuedOn.value == 'undefined') {
      return;
    }
    if (typeof this.voucherForm.controls.expiresOn.value == 'undefined') {
      return;
    }

    const issued = moment(this.voucherForm.controls.issuedOn.value);
    issued.add(this.voucherForm.controls.issuedDays.value, 'days');
    this.voucherForm.controls.expiresOn.setValue(new Date(issued.format('MM/DD/YYYY')));
  }

  removeUnit() {
    this.voucherForm.value.unit = null;
    this.voucher.unit = null;
  }
}
