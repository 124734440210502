import {ComponentRef, Injectable, Injector, ElementRef, Type} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';

import {Overlay, OverlayPositionBuilder, OverlayConfig, OverlayRef, ConnectedPosition} from '@angular/cdk/overlay';

export interface GlobalModalParent {
  isOpen: boolean;
  closePopup(): void;
  updatePosition(minWidthPx?: number): void;
}

export interface GlobalModalChild<T> {
  globalModalParent: GlobalModalParent;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalModalService implements GlobalModalParent {
  overlayCompoentRef?: ComponentRef<GlobalModalChild<any>>;
  overlayRef?: OverlayRef;

  constructor(public overlatPositionBuilder: OverlayPositionBuilder, protected overlay: Overlay) {}

  private overlayCollection: OverlayRef[] = [];

  private getCoverOverlayPositions(): ConnectedPosition[] {
    return [
      {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top'},
      {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
      {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
      {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
    ];
  }

  public getCoverOverlayConfig(overlayOriginElement: ElementRef): OverlayConfig {
    const boundingRect = overlayOriginElement.nativeElement.getBoundingClientRect();
    let minHeight = (boundingRect.width * 9) / 16;
    if (minHeight < boundingRect.height) {
      minHeight = boundingRect.height;
    }
    const overlayConfig: OverlayConfig = {
      width: boundingRect.width,
      minHeight: minHeight,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(overlayOriginElement)
        .withPositions(this.getCoverOverlayPositions())
        .withPush(true)
        .withLockedPosition(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    };
    return overlayConfig;
  }

  private getBelowOverlayPositions(): ConnectedPosition[] {
    return [
      {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
      {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
    ];
  }

  public getBelowOverlayConfig(overlayOriginElement: ElementRef): OverlayConfig {
    const overlayConfig: OverlayConfig = {
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(overlayOriginElement)
        .withPositions(this.getBelowOverlayPositions())
        .withPush(true)
        .withLockedPosition(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
    };
    return overlayConfig;
  }

  private getNextOverlayPositions(): ConnectedPosition[] {
    return [
      {originX: 'end', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
      {originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
    ];
  }

  public getNextOverlayConfig(overlayOriginElement: ElementRef): OverlayConfig {
    const overlayConfig: OverlayConfig = {
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(overlayOriginElement)
        .withPositions(this.getNextOverlayPositions())
        .withPush(true)
        .withLockedPosition(false),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
    };
    return overlayConfig;
  }

  public getModalOverlayConfig() {
    const overlayConfig: OverlayConfig = {
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
    };
    return overlayConfig;
  }

  public openModal<T>(globalModalChildType: Type<GlobalModalChild<T>>, injector: Injector, overlayConfig: OverlayConfig, instanceData?: T) {
    if (this.overlayRef) {
      this.overlayCollection.push(this.overlayRef);
    }
    this.overlayRef = this.overlay.create(overlayConfig);
    const videoPortal = new ComponentPortal(globalModalChildType, undefined, injector);
    this.overlayCompoentRef = this.overlayRef.attach(videoPortal);
    this.overlayCompoentRef.instance.globalModalParent = this;

    if (instanceData) {
      Object.assign(this.overlayCompoentRef.instance, instanceData);
    }
  }

  get isOpen(): boolean {
    return this.overlayRef !== undefined;
  }

  updatePosition(minWidthPx?: number) {
    if (this.overlayRef) {
      if (minWidthPx) {
        this.overlayRef.updateSize({minWidth: minWidthPx});
        // this.overlayRef.getConfig().width = minWidthPx;
      }
      this.overlayRef.updatePosition();
    }
  }

  closePopup(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.overlayRef = this.overlayCollection.length > 0 ? this.overlayCollection.pop() : undefined;
    }
  }
}
