<mat-card class="knso-card utility-action-container">
    <mat-card-header>
        <mat-toolbar>
            <span>{{ header }}</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #utilityActionForm="ngForm">
        <mat-card-content>
            <div class="create-edit-container" *ngIf="actionType != utilityActiontype.deleteUnitType && actionType != utilityActiontype.deleteUtilityItem">
                <mat-form-field
                    appearance="outline"
                    class="flex-60" 
                    *ngIf="actionType == utilityActiontype.addUnitType || actionType == utilityActiontype.updateUnitType || actionType == utilityActiontype.cloneUnitType"
                    >
                    <mat-label>Name</mat-label>
                    <input
                        matInput
                        ngModel 
                        [(ngModel)]="inputCommandObject.name"
                        id="unitTypeName"
                        aria-label="Name"
                        #unitTypeName="ngModel"
                        name="unitTypeName"
                        required
                        >
                    <mat-error *ngIf="unitTypeName.hasError('required')">
                        <div ng-message="duplicated">Name is required.</div>
                    </mat-error>
                </mat-form-field>
                <mat-form-field 
                    appearance="outline" 
                    class="flex-30 effective-date-picker" 
                    *ngIf="actionType == utilityActiontype.addUnitType || actionType == utilityActiontype.cloneUnitType || actionType == utilityActiontype.addEffectiveDate"
                    >
                    <mat-label>Effective Date</mat-label>
                    <input 
                        matInput 
                        [(ngModel)]="inputCommandObject.effectiveDate"
                        ngModel 
                        #effectiveDate="ngModel"
                        name="effectiveDate"
                        aria-label="Effective Date"
                        (dateChange)="effectiveDateRangeModifier(inputCommandObject.effectiveDate)"
                        [matDatepicker]="effectiveDate"
                        required>
                        <mat-datepicker-toggle matIconSuffix [for]="effectiveDate"></mat-datepicker-toggle>
                        <mat-datepicker #effectiveDate></mat-datepicker>
                </mat-form-field>
                <div class="flex-60" *ngIf="actionType == utilityActiontype.addEffectiveDate">
                    Completing this action means that the previous effective rates will only apply for utility allowance calculations performed between the dates of {{ effectiveDateExpectedDisplay }}.
                </div>
                <div class="add-edit-utility-container" *ngIf="actionType == utilityActiontype.addUtilityItem || actionType == utilityActiontype.editUtilityItem">   
                    <div class="category-description-input-container">                               
                        <mat-form-field appearance="outline">
                            <mat-label>Category</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.category"
                                id="utilityCategory"
                                aria-label="category"
                                #utilityCategory="ngModel"
                                name="utilityCategory"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.textOnChange, $event.target.value, 'utilityCategory', combinationList)"
                                maxlength="50"
                                required
                                >
                                <mat-error *ngIf="utilityCategory.hasError('required')">
                                    <div ng-message="required">Category is required.</div>
                                </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                                <mat-label>Description</mat-label>
                                <input
                                    matInput
                                    ngModel 
                                    [(ngModel)]="inputCommandObject.description"
                                    id="utilityDescription"
                                    aria-label="description"
                                    #utilityDescription="ngModel"
                                    name="utilityDescription"
                                    (keyup)="utilityInputChange(utilityActionOnChangeEvent.textOnChange, $event.target.value, 'utilityDescription',combinationList)"
                                    maxlength="50"
                                    >
                        </mat-form-field>
                    </div> 
                    <div *ngIf="combinationDuplicated" class="duplicate-message">
                        <p>Duplicated category & description combination detected.</p>
                    </div>
                    <div class="bedroom-input-container">
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>Studio</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom0"
                                id="utilityeBdroom0"
                                aria-label="bedroom0"
                                #utilityBedroom0="ngModel"
                                name="utilityBedroom0"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom0')"
                                >
                            <mat-error *ngIf="utilityBedroom0.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>1 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom1"
                                id="utilityBedroom1"
                                aria-label="bedroom1"
                                #utilityBedroom1="ngModel"
                                name="utilityBedroom1"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom1')"
                                >
                            <mat-error *ngIf="utilityBedroom1.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>2 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom2"
                                id="utilityBedroom2"
                                aria-label="bedroom2"
                                #utilityBedroom2="ngModel"
                                name="utilityBedroom2"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom2')"
                                >
                            <mat-error *ngIf="utilityBedroom2.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>3 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom3"
                                id="utilityBedroom3"
                                aria-label="bedroom3"
                                #utilityBedroom3="ngModel"
                                name="utilityBedroom3"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom3')"
                                >
                            <mat-error *ngIf="utilityBedroom3.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>4 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom4"
                                id="utilityBedroom4"
                                aria-label="bedroom4"
                                #utilityBedroom4="ngModel"
                                name="utilityBedroom4"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom4')"
                                >
                            <mat-error *ngIf="utilityBedroom4.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>5 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom5"
                                id="utilityBedroom5"
                                aria-label="bedroom5"
                                #utilityBedroom5="ngModel"
                                name="utilityBedroom5"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom5')"
                                >
                            <mat-error *ngIf="utilityBedroom5.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>6 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom6"
                                id="utilityBedroom6"
                                aria-label="bedroom6"
                                #utilityBedroom6="ngModel"
                                name="utilityBedroom6"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom6')"
                                >
                            <mat-error *ngIf="utilityBedroom6.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>7 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom7"
                                id="utilityBedroom7"
                                aria-label="bedroom7"
                                #utilityBedroom7="ngModel"
                                name="utilityBedroom7"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom7')"
                                >
                            <mat-error *ngIf="utilityBedroom7.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-20">
                            <mat-label>8 bedroom</mat-label>
                            <input
                                matInput
                                ngModel 
                                [(ngModel)]="inputCommandObject.bedroom8"
                                id="utilityBedroom8"
                                aria-label="bedroom8"
                                #utilityBedroom8="ngModel"
                                name="utilityBedroom8"
                                type="number"
                                (keyup)="utilityInputChange(utilityActionOnChangeEvent.numberOnChange, $event.target.value, 'utilityBedroom8')"
                                >
                            <mat-error *ngIf="utilityBedroom8.hasError('smallerThanZero')">
                                <div ng-message="smallerThanZero">Equal 0 or greater.</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="delete-container" *ngIf="actionType == utilityActiontype.deleteUnitType || actionType == utilityActiontype.deleteUtilityItem">
                <div [ngSwitch]="actionType" *ngIf="!isBusy">
                    <div *ngSwitchCase="utilityActiontype.deleteUnitType" class="action-type">
                        <div *ngIf="units && units.length > 0; else showActions" class="units-table">
                            <p>This Unit Type cannot be deleted due to Utility Allowance Overrides in these units:</p>
                            <table mat-table [dataSource]="units" class="overrides-table">
                                <ng-container matColumnDef="orders">
                                  <th mat-header-cell *matHeaderCellDef>Order</th>
                                  <td mat-cell *matCellDef="let element; let i = index">
                                    {{ i + 1 }}
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="address">
                                  <th mat-header-cell *matHeaderCellDef>Address</th>
                                  <td mat-cell *matCellDef="let element">
                                    {{ element.streetAddress }}, 
                                    <span *ngIf="element.apartmentNumber"> Apt {{ element.apartmentNumber }}, </span>
                                    {{ element.city }}, {{ element.state }} {{ element.postalCode }}
                                  </td>
                                </ng-container>
                                <ng-container matColumnDef="ppuCode">
                                  <th mat-header-cell *matHeaderCellDef>PPU Code</th>
                                  <td mat-cell *matCellDef="let element">
                                    {{ element.ppuCode }}
                                  </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"class="table-row"></tr>
                            </table>                              
                            <button mat-flat-button class="hds_button cancel" (click)="closeDialog()">Close</button>
                        </div>
                        <ng-template #showActions>
                            <p>Are you sure you want to remove the unit type?</p>
                            <p>Once it is removed, it cannot be undone.</p>
                            <div>
                                <button mat-flat-button class="hds_button cancel" (click)="save()">Delete</button>
                                <button mat-flat-button class="hds_button" (click)="closeDialog()">Cancel</button>
                            </div>
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="utilityActiontype.deleteUtilityItem" class="action-type">
                      <p>Are you sure you want to remove the utility item?</p>
                      <p>Once it is removed, it cannot be undone.</p>
                      <div>
                        <button mat-flat-button class="hds_button cancel" (click)="save()">Delete</button>
                        <button mat-flat-button class="hds_button" (click)="closeDialog()">Cancel</button>
                      </div>
                    </div>
                </div>
                  
                <div class="loading-spinner" *ngIf="isBusy">
                <mat-spinner diameter="50" class="centered_spinner"></mat-spinner> Loading
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions  *ngIf="actionType != utilityActiontype.deleteUnitType && actionType != utilityActiontype.deleteUtilityItem">
            <button mat-flat-button class="hds_button cancel" (click)="closeDialog()">Cancel</button>
            <button *ngIf="combinationDuplicated" mat-flat-button class="hds_button" [disabled]="combinationDuplicated">Save</button>
            <button *ngIf="actionType == utilityActiontype.addEffectiveDate || actionType == utilityActiontype.cloneUnitType; else checkPristine" mat-flat-button class="hds_button" [disabled]="!utilityActionForm.valid" (click)="save()">Save</button>
            <ng-template #checkPristine>
                <button *ngIf="!combinationDuplicated" mat-flat-button class="hds_button" [disabled]="!utilityActionForm.valid || utilityActionForm.pristine" (click)="save()">Save</button>
            </ng-template>
        </mat-card-actions>
    </form>
</mat-card>