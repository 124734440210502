import {Component, Input} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/';
import {FormControl, FormGroup, NgForm, NgModel, Validators} from '@angular/forms';
import {ISubLedgerMap, AccountDefinition, AccountDefinitionType} from '../../accounting/service/accounting-models';
import {Program} from '../../housing-core/services/housing-models';
import {ProgramModel} from 'src/app/shared/services/program.model';

@Component({
  selector: 'account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
})
export class AccountSetupComponent implements GlobalModalChild<any> {
  globalModalParent: GlobalModalParent;
  @Input() editMode: boolean;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  @Input() program: ProgramModel;
  @Input() accountDefinitionType: string;
  @Input()
  set ledgerType(ledgerType) {
    this._ledgerType = ledgerType;
    if (ledgerType != AccountDefinitionType.TENANT && ledgerType != AccountDefinitionType.HOUSINGAUTHORITY) {
      this.accountTypeOptions = [{flag: 'isAccountsPayable', label: 'Accounts Payable'}];
      this.uniqueAccountType = {
        flag: 'isAccountsPayable',
        label: 'Accounts Payable',
      };
    }
    if (ledgerType == AccountDefinitionType.HOUSINGAUTHORITY) {
      this.accountTypeOptions = [
        {flag: 'isAR', label: 'A/R'},
        {flag: 'isAccountsPayable', label: 'Accounts Payable'},
      ];
    }
  }
  get ledgerType() {
    return this._ledgerType;
  }
  _ledgerType: any;
  @Input()
  set accountDefinition(accountDefinition: AccountDefinition) {
    this._accountDefinition = accountDefinition;
    if (accountDefinition) {
      this.editAccountSetup = Object.assign({}, accountDefinition);
      this.uniqueAccountType = {
        flag: 'isOther',
        label: 'Other',
      };
      console.log('accountDefinition', this.accountDefinition);
      if (this.accountDefinition.isPayableAccount) {
        this.uniqueAccountType = {
          flag: 'isAccountsPayable',
          label: 'Accounts Payable',
        };
      }
      if (this.accountDefinition.isArAccount) {
        this.uniqueAccountType = {
          flag: 'isAR',
          label: 'A/R',
        };
      }
      if (this.accountDefinition.isRepaymentAccount) {
        this.uniqueAccountType = {
          flag: 'isRepayment',
          label: 'Repayment Agreement',
        };
      }
      if (this.accountDefinition.isPrincipal) {
        this.uniqueAccountType = {
          flag: 'isPrincipal',
          label: 'Principal',
        };
      }
      if (this.accountDefinition.isDepositAccount) {
        this.uniqueAccountType = {
          flag: 'isDeposit',
          label: 'Deposit',
        };
      }
      if (this.accountDefinition.isBadDebtAccount) {
        this.uniqueAccountType = {
          flag: 'isBadDebt',
          label: 'Bad Debt',
        };
      }

      if (!accountDefinition.defaultTerms) {
        this.editAccountSetup.defaultTerms = {
          rate: 0,
          term: 0,
        };
      }
    } else {
      this.editAccountSetup = {
        activityRules: [],
        adjustmentToEstablishPrincipalBalanceSubCategory: null,
        assistancePaymentSubCategory: null,
        chartOfAccounts: null,
        chartOfAccountsName: '',
        isAutoPostDisabled: false,
        equityAccountDefinitions: [],
        interestChargeSubCategory: null,
        isArAccount: false,
        isPayableAccount: false,
        isAssistance: false,
        isBadDebtAccount: false,
        isDepositAccount: false,
        isPrimary: false,
        isPrincipal: false,
        isRepaymentAccount: false,
        lateFeeSubCategory: null,
        mepaAccountDefinition: null,
        name: '',
        principalChargeSubCategory: null,
        principalReductionSubCategory: null,
        portInReimbursementSubCategory: null,
        portOutHapSubCategory: null,
        utilityAllowanceSubCategory: null,
        defaultTerms: {
          rate: 0,
          term: 0,
        },
      };
      this.uniqueAccountType = {flag: 'N/A', label: 'N/A'};
    }

    this.accountTypeOptions = [
      {flag: 'isAR', label: 'A/R'},
      {flag: 'isAccountsPayable', label: 'Accounts Payable'},
      {flag: 'isDeposit', label: 'Deposit'},
      {flag: 'isPrincipal', label: 'Principal'},
      {flag: 'isBadDebt', label: 'Bad Debt'},
      {flag: 'isRepayment', label: 'Repayment Agreement'},
      {flag: 'isOther', label: 'Other'},
    ];
  }
  get accountDefinition(): AccountDefinition {
    return this._accountDefinition;
  }

  _accountDefinition: AccountDefinition = null;

  @Input()
  set chartOfAccounts(chartOfAccounts: any) {
    this._chartOfAccounts = chartOfAccounts;
    if (chartOfAccounts && this.accountDefinition && this.accountDefinition.chartOfAccounts) {
      this.selectedChartOfAccount = this.chartOfAccounts.find(account => account._id === this.accountDefinition.chartOfAccounts);
      this.onChartOfAccountsSelected(this.selectedChartOfAccount);
    }
  }
  get chartOfAccounts(): any {
    return this._chartOfAccounts;
  }
  _chartOfAccounts: ISubLedgerMap[];

  uniqueAccountType;
  accountTypeOptions: any;
  accountDefinitionAssistance;
  selectedChartOfAccount = {
    chargeCategories: null,
    creditCategories: null,
  };
  principalChargeDestination;
  principalChargeCategory;
  possiblePrincipalChargeCategories;
  interestChargeDestination;
  interestChargeCategory;
  editAccountSetup: AccountDefinition;
  possibleInterestChargeCategories;
  filteredPrincipalChargeCategories;
  allocationWarning;

  // eslint-disable-next-line
  constructor() {}

  compareValues(o1, o2) {
    if (o1._id == o2) {
      return true;
    } else {
      return false;
    }
  }

  compareFlags(o1, o2) {
    if (o1.flag == o2.flag) {
      return true;
    } else {
      return false;
    }
  }

  onPrincipleChargeAccountSelected(selectedCOA) {
    const coa = this.chartOfAccounts.find(x => x._id == selectedCOA);
    this.possiblePrincipalChargeCategories = coa.chargeCategories;
    this.editAccountSetup.principalChargeSubCategory = null;
    this.possibleInterestChargeCategories = coa.chargeCategories;
  }

  onInterestChargeAccountSelected(selectedCOA) {
    const coa = this.chartOfAccounts.find(x => x._id == selectedCOA);
    this.possibleInterestChargeCategories = coa.chargeCategories;
    this.editAccountSetup.interestChargeSubCategory = null;
  }

  onChartOfAccountsSelected(coa) {
    this.selectedChartOfAccount = coa;
    this.editAccountSetup.chartOfAccountsName = coa.name;
    this.editAccountSetup.chartOfAccounts = coa._id;
    if (this.editAccountSetup.principalChargeSubCategory || this.editAccountSetup.interestChargeSubCategory) {
      const programCoa = [];
      for (const accountDef of this.program.accountDefinitions) {
        programCoa.push(this.chartOfAccounts.find(x => x._id == accountDef.chartOfAccounts));
      }
      for (const accountDef of programCoa) {
        for (const chargeCat of accountDef.chargeCategories) {
          if (
            this.editAccountSetup.principalChargeSubCategory &&
            chargeCat.subCategories.some(x => x._id == this.editAccountSetup.principalChargeSubCategory)
          ) {
            this.possiblePrincipalChargeCategories = accountDef.chargeCategories;
            this.principalChargeCategory = chargeCat;
            this.principalChargeDestination = this.program.accountDefinitions.find(ac => ac.chartOfAccounts == accountDef._id);
          }
          if (
            this.editAccountSetup.interestChargeSubCategory &&
            chargeCat.subCategories.some(x => x._id == this.editAccountSetup.interestChargeSubCategory)
          ) {
            this.possibleInterestChargeCategories = accountDef.chargeCategories;
            this.interestChargeCategory = chargeCat;
            this.interestChargeDestination = this.program.accountDefinitions.find(ac => ac.chartOfAccounts == accountDef._id);
          }
        }
      }
    }
  }

  onAllocationPriorityChange(allocationPriorty) {
    const allocationCheck = this.program.accountDefinitions.filter(a => a.creditAllocationPriority === allocationPriorty);
    if (allocationCheck.length > 0) {
      this.allocationWarning = allocationCheck[0].name;
    } else {
      this.allocationWarning = null;
    }
  }

  uniqueAccountTypeChange(type) {
    this.editAccountSetup.isArAccount = false;
    this.editAccountSetup.isPayableAccount = false;
    this.editAccountSetup.isDepositAccount = false;
    this.editAccountSetup.isPrincipal = false;
    this.editAccountSetup.isBadDebtAccount = false;
    this.editAccountSetup.isRepaymentAccount = false;
    switch (type.flag) {
      case 'isAR':
        this.editAccountSetup.isArAccount = true;
        break;
      case 'isAccountsPayable':
        this.editAccountSetup.isPayableAccount = true;
        break;
      case 'isDeposit':
        this.editAccountSetup.isDepositAccount = true;
        break;
      case 'isPrincipal':
        this.editAccountSetup.isPrincipal = true;
        break;
      case 'isBadDebt':
        this.editAccountSetup.isBadDebtAccount = true;
        break;
      case 'isRepayment':
        this.editAccountSetup.isRepaymentAccount = true;
        break;
    }
  }

  getErrorMessage(ngModel: NgModel, message) {
    console.log('Error in Account Definition Setup', ngModel, message);
  }

  cancel() {
    this.globalModalParent.closePopup();
  }

  update() {
    this.editAccountSetup.lateFeeSubCategoryId = this.editAccountSetup.lateFeeSubCategory;
    this.editAccountSetup.accountDefinitionType = this.accountDefinitionType;
    this.editAccountSetup.vendorType = this.accountDefinitionType.toUpperCase();
    this.updateFunction(this.editAccountSetup);
    this.globalModalParent.closePopup();
  }
}
