import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: any;

  constructor(private http: HttpClient) {}

  loadConfig() {
    return this.http
      .get('/assets/config.temp.json')
      .toPromise()
      .then(config => {
        this.config = config;
        sessionStorage.setItem('SITEID', this.config.SITEID);
        sessionStorage.setItem('CUSTOMERID', this.config.CUSTOMERID);
        sessionStorage.setItem('BASE_SERVICE_URI', this.config.BASE_SERVICE_URI);
        sessionStorage.setItem('BASE_SERVICE_API_KEY', this.config.BASE_SERVICE_API_KEY);
        sessionStorage.setItem('DATA_KEY_SERVICE_URI', this.config.DATA_KEY_SERVICE_URI);
        sessionStorage.setItem('DATA_KEY_SERVICE_API_KEY', this.config.DATA_KEY_SERVICE_API_KEY);
        sessionStorage.setItem('FORMS_SERVICE_URI', this.config.FORMS_SERVICE_URI);
        sessionStorage.setItem('FORMS_SERVICE_API_KEY', this.config.FORMS_SERVICE_API_KEY);
        sessionStorage.setItem('KIMONO_SERVICE_URI', this.config.KIMONO_SERVICE_URI);
        sessionStorage.setItem('KIMONO_SERVICE_API_KEY', this.config.KIMONO_SERVICE_API_KEY);
        sessionStorage.setItem('OCCUPANCY_SVC_GRAPHQL_URI', this.config.OCCUPANCY_SVC_GRAPHQL_URI);
        sessionStorage.setItem('OCCUPANCY_SVC_KEY', this.config.OCCUPANCY_SVC_KEY);
        sessionStorage.setItem('TELERIK_SERVICE_URI', this.config.TELERIK_SERVICE_URI);
        sessionStorage.setItem('VOUCHERS_SVC_GRAPHQL_ENDPOINT', this.config.VOUCHERS_SVC_GRAPHQL_ENDPOINT);
        sessionStorage.setItem('VOUCHERS_SVC_API_KEY', this.config.VOUCHERS_SVC_API_KEY);
        sessionStorage.setItem('FILEROOM_SERVICE_URI', this.config.FILEROOM_SERVICE_URI);
        sessionStorage.setItem('FILEROOM_SERVICE_API_KEY', this.config.FILEROOM_SERVICE_API_KEY);
        sessionStorage.setItem('NODE_ENV', this.config.NODE_ENV);
        sessionStorage.setItem('TP_API_KEY', this.config.TP_API_KEY);
        sessionStorage.setItem('TP_API_URI', this.config.TP_API_URI);
        sessionStorage.setItem('INVENTORY_SVC_URI', this.config.INVENTORY_SVC_URI);
        sessionStorage.setItem('INVENTORY_API_KEY', this.config.INVENTORY_API_KEY);
      });
  }
}
