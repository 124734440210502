import {Component, Input, ChangeDetectorRef} from '@angular/core';
import {ControlContainer, NgForm, NgModel} from '@angular/forms';
import {Vendor, VendorAch, VendorEditModalData} from '../../housing-core/services/housing-models';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {VendorService} from '../vendor.service';
import {CustomAttributes} from 'src/app/core/service/core-models';
import {CoreService} from 'src/app/core/service/core.service';
import {User} from 'src/app/core/service/core-models';
import {UserService} from 'src/app/shared/services';
import _ from 'lodash';
import {AchService} from 'src/app/shared/services/ach-service';
import {VendorACH} from '../../accounting/service/nacha-model';
import {EncryptionService} from '../../accounting/service/encryption.service';
import {Console} from 'console';

@Component({
  selector: 'app-vendor-edit',
  templateUrl: './vendor-edit.component.html',
  styleUrls: ['./vendor-edit.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class VendorEditComponent implements GlobalModalChild<VendorEditModalData>, VendorEditModalData {
  showAccountInfo = false;
  isSaving = false;
  @Input() isNew: boolean;
  vendorAchData: VendorACH;
  maskedAccountNumber = '';
  encryptedAccountNumber = '';
  decryptedAccountNumber = '';
  encryptedAccountLoading = false;
  editPermission = 'admin || viewVendorBankingInformation';
  hasPermission = false;
  activeCustomAttributes: CustomAttributes[];
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  editVendor: Vendor;
  editAch: VendorAch;

  globalModalParent: GlobalModalParent;
  orgCheck: string;
  CheckingAcct = true;

  generalInfoPanelOpenState = true;
  addressPanelOpenState = false;
  otherPanelOpenState = false;
  customAttributesInfoPanelOpenState = false;
  @Input() set selectedVendor(selectedVendor: Vendor) {
    this.editVendor = _.cloneDeep({...selectedVendor, addresses: [{...selectedVendor.addresses[0]}]});
    this.maskedAccountNumber = this.editVendor.vendorAch.accountNumber;
    this.orgCheck = selectedVendor.companyName ? '1' : '0';
    if (this.editVendor.vendorAch.id) {
      this.achService.getEncryptedVendorsData([this.editVendor.vendorAch.id]).subscribe(
        (data: any) => {
          this.encryptedAccountNumber = data[0].accountNumberData;
        },
        error => {
          console.log('There was an error decrypting data: ', error);
          this.coreService.displayError('An unexpected error occurred getting encrypted data.');
        }
      );
    } else {
      this.showAccountInfo = true;
    }

    this.encryptedAccountLoading = false;
  }

  constructor(
    private coreService: CoreService,
    public userService: UserService,
    private cdr: ChangeDetectorRef,
    public achService: AchService,
    public encryptionService: EncryptionService
  ) {
    this.coreService.getCustomAttributesByEntity('Landlord').subscribe(result => {
      this.activeCustomAttributes = result.results.filter(attr => attr.active);
    });
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      (user: User) => {
        this.hasPermission = this.userService.permissionExists(user.permissions, this.editPermission);
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was error when retrieving current user information!');
      }
    );
  }

  headerText() {
    return this.isNew ? 'Create Vendor' : 'Edit Vendor';
  }

  phoneNumberValidation(event: KeyboardEvent) {
    if (this.editVendor.phoneNumber != this.editVendor.phoneNumber.replace(/[^0-9]/g, '')) {
      event.preventDefault();
    }
  }

  checkVendorType(selectedVendorType){
    if (selectedVendorType == 'HOUSINGAUTHORITY') {
      this.orgCheck = '1';
    }
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  titleCase(stringParameter) {
    if (stringParameter == 'ACH') {
      return stringParameter;
    }
    return stringParameter.charAt(0) + stringParameter.substring(1).toLowerCase();
  }

  saveVendor = async () => {
    try {
      this.isSaving = true;
      this.nullUnUsedNameFields();
      await this.prepAchData();
      this.updateFunction(this.editVendor);
    } catch (error) {
      console.log(error);
    } finally {
      this.closeDialog();
    }
  };

  private nullUnUsedNameFields() {
    if (this.orgCheck === '1') {
      // is org
      this.editVendor.firstName = null;
      this.editVendor.lastName = null;
    } else {
      // is individual
      this.editVendor.companyName = null;
    }
  }

  private async prepAchData() {
    if (
      this.editVendor.vendorAch.accountNumber != this.decryptedAccountNumber ||
      this.editVendor.vendorAch.accountNumber != this.maskedAccountNumber
    ) {
      let result;
      this.editVendor.vendorAch.accountNumberDisplay = this.editVendor.vendorAch.accountNumber.slice(-4);
      if (this.editVendor.vendorAch.accountNumber && !this.editVendor.vendorAch.accountNumber.includes('*')) {
        if (this.editVendor.vendorAch.id) {
          const decryptedAccount = await this.encryptionService.decryptData(
            this.encryptedAccountNumber,
            this.editVendor.vendorAch.dataKey,
            this.editVendor.vendorAch.dataVector
          );
          if (this.editVendor.vendorAch.accountNumber !== decryptedAccount) {
            result = await this.encryptionService.encryptData(this.editVendor.vendorAch.accountNumber);
          }
        } else {
          result = await this.encryptionService.encryptData(this.editVendor.vendorAch.accountNumber);
        }
      }
      if (result) {
        const {encryptedData, dataKey, dataVector} = result;
        this.editVendor.vendorAch.accountNumber = encryptedData;
        this.editVendor.vendorAch.dataKey = dataKey;
        this.editVendor.vendorAch.dataVector = dataVector;
      }
    }
  }

  getErrorMessage(ngModel: NgModel, inputName: string) {
    if (ngModel.hasError('required')) {
      return this.errorTextRequired(inputName);
    } else if (ngModel.hasError('minlength')) {
      return this.errorTextMinLength(inputName);
    } else if (ngModel.hasError('pattern')) {
      return this.errorTextPattern(inputName);
    }
    return '';
  }

  private errorTextRequired(inputControl: string) {
    switch (inputControl) {
      case 'companyName':
        return 'Please enter a name for this Organization';
      case 'firstName':
        return 'Please enter a first name';
      case 'lastName':
        return 'Please enter a last name';
      case 'payTo':
        return 'Please enter a pay to name';
      case 'phoneNumber':
        return 'Please enter a phone number';
      case 'phaCode':
        return 'Please enter a PHA code';
      default:
        return '';
    }
  }

  private errorTextMinLength(inputControl: string) {
    switch (inputControl) {
      case 'phoneNumber':
        return 'Please enter a 10 digit phone number';
      case 'phaCode':
        return 'Please enter a 5 character PHA code';
      default:
        return '';
    }
  }

  private errorTextPattern(inputControl: string) {
    switch (inputControl) {
      case 'phoneNumber':
        return 'Please only enter numbers';
      case 'emailAddress':
        return 'Please only enter the email address';
      case 'phaCode':
        return 'Please enter in format AA999. Two letters followed by three numbers';
      default:
        return '';
    }
  }

  async toggleAccountVisibility() {
    this.showAccountInfo = !this.showAccountInfo;
    if (this.showAccountInfo) {
      if (_.isEmpty(this.decryptedAccountNumber) && !_.isEmpty(this.encryptedAccountNumber)) {
        this.editVendor.vendorAch.accountNumber = '';
        this.encryptedAccountLoading = true;
        await this.decryptAccountData();
        this.encryptedAccountLoading = false;
        console.log('return decrypt');
      } else {
        this.editVendor.vendorAch.accountNumber = '';
        if (this.decryptedAccountNumber) {
          this.editVendor.vendorAch.accountNumber = this.decryptedAccountNumber;
        }
      }
    } else {
      if (this.decryptedAccountNumber == null || this.editVendor.vendorAch.accountNumber == this.decryptedAccountNumber) {
        this.editVendor.vendorAch.accountNumber = this.maskedAccountNumber;
      } else {
        //value has been edited so no remasking until save or cancel
        this.showAccountInfo = true;
      }
    }
  }

  async decryptAccountData(): Promise<void> {
    try {
      this.encryptedAccountLoading = true;
      const result = await this.encryptionService.decryptData(
        this.encryptedAccountNumber,
        this.editVendor.vendorAch.dataKey,
        this.editVendor.vendorAch.dataVector
      );
      this.decryptedAccountNumber = result;
      this.editVendor.vendorAch.accountNumber = this.decryptedAccountNumber;
    } catch (error) {
      console.log(error);
      this.coreService.displayError(error);
    } finally {
      this.encryptedAccountLoading = false;
    }
  }
}
