import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Authority, BuildingModalData} from 'src/app/custom/housing-core/services/housing-models';
import {GlobalModalService, GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {Building, IBuilding} from 'src/app/custom/housing-core/services/housing-models';
import _ from 'lodash';
import {AmerindUnitService} from 'src/app/shared/services/amerind-unit.service';
import {FeatureConfigurationsService} from 'src/app/shared/services/feature-configurations.service';
import {NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {HousingServices} from 'src/app/custom/housing-core/services/housing-services.service';

@Component({
  selector: 'app-buildings-setup',
  templateUrl: './buildings-setup.component.html',
  styleUrls: ['./buildings-setup.component.scss'],
})
export class BuildingsSetupComponent implements GlobalModalChild<BuildingModalData>, OnInit {
  globalModalParent: GlobalModalParent;
  @ViewChild('buildingForm') buildingForm: NgForm;
  @Input() editMode: boolean;
  @Output() amerindToggleSet = new EventEmitter<boolean>();
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  // eslint-disable-next-line
  @Input() cancelFunction: Function;

  _building: Building;
  @Input() set building(setBuilding: Building) {
    this._building = setBuilding;
    this.editBuilding = _.cloneDeep(setBuilding);
  }

  editBuilding: Building;
  isBusy = false;
  constructionType: string;
  constructionDescriptions: string[] = [];
  dwellingOwnershipType: string;
  dwellingDescriptions: string[] = [];
  roofMaterials: string[] = [];
  roofMaterialsDescriptions: string[] = [];
  roofMaterialsDropdownDescriptions: string[] = [];
  roofSearchQuery = '';
  sidingMaterials: string[] = [];
  sidingMaterialsDescriptions: string[] = [];
  sidingMaterialsDropdownDescriptions: string[] = [];
  sidingSearchQuery = '';
  isToggled = false;
  siteId = sessionStorage.getItem('SITEID');
  amerindToggle = null;
  authorityCopy: Authority;
  amerindFields = false;
  pattern = '^[0-9]{4}$';
  patternFloat = '^[0-9.]+$';
  amerindFieldsInfoPanelOpenState = false;

  constructor(
    private amerindUnitService: AmerindUnitService,
    private featureConfigurationsService: FeatureConfigurationsService,
    private housingServices: HousingServices
  ) {}

  ngOnInit(): void {
    this.housingServices.getHousingAuthority().subscribe(result => {
      this.authorityCopy = result.body;
      if (this.authorityCopy.authorityType === 'Indian Housing Authority (IHA)') {
        this.amerindFields = true;
        if (this.amerindFields && this.amerindToggle == null) {
          this.handleFeatureConfig();
        }
      }
    });
  }

  private handleFeatureConfig(): void {
    this.featureConfigurationsService.getConfigurationsValuesBySiteId(this.siteId).subscribe(
      configValues => {
        this.featureConfigurationsService.getFeatureConfigurations().subscribe(
          result => {
            if (result && result[0]) {
              const featureConfig = result[0].find(fc => fc.subdomain === 'amerindUnitFields');
              if (featureConfig) {
                const selectedValue = configValues[0].find(config => config.featureConfigurationId === featureConfig.id);
                if (selectedValue?.booleanValue === true) {
                  this.amerindToggle = true;
                  this.loadAmerindData();
                } else {
                  this.amerindToggle = false;
                }
              }
            } else {
              console.error('No feature configurations found.');
            }
          },
          error => {
            console.error('Error fetching feature configurations:', error);
          }
        );
      },
      error => {
        console.error('Error fetching configuration values:', error);
      }
    );
  }

  private loadAmerindData(): void {
    this.getConstructionTypeData()
      .pipe(
        switchMap(constructionTypes => {
          this.processConstructionTypes(constructionTypes);
          return this.getDwellingOwnershipTypeData();
        }),
        switchMap(dwellingTypes => {
          this.processDwellingTypes(dwellingTypes);
          return this.getRoofMaterialsData();
        }),
        switchMap(roofMaterials => {
          this.processRoofMaterials(roofMaterials);
          return this.getSidingMaterialsData();
        })
      )
      .subscribe(sidingMaterials => {
        this.processSidingMaterials(sidingMaterials);
      });
  }

  private getConstructionTypeData(): Observable<any> {
    return this.amerindUnitService.getConstructionType<any>().pipe(catchError(err => this.handleError(err)));
  }

  private getDwellingOwnershipTypeData(): Observable<any> {
    return this.amerindUnitService.getAmerindDwellingOwnershipType<any>().pipe(catchError(err => this.handleError(err)));
  }

  private getRoofMaterialsData(): Observable<any> {
    return this.amerindUnitService.getRoofMaterials<any>().pipe(catchError(err => this.handleError(err)));
  }

  private getSidingMaterialsData(): Observable<any> {
    return this.amerindUnitService.getSidingMaterials<any>().pipe(catchError(err => this.handleError(err)));
  }

  private processConstructionTypes(data: any[]): void {
    this.constructionDescriptions = this.processMaterialDescriptions(data);
    if (this.editBuilding.constructionType) {
      this.editBuilding.constructionType = this.findDescriptionForId(this.editBuilding.constructionType, data);
    }
  }

  private processDwellingTypes(data: any[]): void {
    this.dwellingDescriptions = this.processMaterialDescriptions(data);
    if (this.editBuilding.dwellingOwnershipType) {
      this.editBuilding.dwellingOwnershipType = this.findDescriptionForId(this.editBuilding.dwellingOwnershipType, data);
    }
  }

  private processRoofMaterials(data: any[]): void {
    this.roofMaterials = data.map((item: any) => item.description);
    this.roofMaterialsDropdownDescriptions = [...this.roofMaterials];
    this.roofMaterialsDescriptions = this.processMaterialDescriptions(data);
    if (this.editBuilding.buildingRoofMaterials) {
      this.editBuilding.buildingRoofMaterials = this.findDescriptionForId(this.editBuilding.buildingRoofMaterials, data);
    }
  }

  private processSidingMaterials(data: any[]): void {
    this.sidingMaterials = data.map((item: any) => item.description);
    this.sidingMaterialsDropdownDescriptions = [...this.sidingMaterials];
    this.sidingMaterialsDescriptions = this.processMaterialDescriptions(data);
    if (this.editBuilding.buildingExteriorSidingMaterials) {
      this.editBuilding.buildingExteriorSidingMaterials = this.findDescriptionForId(
        this.editBuilding.buildingExteriorSidingMaterials,
        data
      );
    }
  }

  private processMaterialDescriptions(data: any[]): string[] {
    return data.map(item => item.description);
  }

  private findDescriptionForId(id: string, data: any[]): string {
    const descriptionMap = new Map<string, string>();
    data.forEach(item => descriptionMap.set(item.id, item.description));
    return descriptionMap.get(id) || id;
  }

  public filterRoofMaterials(): void {
    if (this.roofSearchQuery.trim()) {
      this.roofMaterialsDropdownDescriptions = this.roofMaterials.filter(material =>
        material.toLowerCase().includes(this.roofSearchQuery.toLowerCase())
      );
    } else {
      this.roofMaterialsDropdownDescriptions = [...this.roofMaterials];
    }
  }

  public filterSidingMaterials(): void {
    if (this.sidingSearchQuery.trim()) {
      this.sidingMaterialsDropdownDescriptions = this.sidingMaterials.filter(material =>
        material.toLowerCase().includes(this.sidingSearchQuery.toLowerCase())
      );
    } else {
      this.sidingMaterialsDropdownDescriptions = [...this.sidingMaterials];
    }
  }

  private handleError(error: any): any {
    console.error('Error in API call:', error);
    return [];
  }

  save() {
    this.updateFunction(this.editBuilding);
    this.globalModalParent.closePopup();
    return;
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);
    return result;
  }

  cancel() {
    this.globalModalParent.closePopup();
    this.cancelFunction();
  }
}
