import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {KansoCommonModule} from './../../kanso-common/kanso-common.module';
import {VendorLedgerComponent} from './vendor-ledger/vendor-ledger.component';
import {VendorTransactionDetailComponent} from './vendor-ledger/vendor-transaction-detail/vendor-transaction-detail.component';
import {VendorAdjustmentComponent} from './vendor-ledger/vendor-adjustment/vendor-adjustment.component';
import {VendorRecurringTransactionEditComponent} from './vendor-ledger/vendor-recurring-transaction-edit/vendor-recurring-transaction-edit.component';

import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {VendorOverviewComponent} from './vendor-overview/vendor-overview.component';
import {VendorHeaderComponent} from './vendor-header/vendor-header.component';
import {VendorNotesComponent} from './vendor-notes/vendor-notes.component';
import {VendorNotesCreateComponent} from './vendor-notes/vendor-notes-create/vendor-notes-create.component';
import {VendorEditComponent} from './vendor-edit/vendor-edit.component';
import {CoreModule} from 'src/app/core';
import {VoidTransactionPopupComponent} from './vendor-ledger/vendor-transaction-detail/void-transaction-popup/void-transaction-popup.component';
import {MaterialModule} from 'src/app/shared/material/material.module';
@NgModule({
  declarations: [
    VendorLedgerComponent,
    VendorTransactionDetailComponent,
    VendorAdjustmentComponent,
    VendorOverviewComponent,
    VendorHeaderComponent,
    VendorNotesComponent,
    VendorNotesCreateComponent,
    VendorEditComponent,
    VoidTransactionPopupComponent,
    VendorRecurringTransactionEditComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    KansoCommonModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatCardModule,
    MatToolbarModule,
    MatInputModule,
    MatButtonModule,
    MatTableModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatOptionModule,
    MatExpansionModule,
    MaterialModule,
    CoreModule,
  ],
})
export class VendorModule {}
