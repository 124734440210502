import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {Observable, Subject, pipe} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {FetchResult} from '@apollo/client/link/core/types';
import {Program} from 'src/generated/graphql';
import {CoreService} from 'src/app/core/service/core.service';
import {Injectable} from '@angular/core';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import lodash from 'lodash';
import {Building, BuildingCreateCommand, BuildingUpdateCommand, IBuilding} from 'src/app/custom/housing-core/services/housing-models';
import {ComplexSaveResult} from 'src/app/core/service/core-models';
import {catchError} from 'rxjs/operators';

export interface IDataResponse {
  status: string;
  failureReason?: string;
}
export interface IBuildingDataResponse extends IDataResponse {
  affectedEntity: IBuilding;
}
export interface IUpdateBuildingDataResponse {
  updateBuilding: IBuildingDataResponse;
}
export interface ICreateBuildingDataResponse {
  createBuilding: IBuildingDataResponse;
}

interface PaginatedBuildingResponse {
  data: Building[];
  pageInfo: any;
  totalCount: number;
}

@Injectable({providedIn: 'root'})
export class AmerindUnitService extends BaseService {
  apollo: Apollo;
  _http: any;
  header: any;
  loggedInUser: string;
  envDat = {
    siteId: sessionStorage.getItem('SITEID'),
    customerId: sessionStorage.getItem('CUSTOMERID'),
  };
  constructor(public http: HttpClient, private apolloProvider: Apollo, public coreService: CoreService) {
    super(http);
    this.apollo = this.apolloProvider;
    this.loggedInUser = coreService.getCurrentUsersLogInCookie();
    const amerindHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...amerindHeaders}},
      'amerindUnits'
    );
  }

  getAllBuildings<T>(
    siteId: string,
    customerId: string,
    pageSize = 10,
    cursor: string,
    direction: string,
    searchText = ''
  ): Observable<PaginatedBuildingResponse> {
    try {
      const data: Building[] = [];
      let pageInfo: any;
      let totalCount: number;
      return this.apollo
        .use('amerindUnits')
        .query({
          query: this.queryAmerindBuildings(pageSize, cursor, direction, searchText),
          variables: {
            siteId,
            customerId,
          },
          fetchPolicy: 'network-only',
        })
        .pipe(
          map((response: any) => {
            for (const edge of response.data.amerindBuildings.edges) {
              data.push(edge.node);
            }
            pageInfo = response.data.amerindBuildings.pageInfo;
            totalCount = response.data.amerindBuildings.totalCount;
            return {data, pageInfo, totalCount};
          })
        );
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private queryAmerindBuildings(pageSize: number, cursor = '', direction = 'forward', searchText = '') {
    try {
      const filter = direction == 'forward' ? `, after: "${cursor}"` : `, before: "${cursor}"`;
      const cursorFilter = lodash.isEmpty(cursor) ? '' : filter;
      const pageSizeFilter = direction == 'forward' ? `first: ${pageSize}` : `last: ${pageSize}`;

      return gql`
      query AmerindBuildings {
        amerindBuildings(${pageSizeFilter},${cursorFilter}) {
          edges {
            node {
              id
              constructionType
              mobileHome
              buildersRiskApplies
              numberOfStories
              automaticSprinklerSystem
              centralFireAlarm
              centralStationBurglarAlarm
              yearRoofUpdated
              buildingRoofMaterials
              buildingExteriorSidingMaterials
              buildingLimit
              additionalInterestApplies
              lossPayeeLoanNumber
              dwellingOwnershipType
              businessPersonalPropertyLimit
              businessIncomeLimit
              increasedDetachedStructureLimit
              fenceLimit
              poolLimit
              buildingExteriorSquareFootage
              address
              buildingDescription
              buildingOccupancy
              yearBuilt
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
            cursor
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    `;
    } catch (error) {
      console.log(error);
    }
  }

  getBuilding<T>(id: string): Observable<any> {
    return this.apollo
      .use('amerindUnits')
      .query({
        query: this.queryBuilding(),
        variables: {id},
      })
      .pipe(
        map((response: any) => {
          const building = response.data?.amerindBuildings?.edges?.find(edge => edge.node.id === id)?.node;
          if (building) {
            return building;
          } else {
            console.error(`Building with ID ${id} not found.`);
            return null;
          }
        })
      );
  }

  private queryBuilding() {
    return gql`
      query AmerindBuildings($id: UUID) {
        amerindBuildings(where: {id: {eq: $id}}) {
          edges {
            node {
              id
              constructionType
              mobileHome
              buildersRiskApplies
              numberOfStories
              automaticSprinklerSystem
              centralFireAlarm
              centralStationBurglarAlarm
              yearRoofUpdated
              buildingRoofMaterials
              buildingExteriorSidingMaterials
              buildingLimit
              additionalInterestApplies
              lossPayeeLoanNumber
              dwellingOwnershipType
              businessPersonalPropertyLimit
              businessIncomeLimit
              increasedDetachedStructureLimit
              fenceLimit
              poolLimit
              buildingExteriorSquareFootage
              address
              buildingDescription
              buildingOccupancy
              yearBuilt
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
          }
        }
      }
    `;
  }

  getConstructionType<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('amerindUnits')
      .query({
        query: this.queryAmerindConstructionType(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.amerindConstructionType.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }
  queryAmerindConstructionType() {
    return gql`
      query AmerindConstructionType {
        amerindConstructionType {
          edges {
            node {
              id
              description
              abbreviation
            }
          }
        }
      }
    `;
  }

  getRoofMaterials<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('amerindUnits')
      .query({
        query: this.queryAmerindRoofMaterials(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.amerindRoofMaterials.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }
  queryAmerindRoofMaterials() {
    return gql`
      query AmerindRoofMaterials {
        amerindRoofMaterials(first: 52) {
          edges {
            node {
              id
              description
              abbreviation
            }
          }
        }
      }
    `;
  }

  getSidingMaterials<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('amerindUnits')
      .query({
        query: this.queryAmerindSidingMaterials(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.amerindSidingMaterials.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }
  queryAmerindSidingMaterials() {
    return gql`
      query AmerindSidingMaterials {
        amerindSidingMaterials(first: 60) {
          edges {
            node {
              id
              description
              abbreviation
            }
          }
        }
      }
    `;
  }

  getAmerindDwellingOwnershipType<T>(): Observable<any> {
    const data = [];
    return this.apollo
      .use('amerindUnits')
      .query({
        query: this.queryAmerindDwellingOwnershipType(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.amerindDwellingOwnershipType.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }
  queryAmerindDwellingOwnershipType() {
    return gql`
      query AmerindDwellingOwnershipType {
        amerindDwellingOwnershipType {
          edges {
            node {
              id
              description
              abbreviation
            }
          }
        }
      }
    `;
  }

  updateBuilding<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('amerindUnits').mutate({
      mutation: gql`
        mutation updateBuildingMutation($command: UpdateAmerindBuildingsCommandInput) {
          updateBuilding(command: $command) {
            commandName
            status
            affectedEntity {
              id
              constructionType
              mobileHome
              buildersRiskApplies
              numberOfStories
              automaticSprinklerSystem
              centralFireAlarm
              centralStationBurglarAlarm
              yearRoofUpdated
              buildingRoofMaterials
              buildingExteriorSidingMaterials
              buildingLimit
              additionalInterestApplies
              lossPayeeLoanNumber
              dwellingOwnershipType
              businessPersonalPropertyLimit
              businessIncomeLimit
              increasedDetachedStructureLimit
              fenceLimit
              poolLimit
              buildingExteriorSquareFootage
              address
              buildingDescription
              buildingOccupancy
              yearBuilt
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createBuilding<T>(command: any): Observable<FetchResult<T>> {
    return this.apollo.use('amerindUnits').mutate({
      mutation: gql`
        mutation createBuildingMutation($command: CreateAmerindBuildingsCommandInput!) {
          createBuilding(command: $command) {
            commandName
            status
            failedOn
            failureReason
            affectedEntity {
              id
              constructionType
              mobileHome
              buildersRiskApplies
              numberOfStories
              automaticSprinklerSystem
              centralFireAlarm
              centralStationBurglarAlarm
              yearRoofUpdated
              buildingRoofMaterials
              buildingExteriorSidingMaterials
              buildingLimit
              additionalInterestApplies
              lossPayeeLoanNumber
              dwellingOwnershipType
              businessPersonalPropertyLimit
              businessIncomeLimit
              increasedDetachedStructureLimit
              fenceLimit
              poolLimit
              buildingExteriorSquareFootage
              address
              buildingDescription
              buildingOccupancy
              yearBuilt
              customerId
              siteId
              createdOn
              createdBy
              modifiedOn
              modifiedBy
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  async saveBuilding(buildingToSave: Building, buildingExisting?: Building): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: null,
    };
    if (buildingExisting) {
      const buildingDirty = !this.coreService.areEqual(buildingToSave, buildingExisting, false);
      if (buildingDirty) {
        const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
        const command = this.buildUpdateBuildingMutation(buildingToSave, buildingExisting);
        const result = await this.updateBuilding(command).toPromise();
        if ((result.data as IUpdateBuildingDataResponse).updateBuilding.status !== 'SUCCESS') {
          response.success = false;
          response.errorMessage = `${(result.data as IUpdateBuildingDataResponse).updateBuilding.failureReason}\n`;
          return response;
        } else {
          response.affectedEntity = (result.data as IUpdateBuildingDataResponse).updateBuilding.affectedEntity;
        }
      }
    } else {
      //create new building
      const command = this.buildCreateBuildingMutation(buildingToSave);
      const result = await this.createBuilding(command).toPromise();
      if ((result.data as ICreateBuildingDataResponse).createBuilding.status !== 'SUCCESS') {
        response.success = false;
        response.errorMessage = `${(result.data as ICreateBuildingDataResponse).createBuilding.failureReason}\n`;
      } else {
        response.affectedEntity = (result.data as ICreateBuildingDataResponse).createBuilding.affectedEntity;
      }
    }
    return response;
  }

  private buildUpdateBuildingMutation(buildingToSave: Building, buildingExisting: Building): BuildingUpdateCommand {
    const buildingToSaveKeys = Object.keys(buildingToSave);
    const buildingExistingKeys = Object.keys(buildingExisting);

    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: BuildingUpdateCommand = {
      id: buildingToSave.id,
      modifiedBy: currentuser,
      modifiedOn: new Date(),
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      constructionType: buildingToSave.constructionType,
      mobileHome: buildingToSave.mobileHome,
      buildersRiskApplies: buildingToSave.buildersRiskApplies,
      numberOfStories: buildingToSave.numberOfStories,
      automaticSprinklerSystem: buildingToSave.automaticSprinklerSystem,
      centralFireAlarm: buildingToSave.centralFireAlarm,
      centralStationBurglarAlarm: buildingToSave.centralStationBurglarAlarm,
      yearRoofUpdated: buildingToSave.yearRoofUpdated,
      buildingRoofMaterials: buildingToSave.buildingRoofMaterials,
      buildingExteriorSidingMaterials: buildingToSave.buildingExteriorSidingMaterials,
      buildingLimit: buildingToSave.buildingLimit,
      additionalInterestApplies: buildingToSave.additionalInterestApplies,
      lossPayeeLoanNumber: buildingToSave.lossPayeeLoanNumber ? buildingToSave.lossPayeeLoanNumber : '',
      dwellingOwnershipType: buildingToSave.dwellingOwnershipType ? buildingToSave.dwellingOwnershipType : '',
      businessPersonalPropertyLimit: buildingToSave.businessPersonalPropertyLimit ? buildingToSave.businessPersonalPropertyLimit : '',
      businessIncomeLimit: buildingToSave.businessIncomeLimit ? buildingToSave.businessIncomeLimit : '',
      increasedDetachedStructureLimit: buildingToSave.increasedDetachedStructureLimit ? buildingToSave.increasedDetachedStructureLimit : '',
      fenceLimit: buildingToSave.fenceLimit ? buildingToSave.fenceLimit : '',
      poolLimit: buildingToSave.poolLimit ? buildingToSave.poolLimit : '',
      buildingExteriorSquareFootage: buildingToSave.buildingExteriorSquareFootage,
      address: buildingToSave.address,
      buildingDescription: buildingToSave.buildingDescription ? buildingToSave.buildingDescription : '',
      buildingOccupancy: buildingToSave.buildingOccupancy ? buildingToSave.buildingOccupancy : '',
      yearBuilt: buildingToSave.yearBuilt ? buildingToSave.yearBuilt : null,
    };

    for (const key of buildingToSaveKeys) {
      const isObject = this.coreService.isObject(buildingToSave[key]) || this.coreService.isObject(buildingExisting[key]);
      const isArray = Array.isArray(buildingToSave[key]);
    }
    return command;
  }

  private buildCreateBuildingMutation(buildingToSave: Building): BuildingCreateCommand {
    const buildingToSaveKeys = Object.keys(buildingToSave);
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: BuildingCreateCommand = {
      customerId: this.envDat.customerId,
      siteId: this.envDat.siteId,
      createdBy: currentuser,
      constructionType: buildingToSave.constructionType,
      mobileHome: buildingToSave.mobileHome,
      buildersRiskApplies: buildingToSave.buildersRiskApplies,
      numberOfStories: buildingToSave.numberOfStories,
      automaticSprinklerSystem: buildingToSave.automaticSprinklerSystem,
      centralFireAlarm: buildingToSave.centralFireAlarm,
      centralStationBurglarAlarm: buildingToSave.centralStationBurglarAlarm,
      yearRoofUpdated: buildingToSave.yearRoofUpdated,
      buildingRoofMaterials: buildingToSave.buildingRoofMaterials,
      buildingExteriorSidingMaterials: buildingToSave.buildingExteriorSidingMaterials,
      buildingLimit: buildingToSave.buildingLimit,
      additionalInterestApplies: buildingToSave.additionalInterestApplies,
      lossPayeeLoanNumber: buildingToSave.lossPayeeLoanNumber ? buildingToSave.lossPayeeLoanNumber : '',
      dwellingOwnershipType: buildingToSave.dwellingOwnershipType ? buildingToSave.dwellingOwnershipType : '',
      businessPersonalPropertyLimit: buildingToSave.businessPersonalPropertyLimit ? buildingToSave.businessPersonalPropertyLimit : '',
      businessIncomeLimit: buildingToSave.businessIncomeLimit ? buildingToSave.businessIncomeLimit : '',
      increasedDetachedStructureLimit: buildingToSave.increasedDetachedStructureLimit ? buildingToSave.increasedDetachedStructureLimit : '',
      fenceLimit: buildingToSave.fenceLimit ? buildingToSave.fenceLimit : '',
      poolLimit: buildingToSave.poolLimit ? buildingToSave.poolLimit : '',
      buildingExteriorSquareFootage: buildingToSave.buildingExteriorSquareFootage,
      address: buildingToSave.address,
      buildingDescription: buildingToSave.buildingDescription ? buildingToSave.buildingDescription : '',
      buildingOccupancy: buildingToSave.buildingOccupancy ? buildingToSave.buildingOccupancy : '',
      yearBuilt: buildingToSave.yearBuilt ? buildingToSave.yearBuilt : null,
    };
    for (const key of buildingToSaveKeys) {
      const isObject = this.coreService.isObject(buildingToSave[key]);
      const isArray = Array.isArray(buildingToSave[key]);
      if (buildingToSave[key] && !isObject && !isArray) {
        command[key] = buildingToSave[key];
      }
    }
    return command;
  }
}
