import {Injectable} from '@angular/core';
import {OverridesCommandListObject, UtilityAllowanceDto, UtilityAllowanceOverride, UtilityAllowancesTableDto} from './setup-utility-model';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SetupUtilityGlobalStoreService {
  private overridesCommandListObject: BehaviorSubject<OverridesCommandListObject | null> = new BehaviorSubject<OverridesCommandListObject>(
    null
  );
  public readonly commandListObject$: Observable<OverridesCommandListObject | null> = this.overridesCommandListObject.asObservable();

  private utilityAllowancesSubject: BehaviorSubject<UtilityAllowanceDto | null> = new BehaviorSubject<UtilityAllowanceDto | null>(null);
  public utilityAllowances$: Observable<UtilityAllowanceDto | null> = this.utilityAllowancesSubject.asObservable();

  private utilityAllowanceTableSubject: BehaviorSubject<UtilityAllowancesTableDto | null> = new BehaviorSubject<UtilityAllowancesTableDto | null>(
    null
  );
  public utilityAllowanceTable$: Observable<UtilityAllowancesTableDto | null> = this.utilityAllowanceTableSubject.asObservable();

  private utilityAllowanceOverridesSubject: BehaviorSubject<UtilityAllowanceOverride[] | null> = new BehaviorSubject<
    UtilityAllowanceOverride[] | null
  >(null);
  public utilityAllowanceOverrides$: Observable<UtilityAllowanceOverride[] | null> = this.utilityAllowanceOverridesSubject.asObservable();
  // three more data store for createCommand Update Command and delete Commad;

  // eslint-disable-next-line
  constructor() {}

  // OverridesCommandListObject
  setOverridesCommandListObject(newData: OverridesCommandListObject): void {
    this.overridesCommandListObject.next(newData);
  }

  getOverridesCommandListObject(): OverridesCommandListObject {
    return this.overridesCommandListObject.value;
  }

  resetOverridesCommandListObject(): void {
    this.overridesCommandListObject.next(null);
  }

  // UtilityAllowanceDto
  setUtilityAllowanceObject(data: UtilityAllowanceDto): void {
    this.utilityAllowancesSubject.next(data);
  }

  getUtilityAllowanceObject(): UtilityAllowanceDto | null {
    return this.utilityAllowancesSubject.value;
  }

  resetUtilityAllowanceObject(): void {
    this.utilityAllowancesSubject.next(null);
  }

  // UtilityAllowancesTableDto
  setUtilityAllowanceTableObject(data: UtilityAllowancesTableDto): void {
    this.utilityAllowanceTableSubject.next(data);
  }

  getUtilityAllowanceTableObject(): UtilityAllowancesTableDto | null {
    return this.utilityAllowanceTableSubject.value;
  }

  resetUtilityAllowanceTableObject(): void {
    this.utilityAllowanceTableSubject.next(null);
  }

  // UtilityAllowanceOverrides
  setUtilityAllowanceOverridesObject(data: UtilityAllowanceOverride[]): void {
    this.utilityAllowanceOverridesSubject.next(data);
  }

  getUtilityAllowanceOverridesObject(): UtilityAllowanceOverride[] | null {
    return this.utilityAllowanceOverridesSubject.value;
  }

  resetUtilityAllowanceOverridesObject(): void {
    this.utilityAllowanceOverridesSubject.next(null);
  }

  resetDataStore(): void {
    this.resetUtilityAllowanceObject();
    this.resetOverridesCommandListObject();
    this.resetUtilityAllowanceTableObject();
    this.resetUtilityAllowanceOverridesObject();
  }
}
