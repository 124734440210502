import {Component, Input, OnInit, Injector} from '@angular/core';
import {Authority, Building, IBuilding, BuildingModalData, BuildingUiSections} from 'src/app/custom/housing-core/services/housing-models';
import {HousingServices} from 'src/app/custom/housing-core/services/housing-services.service';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {CoreService} from 'src/app/core/service/core.service';
import {BuildingsSetupComponent} from 'src/app/custom/setup/units/buildings-setup/buildings-setup.component';
import {ComplexSaveResult, goToPagePayload, pageNext} from 'src/app/core/service/core-models';
import {AmerindUnitService} from 'src/app/shared/services/amerind-unit.service';
import {qqlPageInfo} from 'src/app/custom/vendor/vendor-model';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-buildings-list',
  templateUrl: './buildings-list.component.html',
  styleUrls: ['./buildings-list.component.scss'],
})
export class BuildingsListComponent implements OnInit {
  @Input() buildingId: string;
  @Input() housingAuthority: Authority;
  isBusy = false;
  busyText: string;
  isNewRecord = false;
  showPanelView: any = 'buildings';
  amerindFields = false;
  authorityCopy: Authority;
  amerindToggle = null;
  customerId: string;
  siteId: string;
  selectedBuilding;
  totalCount = 0;
  currentRecordsCount = 0;
  pageInfo: qqlPageInfo;
  lastPageSize = 0;
  buildings: Building[];
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  error = false;
  errorMsg = '';
  pageSize = new FormControl('25');

  constructor(
    public housingServices: HousingServices,
    public globalModalService: GlobalModalService,
    public amerindUnitService: AmerindUnitService,
    protected injector: Injector,
    public coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.customerId = this.CUSTOMER_ID;
    this.siteId = this.SITE_ID;
    this.refreshBuildingsList();
  }

  createBuilding() {
    this.setBusy('Creating new building');
    this.isNewRecord = true;
    this.openEditBuilding();
  }

  editBuilding(building) {
    this.setBusy('Loading building details');
    this.isNewRecord = false;
    this.openEditBuilding(building);
  }

  async openEditBuilding(building?: Building) {
    try {
      if (building) {
        await this.getBuilding(building);
      } else {
        this.selectedBuilding = new Building() as IBuilding;
      }
      if (!this.housingAuthority) {
        await this.getHousingAuthority();
      }
      this.globalModalService.openModal<BuildingModalData>(
        BuildingsSetupComponent,
        this.injector,
        this.globalModalService.getModalOverlayConfig(),
        {
          building: this.selectedBuilding,
          editMode: building ? true : false,
          housingAuthority: this.housingAuthority,
          openPanel: BuildingUiSections.GeneralInfo,
          cancelFunction: null,
          updateFunction: this.saveBuilding,
        }
      );
    } catch (error) {
      this.coreService.displayError(error.message, null, 'Error loading Building Data');
    } finally {
      this.isBusy = false;
    }
  }

  saveBuilding = async (building: Building) => {
    let isError = false;
    let response: ComplexSaveResult;

    try {
      this.setBusy('Saving Building');
      if (this.isNewRecord) {
        response = await this.amerindUnitService.saveBuilding(building);
      } else {
        response = await this.amerindUnitService.saveBuilding(building, this.selectedBuilding);
      }
      if (!response.success) {
        isError = true;
        this.coreService.displayError(response.errorMessage, null, 'Error saving building');
      }
    } catch (error) {
      this.coreService.displayError(error, null, 'Error saving building');
    } finally {
      this.isBusy = false;
      this.refreshBuildingsList();
    }
  };

  async getBuilding(building: Building) {
    try {
      const result = await this.amerindUnitService.getBuilding(building.id).toPromise();
      if (result && result.id) {
        this.selectedBuilding = result;
      } else {
        console.error('Building not found in the response');
        this.selectedBuilding = new Building();
      }
    } catch (error) {
      console.error('Error fetching building:', error);
      this.selectedBuilding = new Building();
    }
  }

  async getHousingAuthority() {
    try {
      const result = await this.housingServices.getHousingAuthority().toPromise();
      this.housingAuthority = result.body;
    } catch (error) {
      throw new Error('Error fetching Housing Authority data');
    }
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  goToPageHandler(pagePayload: goToPagePayload) {
    this.getBuildings(pagePayload.cursor, pagePayload.direction, pagePayload.pageSize);
  }

  getBuildings(cursor: string, direction: string, pageSize: number) {
    const countMultiplier: number = direction != 'forward' ? -1 : 1;
    this.amerindUnitService.getAllBuildings(this.siteId, this.customerId, pageSize, cursor, direction).subscribe(
      response => {
        this.buildings = response.data;
        this.pageInfo = response.pageInfo;
        this.totalCount = response.totalCount;
        const countAdjSize = direction != 'forward' ? this.lastPageSize : response.data.length;
        this.currentRecordsCount = this.currentRecordsCount + countAdjSize * countMultiplier;
        this.lastPageSize = response.data.length;
        this.isBusy = false;
      },
      error => {
        this.coreService.displayError(error.message, null, 'Error loading building data');
      }
    );
  }

  refreshBuildingsList = () => {
    this.setBusy('Loading Buildings');
    this.currentRecordsCount = 0;
    this.getBuildings('', pageNext, this.pageSize.value);
  };
}
