<section *ngIf="showPanelView == 'units'">
    <section class="tabs__panel layout-column">
        <div class="tab-header">
            <div class="layout-column">
                <h2>Units Directory</h2>
            </div>
        </div>
        <div class="flexColumn">
            <nav>
                <ul class="nav-pills">
                <li>
                    <a class="nav-active" (click)="showPanelView = 'units'" routerLinkActive="active">Units</a>
                </li>
                <li>
                    <a *ngIf="amerindToggle" (click)="showPanelView = 'buildings'" routerLinkActive="active">Buildings</a>
                </li>
                </ul>
            </nav>
                <section>
                    <div class="tab-header" *ngIf="!this.isBusy">
                        <mat-form-field appearance="outline">
                            <mat-label>Sort By</mat-label>
                            <mat-select 
                                [formControl]="unitSort">
                                <mat-option value="Address">Sort By Address</mat-option>
                                <mat-option value="Program">Sort By Program</mat-option>
                                <mat-option value="Project">Sort By Project</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="searchInput" appearance="outline">
                            <input 
                                matInput                        
                                (keyup.enter)="searchUnits()"
                                [formControl]="unitFilter"
                                placeholder="search..."/>                        
                        </mat-form-field>

                        <div class="searchButton" (click)="searchUnits()">
                            <mat-icon>search</mat-icon>
                        </div>

                        <div class="top-buttons">
                            <button
                                mat-flat-button
                                has-permission="'addUnits'"
                                (click)="createUnit()"
                                class="hds_button" 
                                style="margin: 20px 0;">
                                Add Unit
                            </button>
                        </div>    
                    </div>
                </section>
                <section>
                    <busy-notice
                        [isBusy]="this.isBusy"
                        [busyText]="this.busyText"
                        headerText="KANSO">
                    </busy-notice>
                    <table  class="kanso-table altRows" *ngIf="!this.isBusy && units && units.length" >
                        <thead>
                            <tr class="centerData">
                                <th>Program</th>
                                <th>Project</th>
                                <th>Address</th>
                                <th>PPU</th>
                            </tr>
                        </thead>
                        <tr class="centerData"
                            *ngFor="let u of units">
                            <td>
                                <span>{{u.programName}}</span>
                            </td>
                            <td>
                                <span>{{u.projectName}}</span>
                            </td>
            
                            <td>
                                <a class="widget-table__link" (click)="editUnit(u)">
                                    {{u.streetAddress}}
                                    <span *ngIf="u.apartmentNumber">, Apt. {{u.apartmentNumber}}</span>
                                    <span>, {{u.city}}</span>
                                </a>
                            </td>
                            <td>
                                <a class="widget-table__link">
                                    <span *ngIf="u.ppuCode">{{u.ppuCode}}</span>
                                    <span *ngIf="!u.ppuCode">--</span>
                                </a>
                            </td>
                        </tr>
                    </table>
                    <div *ngIf="units && units.length && !isBusy" class="pageNavigation">
                        <page-nav
                        [totalCount]="totalCount"
                        [pageInfo]="pageInfo"
                        [currentRecordsCount] = "currentRecordsCount"
                        [pageSize] = "pageSize"
                        (goToPage) ="goToPageHandler($event)">
                        </page-nav>
                    </div>
                    <p *ngIf="!isBusy && ((!units || units.length === 0)) && !error">There are no Units that match the specified criteria</p>
                    <p *ngIf="!isBusy && ((!units || units.length === 0) && error)">{{errorMsg}}</p>
                </section>
        </div>
    </section>
</section>

<div *ngIf="showPanelView == 'buildings'">
    <app-buildings-list [buildingId]="this.buildingId"></app-buildings-list>
</div>

