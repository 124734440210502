<form #buildingForm="ngForm">
    <mat-card class="knso-card buildings-setup-container">
        <div *ngIf="isBusy" class="layout-row spinnerPadding">
            <mat-spinner diameter="25" > </mat-spinner> 
            <div class="leftPadding">Loading building...</div>
        </div>
        <mat-card-header *ngIf="!isBusy">
            <mat-toolbar>
                <span>{{!editMode ? 'New Building' : editBuilding.address}}</span>
                <button mat-icon-button (click)="cancel()"><mat-icon>close</mat-icon></button>
            </mat-toolbar>
        </mat-card-header>
        <mat-card-content *ngIf="!isBusy">
            <div class="modal-content">
                <div class="mat-card-content-content">
                    <div>
                        <div class="section-header chevronAlign">
                            
                            <div class="headerPadding">
                                Building Information
                            </div>
                        </div> 
                    </div>
                </div>
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Address</mat-label>
                            <input 
                                matInput
                                type="string"
                                [(ngModel)]="editBuilding.address"  
                                #address="ngModel"
                                name="address" 
                                aria-label="Address"
                                required>
                            <mat-error *ngIf="address.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Building Description</mat-label>
                            <input 
                                matInput 
                                type="string"
                                [(ngModel)]="editBuilding.buildingDescription"  
                                #buildingDescription="ngModel"
                                name="buildingDescription" 
                                aria-label="Building Description">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Building Occupancy</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.buildingOccupancy"  
                                #buildingOccupancy="ngModel"
                                name="buildingOccupancy" 
                                aria-label="Building Occupancy">
                        </mat-form-field>
                    </div>
                    <div class="section-body layout-row buildings-radio-area layout-align-start-center">
                        <div>
                            <mat-label>Central Fire Alarm</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.centralFireAlarm" 
                                class="flex-20"
                                #centralFireAlarm="ngModel"
                                name="centralFireAlarm"
                                required>
                                <mat-radio-button 
                                    [value]="true">Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false">No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="centralFireAlarm.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </div>
                        <div>
                            <mat-label>Builders Risk Applies</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.buildersRiskApplies" 
                                class="flex-20"
                                #buildersRiskApplies="ngModel"
                                name="buildersRiskApplies"
                                required>
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="buildersRiskApplies.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="buildersRiskApplies.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="buildersRiskApplies.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </div>

                        <div>
                            <mat-label>Automatic Sprinkler System</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.automaticSprinklerSystem" 
                                class="flex-20"
                                #automaticSprinklerSystem="ngModel"
                                name="automaticSprinklerSystem"
                                required>
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="automaticSprinklerSystem.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="automaticSprinklerSystem.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="automaticSprinklerSystem.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </div>
                    </div>
                    <div class="section-body layout-row buildings-radio-area layout-align-start-center">        
                        <div>
                            <mat-label>Mobile Home</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.mobileHome" 
                                class="flex-20"
                                #mobileHome="ngModel"
                                name="mobileHome"
                                required>
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="mobileHome.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="mobileHome.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="mobileHome.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </div>
                        <div>
                            <mat-label>Central Station Burglar Alarm</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.centralStationBurglarAlarm" 
                                class="flex-20"
                                #centralStationBurglarAlarm="ngModel"
                                name="centralStationBurglarAlarm"
                                required>
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="centralStationBurglarAlarm.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="centralStationBurglarAlarm.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="centralStationBurglarAlarm.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </div>
                        <div>
                            <mat-label>Additional Interest Applies</mat-label>
                            <mat-radio-group 
                                [(ngModel)]="editBuilding.additionalInterestApplies" 
                                class="flex-20"
                                #additionalInterestApplies="ngModel"
                                name="additionalInterestApplies">
                                <mat-radio-button 
                                    [value]="true"
                                    [checked]="additionalInterestApplies.value == true"
                                    >Yes </mat-radio-button>
                                <mat-radio-button 
                                    [value]="false" 
                                    [checked]="additionalInterestApplies.value == false"
                                    >No</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="flex-40" appearance="outline">
                            <mat-label>Construction Type</mat-label>
                            <mat-select [(ngModel)]="editBuilding.constructionType" name="constructionType" #constructionType="ngModel" required>
                                <mat-option *ngFor="let description of constructionDescriptions" [value]="description">
                                    {{ description }}
                                </mat-option>
                                <mat-error *ngIf="constructionType.hasError('required')">
                                    <div ng-message="required">This field is required.</div>
                                </mat-error>
                            </mat-select>
                            
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Number of Stories</mat-label>
                            <input 
                                matInput 
                                type="number"
                                [(ngModel)]="editBuilding.numberOfStories"  
                                #numberOfStories="ngModel"
                                name="numberOfStories" 
                                aria-label="Number of Stories"
                                (keypress)="numericOnly($event)"
                                required>
                            <mat-error *ngIf="numberOfStories.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Year Roof Updated</mat-label>
                            <input 
                                matInput 
                                type="number"
                                [(ngModel)]="editBuilding.yearRoofUpdated" 
                                #yearRoofUpdated="ngModel"
                                name="yearRoofUpdated" 
                                aria-label="Year Roof Updated"
                                (keypress)="numericOnly($event)"
                                [pattern]="pattern"   
                                [maxlength]="4"        
                                [minlength]="4"
                                required>
                            <mat-error *ngIf="yearRoofUpdated.hasError('pattern')">
                                <div ng-message="pattern">Must be four digits</div>
                            </mat-error>
                            <mat-error *ngIf="yearRoofUpdated.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field class="flex-40" appearance="outline">
                            <mat-label>Building Roof Materials</mat-label>
                            <mat-select [(ngModel)]="editBuilding.buildingRoofMaterials" name="buildingRoofMaterials" #buildingRoofMaterials="ngModel" required>
                                <mat-option>
                                <input matInput [(ngModel)]="roofSearchQuery" (ngModelChange)="filterRoofMaterials()" name="roofSearchQuery" placeholder="Search..." (click)="$event.stopPropagation()" />
                                </mat-option>
                                <mat-option *ngFor="let material of roofMaterialsDropdownDescriptions" [value]="material">
                                {{ material }}
                                </mat-option>
                                <mat-error *ngIf="buildingRoofMaterials.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                                </mat-error>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Building Exterior Siding Materials</mat-label>
                            <mat-select [(ngModel)]="editBuilding.buildingExteriorSidingMaterials" name="buildingExteriorSidingMaterials" (ngModelChange)="filterSidingMaterials()" #buildingExteriorSidingMaterials="ngModel" required>
                                <mat-option>
                                <input matInput [(ngModel)]="sidingSearchQuery" (ngModelChange)="filterSidingMaterials()" name="sidingSearchQuery" placeholder="Search..." (click)="$event.stopPropagation()" />
                                </mat-option>
                                <mat-option *ngFor="let material of sidingMaterialsDropdownDescriptions" [value]="material">
                                {{ material }}
                                </mat-option>
                                <mat-error *ngIf="buildingExteriorSidingMaterials.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                                </mat-error>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Building Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.buildingLimit"  
                                #buildingLimit="ngModel"
                                name="buildingLimit" 
                                aria-label="Building Limit"
                                [pattern]="patternFloat"
                                required>
                            <mat-error *ngIf="buildingLimit.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                            <mat-error *ngIf="buildingLimit.hasError('pattern')">
                                <div ng-message="required">Only numbers and decimals are allowed.</div>
                            </mat-error>
                            
                        </mat-form-field>
                    </div>
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Building Exterior Square Footage</mat-label>
                            <input 
                                matInput 
                                type="number"
                                [(ngModel)]="editBuilding.buildingExteriorSquareFootage"  
                                #buildingExteriorSquareFootage="ngModel"
                                name="buildingExteriorSquareFootage" 
                                aria-label="Building Exterior Square Footage"
                                (keypress)="numericOnly($event)"
                                required>
                            <mat-error *ngIf="buildingExteriorSquareFootage.hasError('required')">
                                <div ng-message="required">This field is required.</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Loss Payee Loan Number</mat-label>
                            <input 
                                matInput 
                                type="string"
                                [(ngModel)]="editBuilding.lossPayeeLoanNumber"  
                                #lossPayeeLoanNumber="ngModel"
                                name="lossPayeeLoanNumber" 
                                aria-label="Loss Payee Loan Number">
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Business Personal Property Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.businessPersonalPropertyLimit"  
                                #businessPersonalPropertyLimit="ngModel"
                                name="businessPersonalPropertyLimit" 
                                aria-label="Business Personal Property Limit"
                                [pattern]="patternFloat">
                                <mat-error *ngIf="businessPersonalPropertyLimit.hasError('pattern')">
                                    <div ng-message="required">Only numbers and decimals are allowed.</div>
                                </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Increased Detached Structure Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.increasedDetachedStructureLimit"  
                                #increasedDetachedStructureLimit="ngModel"
                                name="increasedDetachedStructureLimit" 
                                aria-label="Increased Detached Structure Limit"
                                [pattern]="patternFloat">
                                <mat-error *ngIf="increasedDetachedStructureLimit.hasError('pattern')">
                                    <div ng-message="required">Only numbers and decimals are allowed.</div>
                                </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Fence Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.fenceLimit"  
                                #fenceLimit="ngModel"
                                name="fenceLimit" 
                                aria-label="Fence Limit"
                                [pattern]="patternFloat">
                                <mat-error *ngIf="fenceLimit.hasError('pattern')">
                                    <div ng-message="required">Only numbers and decimals are allowed.</div>
                                </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Pool Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.poolLimit"  
                                #poolLimit="ngModel"
                                name="poolLimit" 
                                aria-label="Pool Limit"
                                [pattern]="patternFloat">
                                <mat-error *ngIf="poolLimit.hasError('pattern')">
                                    <div ng-message="required">Only numbers and decimals are allowed.</div>
                                </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="section-body layout-row layout-align-start-center">
                        <mat-form-field appearance="outline" class="flex-40">
                            <mat-label>Business Income Limit</mat-label>
                            <input 
                                matInput 
                                [(ngModel)]="editBuilding.businessIncomeLimit"  
                                #businessIncomeLimit="ngModel"
                                name="businessIncomeLimit" 
                                aria-label="Business Income Limit"
                                [pattern]="patternFloat">
                                <mat-error *ngIf="businessIncomeLimit.hasError('pattern')">
                                    <div ng-message="required">Only numbers and decimals are allowed.</div>
                                </mat-error>
                        </mat-form-field>
                        <mat-form-field class="flex-40" appearance="outline">
                            <mat-label>Dwelling Ownership Type</mat-label>
                            <mat-select [(ngModel)]="editBuilding.dwellingOwnershipType" name="dwellingOwnershipType">
                                <mat-option *ngFor="let description of dwellingDescriptions" [value]="description">
                                    {{ description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="flex-30">
                            <mat-label>Year Built</mat-label>
                            <input 
                                matInput 
                                type="number"
                                [(ngModel)]="editBuilding.yearBuilt"  
                                #yearBuilt="ngModel"
                                name="yearBuilt" 
                                aria-label="Year Built"
                                (keypress)="numericOnly($event)"
                                [pattern]="pattern"
                                [maxlength]="4"        
                                [minlength]="4">
                                <mat-error *ngIf="yearBuilt.hasError('pattern')">
                                    <div ng-message="required">Must be four digits.</div>
                                </mat-error>
                        </mat-form-field>
                    </div>
                
            </div>
        </mat-card-content>
        <!--<mat-card-actions *ngIf="!isBusy">-->
        <mat-card-actions>   
            <button
                mat-flat-button 
                class="hds_button cancel" 
                (click)="cancel()" 
                id="cancelButton"
                >Cancel</button>
            <button    
                mat-flat-button 
                class="hds_button" 
                (click)="save()" 
                id="saveButton"
                [disabled]="(!buildingForm.valid || buildingForm.pristine)">
                Save Building</button>
        </mat-card-actions>   
    </mat-card> 
</form>