import {Component, Injector, Input, OnInit} from '@angular/core';
import {GlobalModalService} from 'src/app/kanso-common/core/service';
import {UploaderHandlerComponent} from '../uploader-handler/uploader-handler.component';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent implements OnInit {
  // eslint-disable-next-line
  @Input() fileReturn: Function;
  @Input() householdId = null;
  @Input() userId = null;
  @Input() siteSection = null; // household, unit, inspection, etc.
  @Input() siteSectionId = null;
  @Input() siteSubsection = null; // documents, images, etc

  constructor(public globalModalService: GlobalModalService, protected injector: Injector) {}

  // eslint-disable-next-line
  ngOnInit(): void {}

  openHandler() {
    this.globalModalService.openModal(UploaderHandlerComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      userId: this.userId,
      householdId: this.householdId ?? null,
      siteSection: this.siteSection,
      siteSectionId: this.siteSectionId,
      siteSubsection: this.siteSubsection ?? null,
      updateFunction: files => {
        this.fileReturn(files);
      },
    });
  }
}
