import {Injectable} from '@angular/core';
import {STAGE_MAPPINGS, SUBDOMAIN_MAPPINGS} from '../constants/environment.constants';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  NODE_ENV: string;
  stage: string;
  private static instance: ReportingService;

  constructor() {
    this.NODE_ENV = sessionStorage.getItem('NODE_ENV');
  }

  public static getInstance(): ReportingService {
    if (!ReportingService.instance) {
      ReportingService.instance = new ReportingService();
    }
    return ReportingService.instance;
  }

  public getStageContext(): string {
    if (!this.NODE_ENV) {
      console.warn('NODE_ENV is not set, defaulting to prod stage');
      return 'prod';
    }

    const matchedStage = STAGE_MAPPINGS.find(mapping => this.NODE_ENV === mapping.keyword);
    this.stage = matchedStage?.stage || 'prod';
    return this.stage;
  }

  /**
   * Gets the reporting subdomain based on the current stage
   * @returns The reporting subdomain for the current environment
   */
  public getReportingSubDomain(): string {
    const stage = this.getStageContext();
    const matchedMapping = SUBDOMAIN_MAPPINGS.find(mapping => {
      switch (stage) {
        case 'dev':
          return mapping.keyword === 'development';
        case 'qa':
          return mapping.keyword === 'qa';
        case 'training':
          return mapping.keyword === 'training';
        case 'prod':
          return mapping.keyword === 'production';
        default:
          return mapping.keyword === 'development';
      }
    });

    if (!matchedMapping) {
      console.warn(`No subdomain mapping found for stage: ${stage}, using development`);
      return 'dev-reporting';
    }

    return matchedMapping.subDomain;
  }

  /**
   * Builds a complete report URL with the correct subdomain
   * @param host The current hostname
   * @param protocol The current protocol (http/https)
   * @param reportPath The path to the report
   * @param params URL parameters to append
   * @returns The complete report URL
   */
  public buildReportUrl(host: string, protocol: string, reportPath: string, params: Record<string, unknown>): string {
    const reportingSubDomain = this.getReportingSubDomain();

    // Convert all parameter values to strings
    const stringParams = Object.entries(params).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value?.toString() || '',
      }),
      {} as Record<string, string>
    );

    const urlParams = new URLSearchParams(stringParams);

    if (host.includes('.app.')) {
      host = host.replace('.app.', `.${reportingSubDomain}.`);
    } else {
      host = host.replace('.hdsdoorways.', `.${reportingSubDomain}.hdsdoorways.`);
    }

    return `${protocol}//${host}${reportPath}?${urlParams.toString()}`;
  }
}
