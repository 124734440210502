import {ISubLedgerMap} from '../../accounting/service/accounting-models';
import {Component, Injector, ViewChild} from '@angular/core';
import {GlobalModalService} from '../../../kanso-common/core/service';
import {FundingAndDepartmentModalData, Landlord} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {VendorService} from '../../vendor/vendor.service';
import {
  Authority,
  AuthorityAccountDefinition,
  Department,
  OfficeLocation,
  OfficeLocationsModalData,
} from '../../housing-core/services/housing-models';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {EditOfficeLocationComponent} from './edit-office-location/edit-office-location.component';
import {EditFundingAndDepartmentPopupComponent} from './edit-funding-and-department-popup/edit-funding-and-department-popup.component';
import {EditIncomeLimitAreaComponent} from './edit-income-limit-area/edit-income-limit-area.component';
import {EditRegionalRentLimitComponent} from './edit-regional-rent-limit/edit-regional-rent-limit.component';
import {EditPaymentStandardsComponent} from './edit-payment-standards/edit-payment-standards.component';
import lodash from 'lodash';
import {CoreService} from 'src/app/core/service/core.service';
import {User} from 'src/app/core/service/core-models';
import {UserService} from 'src/app/shared/services';
import {AchService, CreateAuthorityAchCommand, UpdateAuthorityAchCommand} from 'src/app/shared/services/ach-service';
import {EncryptionService} from '../../accounting/service/encryption.service';
import {exit} from 'process';
import {AuthorityACH} from '../../accounting/service/nacha-model';

@Component({
  selector: 'housing-authority-setup',
  templateUrl: './housing-authority-setup.component.html',
  styleUrls: ['./housing-authority-setup.component.scss'],
})
export class HousingAuthoritySetupComponent {
  @ViewChild('editHousingAuthorityForm') editHousingAuthorityForm;
  generalInfoPanelOpenState = true;
  officeLocationPanelOpenState = false;
  departmentsPanelOpenState = false;
  accountingInfoPanelOpenState = false;
  fundingSourcePanelOpenState = false;
  incomeLimitPanelOpenState = false;
  regionalRentLimitPanelOpenState = false;
  miscellaneousPanelOpenState = false;
  showAccountInfo = false;
  maskedAccountNumber = '';
  achPermission = 'admin || viewBankingInformation';
  hasPermission = false;
  achLoading = true;
  encryptData: any;
  encryptedAccountNumber = null;
  returnedData: {
    dataKey: string;
    dataVector: string;
    encryptedData: string;
  };
  encryptedDataToken: string;
  encryptedDatakey: string;
  encryptedDataVector: string;
  chartOfAccounts: ISubLedgerMap[] = [
    {
      _id: null,
      name: '',
      description: '',
      glAccount: null,
      chargeCategories: null,
      creditCategories: null,
      systemCategories: null,
      isGeneric: false,
    },
  ];
  landlordList: Landlord[];
  newAuthDepartment: Department = {
    name: '',
    location: [],
  };
  authorityLandlord: string;
  authority: Authority;
  newAuthority: Authority;
  authorityCopy: Authority;
  selectedLocation: OfficeLocation;
  selectedDepartment: Department;
  selectedFundingSource: Department;
  departmentEdit = false;
  initialAccountDefinition: AuthorityAccountDefinition;
  userEmail: string;
  fundingSourceEdit = false;
  choosenSource: number;
  incomeLimitAreaPanelOpenState = false;
  regionalLimitAreaPanelOpenState = false;
  paymentStandardsPanelOpenState = false;
  selectedChartOfAccounts;
  selectedLandlord: string;
  selectedLandlordCopy: string;
  fromEdit = true;
  loading = true;
  encryptedAccountLoading = true;
  error = false;
  errorMsg = '';
  isBusy = false;
  busyText = '';
  query = {
    filterSet: {},
  };
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';
  authorityAchData: AuthorityACH = {
    bankTokenDisplay: '',
    odfiName: '',
    odfiRoutingNumber: '',
    id: '',
    dataKey: '',
    dataVector: '',
    accountId: '',
    achNameOnAccount: '',
    createdOn: undefined,
    createdBy: '',
    deletedOn: undefined,
    deletedBy: '',
    modifiedOn: undefined,
    modifiedBy: '',
    siteId: '',
    customerId: '',
  };

  createAuthorityAchCommand: CreateAuthorityAchCommand = {
    bankTokenDisplay: null,
    bankToken: null,
    dataKey: null,
    dataVector: null,
    odfiRoutingNumber: null,
    achDescription: null,
    achNameOnAccount: null,
    odfiName: null,
    siteId: null,
    customerId: null,
    createdBy: null,
  };

  updateAuthorityAchCommand: UpdateAuthorityAchCommand = {
    id: null,
    bankTokenDisplay: null,
    bankToken: null,
    dataKey: null,
    dataVector: null,
    odfiRoutingNumber: null,
    achDescription: null,
    achNameOnAccount: null,
    odfiName: null,
    siteId: null,
    customerId: null,
    modifiedBy: null,
  };

  constructor(
    public accountingServices: AccountingService,
    public housingServices: HousingServices,
    public vendorService: VendorService,
    protected injector: Injector,
    public globalModalService: GlobalModalService,
    private coreService: CoreService,
    public userService: UserService,
    public achService: AchService,
    public encryptionService: EncryptionService
  ) {
    const siteId = sessionStorage.SITEID;

    this.setBusy('Loading Housing Authority Data...');
    this.housingServices.getHousingAuthority().subscribe(
      result => {
        this.authorityCopy = result.body;
        if (this.authorityCopy.accountDefinition) {
          this.selectedChartOfAccounts = this.authorityCopy.accountDefinition.chartOfAccounts;
        } else {
          this.selectedChartOfAccounts = null;
        }
        this.initialAccountDefinition = this.authorityCopy.accountDefinition;
        this.vendorService.getVendorsList().subscribe(result => {
          this.landlordList = result.data.filter(v => v.vendorType == 'LANDLORD');
        });
        this.accountingServices.getAllSubLedgerMaps().subscribe(result => {
          this.chartOfAccounts = result.body;
        });
        if (!this.authorityCopy.rentCalc) {
          this.authorityCopy.rentCalc = {
            monthOfFiscalYearEnd: null,
          };
        }

        this.achService.getAuthorityAch().subscribe(
          result => {
            if (result) {
              this.authorityAchData = result;
              this.maskedAccountNumber = `*****${this.authorityAchData.bankTokenDisplay}`;
              this.authorityCopy.accountNumber = this.maskedAccountNumber;
              this.authorityCopy.routingNumber = this.authorityAchData.odfiRoutingNumber;
              this.authorityCopy.odfiName = this.authorityAchData.odfiName;
              this.authorityCopy.nameOnAccount = this.authorityAchData.achNameOnAccount;
              if (this.authorityAchData.id) {
                this.getEncryptedAccount();
              }
            } else {
              this.encryptedAccountLoading = false;
              this.showAccountInfo = true;
            }
            this.authority = lodash.cloneDeep(this.authorityCopy);
            this.achLoading = false;
          },
          error => {
            this.achLoading = false;
            console.log(error);
            this.coreService.displayError('There was error getting ACH Data');
          }
        );
        this.loading = false;
        this.isBusy = false;
      },
      error => {
        console.log(`Error fetching Housing Authority:`, error.message);
        this.errorMsg = 'An unexpected error occurred fetching Housing Authority. Please try again.';
        this.loading = false;
        this.error = true;
      }
    );
  }

  setBusy(busyText: string) {
    this.isBusy = true;
    this.busyText = busyText;
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      (user: User) => {
        this.hasPermission = this.userService.permissionExists(user.permissions, this.achPermission);
        this.userEmail = user.email;
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was error when retrieving current user information!');
      }
    );
  }

  updateAuthorityUI(newAuthorityObject) {
    this.authorityCopy = lodash.cloneDeep(newAuthorityObject);
    this.authority = lodash.cloneDeep(newAuthorityObject);
    this.selectedLandlordCopy = lodash.cloneDeep(this.selectedLandlord);
    this.initialAccountDefinition = this.authorityCopy.accountDefinition;
    this.selectedChartOfAccounts = this.authorityCopy.accountDefinition ? this.authorityCopy.accountDefinition.chartOfAccounts : null;
    this.authorityCopy.odfiName = this.authorityAchData.odfiName;
    this.maskedAccountNumber = `*****${this.authorityAchData.bankTokenDisplay}`;
    this.authorityCopy.accountNumber = this.maskedAccountNumber;
    this.authorityCopy.routingNumber = this.authorityAchData.odfiRoutingNumber;
    this.authorityCopy.nameOnAccount = this.authorityAchData.achNameOnAccount;
    this.showAccountInfo = false;
  }

  updateHousingAuthority() {
    this.setBusy('Saving Housing Authority General Data...');
    this.housingServices.updateHousingAuthority(this.authorityCopy).subscribe(
      response => {
        this.updateAuthorityUI(response.body);
        this.isBusy = false;
      },
      error => {
        console.log('There was an error updating housing authority: ', error);
        this.cancelGeneralInformationForm(this.editHousingAuthorityForm);
        this.isBusy = false;
        this.coreService.displayError('An unexpected error occurred updating Housing Authority. Please try again.');
      }
    );
  }

  updateAuthorityAPI() {
    if (
      (this.authorityCopy.accountNumber || this.authorityCopy.odfiName || this.authorityCopy.routingNumber) &&
      (this.authorityCopy.accountNumber != this.maskedAccountNumber ||
        this.authorityCopy.odfiName != this.authorityAchData.odfiName ||
        this.authorityCopy.routingNumber != this.authorityAchData.odfiRoutingNumber ||
        this.authorityCopy.nameOnAccount != this.authorityAchData.achNameOnAccount)
    ) {
      this.authorityAchChanged();
    } else {
      this.updateHousingAuthority();
    }
  }

  async authorityAchChanged() {
    this.setBusy('Encrypting and Saving Housing Authority ACH Data...');
    const tokenDisplay = this.authorityCopy.accountNumber.slice(-4);
    if (this.authorityAchData.id === '') {
      try {
        const result = await this.encryptionService.encryptData(this.authorityCopy.accountNumber);
        if (result) {
          const {encryptedData, dataKey, dataVector} = result;
          this.encryptedDataToken = encryptedData;
          this.createAuthorityAchCommand.bankToken = encryptedData;
          this.createAuthorityAchCommand.dataKey = dataKey;
          this.createAuthorityAchCommand.dataVector = dataVector;
          this.createAuthorityAchCommand.bankTokenDisplay = tokenDisplay;
          this.createAuthorityAchCommand.siteId = this.SITE_ID;
          this.createAuthorityAchCommand.customerId = this.CUSTOMER_ID;
          this.createAuthorityAchCommand.odfiName = this.authorityCopy.odfiName;
          this.createAuthorityAchCommand.odfiRoutingNumber = this.authorityCopy.routingNumber;
          this.createAuthorityAchCommand.achNameOnAccount = this.authorityCopy.nameOnAccount;
        }
      } catch (error) {
        this.coreService.displayError('An unexpected error occurred updating Housing Authority ACH. Please try again.');
        console.error('Failed to encrypt data:', error);
        this.isBusy = false;
        return;
      }
      this.createAuthorityAchCommand.createdBy = this.userEmail;
      this.achService.createAuthorityAch(this.createAuthorityAchCommand).subscribe(
        (response: any) => {
          if (response.data.createAuthorityAch.status === 'SUCCESS') {
            this.authorityAchData = response.data.createAuthorityAch.affectedEntity;
            this.isBusy = false;
            this.updateHousingAuthority();
          }
        },
        error => {
          this.authorityCopy.odfiName = this.authorityAchData.odfiName;
          this.authorityCopy.accountNumber = this.maskedAccountNumber;
          this.authorityCopy.routingNumber = this.authorityAchData.odfiRoutingNumber;
          console.log('There was an error updating housing authority ACH: ', error);
          this.coreService.displayError('An unexpected error occurred updating Housing Authority ACH. Please try again.');
          this.isBusy = false;
          return;
        }
      );
    } else {
      this.updateAuthorityAchCommand.modifiedBy = this.userEmail;
      this.updateAuthorityAchCommand.id = this.authorityAchData.id;
      this.updateAuthorityAchCommand.siteId = this.SITE_ID;
      this.updateAuthorityAchCommand.customerId = this.CUSTOMER_ID;
      this.updateAuthorityAchCommand.odfiName = this.authorityCopy.odfiName;
      this.updateAuthorityAchCommand.odfiRoutingNumber = this.authorityCopy.routingNumber;
      this.updateAuthorityAchCommand.achNameOnAccount = this.authorityCopy.nameOnAccount;
      if (this.authorityCopy.accountNumber != this.encryptedAccountNumber && this.showAccountInfo) {
        try {
          const result = await this.encryptionService.encryptData(this.authorityCopy.accountNumber);
          if (result) {
            const {encryptedData, dataKey, dataVector} = result;
            this.updateAuthorityAchCommand.bankToken = encryptedData;
            this.updateAuthorityAchCommand.dataKey = dataKey;
            this.updateAuthorityAchCommand.dataVector = dataVector;
            this.updateAuthorityAchCommand.bankTokenDisplay = tokenDisplay;
          }
        } catch (error) {
          this.coreService.displayError('An unexpected error occurred updating Housing Authority ACH. Please try again.');
          console.error('Failed to encrypt data:', error);
          this.isBusy = false;
          return;
        }
      }

      this.achService.updateAuthorityAch(this.updateAuthorityAchCommand).subscribe(
        (response: any) => {
          if (response.data.updateAuthorityAch.status === 'SUCCESS') {
            this.authorityAchData = response.data.updateAuthorityAch.affectedEntity;
            this.isBusy = false;
            this.updateHousingAuthority();
          }
        },
        error => {
          this.authorityCopy.odfiName = this.authorityAchData.odfiName;
          this.authorityCopy.accountNumber = this.maskedAccountNumber;
          this.authorityCopy.routingNumber = this.authorityAchData.odfiRoutingNumber;
          console.log('There was an error updating housing authority ACH: ', error);
          this.isBusy = false;
          this.coreService.displayError('An unexpected error occurred updating Housing Authority ACH. Please try again.');
          return;
        }
      );
    }
  }

  createNewAuthority(): Authority {
    return {
      _id: '',
      name: '',
      accountDefinition: {},
      systemName: '',
      address: [
        {
          streetAddress: '',
          isMailingAddress: null,
          unit: '',
          city: '',
          state: '',
          postalCode: '',
        },
      ],
      fedTaxId: null,
      fundingSources: [],
      rentCalc: {
        monthOfFiscalYearEnd: null,
      },
      phone: '',
      fax: '',
      email: '',
      phaCode: '',
      landlordId: '',
      timeZoneName: '',
      officeLocations: [],
      departments: [],
      incomeLimitAreas: null,
      rentLimitAreas: null,
      paymentStandards: null,
      prePaymentAccountSetup: null,
      nameOnAccount: '',
      accountNumber: '',
      routingNumber: '',
      odfiName: '',
      authorityType: '',
    };
  }

  cancelGeneralInformationForm(editHousingAuthorityForm) {
    this.updateAuthorityUI(this.authority);
    this.editHousingAuthorityForm.form.markAsPristine();
  }

  saveGeneralInformationForm(editHousingAuthorityForm) {
    this.updateAuthorityAPI();
    this.editHousingAuthorityForm.form.markAsPristine();
  }

  editOfficeLocation(location, editHousingAuthorityForm) {
    this.fromEdit = true;
    if (location == 'add') {
      this.fromEdit = false;
      this.selectedLocation = {
        _id: null,
        name: '',
        address: [
          {
            category: '',
            streetAddress: '',
            unit: '',
            city: '',
            state: '',
            postalCode: '',
            isMailingAddress: false,
          },
        ],
        acceptCash: false,
      };
    } else {
      this.selectedLocation = location;
    }
    this.officeLocationPanelOpenState = true;
    this.globalModalService.openModal<OfficeLocationsModalData>(
      EditOfficeLocationComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        officeAddresses: this.authorityCopy.officeLocations,
        editMode: this.fromEdit,
        selectedLocation: this.selectedLocation,
        updateFunction: this.saveOfficeLocations,
      }
    );
  }

  saveOfficeLocations = () => {
    if (!this.fromEdit) {
      this.authorityCopy.officeLocations.push(this.selectedLocation);
    }
    this.onCheckPristineForm();
  };

  removeOfficeLocation(locationIndex, editHousingAuthorityForm) {
    this.authorityCopy.officeLocations.splice(locationIndex, 1);
    this.onCheckPristineForm();
  }

  deleteDepartment(index, editHousingAuthorityForm) {
    this.authorityCopy.departments.splice(index, 1);
    editHousingAuthorityForm.form.pristine = false;
    this.onCheckPristineForm();
  }

  editDepartment(department, editHousingAuthorityForm) {
    this.fromEdit = true;
    if (department == 'add') {
      this.selectedDepartment = lodash.cloneDeep(this.newAuthDepartment);
      this.fromEdit = false;
    } else {
      this.selectedDepartment = department;
    }
    this.departmentsPanelOpenState = true;
    editHousingAuthorityForm.form.pristine = false;
    this.globalModalService.openModal<FundingAndDepartmentModalData>(
      EditFundingAndDepartmentPopupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        allObjects: this.authorityCopy.departments,
        editMode: this.fromEdit,
        type: 'Departments',
        selectedObject: this.selectedDepartment,
        updateFunction: this.saveEditDepartment,
      }
    );
  }

  saveEditDepartment = editedDepartment => {
    if (!this.fromEdit) {
      this.authorityCopy.departments.push(this.selectedDepartment);
      this.onCheckPristineForm();
    }
  };

  deleteFundingSource(index, editHousingAuthorityForm) {
    this.authorityCopy.fundingSources.splice(index, 1);
    this.onCheckPristineForm();
  }

  editFundingSource(index, source, editHousingAuthorityForm) {
    this.fromEdit = true;
    if (source == 'add') {
      this.selectedFundingSource = lodash.cloneDeep(this.newAuthDepartment);
      this.fromEdit = false;
    } else {
      this.selectedFundingSource = lodash.cloneDeep(this.newAuthDepartment);
      this.selectedFundingSource.name = source;
    }
    this.fundingSourcePanelOpenState = true;
    this.globalModalService.openModal<any>(
      EditFundingAndDepartmentPopupComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {
        name: source,
        editMode: this.fromEdit,
        index: index,
        type: 'Funding Sources',
        selectedObject: this.selectedFundingSource,
        updateFunction: this.saveEditFundingSource,
      }
    );
  }

  saveEditFundingSource = index => {
    if (!this.fromEdit) {
      this.authorityCopy.fundingSources.push(this.selectedFundingSource.name);
    } else {
      this.authorityCopy.fundingSources[index] = this.selectedFundingSource.name;
    }
    this.onCheckPristineForm();
  };

  deleteIncomeLimitArea(index) {
    this.authorityCopy.incomeLimitAreas.splice(index, 1);
    this.onCheckPristineForm();
  }

  addNewIncomeLimitArea() {
    this.incomeLimitPanelOpenState = true;
    this.globalModalService.openModal<any>(EditIncomeLimitAreaComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      incomeLimitArea: null,
      editMode: true,
      index: this.authorityCopy.incomeLimitAreas.length + 1,
      updateFunction: this.saveNewIncomeLimitArea,
    });
  }

  editIncomeLimitArea(area, edit, index) {
    this.incomeLimitPanelOpenState = true;
    this.globalModalService.openModal<any>(EditIncomeLimitAreaComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      incomeLimitArea: area,
      editMode: edit,
      index: index,
      updateFunction: this.saveEditIncomeLimitArea,
    });
  }

  saveEditIncomeLimitArea = (incomeLimit, index) => {
    this.authorityCopy.incomeLimitAreas[index] = incomeLimit;
    this.onCheckPristineForm();
  };

  saveNewIncomeLimitArea = (incomeLimit, index) => {
    if (index > this.authorityCopy.incomeLimitAreas.length) {
      this.authorityCopy.incomeLimitAreas.push(incomeLimit);
      this.onCheckPristineForm();
    }
  };

  deleteRegionalRentLimit(index) {
    this.authorityCopy.rentLimitAreas.splice(index, 1);
    this.onCheckPristineForm();
  }

  addNewRegionalRentLimit() {
    this.regionalRentLimitPanelOpenState = true;
    this.globalModalService.openModal<any>(EditRegionalRentLimitComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      rentLimitArea: null,
      editMode: true,
      index: this.authorityCopy.rentLimitAreas.length + 1,
      updateFunction: this.saveNewRegionalRentLimit,
    });
  }

  editRegionalRentLimit(area, edit, index) {
    this.globalModalService.openModal<any>(EditRegionalRentLimitComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      rentLimitArea: area,
      editMode: edit,
      index: index,
      updateFunction: this.saveEditRegionalRentLimit,
    });
  }

  saveEditRegionalRentLimit = (rentLimit, index) => {
    this.authorityCopy.rentLimitAreas[index] = rentLimit;
    this.onCheckPristineForm();
  };

  saveNewRegionalRentLimit = (rentLimit, index) => {
    if (index > this.authorityCopy.rentLimitAreas.length) {
      this.authorityCopy.rentLimitAreas.push(rentLimit);
      this.onCheckPristineForm();
    }
  };

  addNewPaymentStandard(editHousingAuthorityForm) {
    this.paymentStandardsPanelOpenState = true;
    this.globalModalService.openModal<any>(EditPaymentStandardsComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      paymentStandard: null,
      editMode: true,
      updateFunction: this.saveNewPaymentStandard,
    });
  }

  editPaymentStandard(paymentStandardToEdit, edit, index, editHousingAuthorityForm) {
    this.incomeLimitPanelOpenState = true;
    this.globalModalService.openModal<any>(EditPaymentStandardsComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      paymentStandard: paymentStandardToEdit,
      editMode: edit,
      index: index,
      editHousingAuthorityForm: editHousingAuthorityForm,
      updateFunction: this.saveEditPaymentStandard,
    });
  }

  removePaymentStandard(index, editHousingAuthorityForm) {
    this.authorityCopy.paymentStandards.splice(index, 1);
    this.onCheckPristineForm();
  }

  saveEditPaymentStandard = (paymentStandard, index) => {
    this.authorityCopy.paymentStandards[index] = paymentStandard;
    this.onCheckPristineForm();
  };

  saveNewPaymentStandard = paymentStandard => {
    this.authorityCopy.paymentStandards.push(paymentStandard);
    this.onCheckPristineForm();
  };

  onCheckPristineForm() {
    if (this.editHousingAuthorityForm.valid) {
      this.editHousingAuthorityForm.form.pristine = false;
    }
  }

  numericOnly(event): boolean {
    const patt = /^([0-9])$/;
    const result = patt.test(event.key);
    return result;
  }

  stateValidation(event): boolean {
    const patt = /^([a-zA-Z])$/;
    const result = patt.test(event.key);
    return result;
  }

  phaCodeValidation(value: string): boolean {
    const patt = /^[a-zA-Z]{2}[0-9]{3}$/;
    const result = patt.test(value);
    return result;
  }

  changeSelectedAccountDefinition() {
    if (!this.selectedChartOfAccounts) {
      this.authorityCopy.accountDefinition = null;
    } else {
      //update
      if (this.initialAccountDefinition && this.initialAccountDefinition.chartOfAccounts === this.selectedChartOfAccounts._id) {
        this.authorityCopy.accountDefinition = {
          chartOfAccounts: this.selectedChartOfAccounts._id,
          chartOfAccountsName: this.selectedChartOfAccounts.name,
          name: 'Miscellaneous Receipts',
          _id: this.initialAccountDefinition._id,
        };
      } else {
        //create
        this.authorityCopy.accountDefinition = {
          chartOfAccounts: this.selectedChartOfAccounts._id,
          chartOfAccountsName: this.selectedChartOfAccounts.name,
          name: 'Miscellaneous Receipts',
        };
      }
    }
  }

  compareCOA(o1, o2) {
    if (o1._id == o2) {
      return true;
    } else {
      return false;
    }
  }

  compareHAAccountDefinition(o1, o2) {
    if (o1._id == o2) {
      return true;
    } else {
      return false;
    }
  }

  decryptAccountData() {
    this.encryptedAccountLoading = true;
    const result = this.encryptionService
      .decryptData(this.encryptedDataToken, this.authorityAchData.dataKey, this.authorityAchData.dataVector)
      .then(value => {
        this.encryptedAccountNumber = value;
        this.authorityCopy.accountNumber = this.encryptedAccountNumber;
        this.encryptedAccountLoading = false;
      });
  }
  getEncryptedAccount() {
    this.achService.getEncryptedAuthorityAch(this.authorityAchData.id).subscribe(
      (data: any) => {
        this.encryptedDataToken = data.node.tokenData;
      },
      error => {
        console.log('There was an error decrypting data: ', error);
        this.coreService.displayError('An unexpected error occurred getting encrypted data.');
      }
    );
    this.encryptedAccountLoading = false;
  }

  toggleAccountVisibility() {
    this.showAccountInfo = !this.showAccountInfo;
    if (this.showAccountInfo) {
      if (this.encryptedAccountNumber == null && this.encryptedDataToken != null) {
        this.authorityCopy.accountNumber = ' ';
        this.decryptAccountData();
      } else {
        this.authorityCopy.accountNumber = ' ';
        if (this.encryptedAccountNumber) {
          this.authorityCopy.accountNumber = this.encryptedAccountNumber;
        }
      }
    } else {
      if (this.encryptedAccountNumber == null || this.authorityCopy.accountNumber == this.encryptedAccountNumber) {
        this.authorityCopy.accountNumber = this.maskedAccountNumber;
      } else {
        //value has been edited so no remasking until save or cancel
        this.showAccountInfo = true;
      }
    }
  }
}
