<div class="paddingTable">
    <div class="section-body layout-row layout-align-start-center">
        <mat-radio-group ngModel
            [(ngModel)]="program.enhancedRentToggle"
            id="enhancedRentToggle"
            name="enhancedRentToggle">
            <mat-radio-button [value]="false" class="md-primary leftPadding" id="enhancedRentToggleOff">Legacy Calc 
            </mat-radio-button>
            <mat-radio-button [value]="true" class="md-primary leftPadding" id="enhancedRentToggleOn">Enhanced Rent </mat-radio-button>
        </mat-radio-group>
    </div>
    <div *ngIf="program.enhancedRentToggle == true">
        <div *ngIf="!tenantLedgers.length && !landlordLedger.length && !utilityLedger.length && !maintenanceLedger.length && !housingauthorityLedger.length && !otherLedger.length"> 
            <b class="headerPadding">Please add a ledger above.</b>
        </div>
        <div class= "headerPadding" *ngIf="tenantLedgers.length || landlordLedger.length || utilityLedger.length || maintenanceLedger.length || housingauthorityLedger.length || otherLedger.length">
            <mat-card-header class="headerPadding">Each Transaction below will post on the 1st of the Month for accounting purposes, but will automatically be created on the input Created Date each month
            </mat-card-header>
            <div class="section-header layout-row rowPaddingLeft layout-align-start-center moveButton mtb-header">
                <div class="headerPadding">
                    Adjustment 
                </div>
                <div class="info-header">
                    Adjustments are often transactions posting to Accounts Payable or Principal type accounts.
                    <br>
                    For example: housing assistance payments, utility reimbursements, principal reductions etc.
                </div>
                <button mat-flat-button color="primary" class="hds_button" 
                    (click)="addERBTransaction(true)" >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <section>
                <table class="table altRows" *ngIf="eRBAdjustments.length > 0; else noERBAdjustments">
                    <thead>
                        <tr>
                            <th>Ledger Type</th>
                            <th>Account Definition</th>
                            <th>Transaction Type</th>
                            <th>Created Date of Month</th>
                            <th>Created On Month</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody [formGroup]="eRBAdjustmentform">
                        <ng-container formArrayName="eRBAdjustments">
                            <tr class="centerData" *ngFor="let transactionForm of eRBAdjustments.controls; trackBy: onTrackById; let i = index" [formGroup]="transactionForm">
                                <td >
                                    <mat-form-field class="erbLedgerType" appearance="outline">
                                        <mat-select 
                                            formControlName="ledgerType"
                                            name="eRBLedger" 
                                            aria-label="Ledger Type" 
                                            id="eRBTLedgerType"
                                            [(ngModel)]="enhancedRentBehaviorAdjustments[i].ledgerType"
                                            (selectionChange)="eRBTLedgerTypeChange(transactionForm.value.ledgerType, i, true)">
                                            <mat-option [value]="0" id="Tenant">Tenant</mat-option>
                                            <mat-option *ngIf="landlordLedger.length > 0" [value]="1" id="Landlord">Landlord</mat-option>
                                            <mat-option *ngIf="utilityLedger.length > 0" [value]="2" id="Utility">Utility</mat-option>
                                            <mat-option *ngIf="otherLedger.length > 0" [value]="3" id="Other">Other</mat-option>
                                            <mat-option *ngIf="maintenanceLedger.length > 0" [value]="4" id="Maintenance">Maintenance</mat-option>
                                            <mat-option *ngIf="housingauthorityLedger.length > 0" [value]="5" id="Housingauthority">Housing Authority</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-form-field class="erbAccountDefinition" appearance="outline">
                                        <mat-select 
                                            formControlName="accountDefinition"
                                            [(ngModel)]="enhancedRentBehaviorAdjustments[i].accountDefinition"
                                            (selectionChange)="eRBTAccountDefinitionChange(transactionForm.value.accountDefinition, i, true)"
                                            >
                                            <mat-option
                                                *ngFor="let accountDef of allowedAdjustmentLedgerAccounts[i]" [value]="accountDef.id">
                                                {{accountDef.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="erbTransactionType" appearance="outline">
                                        <mat-select 
                                            formControlName="transactionType"
                                            [(ngModel)]="enhancedRentBehaviorAdjustments[i].transactionType"
                                            [compareWith]="compareWith"
                                            >
                                            <mat-optgroup 
                                                *ngFor="let category of allowedERBTAdjustmentTypes[i].chargeCategories"
                                                label="{{category.name}}">
                                                <mat-option 
                                                    *ngFor="let subCategory of category.subCategories" 
                                                    [value]="subCategory">
                                                    {{subCategory.name}}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-form-field class="erbCreatedDate" appearance="outline">
                                        <input 
                                            matInput 
                                            formControlName="createdDay"
                                            [(ngModel)]="enhancedRentBehaviorAdjustments[i].createdDay"
                                            id="createdDayInput" 
                                            aria-label="Created Date"
                                            type="number"
                                            [maxLength]="2"
                                            [max]="28"
                                            [min]="1"
                                            (change)="eRBTDateChanged(transactionForm.value.createdDay)"
                                            >
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-radio-group
                                        formControlName="currentMonth"
                                        (change)="elementChanged()"
                                        [(ngModel)]="enhancedRentBehaviorAdjustments[i].currentMonth"
                                        >
                                        <mat-radio-button [value]="true" class="md-primary" >Current 
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false" class="md-primary leftPadding" >Previous 
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <a class="marg-auto" popover-trigger="mouseenter"
                                    (click)="deleteERBTransaction(i, true)">
                                        <mat-icon>delete</mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <ng-template #noERBAdjustments>
                    <p class="headerPadding">No adjustment behavior setup, click Add Behavior to get started.</p>
                </ng-template>
            </section>
            <div class="section-header layout-row rowPaddingLeft layout-align-start-center moveButton  mtb-header">
                <div class="headerPadding">
                    Charges
                </div>
                <div class="info-header">
                    Charges are transactions posting to Accounts Receivable type accounts.
                    <br>
                    For example: Rent, Admin Fee, etc.
                </div>
                <button mat-flat-button color="primary" class="hds_button" 
                    (click)="addERBTransaction(false)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <section>
                <table class="table altRows" *ngIf="eRBTransactions.length > 0; else noERBTransactions">
                    <thead>
                        <tr>
                            <th>Ledger Type</th>
                            <th>Account Definition</th>
                            <th>Transaction Type</th>
                            <th>Created Date of Month</th>
                            <th>Created On Month</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody [formGroup]="eRBTransactionform">
                        <ng-container formArrayName="eRBTransactions">
                            <tr class="centerData" *ngFor="let transactionForm of eRBTransactions.controls; trackBy: onTrackById; let i = index" [formGroup]="transactionForm">
                                <td >
                                    <mat-form-field class="erbLedgerType" appearance="outline">
                                        <mat-select 
                                            formControlName="ledgerType"
                                            name="eRBLedger" 
                                            aria-label="Ledger Type" 
                                            id="eRBTLedgerType"
                                            [(ngModel)]="enhancedRentBehaviorTransactions[i].ledgerType"
                                            (selectionChange)="eRBTLedgerTypeChange(transactionForm.value.ledgerType, i, false)">
                                            <mat-option [value]="0" id="Tenant">Tenant</mat-option>
                                            <mat-option *ngIf="landlordLedger.length > 0" [value]="1" id="Landlord">Landlord</mat-option>
                                            <mat-option *ngIf="utilityLedger.length > 0" [value]="2" id="Utility">Utility</mat-option>
                                            <mat-option *ngIf="otherLedger.length > 0" [value]="3" id="Other">Other</mat-option>
                                            <mat-option *ngIf="maintenanceLedger.length > 0" [value]="4" id="Maintenance">Maintenance</mat-option>
                                            <mat-option *ngIf="housingauthorityLedger.length > 0" [value]="5" id="Housingauthority">Housing Authority</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-form-field class="erbAccountDefinition" appearance="outline">
                                        <mat-select 
                                            formControlName="accountDefinition"
                                            [(ngModel)]="enhancedRentBehaviorTransactions[i].accountDefinition"
                                            (selectionChange)="eRBTAccountDefinitionChange(transactionForm.value.accountDefinition, i, false)">
                                            <mat-option
                                                *ngFor="let accountDef of allowedTransactionLedgerAccounts[i]" [value]="accountDef.id">
                                                {{accountDef.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field class="erbTransactionType" appearance="outline">
                                        <mat-select 
                                            formControlName="transactionType"
                                            [(ngModel)]="enhancedRentBehaviorTransactions[i].transactionType"
                                            [compareWith]="compareWith"
                                            >
                                            <mat-optgroup 
                                                *ngFor="let category of allowedERBTransactionTypes[i].chargeCategories"
                                                label="{{category.name}}">
                                                <mat-option 
                                                    *ngFor="let subCategory of category.subCategories" 
                                                    [value]="subCategory">
                                                    {{subCategory.name}}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-form-field class="erbCreatedDate" appearance="outline">
                                        <input 
                                            matInput 
                                            formControlName="createdDay"
                                            [(ngModel)]="enhancedRentBehaviorTransactions[i].createdDay"
                                            id="createdDayInput" 
                                            aria-label="Created Date"
                                            type="number"
                                            [maxLength]="2"
                                            [max]="28"
                                            [min]="1"
                                            (change)="eRBTDateChanged(transactionForm.value.createdDay)"
                                            >
                                    </mat-form-field>
                                </td>
                                <td >
                                    <mat-radio-group formControlName="currentMonth"
                                    (change)="elementChanged()"
                                    [(ngModel)]="enhancedRentBehaviorTransactions[i].currentMonth">
                                        <mat-radio-button [value]="true" class="md-primary" >Current 
                                        </mat-radio-button>
                                        <mat-radio-button [value]="false" class="md-primary leftPadding" >Previous 
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </td>
                                <td>
                                    <a class="marg-auto" popover-trigger="mouseenter"
                                    (click)="deleteERBTransaction(i, false)">
                                        <mat-icon>delete</mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <ng-template #noERBTransactions>
                    <p class="headerPadding">No charges behavior setup, click Add Behavior to get started.</p>
                </ng-template>
            </section>
        </div>
    </div>
</div>