<mat-card class="knso-card upload-handler-container">
    <busy-notice
        [isBusy]="this.isBusy"
        [busyText]="this.busyText"
        headerText="KANSO">
    </busy-notice>
    <mat-card-header>
        <mat-toolbar>
            <span>Upload Files</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="uploadForm">
            <div class="drag-n-drop-container" (fileDrroped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                <h1>
                    <mat-icon class="upload-icon" alt="cloud upload">cloud_upload</mat-icon>
                </h1>
                <h4>
                    Drag and drop<br />
                    or<br />
                    <button 
                        mat-flat-button
                        class="hds_button">
                        Browse
                    </button>
                </h4>
            </div>
            <div class="files-list-container">
                <div class="file-list" *ngFor="let fileObject of files; let i = index">
                    <span class="fileroom-extension {{ getFileExtension(fileObject) }}">{{ getFileExtension(fileObject) }}</span>
                    <div class="info">
                        <h4 class="name">{{ fileObject.file?.name }}</h4>
                      <div>
                        <p class="size">{{ formatBytes(fileObject.file?.size) }}</p>
                        <button mat-icon-button (click)="deleteFile(i)"><mat-icon>close</mat-icon></button>
                      </div>
                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions>
        <button
        mat-flat-button
        class="hds_button cancel"          
        (click)="closeDialog()">
        Cancel
        </button>
        <button
            mat-flat-button
            class="hds_button"
            (click)="save()">
            Save
        </button>
    </mat-card-actions>   
</mat-card>