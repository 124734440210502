export type NodeEnv = 'development' | 'qa' | 'training' | 'production';
export type Stage = 'dev' | 'qa' | 'training' | 'prod';

export interface StageMapping {
  keyword: NodeEnv;
  stage: Stage;
}

export interface SubDomainMapping {
  keyword: NodeEnv;
  subDomain: string;
}

export const STAGE_MAPPINGS = [
  {keyword: 'development' as NodeEnv, stage: 'dev' as Stage},
  {keyword: 'qa' as NodeEnv, stage: 'qa' as Stage},
  {keyword: 'training' as NodeEnv, stage: 'training' as Stage},
  {keyword: 'production' as NodeEnv, stage: 'prod' as Stage},
] as const;

export const SUBDOMAIN_MAPPINGS = [
  {keyword: 'development' as NodeEnv, subDomain: 'dev-reporting'},
  {keyword: 'qa' as NodeEnv, subDomain: 'qa-reporting'},
  {keyword: 'training' as NodeEnv, subDomain: 'training-reporting'},
  {keyword: 'production' as NodeEnv, subDomain: 'reporting'},
] as const;

export function isValidNodeEnv(env: string | null): env is NodeEnv {
  return env !== null && STAGE_MAPPINGS.some(mapping => mapping.keyword === env);
}
