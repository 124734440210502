import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {User, MongoResults, KansoActionResult} from '../service/core-models';
import {BaseService} from '../../kanso-common/core/service/base.service';

@Injectable({providedIn: 'root'})
export class UserService extends BaseService {
  userObservable: Observable<User>;
  //   user: User;
  //  isAdmin = false;
  //  email: string;

  constructor(public http: HttpClient) {
    super(http);
  }

  //  get permissions(): string[] {
  //    if (this.user) {
  //      return this.user.permissions;
  //    }
  //  }

  emailCache: Map<string, Observable<string>> = new Map<string, Observable<string>>();

  getEmail(userId: string): Observable<string> {
    let emailObservable = this.emailCache.get(userId);
    if (!emailObservable) {
      emailObservable = new Observable<string>(observer => {
        console.log(' userId ', userId);
        this.apiGet<User>('/api/users/' + userId).subscribe({
          next: response => {
            console.log('get User ', response);
            observer.next(response.email);
            observer.complete();
          },
          error: response => {
            console.log('getEmail failed ', response);
            observer.next('unknown');
            observer.complete();
          },
        });
      });
      this.emailCache.set(userId, emailObservable);
    }
    return emailObservable;
  }

  checkIfAuthorized(forceRecheck?: boolean): Observable<User> {
    if (this.userObservable && !forceRecheck) {
      return this.userObservable;
    }
    this.userObservable = new Observable<User>(observer => {
      this.http.get<KansoActionResult<User>>('/api/user', {}).subscribe({
        next: response => {
          observer.next(Object.assign(new User(), response.body));
          observer.complete();
        },
        error: response => {
          observer.error(response);
        },
      });
    });
    return this.userObservable;
  }

  // eslint-disable-next-line
  logout() {}
}
