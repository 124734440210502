<section class="tabs__panel layout-column">
    <h2 *ngIf="showPage === 'vendors-directory'" >Vendors Directory 2.0</h2>    
</section>

<section *ngIf="showPage === 'vendors-directory'" class="tabs__panel layout-column">
    <div class="tab-header">
        <nav>
            <ul class="nav-pills">
              <li>
                <a [class]="getNavClass('vendors-directory')" (click)="showPage = 'vendors-directory'" routerLinkActive="active">Vendors Directory</a>
              </li>
            </ul>
        </nav>
        <div class="top-buttons">
            <button mat-flat-button
                type="button"
                class="hds_button"
                (click)="addVendor()"
                id="addVendorButton"
                    >Add New
            </button>
        </div>
    <hr class="throughTout"/>
        
    <busy-notice
        [isBusy]="this.loading"
        [busyText]="this.busyText"
        headerText="KANSO">
    </busy-notice>

    <div class="tableContainer" *ngIf="!loading">
        <p *ngIf="!vendors && !loadError || !vendors.length && !loadError">There are no Vendors added yet</p>    
        <div style="display: flex;">
            <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select 
                    [formControl]="vendorTypes">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="Landlord">Landlord</mat-option>
                    <mat-option value="Utility">Utility</mat-option>
                    <mat-option value="Maintenance">Maintenance</mat-option>
                    <mat-option value="Other">Other</mat-option>
                    <mat-option value="Housingauthority">Housing Authority</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Sort By</mat-label>
                <mat-select 
                    [formControl]="vendorSort">
                    <mat-option value="Name">Sort By Name</mat-option>
                    <mat-option value="Type" *ngIf="vendorTypes.value == 'All'">Sort By Type</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="searchInput" appearance="outline">
                <input 
                    matInput                        
                    (keyup.enter)="searchVendors()"
                    [formControl]="vendorFilter"
                    placeholder="search..."/>                        
            </mat-form-field>
            <div class="searchButton" (click)="searchVendors()">
                <mat-icon>search</mat-icon>
            </div>
        </div>         
    <table class="kanso-table altRows">
        <thead>
            <tr class="centerData">
                <th>Vendor Name</th>
                <th>Type</th>
                <th>Address</th>
                <!--<th></th> -->
            </tr>
        </thead>
        <tr class="centerData" *ngFor="let vendor of vendors, let $index=index">
            <td *ngIf="vendor.companyName" ><a (click)="createVendorURL(vendor.id)">{{vendor.companyName}}</a></td>
            <td *ngIf="!vendor.companyName" ><a (click)="createVendorURL(vendor.id)">{{vendor.firstName}} {{vendor.lastName}}</a></td>
            <td>{{vendor.vendorType | titlecase }}</td>
            <td *ngIf="vendor.addresses.length">{{vendor.addresses[0].streetAddress}} {{vendor.addresses[0].unit}}
                , {{vendor.addresses[0].city}}, {{vendor.addresses[0].state}}, {{vendor.addresses[0].postalCode}}</td>
            <td *ngIf="!vendor.addresses.length"></td>
            <!-- <td>
                <a (click)="deleteVendor($index)">
                    <mat-icon>delete_outline</mat-icon>
                </a>
            </td> -->
        </tr>
    </table>    
    <div *ngIf="vendors && vendors.length" class="pageNavigation">
        <page-nav
        [totalCount]="totalCount"
        [pageInfo]="pageInfo"
        [currentRecordsCount] = "currentRecordsCount"
        [pageSize] = "pageSize"
        (goToPage) ="goToPageHandler($event)">
        </page-nav>
     </div>
    </div>

    <div class="errorMsg" *ngIf="loadError">
        An unexpected error has occurred. Please try again.
    </div>
</div>
</section>

<span *ngIf="showPage == 'landlord-properties'">
    <app-landlord-properties></app-landlord-properties>
</span>
