import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchAll, switchMap } from 'rxjs/operators';
import { ChargeGroup, ChargeType, CreateVendorRecurringTransactionCommandInterface, UpdateVendorRecurringTransactionCommandInterface, VendorRecurringTransactionClass, VendorRecurringTransactionModalData } from 'src/app/custom/accounting/service/accounting-models';
import { GlobalModalChild, GlobalModalParent } from '../../../../kanso-common/core/service/globalmodal.service';
import { VendorService } from '../../vendor.service';
import { VoucherService } from 'src/app/vouchers/vouchers-services/vouchers.service';
import { Query } from 'src/generated/graphql';
import _ from 'lodash';

@Component({
  selector: 'app-vendor-recurring-transaction-edit',
  templateUrl: './vendor-recurring-transaction-edit.component.html',
  styleUrls: ['./vendor-recurring-transaction-edit.component.scss']
})
export class VendorRecurringTransactionEditComponent implements GlobalModalChild<VendorRecurringTransactionModalData>, VendorRecurringTransactionModalData  {
  globalModalParent: GlobalModalParent;
  vendorRecurringTransactionInput: VendorRecurringTransactionClass;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  vendorLedgerReport: any;
  vendorType: string;
  portabilityTypeList: string[];
  accountFilter = new FormControl('');
  filteredAccounts$: Observable<any>;
  subledgers$: Observable<ChargeType[]>;
  subledgerMapFilter = new FormControl();
  chargeTypes: ChargeType[];
  chargeGroup: ChargeGroup;
  loggedInUser: string;
  chargeGroupList;
  householdFilter = new FormControl('');
  filteredHouseholdMembers$: Observable<any>;
  searchSubject: Subject<string> = new Subject<string>();
  minStartOnDate: Date;

  constructor(private vendorService: VendorService, private voucherService: VoucherService) {
    this.portabilityTypeList = ['Not Applicable', 'Port In', 'Port Out'];
    this.filteredHouseholdMembers$ = this.searchSubject.pipe(
      startWith(null),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((searchTerm) => 
        this.getHouseholdMembers(searchTerm).pipe(
          map((result) => this._filterHouseholdMembers(result)),
          catchError((error) => {
            console.error('Error fetching household members:', error);
            return of([]);
          })
        )
      )
    );
    
   }

  ngOnInit(): void {
    this.getAccts();
    this.getSubledgers(this.vendorRecurringTransactionInput.subledgerMappingId);
    this.onHouseholdSelected(this.vendorRecurringTransactionInput.householdId);
    this.getCurrentUsersLogInCookie();
    this.minStartOnDate = this.vendorRecurringTransactionInput.startOn ? this.vendorRecurringTransactionInput.startOn: new Date();
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }

  public sumTotalAmount() {
    this.vendorRecurringTransactionInput.totalAmount = 
      (this.vendorRecurringTransactionInput.hapAmount ?? 0) 
      + (this.vendorRecurringTransactionInput.urpAmount ?? 0)
      + (this.vendorRecurringTransactionInput.adminAmount ?? 0)
      + (this.vendorRecurringTransactionInput.hardToHouseAmount ?? 0);
  }

  public resetAmount() {
    if (this.vendorRecurringTransactionInput.portReimbursements != 'Not Applicable') {
      this.vendorRecurringTransactionInput.totalAmount = 0;
    }
    else {
      this.vendorRecurringTransactionInput.totalAmount = 0;
      this.vendorRecurringTransactionInput.contractRent = null;
      this.vendorRecurringTransactionInput.tenantPortion = null;
      this.vendorRecurringTransactionInput.hapAmount = null; 
      this.vendorRecurringTransactionInput.urpAmount = null;
      this.vendorRecurringTransactionInput.adminAmount = null;
      this.vendorRecurringTransactionInput.hardToHouseAmount = null;
      this.vendorRecurringTransactionInput.fssEscrowAmount = null;
    }
  }

  private getAccts() {
    this.filteredAccounts$ = this.accountFilter.valueChanges.pipe(
      startWith(null),
      map(value => this._filterAccounts(value))
    );
  }

  private _filterAccounts(filterValue?: string): any {
    return filterValue
      ? this.vendorLedgerReport.filter(account => account.node.displayName.toLowerCase().includes(filterValue.toLowerCase()))
      : this.vendorLedgerReport;
  }

  private getSubledgers(subLedgerMappingId) {
    if (subLedgerMappingId) {
      this.vendorRecurringTransactionInput.subledgerMappingId = subLedgerMappingId;
      this.vendorService.getSubledgerChargeTypes(sessionStorage.SITEID, subLedgerMappingId).subscribe(
        response => {
          this.chargeGroupList = response[0].data.subledgerMappings.nodes[0].subledgerChargeGroups;
          this.chargeTypes = [];
          this.vendorRecurringTransactionInput.subledgerMappingName = response[0].data.subledgerMappings.nodes[0].name;
          for (const group of this.chargeGroupList) {
            for (const type of group.chargeGroup.chargeTypes) {
              this.chargeTypes.push(type);
            }
          }
          this.subledgers$ = this.subledgerMapFilter.valueChanges.pipe(
            startWith(null),
            map(mapName => this._filterMaps(mapName))
          );
        },
        error => {
          console.log(`Error fetching subledger charge groups`);
        }
      );
    }
  }

  private _filterMaps(filterValue?: string): ChargeType[] {
    return this.chargeTypes;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  onHouseholdSelected(householdId: string): void {
    this.vendorRecurringTransactionInput.voucherNumber = null;
    this.vendorRecurringTransactionInput.voucherSpecialType = null;
    if (householdId) {
      this.voucherService.getVoucherByHouseHoldId(householdId).subscribe(
        async (result: any) => {
          const query: Query = await result?.data;
          const edges = query.vouchers.edges;
          for (const voucherEdge of edges) {
            if (voucherEdge.node.state === 'LEASED') {
              this.vendorRecurringTransactionInput.voucherNumber = voucherEdge.node.voucherNumber;
              this.vendorRecurringTransactionInput.voucherSpecialType = voucherEdge.node.specialType && voucherEdge.node.specialType == 'NONE' ? 'Not Applicable' : voucherEdge.node.specialType;
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  onSearchInput(event: Event): void {
    const input = (event.target as HTMLInputElement).value;
    if (input != '') this.searchSubject.next(input);
  }

  getHouseholdMembers(searchTerm: string): Observable<any> {
    if (this.vendorRecurringTransactionInput.householdId) {
      return this.voucherService.getHeadHouseholdById(sessionStorage.SITEID, this.vendorRecurringTransactionInput.householdId);
    }

    if (searchTerm) {
      return this.voucherService.getHeadHouseholdMembers(sessionStorage.SITEID, searchTerm);
    }
    return of([]); // return empty observable to allow search observable to continue
  }

  private _filterHouseholdMembers(filterValue: any): any {
    if (!filterValue || !filterValue.householdMembers) return [];
    const members = filterValue.householdMembers.edges.map((member: any) => member.node);
    return _.orderBy(members, ["firstName", "middleName", "lastName", "suffix"], ["asc", "asc", "asc", "asc"]);
  }
  

  setChargeTypeName(chargeTypeName) {
    this.vendorRecurringTransactionInput.chargeTypeName = chargeTypeName;
  }

  saveVendorRecurringTransaction() {
    var existingTransaction = this.vendorRecurringTransactionInput.id != '';
    if (existingTransaction) {
      var shouldArchive = this.vendorRecurringTransactionInput.completedOn && new Date(this.vendorRecurringTransactionInput.completedOn) < new Date();
      const UpdateVendorRecurringTransactionCommand: UpdateVendorRecurringTransactionCommandInterface = {
        customerId: sessionStorage.CUSTOMERID,
        siteId: sessionStorage.SITEID,
        id: this.vendorRecurringTransactionInput.id,
        name: this.vendorRecurringTransactionInput.name,
        startOn: this.vendorRecurringTransactionInput.startOn,
        completedOn: this.vendorRecurringTransactionInput.completedOn,
        vendorAccountId: this.vendorRecurringTransactionInput.vendorAccountId,
        subledgerMappingId: this.vendorRecurringTransactionInput.subledgerMappingId,
        subledgerMappingName: this.vendorRecurringTransactionInput.subledgerMappingName,
        chargeTypeId: this.vendorRecurringTransactionInput.chargeTypeId,
        chargeTypeName: this.vendorRecurringTransactionInput.chargeTypeName,
        description: this.vendorRecurringTransactionInput.description,
        portReimbursements: _.toUpper(this.vendorRecurringTransactionInput.portReimbursements).replace(" ", "_"),
        householdId: this.vendorRecurringTransactionInput.householdId,
        voucherNumber: this.vendorRecurringTransactionInput.voucherNumber,
        voucherSpecialType: this.vendorRecurringTransactionInput.voucherSpecialType,
        totalAmount: this.vendorRecurringTransactionInput.totalAmount,
        contractRent: this.vendorRecurringTransactionInput.contractRent,
        tenantPortion: this.vendorRecurringTransactionInput.tenantPortion,
        hapAmount: this.vendorRecurringTransactionInput.hapAmount,
        urpAmount: this.vendorRecurringTransactionInput.urpAmount,
        adminAmount: this.vendorRecurringTransactionInput.adminAmount,
        hardToHouseAmount: this.vendorRecurringTransactionInput.hardToHouseAmount,
        fssEscrowAmount: this.vendorRecurringTransactionInput.fssEscrowAmount,
        modifiedBy: this.loggedInUser,
        archivedOn: shouldArchive ? this.vendorRecurringTransactionInput.completedOn : null,
        archivedBy: shouldArchive ? this.loggedInUser : null, 
      }
      this.updateFunction(UpdateVendorRecurringTransactionCommand);
    }
    else {
      const CreateVendorRecurringTransactionCommand: CreateVendorRecurringTransactionCommandInterface = {
        customerId: sessionStorage.CUSTOMERID,
        siteId: sessionStorage.SITEID,
        name: this.vendorRecurringTransactionInput.name,
        startOn: this.vendorRecurringTransactionInput.startOn,
        completedOn: this.vendorRecurringTransactionInput.completedOn,
        vendorId: this.vendorRecurringTransactionInput.vendorId,
        vendorAccountId: this.vendorRecurringTransactionInput.vendorAccountId,
        subledgerMappingId: this.vendorRecurringTransactionInput.subledgerMappingId,
        subledgerMappingName: this.vendorRecurringTransactionInput.subledgerMappingName,
        chargeTypeId: this.vendorRecurringTransactionInput.chargeTypeId,
        chargeTypeName: this.vendorRecurringTransactionInput.chargeTypeName,
        description: this.vendorRecurringTransactionInput.description,
        portReimbursements: _.toUpper(this.vendorRecurringTransactionInput.portReimbursements).replace(" ", "_"),
        householdId: this.vendorRecurringTransactionInput.householdId,
        voucherNumber: this.vendorRecurringTransactionInput.voucherNumber,
        voucherSpecialType: this.vendorRecurringTransactionInput.voucherSpecialType,
        totalAmount: this.vendorRecurringTransactionInput.totalAmount,
        contractRent: this.vendorRecurringTransactionInput.contractRent,
        tenantPortion: this.vendorRecurringTransactionInput.tenantPortion,
        hapAmount: this.vendorRecurringTransactionInput.hapAmount,
        urpAmount: this.vendorRecurringTransactionInput.urpAmount,
        adminAmount: this.vendorRecurringTransactionInput.adminAmount,
        hardToHouseAmount: this.vendorRecurringTransactionInput.hardToHouseAmount,
        fssEscrowAmount: this.vendorRecurringTransactionInput.fssEscrowAmount,
        createdBy: this.loggedInUser,
      };
      this.updateFunction(CreateVendorRecurringTransactionCommand);
    }
    this.closeDialog();
  }

  stopVendorRecurringTransaction(){
    const StopVendorRecurringTransactionCommand: UpdateVendorRecurringTransactionCommandInterface = {
      customerId: sessionStorage.CUSTOMERID,
      siteId: sessionStorage.SITEID,
      id: this.vendorRecurringTransactionInput.id,
      archivedBy: this.loggedInUser,
      completedOn: new Date()
    }
    
    this.updateFunction(StopVendorRecurringTransactionCommand);
    this.closeDialog();
  }
}
