import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {GlobalModalChild, GlobalModalParent} from 'src/app/kanso-common/core/service';
import {CustomFile} from '../services/upload-file.object';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FileHandlerService} from '../services/file-handler.service';
import {CoreService} from 'src/app/core/service/core.service';

@Component({
  selector: 'app-uploader-handler',
  templateUrl: './uploader-handler.component.html',
  styleUrls: ['./uploader-handler.component.scss'],
})
export class UploaderHandlerComponent implements OnInit, GlobalModalChild<any> {
  @ViewChild('fileDropRef', {static: false}) fileDropEl!: ElementRef;
  globalModalParent: GlobalModalParent;
  uploadForm: FormGroup;
  public files: Array<CustomFile> = [];
  @Input() householdId = null;
  @Input() userId = null;
  @Input() siteSection = null; // household, unit, inspection, etc.
  @Input() siteSectionId = null;
  @Input() siteSubsection = null; // documents, images, etc
  isBusy = false;
  busyText: string;
  // eslint-disable-next-line
  @Input() updateFunction: Function;
  constructor(private fb: FormBuilder, public fhs: FileHandlerService, public coreService: CoreService) {
    this.uploadForm = this.fb.group({});
  }

  // eslint-disable-next-line
  ngOnInit(): void {}

  fileBrowseHandler(files: any[]): void {
    this.prepareFilesList(files);
  }
  onFileDropped($event: any[]): void {
    this.prepareFilesList($event);
  }

  prepareFilesList(files: Array<File>): void {
    for (const file of files) {
      const customFile = new CustomFile(file);
      this.files.push(customFile);
    }
    this.fileDropEl.nativeElement.value = '';
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getFileExtension(theFile: CustomFile): string {
    return theFile?.file.name.split('.').pop() ?? '';
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  setBusy(message: string) {
    this.busyText = message;
    this.isBusy = true;
  }

  async save() {
    this.setBusy('Loading');
    const fileUploaded = await this.fhs.uploadS3Files(
      this.userId,
      this.householdId,
      this.siteSection,
      this.siteSectionId,
      this.siteSubsection,
      this.files
    );
    this.updateFunction(fileUploaded);
    this.isBusy = false;
    this.globalModalParent.closePopup();
  }

  closeDialog() {
    this.globalModalParent.closePopup();
  }
}
