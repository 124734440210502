/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {IExportedActionsBatch} from './exported-actions-interface';
import {IExportedBatchDetails} from './exported-actions-details-interface';
import {IPicBatchHousehold} from '../pic-batching/household-interface';
import {BaseService} from '../../kanso-common/core/service';

@Injectable({
  providedIn: 'root',
})
export class PICActionsDataService {
  uri: string;
  secret: string;
  siteId: string;
  customerId: string;
  stage: string;
  headers: {
    'content-type': 'application/json';
    'x-api-key': string;
    'x-site-id': string;
    'x-customer-id': string;
  };

  constructor(private http: HttpClient, private baseService: BaseService) {
    this.getSiteContext();
  }

  getSiteContext() {
    this.uri = sessionStorage.getItem('BASE_SERVICE_URI');
    this.secret = sessionStorage.getItem('BASE_SERVICE_API_KEY');
    this.siteId = sessionStorage.getItem('SITEID');
    this.customerId = sessionStorage.getItem('CUSTOMERID');
    this.headers = {
      'content-type': 'application/json',
      'x-api-key': this.secret,
      'x-site-id': this.siteId,
      'x-customer-id': this.customerId,
    };
    this.stage = this.baseService.getStageContext();
  }

  async getPicBatchs() {
    const replace = '{proxy+}';
    return this.http
      .get<IPicBatchHousehold[]>(this.uri.replace(replace, '') + '/forms/api/export', {headers: this.headers})
      .pipe(catchError(this.handleError));
  }

  async getExportedBatchById(batchRequest) {
    const replace = '{proxy+}';

    return this.http.post(this.uri.replace(replace, '') + '/forms/api/export/historical', batchRequest, {
      responseType: 'blob',
      headers: this.headers,
    });
  }

  async postPicBatchs(picBatchHouseholdIds) {
    const replace = '{proxy+}';
    return this.http
      .post(this.uri.replace(replace, '') + '/forms/api/export', picBatchHouseholdIds, {responseType: 'blob', headers: this.headers})
      .pipe(catchError(this.handleError));
  }

  async getExportedActionsBatchs() {
    const replace = '{proxy+}';

    return this.http.get<IExportedActionsBatch[]>(this.uri.replace(replace, '') + '/forms/api/export/historical', {headers: this.headers});
  }

  async getExportedActionsDetails(batchId): Promise<Observable<IExportedBatchDetails>> {
    const replace = '{proxy+}';

    return this.http
      .get<IExportedBatchDetails>(this.uri.replace(replace, '') + '/forms/api/export/' + batchId, {headers: this.headers})
      .pipe(catchError(returnedError => this.handleError(returnedError)));
  }

  private handleError(err: HttpErrorResponse) {
    // TODO:
    // -Send server some remote logging infrastructure
    // -Implement error display for user
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // Client-side or network error occured
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response
      errorMessage = `Server returned code: ${err.status}, error message is ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
