import {Injectable} from '@angular/core';
import {TracsAssetType, TracsIncomeDescriptionToType, TracsRecordOutputOrder, TracsRecordTypeEnum} from './tracs-models';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TracsService {
  // eslint-disable-next-line
  constructor() {}

  gatherTracsDetails(service, household, request, financialCategoryData) {
    let members = household.members;
    members = this.tracsAddFamilyNumber(household.members);
    members = this.tracsInjectFinancialCategories(members, financialCategoryData);
    members = this.tracsProcessExpenses(members);
    members = this.tracsSetCareCodes(members);
    const detailRecords = {
      [TracsRecordTypeEnum.HEADER_INFO_STRING]: this.tracsCreateHeaderInfoString(request, service),
      [TracsRecordTypeEnum.EXPENSE_DETAIL]: this.tracsCreateExpenseDetailRecords(members),
      [TracsRecordTypeEnum.FAMILY_DETAIL]: this.tracsCreateFamilyDetailRecords(members),
      [TracsRecordTypeEnum.ASSET_DETAIL]: this.tracsCreateAssetDetailRecords(members),
      [TracsRecordTypeEnum.INCOME_DETAIL]: this.tracsCreateIncomeDetailRecords(members),
    };
    return detailRecords;
  }

  tracsSubmissionPrep(tracsDetailRecords) {
    const tracsSubmissionPsvString = this.processTracsDetailRecords(tracsDetailRecords);
    return tracsSubmissionPsvString;
  }

  private tracsCreateHeaderInfoString(request, service) {
    const action = this.tracsMapHeaderInfoActionType(service);
    let actionDate = '';
    switch (action) {
      case 'IR':
      case 'AR':
      case 'MO':
        actionDate = request.effectiveDate;
        break;
      default:
        actionDate = request.moveInDate;
        break;
    }
    return [
      {
        recordType: TracsRecordTypeEnum.HEADER_INFO_STRING,
        projectCode: request.projectId,
        unitId: service.currentUnit.unitNumber,
        // eslint-disable-next-line
        effectiveDate: moment(actionDate).format('MM/DD/YYYY'),
        hc1: '',
        previousUnitId: '',
        action: this.tracsMapHeaderInfoActionType(service),
        correctionType: '',
        mailbox: this.tracsGetMailbox(request),
      },
    ];
  }

  private processTracsDetailRecords(tracsDetailRecords) {
    const output = [];
    // eslint-disable-next-line
    const ordering = TracsRecordOutputOrder;

    for (const [recordType, data] of Object.entries(tracsDetailRecords)) {
      const order = ordering[recordType];
      if (!order) {
        console.warn(`No output order defined for record type: ${recordType}`);
        continue;
      }

      const processItem = item => {
        const values = order.map(key => item[key] ?? '');
        output.push(values.join('|'));
      };

      if (Array.isArray(data)) {
        data.forEach(processItem);
      } else {
        processItem(data);
      }
    }

    return output.join('\n');
  }

  private tracsGetMailbox(request) {
    return request.projectMailboxNumber ? request.projectMailboxNumber : 'MIST';
  }

  private tracsAddFamilyNumber(members) {
    const sortedMembers = [
      ...members.filter(member => member.relation.hudCode === 'H'),
      ...members.filter(member => member.relation.hudCode !== 'H'),
    ];

    return sortedMembers.map((item, index) => ({
      ...item,
      familyNumber: (index + 1).toString().padStart(2, '0'),
    }));
  }

  private tracsInjectFinancialCategories(members, financialCategoryData) {
    return members.map(member => {
      if (member.incomes) {
        member.incomes = member.incomes.map(income => ({
          ...income,
          category: financialCategoryData.incomeCategories[income.category] || income.category,
        }));
      }
      if (member.expenses) {
        member.expenses = member.expenses.map(expense => ({
          ...expense,
          category: financialCategoryData.expenseCategories[expense.category] || expense.category,
        }));
      }
      if (member.assets) {
        member.assets = member.assets.map(asset => ({
          ...asset,
          category: financialCategoryData.assetCategories[asset.category] || asset.category,
        }));
      }
      return member;
    });
  }

  private tracsGetAssetType(asset) {
    return TracsAssetType[asset.category.name] || 'Other';
  }

  private tracsCreateExpenseDetailRecords(members) {
    const accExpenses = members.reduce((acc, item) => {
      if (item && typeof item.tracsExpenses === 'object' && item.tracsExpenses !== null) {
        Object.entries(item.tracsExpenses).forEach(([key, value]) => {
          if (typeof value === 'number') {
            if (!(key in acc)) {
              acc[key] = 0;
            }
            acc[key] += value / 100;
          }
        });
      }
      return acc;
    }, {});

    const retval = [
      {
        recordType: TracsRecordTypeEnum.EXPENSE_DETAIL,
        childcareWork: accExpenses.CHILDCAREWORK || 0,
        childcareSchool: accExpenses.CHILDCARESCHOOL || 0,
        disability: accExpenses.DISABILITY || 0,
        medical: accExpenses.MEDICAL || 0,
        securityDeposit: '0',
      },
    ];

    return retval;
  }

  private tracsCreateAssetDetailRecords(members) {
    return members.flatMap(mem => {
      if (mem.assets) {
        return mem.assets.map(item => {
          let formattedDisposalDate = '';
          if (item.disposalDate) {
            // eslint-disable-next-line
            const disposalMoment = moment(item.disposalDate, moment.ISO_8601, true);
            // eslint-disable-next-line
            formattedDisposalDate = disposalMoment.isValid() ? disposalMoment.format('MM/DD/YYYY') : '';
          }
          return {
            recordType: TracsRecordTypeEnum.ASSET_DETAIL,
            id: mem._id,
            hc1: '0',
            familyNumber: mem.familyNumber,
            current: item.imputed ? 'I' : 'C',
            cashValue: (item.cashValue / 100).toFixed(2),
            assetIncome: (item.assetIncome / 100).toFixed(2),
            assetType: this.tracsGetAssetType(item),
            disposalDate: formattedDisposalDate,
            hc2: '0',
            hc3: '0',
          };
        });
      }
      return []; // return empty array if no mem.assets
    });
  }

  private tracsMapHeaderInfoActionType(service) {
    const map = {
      'move in': 'MI',
      'annual recertification': 'AR',
      'interim recertification': 'IR',
      'move out': 'MO',
    };
    return map[service.action] || 'unknown mapping';
  }

  private tracsYearsFromDate(birthDate) {
    if (!birthDate || isNaN(new Date(birthDate).getTime())) {
      return null;
    }

    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  }

  private tracsDetermineCareCode(member) {
    const hasEarnedIncome = member.incomes.some(inc => ['B', 'F', 'M', 'W'].includes(TracsIncomeDescriptionToType[inc.category.name]));

    if (!hasEarnedIncome) {
      return '';
    }

    if (member.tracsExpenses.DISABILITY > 0) {
      return 'H';
    }

    if (member.tracsExpenses.CHILDCAREWORK > 0) {
      return 'C';
    }

    return '';
  }

  private tracsSetCareCodes(members) {
    members.forEach(mem => {
      mem.careCode = this.tracsDetermineCareCode(mem);
    });
    return members;
  }

  private tracsProcessExpenses(members) {
    return members.map(member => {
      if (member.expenses) {
        const tracsExpenses = member.expenses.reduce((acc, expense) => {
          const expenseType = expense.category.expenseType;
          const amount = expense.amount || 0;
          acc[expenseType] = (acc[expenseType] || 0) + amount;
          return acc;
        }, {});
        return {...member, tracsExpenses};
      }
      return member;
    });
  }

  private tracsCreateIncomeDetailRecords(members) {
    return members.flatMap(mem => {
      return mem.incomes.map(item => {
        return {
          recordType: TracsRecordTypeEnum.INCOME_DETAIL,
          hc1: '0',
          hc2: '0',
          familyNumber: mem.familyNumber,
          incomeType: TracsIncomeDescriptionToType[item.category.name],
          careCode: mem.careCode,
          incomeAmount: (item.amount / 100).toFixed(2),
          hc3: 'A',
          netExcludedAmount: ((item.amount - item.excludedAmount) / 100).toFixed(2),
          hc4: '',
          hc5: '0',
          hc6: '0',
          hc7: '0',
          hc8: '0',
        };
      });
    });
  }

  private tracsGetRaceCodes(raceArray) {
    const result = {
      raceIndian: '',
      raceAsian: '',
      racePacific: '',
      raceWhite: '',
      raceBlack: '',
      raceOther: '',
      raceDeclined: '',
    };

    if (raceArray.length === 0) {
      result.raceDeclined = 'Y';
      return result;
    }

    const hudCodeMap = {
      1: 'raceWhite',
      2: 'raceBlack',
      3: 'raceIndian',
      4: 'raceAsian',
      5: 'racePacific',
    };

    raceArray.forEach(item => {
      const key = hudCodeMap[item.hudCode];
      if (key) {
        result[key] = 'Y';
      }
    });

    return result;
  }

  private tracsCreateFamilyDetailRecords(members) {
    return members.map(member => {
      const retval = {
        _id: member._id,
        recordType: TracsRecordTypeEnum.FAMILY_DETAIL,
        hc1: '0',
        hc2: '0',
        familyNumber: member.familyNumber,
        lastName: member.lastName,
        firstName: member.firstName,
        middleName: member.middleName
          ? member.middleName
              .trim()
              .charAt(0)
              .toUpperCase()
          : ' ',
        relation: this.tracsGetRelation(member),
        sex: ['M', 'F'].includes(member.sex) ? member.sex : '',
        // eslint-disable-next-line
        DOB: moment(member.birthDate).format('MM/DD/YYYY'),
        age: this.tracsYearsFromDate(member.birthDate),
        ssn: member.ssn,
        citizenship: member.citizenship?.hudCode || 'EC',
        arn: '',
        statuscds: this.tracsGetStatusCodes(member),
        hc3: '0',
        hc4: '0',
        hc5: '0',
        ethnicity: member.ethnicity?.hudCode ? member.ethnicity.hudCode : '0', // This isn't covered in the Word Doc. The potential values I have for this field on the householdMembers are:
        // hudCode: 0, description: "None Specified"
        // hudCode: 1, description: "Hispanic or Latino"
        // hudCode: 2, description: "Not Hispanic or Latino"
        ...this.tracsGetRaceCodes(member.race),
      };
      return retval;
    });
  }

  private tracsGetRelation(member) {
    if (!member.relation.hudCode || !['H', 'S', 'K', 'D', 'O', 'F', 'L', 'N'].includes(member.relation.hudCode)) {
      return 'O';
    }
    return member.relation.hudCode;
  }

  private tracsGetStatusCodes(member) {
    let retval = '';
    // E
    // (Head of household or spouse or co-head) && age > 62
    if (['H', 'S', 'K'].includes(member.relation.hudCode) && this.tracsYearsFromDate(member.birthdate) >= 62) {
      retval += 'E';
    }

    // S
    // dependant over 18 and full time student
    if (this.tracsYearsFromDate(member.birthdate) >= 18 && member.isFullTimeStudent) {
      retval += 'S';
    }

    // H
    // Handicapped
    if (member.isDisabledOrHandicap) {
      retval += 'H';
    }

    // M
    // Military Veteran
    if (member.isVeteran) {
      retval += 'M';
    }

    return retval;
  }
}
