<mat-card class="knso-card vendor-edit-container">
    <mat-card-header>
        <mat-toolbar>
            <span>{{headerText()}}</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>
    <form #editVendorForm="ngForm" class="modal-content">
        <mat-card-content>
            <div class="mat-card-content-content">
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : generalInfoPanelOpenState}"
                            (click)="generalInfoPanelOpenState = !generalInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        General Information
                    </div>
                </div>
                <mat-expansion-panel [expanded]="generalInfoPanelOpenState" class="hidePanel">
                <div class="section-body">
                    <div class="radioContainer"> Vendor Type *:
                        <mat-radio-group 
                            ngModel
                            [(ngModel)]="editVendor.vendorType"
                            #vendorType="ngModel"
                            name="vendorType"
                            id="vendorType"
                            [disabled] = '!isNew'
                            (change)="checkVendorType(editVendor.vendorType)">
                            <label class="mat-radio-label-content">
                                <mat-radio-button value="LANDLORD">Landlord</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button class="shift" value="UTILITY">Utility</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button class="shift" value="MAINTENANCE">Maintenance</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button class="shift" value="OTHER">Other</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button class="shift" value="HOUSINGAUTHORITY">Housing Authority</mat-radio-button>
                            </label>
                        </mat-radio-group>
                    </div>
                    <div class="radioContainer"> Correspondence Type *:
                        <mat-radio-group  
                            [(ngModel)]="orgCheck"
                            name="orgCheck"
                            id="orgCheck">
                            <label class="mat-radio-label-content">
                                <mat-radio-button value="0" [disabled]="editVendor.vendorType == 'HOUSINGAUTHORITY'">Individual</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button value="1">Organization</mat-radio-button>
                            </label>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="orgCheck == '1'">
                        <mat-form-field appearance="outline">
                            <mat-label>Organization Name:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.companyName"
                                #companyName="ngModel"
                                name="companyName" 
                                id="companyName" 
                                type="text" 
                                aria-label="company name" 
                                maxlength="60"
                                required="orgCheck === '1'">
                            <mat-error *ngIf="companyName.hasError('required')">
                                <div ng-message="required">{{getErrorMessage(companyName,'companyName')}}</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="orgCheck == '0'">
                        <mat-form-field  appearance="outline">
                            <mat-label>First Name:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.firstName"
                                #firstName="ngModel"
                                name="firstName"
                                id="firstName"
                                type="text"
                                aria-label="First Name" 
                                maxlength="30"
                                required="orgCheck === '0'">
                            <mat-error *ngIf="firstName.hasError('required')">
                                <div ng-message="required">{{getErrorMessage(firstName,'firstName')}}</div>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field  appearance="outline">
                            <mat-label>Last Name:</mat-label>
                            <input
                                matInput 
                                ngModel 
                                [(ngModel)]="editVendor.lastName"
                                #lastName="ngModel"
                                name="lastName"
                                id="lastName"
                                type="text"
                                aria-label="Last Name"
                                maxlength="30"
                                required="orgCheck === '0'">
                            <mat-error *ngIf="lastName.hasError('required')">
                                <div ng-message="required">{{getErrorMessage(lastName,'lastName')}}</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="payTo" appearance="outline">
                            <mat-label>Pay To Name:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.payTo"
                                #payTo="ngModel"
                                name="payTo"
                                id="payTo"
                                type="text"
                                aria-label="Pay To Name"
                                required
                                >
                            <mat-error *ngIf="payTo.hasError('required')">
                                <div ng-message="required">{{getErrorMessage(payTo,'payTo')}}.</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="email" appearance="outline">
                            <mat-label>Email:</mat-label>
                                <input 
                                    matInput
                                    ngModel
                                    [(ngModel)]="editVendor.emailAddress"
                                    #emailAddress="ngModel" 
                                    name="emailAddress" 
                                    id="emailAddress"
                                    type="email" 
                                    aria-label="Email" 
                                    trim="false"
                                    pattern="^[^\s@'&quot;]+[^\s@&quot;]+@[-a-zA-Z0-9]+\.[a-zA-Z0-9.]{2,}$">
                            <mat-error *ngIf="emailAddress.hasError('pattern')">
                                <p ng-message="pattern">{{getErrorMessage(emailAddress,'emailAddress')}}.</p>
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="phone" appearance="outline">
                            <mat-label>Phone Number: {{editVendor.phoneNumber}}</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.phoneNumber"
                                #phoneNumber="ngModel"
                                name="phoneNumber"
                                id="phoneNumber"
                                type="tel"
                                aria-label="Phone Number"
                                minlength="10"
                                maxlength="10"
                                pattern="^[0-9]{0,10}$"
                                trim="false" 
                                required
                                >
                            <mat-error *ngIf="phoneNumber.hasError('required')">
                                <div ng-message="required">{{getErrorMessage(phoneNumber,'phoneNumber')}}.</div>
                            </mat-error>
                            <mat-error *ngIf="phoneNumber.hasError('minlength')">
                                <div ng-message="minlength">{{getErrorMessage(phoneNumber,'phoneNumber')}}.</div>
                            </mat-error>
                            <mat-error *ngIf="phoneNumber.hasError('pattern')">
                                <div ng-message="pattern">{{getErrorMessage(phoneNumber,'phoneNumber')}}.</div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="phaCode" appearance="outline" *ngIf="editVendor.vendorType == 'HOUSINGAUTHORITY'">
                            <mat-label>PHA Code:</mat-label>
                                <input 
                                    matInput
                                    ngModel
                                    [(ngModel)]="editVendor.phaCode"
                                    #phaCode="ngModel" 
                                    name="phaCode" 
                                    id="phaCode"
                                    type="text" 
                                    aria-label="PHA Code" 
                                    trim="false"
                                    pattern="[a-zA-Z]{2}[0-9]{3}"
                                    required="editVendor.vendorType == 'HOUSINGAUTHORITY'"
                                    minlength="5"
                                    maxlength="5">
                                <mat-error *ngIf="phaCode.hasError('required')">
                                    <p ng-message="required">{{getErrorMessage(phaCode,'phaCode')}}.</p>
                                </mat-error>
                                <mat-error *ngIf="phaCode.hasError('minlength')">
                                    <p ng-message="minlength">{{getErrorMessage(phaCode,'phaCode')}}.</p>
                                </mat-error>
                                <mat-error *ngIf="phaCode.hasError('pattern')">
                                    <p ng-message="pattern">{{getErrorMessage(phaCode,'phaCode')}}.</p>
                                </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                </mat-expansion-panel>
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : addressPanelOpenState}"
                            (click)="addressPanelOpenState = !addressPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Address
                    </div>
                </div>
                <mat-expansion-panel [expanded]="addressPanelOpenState" class="hidePanel">
                <div class="section-body">
                    <div>
                        <mat-form-field class="street" appearance="outline">
                            <mat-label>Street:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.addresses[0].streetAddress"
                                #streetAddress="ngModel"
                                name="streetAddress"
                                id="streetAddressInput"
                                type="text"
                                aria-label="Street">
                        </mat-form-field>
                        <mat-form-field class="unit" appearance="outline">
                            <mat-label>Unit:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.addresses[0].unit"
                                #unit="ngModel"
                                name="unit"
                                id="unit"
                                type="text"
                                aria-label="Unit">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="city" appearance="outline">
                            <mat-label>City:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.addresses[0].city"
                                #city="ngModel"
                                name="city"
                                id="city"
                                type="text"
                                aria-label="City">
                        </mat-form-field>
                        <mat-form-field class="state" appearance="outline">
                            <mat-label>State:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.addresses[0].state"
                                #state="ngModel"
                                id="unitStateInput"
                                name="state"
                                aria-label="State" >
                        </mat-form-field>
                        <mat-form-field class="zip" appearance="outline">
                            <mat-label>Zip:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.addresses[0].postalCode"
                                #postalCode="ngModel"
                                id="postalCode"
                                name="postalCode"
                                aria-label="Zip code"
                                maxlength="10">
                        </mat-form-field>
                    </div>
                </div>
                </mat-expansion-panel>

                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : otherPanelOpenState}"
                            (click)="otherPanelOpenState = !otherPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Other Details
                    </div>
                </div>
                <mat-expansion-panel [expanded]="otherPanelOpenState" class="hidePanel">
                <div class="section-body">
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Tax ID:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.taxId"
                                #taxId="ngModel"
                                name="taxId"
                                id="taxId"
                                type="text"
                                aria-label="Tax ID">
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Vendor ID:</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.externalReferenceId"
                                #vendorId="ngModel"
                                name="vendorId"
                                id="vendorId"
                                type="text"
                                aria-label="Vendor ID">
                        </mat-form-field>
                    </div>
                    <div>
                        <div class="radioContainer">Payment Preference:
                            <mat-radio-group
                                ngModel
                                [(ngModel)]="editVendor.paymentPreference"
                                #paymentPreference="ngModel"
                                name="paymentPreference"
                                id="paymentPreference">
                                <label class="mat-radio-label-content">
                                    <mat-radio-button value="ACH">ACH</mat-radio-button>
                                </label>
                                <label class="mat-radio-label-content">
                                    <mat-radio-button value="CHECK">Check</mat-radio-button>
                                </label>
                                <label class="mat-radio-label-content">
                                    <mat-radio-button value="OTHER">Other</mat-radio-button>
                                </label>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div class="layout-row layout-align-start-center rowPaddingLeft">
                        <mat-form-field appearance="outline" class="largeInputBox" *ngIf="editVendor.paymentPreference === 'ACH' && hasPermission">
                            <mat-label>ACH Account Number</mat-label>
                            <input
                                matInput
                                ngModel
                                [(ngModel)]="editVendor.vendorAch.accountNumber" 
                                #accountNumber="ngModel"
                                [value]="maskedAccountNumber"
                                maxlength="17"
                                minlength="6"
                                inputmode="numeric" 
                                [placeholder]="encryptedAccountLoading? '' : 'Enter bank account number'"
                                name="accountNumber"
                                id="accountNumberInput" 
                                aria-label="ACH Account Number"
                                [required]="editVendor.paymentPreference === 'ACH'"
                                [disabled]="!showAccountInfo || encryptedAccountLoading">                               
                                <div *ngIf="showAccountInfo && encryptedAccountLoading " class="layout-row">
                                    <mat-spinner diameter="25" > </mat-spinner>
                                    <div class="rowPaddingLeft"> Loading Account Number...</div>
                                </div>
                                <span>
                                    <span class="hideGlyphRight glyphicon" [ngClass]="{'glyphicon-eye-close': showAccountInfo, 'glyphicon-eye-open': !showAccountInfo}" (click)="toggleAccountVisibility()"></span>
                                </span>
                        </mat-form-field>
                        <mat-form-field  appearance="outline" class="largeInputBox" *ngIf="editVendor.paymentPreference === 'ACH' && hasPermission">
                            <mat-label>ACH Routing Number</mat-label> 
                            <input matInput
                                ngModel
                                [(ngModel)]="editVendor.vendorAch.routingNumber"
                                name="routingNumber"
                                id="routingNumberInput" 
                                aria-label="ACH Routing Number"
                                maxlength="9"
                                minlength="9" 
                                pattern="^\d{9}$"
                                inputmode="numeric" 
                                placeholder="Enter 9-digit routing number"
                                [required]="editVendor.paymentPreference === 'ACH'">
                        </mat-form-field>       
                    </div> 
                    <div class="radioContainer" name="account" *ngIf="editVendor.paymentPreference === 'ACH' && hasPermission">Account:                        
                       
                        <mat-radio-group
                            ngModel
                            [(ngModel)]="editVendor.vendorAch.checkingAcct"
                            #paymentPreference="ngModel"
                            name="checkingAccount"
                            id="checkingAccount">
                        <label class="mat-radio-label-content">
                                <mat-radio-button name="accountOption" [value]="true" checked>Checking</mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button name="accountOption" [value]="false">Savings</mat-radio-button>
                            </label>
                        </mat-radio-group>
                    </div>
                    <div class="radioContainer" name="accountType" *ngIf="editVendor.paymentPreference === 'ACH' && hasPermission">Account Type:
                        <mat-radio-group
                            ngModel
                            [(ngModel)]="editVendor.vendorAch.consumerAcct"
                            #paymentPreference="ngModel"
                            name="consumerAccount"
                            id="consumerAccount">

                            <label class="mat-radio-label-content">
                                <mat-radio-button name="accountTypeOption" [value]="false" checked>Commercial </mat-radio-button>
                            </label>
                            <label class="mat-radio-label-content">
                                <mat-radio-button name="accountTypeOption" [value]="true">Consumer</mat-radio-button>
                            </label>
                        </mat-radio-group>
                    </div>
                </div>
                </mat-expansion-panel>
                <div class="section-header chevronAlign">
                    <a class="account__details-collapse">
                        <mat-icon class="collapse-icon" 
                            [ngClass]="{'collapse-icon--open' : customAttributesInfoPanelOpenState}"
                            (click)="customAttributesInfoPanelOpenState = !customAttributesInfoPanelOpenState">chevron_right
                        </mat-icon>
                    </a>
                    <div class="headerPadding">
                        Custom Attributes
                    </div>
                </div>
                <mat-expansion-panel [expanded]="customAttributesInfoPanelOpenState" class="hidePanel">
                <div class="section-body">
                    <div class= "messageBody">
                        <app-custom-attributes
                        (valuesChanged)="onCustomAttributeHandler($event)" 
                        *ngIf="activeCustomAttributes?.length; else noCustomAttributes"  
                        [entity]="'Landlord'" 
                        [editMode]="true" 
                        [sqlData]="true"
                        [(currentCustomAttributes)]="editVendor.attributeValues"></app-custom-attributes>
                        <ng-template #noCustomAttributes>
                            <p>There are no custom attributes that apply to this Vendor.</p>
                        </ng-template>
                    </div>
                </div>
                </mat-expansion-panel>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button 
                mat-flat-button
                (click)="closeDialog()"
                class="hds_button cancel"            
                id="cancelButton">
                    Cancel
            </button>
            <button 
                mat-flat-button 
                class="hds_button"             
                (click)="saveVendor()"
                [disabled]="!editVendorForm.valid || editVendorForm.pristine || isSaving"
                id="Submit">
                    Save Vendor
            </button>
        </mat-card-actions>        
    </form>
</mat-card>