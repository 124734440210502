<div class="trasc-container">
    <form #tracsForm ngNoForm action="https://tracs3.shofcorp.com/hdsload.aspx" method="post" target="_blank" id="tracsSubmissionForm">
        <input type="hidden" name="hdsData" [value]="tracsSubmissionPsvString">
        <button mat-flat-button class="hds_button" (click)=" tracsSubmit()" id="tracsSubmissionButton">
            Send to TRACS Vendor
        </button>
    </form>
    <app-upload-button
        [fileReturn]="getFilesReturn"
        [householdId]="request.household"
        [userId]="currentUser"
        [siteSection]="'household'"
        [siteSectionId]="household.id"
    ></app-upload-button>
</div>