import {
  CloneUtilityAllowanceCommandInput,
  CreateUtilityAllowanceCommandInput,
  CreateUtilityAllowanceTableCommandInput,
  CreateUtilityAllowanceTableValuesCommandInput,
  DeleteUtilityAllowanceCommandInput,
  DeleteUtilityAllowanceTableValueCommandInput,
  UpdateUtilityAllowanceCommandInput,
  UpdateUtilityAllowanceTableValuesCommandInput,
} from './setup-utility-object';

export interface UtilityAllowance {
  id: string;
  siteId: string;
  customerId: string;
  name: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
}

export interface UtilityAllowancesTable {
  id: string;
  siteId: string;
  customerId: string;
  utilityAllowanceId: string;
  effectiveDate: Date;
  createdOn: Date;
  createdBy: string;
}

export interface UtilityAllowancesTableValue {
  id: string;
  utilityAllowanceTableId: string;
  createdOn?: Date;
  createdBy: string;
  category: string;
  description?: string;
  bedroom0: number;
  bedroom1: number;
  bedroom2: number;
  bedroom3: number;
  bedroom4: number;
  bedroom5: number;
  bedroom6: number;
  bedroom7: number;
  bedroom8: number;
  siteId: string;
  customerId: string;
}

export interface UtilityAllowancesTableOverrideValue extends UtilityAllowancesTableValue {
  selected: boolean;
}

export enum UtilityActionType {
  addUnitType = 0,
  updateUnitType,
  deleteUnitType,
  cloneUnitType,
  addEffectiveDate,
  addUtilityItem,
  editUtilityItem,
  deleteUtilityItem,
}

export enum UtilitySortType {
  category = 0,
  description,
}

export enum SortOrder {
  ascending = 0,
  descending = 1,
}

export enum UtilityActionOnChangeEvent {
  textOnChange = 0,
  numberOnChange,
}

export interface UtilityActionModal {
  header: string;
  actionType: number;
  inputCommandObject?:
    | CreateUtilityAllowanceCommandInput
    | UpdateUtilityAllowanceCommandInput
    | DeleteUtilityAllowanceCommandInput
    | CloneUtilityAllowanceCommandInput
    | CreateUtilityAllowanceTableCommandInput
    | CreateUtilityAllowanceTableValuesCommandInput
    | UpdateUtilityAllowanceTableValuesCommandInput
    | DeleteUtilityAllowanceTableValueCommandInput;
  effectiveDateList?: Date[];
  combinationList?: string[];
  initialObject?: any;
  // eslint-disable-next-line
  updateFunction: Function;
}

export interface UtilityAllowanceObject extends UtilityAllowance {
  id: string;
  siteId: string;
  customerId: string;
  name: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  utilityAllowanceTable: UtilityAllowancesTableObject[];
}

export interface UtilityAllowancesTableObject extends UtilityAllowancesTable {
  expectedDisplay?: string;
  utilityAllowanceTableValues: UtilityAllowancesTableValue[];
}

export interface UtilityAllowanceDto extends UtilityAllowance {
  id: string;
  siteId: string;
  customerId: string;
  name: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  utilityAllowanceTable: UtilityAllowancesTableDto[];
}

export interface UtilityAllowancesTableDto extends UtilityAllowancesTable {
  expectedDisplay?: string;
  utilityAllowanceTableValues: UtilityAllowancesTableOverrideValue[];
}

export interface utilityAllowanceOverrideCommandInput {
  utilityAllowanceTableValuesId: string;
  categoryDescription: string;
}
export interface OverridesCommandListObject {
  createCommands: CreateUtilityAllowanceOverrideCommand[];
  updateCommands: UpdateUtilityAllowanceOverrideCommand[];
  deleteCommands: DeleteUtilityAllowanceOverrideCommand[];
}

export interface UtilityAllowanceOverride {
  id: string;
  siteId: string;
  customerId?: string;
  unitId: string;
  utilityAllowanceTableId: string;
  utilityAllowanceTableValueId?: string;
  category?: string;
  description?: string | null;
  selected: boolean;
  createdOn: Date;
  createdBy: string;
  modifiedOn?: Date | null;
  modifiedBy?: string | null;
}
export interface CreateUtilityAllowanceOverrideCommand {
  unitId: string;
  utilityAllowanceTableId: string;
  utilityAllowanceTableValueId: string;
  selected: boolean;
  createdBy: string;
  siteId: string;
  customerId: string;
}

export interface UpdateUtilityAllowanceOverrideCommand {
  id: string;
  utilityAllowanceTableId: string;
  utilityAllowanceTableValueId: string;
  selected: boolean;
  modifiedBy: string;
  siteId: string;
  customerId: string;
}

export interface DeleteUtilityAllowanceOverrideCommand {
  id: string;
  siteId: string;
  customerId: string;
}
