import {Apollo, gql} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/cache';
import {Observable, Subject, pipe} from 'rxjs';
import {map} from 'rxjs/internal/operators/map';
import {FetchResult} from '@apollo/client/link/core/types';
import {CoreService} from 'src/app/core/service/core.service';
import {Injectable} from '@angular/core';
import {BaseService} from 'src/app/kanso-common/core/service';
import {HttpClient} from '@angular/common/http';
import {
  CreateProgramCommand,
  CreateProgramRentCalcCommand,
  ICreateProgramRentCalcDataResponse,
  IUpdateProgramRentCalcDataResponse,
  ProgramModel,
  ProgramSettings,
  UpdateProgramCommand,
  UpdateProgramRentCalcCommand,
  CreateAccountDefinitionCommand,
  UpdateAccountDefinitionCommand,
  DeleteAccountDefinitionCommand,
  CreateEnhancedRentCommand,
  UpdateEnhancedRentCommand,
  DeleteEnhancedRentCommand,
  ProgramFeeTable,
  CreateProgramFeeTableCommand,
  UpdateProgramFeeTableCommand,
  DeleteProgramFeeTableCommand,
  DeleteActivityRuleCommand,
  UpdateActivityRuleCommand,
  CreateActivityRuleCommand,
  CreateAutoChargeConfigurationValueCommand,
  UpdateAutoChargeConfigurationValueCommand,
  UpdateProgramCustomAttributes,
} from './program.model';
import _, {update} from 'lodash';
import {ComplexSaveResult, CustomAttributesValue, ICustomAttributesValue, CustomAttribute} from 'src/app/core/service/core-models';
import {
  ICreateProgramDataResponse,
  IUpdateProgramDataResponse,
  EnhancedRentTransactions,
  Program,
  IProgram,
  ProgramCreateCommand,
  ProgramUpdateCommand,
  IUnitAttributeCommand,
  IDataResponse,
  IProgramAttributeCommand,
  ICreateProgramAttributeCommand,
  IUpdateProgramAttributeCommand,
} from 'src/app/custom/housing-core/services/housing-models';
import {
  AccountDefinition,
  AccountDefinitionType,
  AccountActivityRule,
  SourceTransactionTypeEnum,
  AdjustmentTransactionTypeEnum,
} from 'src/app/custom/accounting/service/accounting-models';
import {ProgramFeeStatus} from './program.object';

export interface IUpdateProgramAttributeDataResponse {
  updateProgramAttributeValue: IDataResponse;
}
export interface ICreateProgramAttributeDataResponse {
  createProgramAttributeValue: IDataResponse;
}

@Injectable({providedIn: 'root'})
export class ProgramsService extends BaseService {
  apollo: Apollo;
  _http: any;
  header: any;
  loggedInUser: string;
  id: any;
  envDat = {
    siteId: sessionStorage.getItem('SITEID'),
    customerId: sessionStorage.getItem('CUSTOMERID'),
  };
  constructor(public http: HttpClient, private apolloProvider: Apollo, public coreService: CoreService) {
    super(http);
    this.apollo = this.apolloProvider;
    this.loggedInUser = coreService.getCurrentUsersLogInCookie();
    const programHeaders: any = {
      'x-api-key': sessionStorage.getItem('OCCUPANCY_SVC_KEY'),
      'x-site-id': sessionStorage.getItem('SITEID'),
      'x-customer-id': sessionStorage.getItem('CUSTOMERID'),
      // 'x-token': this.header.headers['x-token'],
    };
    this.apollo.create(
      {cache: new InMemoryCache(), uri: sessionStorage.getItem('OCCUPANCY_SVC_GRAPHQL_URI'), headers: {...programHeaders}},
      'programs'
    );
  }

  getProgram<T>(Id: string): Observable<ProgramModel[]> {
    const data = [];
    return this.apollo
      .use('programs')
      .query({
        query: this.queryProgram(),
        variables: {
          Id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.programs.edges) {
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  queryProgram() {
    return gql`
      query Programs($Id: String) {
        programs(where: {id: {eq: $Id}}) {
          edges {
            node {
              id
              customerId
              siteId
              name
              programType
              programCode
              isHousingProgram
              chartOfAccountsId
              housingAuthorityId
              isRecertificationRequired
              recertificationPeriod
              recertificationPeriodUnit
              recertPeriodUnit
              createdOn
              createdBy
              aelValue
              phaCode
              requires58PicForm
              enhancedRentToggle
              programSettings {
                id
                programId
                calcKey
                assistanceCalcKey
                elderlyDeductionAge
                allowableDependentDeduction
                allowableElderlyDisabilityDeduction
                totalTenantPaymentPercentage
                nearElderAge
                observeNahasda30Percent
                minTTP
                incomeLimitAreaId
                taxCreditIncomeLimitAreaId
                rentLimitAreaId
                paymentStandardId
                imputedAssetRate
                monthOfFiscalYearEnd
                fundingSource
                requireHotmaUpdates
                isUtilityAllowanceCalculations
                programFeeTable {
                  id
                  programSettingsId
                  effectiveDate
                  feeTableType
                  feeTableValues {
                    id
                    feeTableId
                    householdSize
                    amount
                  }
                }
              }
            }
          }
        }
      }
    `;
  }

  getPrograms<T>(): Observable<ProgramModel[]> {
    const data = [];
    return this.apollo
      .use('programs')
      .query({
        query: this.queryPrograms(),
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.programs.edges) {
            edge.node._id = edge.node.id;
            edge.node.accountDefinitions = edge.node.accountTemplates;
            edge.node.rentCalc = edge.node.programSettings;
            edge.node.chartOfAccounts = edge.node.subledgerMappingId;
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  queryPrograms() {
    return gql`
      query Programs {
        programs(first: 30) {
          edges {
            node {
              id
              customerId
              siteId
              name
              programType
              programCode
              isHousingProgram
              chartOfAccountsId
              housingAuthorityId
              isRecertificationRequired
              recertificationPeriod
              recertificationPeriodUnit
              recertPeriodUnit
              createdOn
              createdBy
              aelValue
              phaCode
              requires58PicForm
              enhancedRentToggle
              accountTemplates {
                id
                name
                chartOfAccountsName
                subledgerMappingId
                vendorType
                isArAccount
                isPrimary
                toDelete
                activityRules {
                  displayName
                  id
                  sourceAccountId
                  sourceTypeName
                  sourceTransactionSubCategoryId
                  destinationAccountId
                  destinationTypeName
                  adjustmentTransactionChartOfAccountsId
                  adjustmentTransactionSubCategoryId
                  deletedBy
                  deletedOn
                }
              }
              customAttributes {
                id
                textValue
                booleanValue
                dateTimeValue
                entityInstanceId
                entityAttributeId
                siteId
              }
              programSettings {
                id
                programId
                calcKey
                assistanceCalcKey
                elderlyDeductionAge
                allowableDependentDeduction
                allowableElderlyDisabilityDeduction
                totalTenantPaymentPercentage
                nearElderAge
                observeNahasda30Percent
                minTTP
                incomeLimitAreaId
                taxCreditIncomeLimitAreaId
                rentLimitAreaId
                paymentStandardId
                imputedAssetRate
                monthOfFiscalYearEnd
                fundingSource
                requireHotmaUpdates
                isUtilityAllowanceCalculations
                programFeeTable {
                  id
                  programSettingsId
                  effectiveDate
                  feeTableType
                  feeTableValues {
                    id
                    feeTableId
                    householdSize
                    amount
                  }
                }
              }
              autoChargeConfigurationValues {
                id
                type
                categoryId
                categoryName
                subCategoryId
                subCategoryName
                accountDefinitionId
                chartOfAccountsId
                chartOfAccountsName
                siteId
                customerId
                programId
              }
              enhancedRentToggle
              enhancedRents {
                id
                siteId
                customerId
                ledgerType
                accountDefinition
                accountName
                createdDay
                currentMonth
                adjustment
                transactionType
              }
            }
          }
        }
      }
    `;
  }

  getAllAccountDefinitions(id: string): Observable<AccountDefinition[]> {
    const data = [];
    return this.apollo
      .use('programs')
      .query({
        query: this.queryAccountDefinitionByProgram(),
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.accountDefinitions.edges) {
            edge.node.chartOfAccounts = edge.node.subledgerMappingId;
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  queryAccountDefinitionByProgram() {
    return gql`
      query AccountDefinitions($programId: String) {
        accountDefinitions(first: 30, where: {programId: {eq: $programId}}) {
          edges {
            node {
              id
              name
              programId
              repaymentReductionSubCategoryId
              subledgerMappingId
              vendorType
              activityRules {
                id
                accountDefinitionId
                displayName
                sourceAccountId
                destinationAccountId
                createdOn
                createdBy
                modifiedOn
                modifiedBy
              }
              lateFeeSubcategoryId
              assistancePaymentSubCategoryId
              isAutoPostDisabled
              isArAccount
              isPayableAccount
              isPrimary
              isDepositAccount
              isBadDebtAccount
              isRepaymentAccount
              creditAllocationPriority
              adjustmentToEstablishRepaymentBalanceSubCategoryId
              principalChargeSubCategory
              interestChargeSubCategory
              principalReductionSubCategory
              adjustmentToEstablishPrincipalBalanceSubCategory
              equityAccountDefinitions {
                accountDefinitionId
                id
              }
              mepaAccountDefinition
              isPrincipal
              isAssistance
              chartOfAccountsName
              payableAchSubCategory
              payableCheckSubCategory
              payableOtherSubCategory
              utilityAllowanceSubCategory
              portInReimbursementSubCategory
              portOutHapSubCategory
            }
          }
        }
      }
    `;
  }

  getAccountDefinition(id: string): Observable<AccountDefinition[]> {
    const data = [];
    return this.apollo
      .use('programs')
      .query({
        query: this.queryAccountDefinition(),
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((response: any) => {
          for (const edge of response.data.accountDefinitions.edges) {
            edge.node.chartOfAccounts = edge.node.subledgerMappingId;
            data.push(edge.node);
          }
          return data;
        })
      );
  }

  queryAccountDefinition() {
    return gql`
      query AccountDefinitions($id: String) {
        accountDefinitions(where: {id: {eq: $id}}) {
          edges {
            node {
              id
              name
              programId
              repaymentReductionSubCategoryId
              subledgerMappingId
              vendorType
              activityRules {
                id
                accountDefinitionId
                displayName
                sourceAccountId
                destinationAccountId
                createdOn
                createdBy
                modifiedOn
                modifiedBy
              }
              lateFeeSubcategoryId
              assistancePaymentSubCategoryId
              isAutoPostDisabled
              isArAccount
              isPayableAccount
              isPrimary
              isDepositAccount
              isBadDebtAccount
              isRepaymentAccount
              creditAllocationPriority
              adjustmentToEstablishRepaymentBalanceSubCategoryId
              principalChargeSubCategory
              interestChargeSubCategory
              principalReductionSubCategory
              adjustmentToEstablishPrincipalBalanceSubCategory
              equityAccountDefinitions {
                accountDefinitionId
                id
              }
              mepaAccountDefinition
              isPrincipal
              isAssistance
              chartOfAccountsName
              payableAchSubCategory
              payableCheckSubCategory
              payableOtherSubCategory
              utilityAllowanceSubCategory
              portInReimbursementSubCategory
              portOutHapSubCategory
            }
          }
        }
      }
    `;
  }

  createProgram<T>(program: CreateProgramCommand): Observable<FetchResult<T>> {
    const command: CreateProgramCommand = {
      customerId: this.customerId,
      siteId: this.siteId,
      name: program.name,
      programCode: program.programCode,
      phaCode: program.phaCode ? program.phaCode : '',
      enhancedRentToggle: program.enhancedRentToggle ? program.enhancedRentToggle : false,
      requires58PicForm: program.requires58PicForm,
      recertPeriodUnit: program.recertPeriodUnit ? program.recertPeriodUnit : null,
      recertPeriod: Math.floor(Number(program.recertPeriod)),
      recertificationRequired: program.isRecertRequired ? program.isRecertRequired : false,
      isRecertRequired: program.isRecertRequired ? program.isRecertRequired : false,
      isHousingProgram: program.isHousingProgram ? program.isHousingProgram : false,
      createdBy: this.loggedInUser,
      type: program.type ? program.type : '',
      aelValue: program.aelValue,
      housingAuthorityId: program.housingAuthorityId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation CreateProgram($command: CreateProgramCommandInput!) {
          createProgram(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              customerId
              siteId
              name
              programType
              programCode
              isHousingProgram
              chartOfAccountsId
              housingAuthorityId
              isRecertificationRequired
              recertificationPeriod
              recertificationPeriodUnit
              recertPeriodUnit
              createdOn
              createdBy
              aelValue
              phaCode
              requires58PicForm
              enhancedRentToggle
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateProgram<T>(program: UpdateProgramCommand): Observable<FetchResult<T>> {
    const command: UpdateProgramCommand = {
      customerId: program.customerId,
      siteId: program.siteId,
      id: program.id,
      name: program.name,
      programCode: program.programCode,
      phaCode: program.phaCode,
      enhancedRentToggle: program.enhancedRentToggle ? program.enhancedRentToggle : false,
      requires58PicForm: program.requires58PicForm,
      recertPeriodUnit: program.recertPeriodUnit ? program.recertPeriodUnit : null,
      recertPeriod: Math.floor(Number(program.recertificationPeriod)),
      recertificationRequired: program.recertificationRequired ? program.recertificationRequired : false,
      isRecertRequired: program.isRecertRequired ? program.isRecertRequired : false,
      aelValue: program.aelValue,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateProgram($command: UpdateProgramCommandInput!) {
          updateProgram(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              customerId
              siteId
              name
              programType
              programCode
              isHousingProgram
              chartOfAccountsId
              housingAuthorityId
              isRecertificationRequired
              recertificationPeriod
              recertificationPeriodUnit
              recertPeriodUnit
              createdOn
              createdBy
              aelValue
              phaCode
              requires58PicForm
              enhancedRentToggle
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createProgramRentCalc<T>(command: CreateProgramRentCalcCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation CreateProgramRentCalc($command: CreateProgramRentCalcCommandInput!) {
            createProgramRentCalc(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
                programId
                calcKey
                assistanceCalcKey
                elderlyDeductionAge
                allowableDependentDeduction
                totalTenantPaymentPercentage
                nearElderAge
                observeNahasda30Percent
                incomeLimitAreaId
                taxCreditIncomeLimitAreaId
                rentLimitAreaId
                siteId
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateProgramRentCalc<T>(command: UpdateProgramRentCalcCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation updateProgramRentCalc($command: UpdateProgramRentCalcCommandInput!) {
            updateProgramRentCalc(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                id
                programId
                calcKey
                assistanceCalcKey
                elderlyDeductionAge
                allowableDependentDeduction
                totalTenantPaymentPercentage
                nearElderAge
                observeNahasda30Percent
                incomeLimitAreaId
                taxCreditIncomeLimitAreaId
                rentLimitAreaId
                siteId
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createProgramFeeTable<T>(command: CreateProgramFeeTableCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation createProgramFeeTable($command: CreateProgramFeeTableCommandInput!) {
            createProgramFeeTable(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  updateProgramFeeTable<T>(command: UpdateProgramFeeTableCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation updateProgramFeeTable($command: UpdateProgramFeeTableCommandInput!) {
            updateProgramFeeTable(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  deleteProgramFeeTable<T>(command: DeleteProgramFeeTableCommand): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation deleteProgramFeeTable($command: DeleteProgramFeeTableCommandInput!) {
            deleteProgramFeeTable(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }

  createAccountDefinition<T>(programId: string, accountDefinition: AccountDefinition): Observable<FetchResult<T>> {
    const command: CreateAccountDefinitionCommand = {
      customerId: this.customerId,
      siteId: this.siteId,
      createdBy: this.loggedInUser,
      programId: programId,
      name: accountDefinition.name,
      subledgerMappingId: accountDefinition.chartOfAccounts.toString(),
      isAutoPostDisabled: accountDefinition.isAutoPostDisabled,
      isArAccount: accountDefinition.isArAccount,
      isPayableAccount: accountDefinition.isPayableAccount,
      isDepositAccount: accountDefinition.isDepositAccount,
      isBadDebtAccount: accountDefinition.isBadDebtAccount,
      isRepaymentAccount: accountDefinition.isRepaymentAccount,
      isAssistance: accountDefinition.isAssistance,
      isPrincipal: accountDefinition.isPrincipal,
      isPrimary: accountDefinition.isPrimary,
      vendorType: accountDefinition.accountDefinitionType,
      lateFeeSubcategoryId: accountDefinition.lateFeeSubCategory,
      assistancePaymentSubCategoryId: accountDefinition.assistancePaymentSubCategory,
      creditAllocationPriority: accountDefinition.creditAllocationPriority,
      payableAchSubcategory: accountDefinition.payableAchSubCategory,
      payableCheckSubcategory: accountDefinition.payableCheckSubCategory,
      payableOtherSubcategory: accountDefinition.payableOtherSubCategory,
      utilityAllowanceSubcategory: accountDefinition.utilityAllowanceSubCategory,
      portInReimbursementSubcategory: accountDefinition.portInReimbursementSubCategory,
      portOutHapSubcategory: accountDefinition.portOutHapSubCategory
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation CreateAccountDefinition($command: CreateAccountDefinitionCommandInput!) {
          createAccountDefinition(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateAccountDefinition<T>(programId: string, accountDefinition: AccountDefinition): Observable<FetchResult<T>> {
    const command: UpdateAccountDefinitionCommand = {
      id: accountDefinition.id,
      customerId: this.customerId,
      siteId: this.siteId,
      modifiedBy: this.loggedInUser,
      programId: programId,
      name: accountDefinition.name,
      subledgerMappingId: accountDefinition.chartOfAccounts.toString(),
      isAutoPostDisabled: accountDefinition.isAutoPostDisabled,
      isArAccount: accountDefinition.isArAccount,
      isPayableAccount: accountDefinition.isPayableAccount,
      isDepositAccount: accountDefinition.isDepositAccount,
      isBadDebtAccount: accountDefinition.isBadDebtAccount,
      isRepaymentAccount: accountDefinition.isRepaymentAccount,
      isAssistance: accountDefinition.isAssistance,
      isPrincipal: accountDefinition.isPrincipal,
      isPrimary: accountDefinition.isPrimary,
      vendorType: accountDefinition.accountDefinitionType,
      lateFeeSubcategoryId: accountDefinition.lateFeeSubCategory,
      assistancePaymentSubCategoryId: accountDefinition.assistancePaymentSubCategory,
      creditAllocationPriority: accountDefinition.creditAllocationPriority,
      payableAchSubcategory: accountDefinition.payableAchSubCategory,
      payableCheckSubcategory: accountDefinition.payableCheckSubCategory,
      payableOtherSubcategory: accountDefinition.payableOtherSubCategory,
      utilityAllowanceSubcategory: accountDefinition.utilityAllowanceSubCategory,
      portInReimbursementSubcategory: accountDefinition.portInReimbursementSubCategory,
      portOutHapSubcategory: accountDefinition.portOutHapSubCategory
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateAccountDefinition($command: UpdateAccountDefinitionCommandInput!) {
          updateAccountDefinition(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  deleteAccountDefinition<T>(accountDefinition: AccountDefinition): Observable<FetchResult<T>> {
    const command: DeleteAccountDefinitionCommand = {
      id: accountDefinition.id,
      customerId: this.customerId,
      siteId: this.siteId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation DeleteAccountDefinition($command: DeleteAccountDefinitionCommandInput!) {
          deleteAccountDefinition(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createActivityRule<T>(accountDefinitionId: string, activityRule: AccountActivityRule): Observable<FetchResult<T>> {
    const command: CreateActivityRuleCommand = {
      accountDefinitionId: accountDefinitionId,
      displayName: activityRule.displayName,
      sourceAccountId: activityRule.sourceAccount,
      sourceTransactionType: activityRule.sourceTransactionType.toString(),
      sourceTransactionChartOfAccountsId: activityRule.sourceTransactionChartOfAccounts,
      sourceTransactionSubCategoryId: activityRule.sourceTransactionSubCategory,
      destinationAccountId: activityRule.destinationAccount,
      adjustmentTransactionType: activityRule.adjustmentTransactionType.toString(),
      adjustmentTransactionChartOfAccountsId: activityRule.adjustmentTransactionChartOfAccounts,
      adjustmentTransactionSubCategoryId: activityRule.adjustmentTransactionSubCategory,
      createdBy: this.loggedInUser,
      customerId: this.customerId,
      siteId: this.siteId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation CreateActivityRule($command: CreateActivityRuleCommandInput!) {
          createActivityRule(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateActivityRule<T>(activityRule: AccountActivityRule): Observable<FetchResult<T>> {
    const command: UpdateActivityRuleCommand = {
      id: activityRule.id,
      displayName: activityRule.displayName,
      sourceAccountId: activityRule.sourceAccount,
      sourceTransactionType: activityRule.sourceTransactionType.toString(),
      sourceTransactionChartOfAccountsId: activityRule.sourceTransactionChartOfAccounts,
      sourceTransactionSubCategoryId: activityRule.sourceTransactionSubCategory,
      destinationAccountId: activityRule.destinationAccount,
      adjustmentTransactionType: activityRule.adjustmentTransactionType.toString(),
      adjustmentTransactionChartOfAccountsId: activityRule.adjustmentTransactionChartOfAccounts,
      adjustmentTransactionSubCategoryId: activityRule.adjustmentTransactionSubCategory,
      modifiedBy: this.loggedInUser,
      modifiedOn: new Date(),
      customerId: this.customerId,
      siteId: this.siteId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateActivityRule($command: UpdateActivityRuleCommandInput!) {
          updateActivityRule(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  deleteActivityRule<T>(activityRule: AccountActivityRule): Observable<FetchResult<T>> {
    const command: DeleteActivityRuleCommand = {
      id: activityRule.id,
      customerId: this.customerId,
      siteId: this.siteId,
      deletedOn: new Date(),
      deletedBy: this.loggedInUser,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation DeleteActivityRule($command: DeleteActivityRuleCommandInput!) {
          deleteActivityRule(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createEnhancedRent<T>(programId: string, enhancedRent: EnhancedRentTransactions): Observable<FetchResult<T>> {
    const command: CreateEnhancedRentCommand = {
      customerId: this.customerId,
      siteId: this.siteId,
      programId: programId,
      ledgerType: enhancedRent.ledgerType.toString(),
      accountDefinition: enhancedRent.accountDefinition,
      accountName: enhancedRent.accountName,
      createdDay: enhancedRent.createdDay.toString(),
      currentMonth: enhancedRent.currentMonth,
      adjustment: enhancedRent.adjustment,
      transactionType: enhancedRent.transactionType._id,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation CreateEnhancedRent($command: CreateEnhancedRentCommandInput!) {
          createEnhancedRent(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  updateEnhancedRent<T>(programId: string, enhancedRent: EnhancedRentTransactions): Observable<FetchResult<T>> {
    const command: UpdateEnhancedRentCommand = {
      id: enhancedRent.id,
      customerId: this.customerId,
      siteId: this.siteId,
      programId: programId,
      ledgerType: enhancedRent.ledgerType.toString(),
      accountDefinition: enhancedRent.accountDefinition,
      accountName: enhancedRent.accountName,
      createdDay: enhancedRent.createdDay.toString(),
      currentMonth: enhancedRent.currentMonth,
      adjustment: enhancedRent.adjustment,
      transactionType: enhancedRent.transactionType._id,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateEnhancedRent($command: EnhancedRentCommandInput!) {
          updateEnhancedRent(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  deleteEnhancedRent<T>(programId: string, enhancedRent: EnhancedRentTransactions): Observable<FetchResult<T>> {
    const command: DeleteEnhancedRentCommand = {
      id: enhancedRent.id,
      customerId: this.customerId,
      siteId: this.siteId,
      programId: programId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation DeleteEnhancedRent($command: DeleteEnhancedRentCommandInput!) {
          deleteEnhancedRent(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createAutoChargeConfigurationValue<T>(program: CreateAutoChargeConfigurationValueCommand): Observable<FetchResult<T>> {
    const command: CreateAutoChargeConfigurationValueCommand = {
      type: program.type,
      programId: program.programId,
      categoryId: program.categoryId,
      categoryName: program.categoryName,
      subCategoryId: program.subCategoryId,
      subCategoryName: program.subCategoryName,
      accountDefinitionId: program.accountDefinitionId,
      chartOfAccountsId: program.chartOfAccountsId,
      chartOfAccountsName: program.chartOfAccountsName,
      siteId: this.siteId,
      customerId: this.customerId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation CreateAutoChargeConfigurationValues($command: CreateAutoChargeConfigurationValuesCommandInput!) {
          createAutoChargeConfigurationValues(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              type
              categoryId
              categoryName
              subCategoryId
              subCategoryName
              accountDefinitionId
              chartOfAccountsId
              chartOfAccountsName
              customerId
              siteId
              programId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  updateAutoChargeConfigurationValue<T>(program: UpdateAutoChargeConfigurationValueCommand): Observable<FetchResult<T>> {
    const command: UpdateAutoChargeConfigurationValueCommand = {
      id: program.id,
      type: program.type,
      programId: program.programId,
      categoryId: program.categoryId,
      categoryName: program.categoryName,
      subCategoryId: program.subCategoryId,
      subCategoryName: program.subCategoryName,
      accountDefinitionId: program.accountDefinitionId,
      chartOfAccountsId: program.chartOfAccountsId,
      chartOfAccountsName: program.chartOfAccountsName,
      siteId: this.siteId,
      customerId: this.customerId,
    };
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateAutoChargeConfigurationValues($command: UpdateAutoChargeConfigurationValuesCommandInput!) {
          updateAutoChargeConfigurationValues(command: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              type
              categoryId
              categoryName
              subCategoryId
              subCategoryName
              accountDefinitionId
              chartOfAccountsId
              chartOfAccountsName
              siteId
              customerId
              programId
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }

  createProgramCustomAttribute<T>(command: ICustomAttributesValue): Observable<FetchResult<T>> {
    try {
      return this.apollo.use('programs').mutate({
        mutation: gql`
          mutation CreateProgramAttributeValue($command: CreateProgramAttributeValueCommandInput!) {
            createProgramAttributeValue(command: $command) {
              commandName
              status
              issuedOn
              acceptedOn
              succeededOn
              failedOn
              failureReason
              affectedEntity {
                textValue
                booleanValue
                dateTimeValue
                entityInstanceId
                entityAttributeId
                siteId
              }
            }
          }
        `,
        variables: {
          command,
        },
      });
    } catch (error) {
      this.coreService.displayError(error);
      console.log(error);
    }
  }
  updateProgramCustomAttribute<T>(command: UpdateProgramCustomAttributes): Observable<FetchResult<T>> {
    return this.apollo.use('programs').mutate({
      mutation: gql`
        mutation UpdateProgramAttributeValue($command: UpdateProgramAttributeValueCommandInput!) {
          updateProgramAttributeValue(valueCommand: $command) {
            commandName
            status
            issuedOn
            acceptedOn
            succeededOn
            failedOn
            failureReason
            affectedEntity {
              id
              textValue
              booleanValue
              dateTimeValue
            }
          }
        }
      `,
      variables: {
        command,
      },
    });
  }
  catch(error) {
    this.coreService.displayError(error);
    console.log(error);
  }

  async saveProgram(programToSave: ProgramModel, programExisting?: ProgramModel): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: programExisting ?? null,
    };
    let attributeResponse: ComplexSaveResult = _.clone(response);
    try {
      if (programExisting && programExisting.id) {
        const programDirty = !this.coreService.areEqual(programToSave, programExisting);
        if (programDirty) {
          const command = this.buildUpdateProgramMutation(programToSave, programExisting);
          const result = (await this.updateProgram(command).toPromise()) as {data: IUpdateProgramDataResponse};
          if (result.data.updateProgram.status !== 'SUCCESS') {
            response.success = false;
            response.errorMessage = `${result.data.updateProgram.failureReason || 'Unknown error'}`;
            return response;
          } else {
            response.affectedEntity = result.data.updateProgram.affectedEntity;
          }
        }
        if (programToSave.programSettings) {
          await this.saveProgramRentCalc(programToSave.programSettings, programExisting.programSettings, programExisting.id);
        }

        for (const accountDefinition of programToSave.accountDefinitions) {
          const existingAccountDef = programExisting.accountDefinitions.find(ad => ad.id == accountDefinition.id);
          if (accountDefinition.toDelete) {
            const result = (await this.deleteAccountDefinition(accountDefinition).toPromise()) as {data};
            if (result.data.deleteAccountDefinition.status !== 'SUCCESS') {
              response.success = false;
              response.errorMessage = `${result.data.deleteAccountDefinition.failureReason || 'Unknown error'}`;
              return response;
            }
            if (accountDefinition.activityRules.length > 0) {
              for (const activityRule of accountDefinition.activityRules) {
                await this.deleteActivityRule(activityRule).toPromise();
              }
            }
          } else {
            if (existingAccountDef) {
              const dirtyAccountDefinition = !this.coreService.areEqual(accountDefinition, existingAccountDef);
              if (dirtyAccountDefinition) {
                const result = (await this.updateAccountDefinition(programToSave.id, accountDefinition).toPromise()) as {data};
                if (result.data.updateAccountDefinition.status !== 'SUCCESS') {
                  response.success = false;
                  response.errorMessage = `${result.data.updateAccountDefinition.failureReason || 'Unknown error'}`;
                  return response;
                }
              }
              if (accountDefinition.activityRules.length > 0) {
                await this.saveOrUpdateActivityRules(accountDefinition, existingAccountDef);
              }
            } else {
              const result = (await this.createAccountDefinition(programToSave.id, accountDefinition).toPromise()) as {data};
              if (result.data.createAccountDefinition.status !== 'SUCCESS') {
                response.success = false;
                response.errorMessage = `${result.data.createAccountDefinition.failureReason || 'Unknown error'}`;
                return response;
              }
              if (accountDefinition.activityRules.length > 0) {
                await this.saveOrUpdateActivityRules(accountDefinition, existingAccountDef);
              }
            }
          }
        }
        for (const enhancedRent of programToSave.enhancedRents) {
          const existingEnhancedRent = programExisting.enhancedRents.find(rt => rt.id == enhancedRent.id);
          if (enhancedRent.toDelete) {
            const result = (await this.deleteEnhancedRent(programExisting.id, enhancedRent).toPromise()) as {data};
            if (result.data.deleteEnhancedRent.status !== 'SUCCESS') {
              response.success = false;
              response.errorMessage = `${result.data.deleteEnhancedRent.failureReason || 'Unknown error'}`;
              return response;
            }
          } else {
            if (existingEnhancedRent) {
              const dirtyEnhancedRent = !this.coreService.areEqual(enhancedRent, existingEnhancedRent);
              if (dirtyEnhancedRent) {
                const result = (await this.updateEnhancedRent(programToSave.id, enhancedRent).toPromise()) as {data};
                if (result.data.updateEnhancedRent.status !== 'SUCCESS') {
                  response.success = false;
                  response.errorMessage = `${result.data.updateEnhancedRent.failureReason || 'Unknown error'}`;
                  return response;
                }
              }
            } else {
              const result = (await this.createEnhancedRent(programToSave.id, enhancedRent).toPromise()) as {data};
              if (result.data.createEnhancedRent.status !== 'SUCCESS') {
                response.success = false;
                response.errorMessage = `${result.data.createEnhancedRent.failureReason || 'Unknown error'}`;
                return response;
              }
            }
          }
        }
        for (const autoChargeConfigurationValue of programToSave.autoChargeConfigurationValues) {
          const existingAutoChargeConfig = programExisting.autoChargeConfigurationValues.find(
            coa => coa.id == programExisting.autoChargeConfigurationValues?.[0].id
          );

          if (existingAutoChargeConfig) {
            const dirtyAutoChargeConfig = !this.coreService.areEqual(autoChargeConfigurationValue, existingAutoChargeConfig);
            if (dirtyAutoChargeConfig) {
              const result = (await this.updateAutoChargeConfigurationValue(autoChargeConfigurationValue).toPromise()) as {data};
              if (result.data.updateAutoChargeConfigurationValue.status !== 'SUCCESS') {
                response.success = false;
                response.errorMessage = `${result.data.updateAutoChargeConfigurationValue.failureReason || 'Unknown error'}`;
                return response;
              }
            }
          } else {
            const result = (await this.createAutoChargeConfigurationValue(autoChargeConfigurationValue).toPromise()) as {data};
            if (result.data.createAutoChargeConfigurationValue.status !== 'SUCCESS') {
              response.success = false;
              response.errorMessage = `${result.data.createAutoChargeConfigurationValue.failureReason || 'Unknown error'}`;
              return response;
            }
          }
        }
        programToSave.customAttributes = programToSave.customAttributes.sort((a, b) =>
          a.entityAttributeId.localeCompare(b.entityAttributeId)
        );
        programExisting.customAttributes = programExisting.customAttributes.sort((a, b) =>
          a.entityAttributeId.localeCompare(b.entityAttributeId)
        );
        const attributesDirty = !this.coreService.areEqual(programToSave.customAttributes, programExisting.customAttributes, true);
        if (attributesDirty) {
          attributeResponse = await this.saveCustomAttributes(programToSave, programExisting);
          if (!attributeResponse.success) {
            return attributeResponse;
          }
        }
      } else {
        const command = this.buildCreateProgramMutation(programToSave);
        const result = (await this.createProgram(command).toPromise()) as {data: ICreateProgramDataResponse};
        if (result.data.createProgram.status !== 'SUCCESS') {
          response.success = false;
          response.errorMessage = `${result.data.createProgram.failureReason || 'Unknown error'}`;
          return response;
        } else {
          response.affectedEntity = result.data.createProgram.affectedEntity;
          if (programToSave.programSettings) {
            await this.saveProgramRentCalc(programToSave.programSettings, null, response.affectedEntity.id);
          }
          if (programToSave.accountDefinitions && programToSave.accountDefinitions.length > 0) {
            for (const accountDefinition of programToSave.accountDefinitions) {
              const result = (await this.createAccountDefinition(response.affectedEntity.id, accountDefinition).toPromise()) as {data};
              if (result.data.createAccountDefinition.status !== 'SUCCESS') {
                response.success = false;
                response.errorMessage = `${result.data.createAccountDefinition.failureReason || 'Unknown error'}`;
                return response;
              }
            }
          }
          if (programToSave.enhancedRentToggle && programToSave.enhancedRents.length > 0) {
            for (const enhancedRent of programToSave.enhancedRents) {
              const result = (await this.createEnhancedRent(response.affectedEntity.id, enhancedRent).toPromise()) as {data};
              if (result.data.createEnhancedRent.status !== 'SUCCESS') {
                response.success = false;
                response.errorMessage = `${result.data.createEnhancedRent.failureReason || 'Unknown error'}`;
                return response;
              }
            }
          }
          programToSave.customAttributes = programToSave.customAttributes.sort((a, b) =>
            a.entityAttributeId.localeCompare(b.entityAttributeId)
          );
          programExisting.customAttributes = programExisting.customAttributes.sort((a, b) =>
            a.entityAttributeId.localeCompare(b.entityAttributeId)
          );
          const attributesDirty = !this.coreService.areEqual(programToSave.customAttributes, programExisting.customAttributes, true);
          if (attributesDirty) {
            attributeResponse = await this.saveCustomAttributes(programToSave, programExisting);
            if (!attributeResponse.success) {
              return attributeResponse;
            }
          }
        }
      }
    } catch (error) {
      console.error('Error in saveProgram:', error);
      response.errorMessage = 'Error occurred during save operation';
    }
    return response;
  }

  private async saveOrUpdateActivityRules(accountDefinition: AccountDefinition, existingAccountDef: AccountDefinition) {
    for (const activityRule of accountDefinition.activityRules) {
      const existingAR = existingAccountDef.activityRules.find(x => x.id == activityRule.id);
      if (existingAR) {
        const diryActivityRule = !this.coreService.areEqual(activityRule, existingAR);
        if (diryActivityRule) {
          if (activityRule.deletedOn) {
            await this.deleteActivityRule(activityRule).toPromise();
          } else {
            await this.updateActivityRule(activityRule).toPromise();
          }
        }
      } else {
        await this.createActivityRule(accountDefinition.id, activityRule).toPromise();
      }
    }
  }

  private buildUpdateProgramMutation(programToSave: ProgramModel, programExisting: ProgramModel): UpdateProgramCommand {
    const programToSaveKeys = Object.keys(programToSave);
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: UpdateProgramCommand = {
      customerId: this.customerId,
      siteId: this.siteId,
      id: programToSave.id,
      name: programToSave.name,
      programCode: programToSave.programCode,
      phaCode: programToSave.phaCode,
      enhancedRentToggle: programToSave.enhancedRentToggle ? programToSave.enhancedRentToggle : false,
      requires58PicForm: programToSave.requires58PicForm,
      recertPeriodUnit: programToSave.recertPeriodUnit ? programToSave.recertPeriodUnit : null,
      recertPeriod: Math.floor(Number(programToSave.recertificationPeriod)),
      recertificationRequired: programToSave.isRecertificationRequired ? programToSave.isRecertificationRequired : false,
      isRecertRequired: programToSave.isRecertificationRequired ? programToSave.isRecertificationRequired : false,
    };

    for (const key of programToSaveKeys) {
      const isObject = this.coreService.isObject(programToSave[key]) || this.coreService.isObject(programExisting[key]);
      const isArray = Array.isArray(programToSave[key]);
      if (isArray || isObject) {
        continue;
      }
      if (!Object.prototype.hasOwnProperty.call(programExisting, key) || programToSave[key] !== programExisting[key]) {
        const commandKey = key;
        command[commandKey] = programToSave[key];
      }
    }
    return command;
  }

  private buildCreateProgramMutation(programToSave: ProgramModel): CreateProgramCommand {
    const programToSaveKeys = Object.keys(programToSave);
    const currentuser: string = this.coreService.getCurrentUsersLogInCookie();
    const command: CreateProgramCommand = {
      customerId: this.customerId,
      siteId: this.siteId,
      name: programToSave.name,
      programCode: programToSave.programCode,
      phaCode: programToSave.phaCode ? programToSave.phaCode : '',
      enhancedRentToggle: programToSave.enhancedRentToggle ? programToSave.enhancedRentToggle : false,
      aelValue: programToSave.aelValue,
      requires58PicForm: programToSave.requires58PicForm,
      recertPeriodUnit: programToSave.recertPeriodUnit ? programToSave.recertPeriodUnit : null,
      recertPeriod: Math.floor(Number(programToSave.recertificationPeriod)),
      recertificationRequired: programToSave.isRecertificationRequired ? programToSave.isRecertificationRequired : false,
      isRecertRequired: programToSave.isRecertificationRequired ? programToSave.isRecertificationRequired : false,
      isHousingProgram: programToSave.isHousingProgram ? programToSave.isHousingProgram : false,
      createdBy: this.loggedInUser ? this.loggedInUser : '',
      type: programToSave.programType ? programToSave.programType : '',
      housingAuthorityId: programToSave.housingAuthority,
    };

    for (const key of programToSaveKeys) {
      const isObject = this.coreService.isObject(programToSave[key]);
      const isArray = Array.isArray(programToSave[key]);
      if (programToSave[key] && !isObject && !isArray) {
        command[key] = programToSave[key];
      }
    }
    return command;
  }

  async saveProgramRentCalc(rentCalcToSave: ProgramSettings, rentCalcExisting?: ProgramSettings, programId?: string) {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: rentCalcExisting ?? null,
    };
    try {
      if (rentCalcToSave.imputedAssetRate && typeof rentCalcToSave.imputedAssetRate == 'string') {
        rentCalcToSave.imputedAssetRate = parseFloat(rentCalcToSave.imputedAssetRate);
      }
      if (rentCalcToSave.minTTP && typeof rentCalcToSave.minTTP == 'string') {
        rentCalcToSave.minTTP = parseFloat(rentCalcToSave.minTTP);
      }
      if (rentCalcExisting && rentCalcExisting.id) {
        const rentCalcDirty = !this.coreService.areEqual(rentCalcToSave, rentCalcExisting);
        if (rentCalcDirty) {
          const command = this.buildUpdateProgramSettingsMutation(rentCalcToSave);
          const result = (await this.updateProgramRentCalc(command).toPromise()) as {data: IUpdateProgramRentCalcDataResponse};
          if (result.data.updateProgramRentCalc.status !== 'SUCCESS') {
            response.success = false;
            response.errorMessage = `${result.data.updateProgramRentCalc.failureReason || 'Unknown error'}`;
            return response;
          } else {
            response.affectedEntity = result.data.updateProgramRentCalc.affectedEntity;
          }
        }
        if (rentCalcToSave.programFeeTable.length > 0) {
          await this.saveProgramFeeTableAndValues(
            rentCalcToSave.programFeeTable,
            rentCalcExisting.programFeeTable,
            rentCalcExisting.id,
            rentCalcExisting.programId
          );
        }
      } else {
        const command = this.buildCreateProgramSettingsMutation(rentCalcToSave, programId);
        const result = (await this.createProgramRentCalc(command).toPromise()) as {data: ICreateProgramRentCalcDataResponse};
        if (result.data.createProgramRentCalc.status !== 'SUCCESS') {
          response.success = false;
          response.errorMessage = `${result.data.createProgramRentCalc.failureReason || 'Unknown error'}`;
          return response;
        } else {
          response.affectedEntity = result.data.createProgramRentCalc.affectedEntity;
          if (rentCalcToSave.programFeeTable.length > 0) {
            await this.saveProgramFeeTableAndValues(
              rentCalcToSave.programFeeTable,
              null,
              result.data.createProgramRentCalc.affectedEntity.id,
              result.data.createProgramRentCalc.affectedEntity.programId
            );
          }
        }
      }
    } catch (error) {
      console.error('Error in saveProgramRentCalc:', error);
      response.errorMessage = 'Error occurred during save operation';
    }
  }

  private buildCreateProgramSettingsMutation(programSettingsToSave: ProgramSettings, programId: string) {
    const command: CreateProgramRentCalcCommand = {
      createdBy: this.loggedInUser,
      customerId: this.customerId,
      siteId: this.siteId,
      programId: programId,
      calcKey: programSettingsToSave.calcKey,
      assistanceCalcKey: programSettingsToSave.assistanceCalcKey,
      elderlyDeductionAge: programSettingsToSave.elderlyDeductionAge,
      totalTenantPaymentPercentage: programSettingsToSave.totalTenantPaymentPercentage,
      allowableDependentDeduction: programSettingsToSave.allowableDependentDeduction,
      allowableElderlyDisabilityDeduction: programSettingsToSave.allowableElderlyDisabilityDeduction,
      nearElderAge: programSettingsToSave.nearElderAge,
      observeNahasda30Percent: programSettingsToSave.observeNahasda30Percent,
      minTTP: programSettingsToSave.minTTP,
      incomeLimitAreaId: programSettingsToSave.incomeLimitAreaId,
      taxCreditIncomeLimitAreaId: programSettingsToSave.taxCreditIncomeLimitAreaId,
      rentLimitAreaId: programSettingsToSave.rentLimitAreaId,
      paymentStandardId: programSettingsToSave.paymentStandardId,
      imputedAssetRate: programSettingsToSave.imputedAssetRate,
      monthOfFiscalYearEnd: programSettingsToSave.monthOfFiscalYearEnd,
      fundingSource: programSettingsToSave.fundingSource,
      requireHotmaUpdates: programSettingsToSave.requireHotmaUpdates,
      isUtilityAllowanceCalculations: programSettingsToSave.isUtilityAllowanceCalculations,
    };
    return command;
  }

  private buildUpdateProgramSettingsMutation(programSettingsToSave: ProgramSettings) {
    const command: UpdateProgramRentCalcCommand = {
      id: programSettingsToSave.id,
      modifiedBy: this.loggedInUser,
      customerId: this.customerId,
      siteId: this.siteId,
      calcKey: programSettingsToSave.calcKey,
      assistanceCalcKey: programSettingsToSave.assistanceCalcKey,
      elderlyDeductionAge: programSettingsToSave.elderlyDeductionAge,
      totalTenantPaymentPercentage: programSettingsToSave.totalTenantPaymentPercentage,
      allowableDependentDeduction: programSettingsToSave.allowableDependentDeduction,
      allowableElderlyDisabilityDeduction: programSettingsToSave.allowableElderlyDisabilityDeduction,
      nearElderAge: programSettingsToSave.nearElderAge,
      observeNahasda30Percent: programSettingsToSave.observeNahasda30Percent,
      minTTP: programSettingsToSave.minTTP,
      incomeLimitAreaId: programSettingsToSave.incomeLimitAreaId,
      taxCreditIncomeLimitAreaId: programSettingsToSave.taxCreditIncomeLimitAreaId,
      rentLimitAreaId: programSettingsToSave.rentLimitAreaId,
      paymentStandardId: programSettingsToSave.paymentStandardId,
      imputedAssetRate: programSettingsToSave.imputedAssetRate,
      monthOfFiscalYearEnd: programSettingsToSave.monthOfFiscalYearEnd,
      fundingSource: programSettingsToSave.fundingSource,
      requireHotmaUpdates: programSettingsToSave.requireHotmaUpdates,
      isUtilityAllowanceCalculations: programSettingsToSave.isUtilityAllowanceCalculations,
    };
    return command;
  }

  async saveProgramFeeTableAndValues(
    programFeeTablesToSave: ProgramFeeTable[],
    programFeeTableExisting: ProgramFeeTable[],
    rentcalcId: string,
    programId: string
  ) {
    const createCommandList: CreateProgramFeeTableCommand[] = [];
    const updateCommandList: UpdateProgramFeeTableCommand[] = [];
    const deleteCommandList: DeleteProgramFeeTableCommand[] = [];
    programFeeTablesToSave.forEach((pft, index) => {
      switch (true) {
        case pft.status == ProgramFeeStatus.Create:
        case pft.status == ProgramFeeStatus.CreateAndModified: {
          const createCommand = this.buildCreateProgramFeeTableCommand(pft, rentcalcId, programId);
          createCommandList.push(createCommand);
          break;
        }

        case pft.status == ProgramFeeStatus.Modified: {
          const feeTableDirty = !this.coreService.areEqual(pft, programFeeTableExisting[index], true);
          if (feeTableDirty) {
            const updateCommand = this.buildUpdateProgramFeeTableCommand(pft, programId);
            updateCommandList.push(updateCommand);
          }
          break;
        }

        case pft.status == ProgramFeeStatus.Delete: {
          const deleteCommand = this.buildDeleteProgramFeeTableCommand(pft.id, programId);
          deleteCommandList.push(deleteCommand);
          break;
        }
      }
    });
    try {
      const resultArray = [];
      if (createCommandList.length > 0) {
        for (const command of createCommandList) {
          const result = await this.createProgramFeeTable(command).toPromise();
          resultArray.push(result);
        }
      }

      if (updateCommandList.length > 0) {
        for (const command of updateCommandList) {
          const result = await this.updateProgramFeeTable(command).toPromise();
          resultArray.push(result);
        }
      }

      if (deleteCommandList.length > 0) {
        for (const command of deleteCommandList) {
          const result = await this.deleteProgramFeeTable(command).toPromise();
          resultArray.push(result);
        }
      }
      return resultArray;
    } catch (error) {
      console.error('Error in saveProgramFeeTable:', error);
    }
  }

  private buildCreateProgramFeeTableCommand(programFeeTable: ProgramFeeTable, rentCalcId: string, programId: string) {
    const command: CreateProgramFeeTableCommand = {
      programSettingsId: rentCalcId,
      feeTableType: programFeeTable.feeTableType,
      effectiveDate: programFeeTable.effectiveDate,
      program: programId,
      createdBy: this.loggedInUser,
      feeTableValues: programFeeTable.feeTableValues.map(ftv => {
        if (ftv.amount) {
          return ftv.amount;
        } else {
          return 0;
        }
      }),
      siteId: this.siteId,
      customerId: this.customerId,
    };
    return command;
  }

  private buildUpdateProgramFeeTableCommand(programFeeTable: ProgramFeeTable, programId: string) {
    const command: UpdateProgramFeeTableCommand = {
      id: programFeeTable.id,
      effectiveDate: programFeeTable.effectiveDate,
      program: programId,
      modifiedBy: this.loggedInUser,
      feeTableValues: programFeeTable.feeTableValues.map(ftv => {
        if (ftv.amount) {
          return ftv.amount;
        } else {
          return 0;
        }
      }),
      siteId: this.siteId,
      customerId: this.customerId,
    };
    return command;
  }

  private buildDeleteProgramFeeTableCommand(programFeeTableId: string, programId: string) {
    const command: DeleteProgramFeeTableCommand = {
      id: programFeeTableId,
      program: programId,
      deletedBy: this.loggedInUser,
      siteId: this.siteId,
      customerId: this.customerId,
    };
    return command;
  }

  async saveCustomAttributes(programToSave: ProgramModel, programExisting?: ProgramModel): Promise<ComplexSaveResult> {
    const response: ComplexSaveResult = {
      success: true,
      errorMessage: '',
      affectedEntity: programToSave.customAttributes ?? null,
    };
    for (const attribute of programToSave.customAttributes) {
      const customAttributeValue: ICustomAttributesValue = attribute as CustomAttributesValue;
      const attributeCommand: IProgramAttributeCommand = {
        ...this.envDat,
        textValue: customAttributeValue.textValue,
        booleanValue: customAttributeValue.booleanValue,
        dateTimeValue: customAttributeValue.dateTimeValue,
      };
      switch (true) {
        case !customAttributeValue.id &&
          (customAttributeValue.booleanValue !== null ||
            customAttributeValue.dateTimeValue !== null ||
            customAttributeValue.textValue !== null): {
          //new attribute w/ value
          const createAttributeCommand: ICustomAttributesValue = {
            ...attributeCommand,
            entityAttributeId: customAttributeValue.entityAttributeId,
            entityInstanceId: programToSave.id,
          };
          const result = await this.createProgramCustomAttribute(createAttributeCommand).toPromise();
          if ((result.data as ICreateProgramAttributeDataResponse).createProgramAttributeValue.status !== 'SUCCESS') {
            if (!response.success) {
              response.errorMessage += `${
                (result.data as ICreateProgramAttributeDataResponse).createProgramAttributeValue.failureReason
              }\n`;
            }
          }
          break;
        }
        case !!attribute.id: {
          //existing attribute check if dirty
          const existingAttribute = (programExisting.customAttributes as ICustomAttributesValue[]).find(a => a.id == attribute.id);
          const attributeValueFortest = new CustomAttributesValue(existingAttribute as CustomAttributesValue);
          if (!this.coreService.areEqual(attributeValueFortest, attribute)) {
            // update attribute
            const updateAttributeCommand: UpdateProgramCustomAttributes = {
              textValue: customAttributeValue.textValue,
              booleanValue: customAttributeValue.booleanValue,
              dateTimeValue: customAttributeValue.dateTimeValue,
              id: attribute.id,
              siteId: sessionStorage.getItem('SITEID'),
              customerId: sessionStorage.getItem('CUSTOMERID'),
            };
            const result = await this.updateProgramCustomAttribute(updateAttributeCommand).toPromise();
            if ((result.data as IUpdateProgramAttributeDataResponse).updateProgramAttributeValue.status !== 'SUCCESS') {
              if (!response.success) {
                response.errorMessage += `${
                  (result.data as IUpdateProgramAttributeDataResponse).updateProgramAttributeValue.failureReason
                }\n`;
              }
            }
          }
          break;
        }
      }
    }
    return response;
  }
}
