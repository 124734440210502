import {Component, inject, Injector} from '@angular/core';
import {
  ILandlordAddress,
  IVendor,
  VendorEditModalData,
  VendorModalData,
  Vendor,
  VendorTypes,
} from '../../housing-core/services/housing-models';
import {ICustomAttributesSql, goToPagePayload, pageNext} from 'src/app/core/service/core-models';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {VendorService} from '../../vendor/vendor.service';
import {VendorAttributeCommand, VendorSortField, qqlPageInfo} from '../../vendor/vendor-model';
import {FormControl} from '@angular/forms';
import {CoreService} from 'src/app/core/service/core.service';
import {finalize} from 'rxjs/operators';
import {VendorEditComponent} from '../../vendor/vendor-edit/vendor-edit.component';
import {ComplexSaveResult} from 'src/app/core/service/core-models';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss'],
})
export class VendorsComponent {
  customerId: string;
  siteId: string;
  vendors: Vendor[];
  //pageing properties
  pageSize = new FormControl('25');
  vendorId: string;
  pageInfo: qqlPageInfo;
  totalCount = 0;
  currentRecordsCount = 0;
  lastPageSize = 0;
  //end pageing properties
  loading = true;
  loadError = false;
  loggedInUser: string;
  selectedVendor: Vendor;
  showPage = 'vendors-directory';
  disabledNavButtonClass = 'disabled-icon';
  pagePreviousClass = 'navButton-previous';
  baseClass = 'mat-icon notranslate material-icons mat-icon-no-color';
  busyText: string;
  initialVendorAddress: ILandlordAddress[];
  vendorSort = new FormControl('');
  vendorFilter = new FormControl('');
  vendorTypes = new FormControl('');
  vendorSortField: VendorSortField = VendorSortField.Name;
  vendorTypeField: VendorTypes;
  private CUSTOMER_ID = sessionStorage.getItem('CUSTOMERID') || '';
  private SITE_ID = sessionStorage.getItem('SITEID') || '';

  constructor(
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    private vendorService: VendorService,
    private coreService: CoreService
  ) {
    this.setBusyText('Loading Vendors...');
    this.loggedInUser = this.coreService.getCurrentUsersLogInCookie();
    this.vendorTypes.setValue('All');
    this.vendorSort.setValue('Name');
    this.pageSize.valueChanges.subscribe(value => {
      //page size has changed so start over from beginnning
      this.currentRecordsCount = 0;
      this.refreshVendorList();
    });
    this.vendorFilter.valueChanges.subscribe(value => {
      this.currentRecordsCount = 0;
      if (value == '') {
        this.refreshVendorList();
      }
    });
    this.vendorSort.valueChanges.subscribe(value => {
      this.currentRecordsCount = 0;
      switch (value) {
        case 'Name':
          this.vendorSortField = VendorSortField.Name;
          break;
        case 'Type':
          this.vendorSortField = VendorSortField.Type;
          break;
      }
      this.refreshVendorList();
    });
    this.vendorTypes.valueChanges.subscribe(value => {
      this.currentRecordsCount = 0;
      switch (value) {
        case 'Landlord':
          this.vendorTypeField = VendorTypes.Landlord;
          break;
        case 'Utility':
          this.vendorTypeField = VendorTypes.Utility;
          break;
        case 'Maintenance':
          this.vendorTypeField = VendorTypes.Maintenance;
          break;
        case 'Other':
          this.vendorTypeField = VendorTypes.Other;
          break;
        case 'Housingauthority':
          this.vendorTypeField = VendorTypes.Housingauthority;
          break;
        case 'All':
          this.vendorTypeField = null;
          break;
      }
      this.refreshVendorList();
    });
    this.refreshVendorList();
  }

  fetchVendors(
    cursor,
    direction,
    pageSize: number,
    searchText = '',
    vendorType: VendorTypes = null,
    sortField: VendorSortField = VendorSortField.Name,
    skipCache = false
  ) {
    this.loadError = false;
    const countMultiplier: number = direction != 'forward' ? -1 : 1;
    this.vendorService.getVendors(pageSize, cursor, direction, searchText, vendorType, sortField, skipCache).subscribe(
      result => {
        this.vendors = result.data;
        this.pageInfo = result.pageInfo;
        this.totalCount = result.totalCount;
        const countAdjSize = direction != 'forward' ? this.lastPageSize : result.data.length;
        this.currentRecordsCount = this.currentRecordsCount + countAdjSize * countMultiplier;
        this.lastPageSize = result.data.length;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was error getting Vendor.');
        this.loadError = true;
        this.loading = false;
      }
    );
  }
  //not sure what this or markup that uses is for
  getNavClass(page: string) {
    return page === this.showPage ? 'nav-active' : '';
  }

  goToPageHandler(pagePayload: goToPagePayload) {
    this.fetchVendors(
      pagePayload.cursor,
      pagePayload.direction,
      pagePayload.pageSize,
      this.vendorFilter.value,
      this.vendorTypeField,
      this.vendorSortField
    );
  }

  refreshVendorList() {
    this.fetchVendors('', pageNext, this.pageSize.value, this.vendorFilter.value, this.vendorTypeField, this.vendorSortField, true);
  }

  searchVendors() {
    this.refreshVendorList();
  }

  addVendor() {
    this.selectedVendor = new Vendor();
    this.initialVendorAddress = this.selectedVendor.addresses;
    this.globalModalService.openModal<VendorEditModalData>(
      VendorEditComponent,
      this.injector,
      this.globalModalService.getModalOverlayConfig(),
      {selectedVendor: this.selectedVendor, updateFunction: this.saveNewVendor, isNew: true}
    );
  }

  saveNewVendor = async vendor => {
    try {
      this.customerId = this.CUSTOMER_ID;
      this.siteId = this.SITE_ID;
      this.setBusyText('Creating Vendor...');
      const result: ComplexSaveResult = await this.vendorService.saveVendor(vendor);

      if (!result.success) {
        this.coreService.displayError(result.errorMessage);
      }
    } catch (error) {
      this.coreService.displayError(error);
    } finally {
      this.loading = false;
      this.refreshVendorList();
    }
  };

  //  Note leaving till we circle back on custom attributes. This logiic belongs in vendorservice
  // saveVendorCustomAttributes(attributes: ICustomAttributesSql[], vendorId: string) {
  //   this.customerId = this.CUSTOMER_ID;
  //   this.siteId = this.SITE_ID;
  //   attributes.forEach(attr => {
  //     if (attr.value !== null) {
  //       const vendorAttributeCommand: VendorAttributeCommand = {
  //         customerId: this.customerId,
  //         siteId: this.siteId,
  //         entityAttributeId: attr.entityAttributeId,
  //         entityInstanceId: vendorId,
  //       };
  //       switch (attr.type) {
  //         case 'text':
  //           vendorAttributeCommand.textValue = attr.value;
  //           break;
  //         case 'toggle':
  //           vendorAttributeCommand.booleanValue = attr.value;
  //           break;
  //         case 'date':
  //           vendorAttributeCommand.dateTimeValue = attr.value;
  //           break;
  //         case 'dropdown':
  //           vendorAttributeCommand.textValue = attr.value;
  //           break;
  //         case 'multi':
  //           vendorAttributeCommand.textValue = attr.value.join(', ');
  //           break;
  //       }
  //       this.vendorService.createVendorAttributeValues(vendorAttributeCommand).subscribe(
  //         result => {
  //           console.log('successfully creating a vendor attribute value!');
  //         },
  //         error => {
  //           console.log('there was an error creating a vendor custom attributes', error);
  //         }
  //       );
  //     }
  //   });
  // }

  deleteVendor(indexOfVendor) {
    //Todo: Backend delete call
    this.vendors.splice(indexOfVendor, 1);
  }

  createVendorURL(id) {
    window.location.href = `/vendors/${id}/details`;
  }

  setBusyText(message: string) {
    this.loading = true;
    this.busyText = message;
  }
}
