<section *ngIf="showPanelView == 'buildings'">
    <section class="tabs__panel layout-column">
        <div class="tab-header">
            <div class="layout-column">
                <h2>Buildings</h2>
            </div>
        </div>
        <div>
            <section>
                <div class="tab-header" *ngIf="!this.isBusy">
                    <nav>
                        <ul class="nav-pills">
                          <li>
                            <a (click)="showPanelView = 'units'" routerLinkActive="active">Units</a>
                          </li>
                          <li>
                            <a class="nav-active" (click)="showPanelView = 'buildings'" routerLinkActive="active">Buildings</a>
                          </li>
                        </ul>
                      </nav>
                      <div class="top-buttons">
                        <button
                            mat-flat-button
                            (click)="createBuilding()"
                            class="hds_button" 
                            style="margin: 20px 0;">
                            Add Building
                        </button>
                    </div>
                </div>
            </section>
                <table  class="kanso-table altRows" *ngIf="!this.isBusy && buildings && buildings.length" >
                    <thead>
                        <tr class="centerData">
                            <th>Address</th>
                            <th>Building Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="centerData" *ngFor="let b of buildings">
                            <td>
                                <a class="widget-table__link" (click)="editBuilding(b)">
                                    {{b.address}}
                                </a>
                            </td>
                            <td>
                                <a class="widget-table__link" (click)="editBuilding(b)">
                                    {{b.buildingDescription}}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="buildings && buildings.length && !isBusy" class="pageNavigation">
                    <page-nav
                    [totalCount]="totalCount"
                    [pageInfo]="pageInfo"
                    [currentRecordsCount] = "currentRecordsCount"
                    [pageSize] = "pageSize"
                    (goToPage) ="goToPageHandler($event)">
                    </page-nav>
                </div>

            <div *ngIf="this.loading">
                <mat-spinner diameter="50" class="centered_spinner"> </mat-spinner>
            </div>
        </div>

       <div *ngIf="!this.loading">
      </div>
    </section>
</section>

<div *ngIf="showPanelView == 'units'">
    <units-list></units-list>
</div>
