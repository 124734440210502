    <busy-notice
        [isBusy]="this.isBusy"
        [busyText]="this.busyText"
        headerText="KANSO">
    </busy-notice>
    <div class="summary">
        <button mat-raised-button class="summary-btn">Summary Report</button>
    </div>
    <div class="flexColumn" *ngIf="showAchDirectDeposit">
        <div *ngIf="showTableName">
            <h4>ACH/Direct Deposit</h4>
        </div>
        <div class="scrollable-container">
            <table mat-table [dataSource]="achDirectDeposit">
                <ng-container matColumnDef="Pay to">
                    <th mat-header-cell *matHeaderCellDef>Pay to</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.payTo}}</td>
                </ng-container>
                <ng-container matColumnDef="Last Payment Amount">
                    <th mat-header-cell *matHeaderCellDef>Last Payment Amount</th>
                    <td mat-cell *matCellDef="let element">{{ getAbsoluteValue(element.lastPaymentAmount) | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="Amount Paid">
                    <th mat-header-cell *matHeaderCellDef>Amount Paid</th>
                    <td mat-cell *matCellDef="let element">
                        {{ getAbsoluteValue(element.balanceToBePaid) | currency}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="download" *ngIf="showTableName">
                    <th mat-header-cell *matHeaderCellDef><mat-icon class="download-btn" (click)="generateNACHAFile()">download</mat-icon></th>
                    <td mat-cell *matCellDef="let element">
                        
                    </td>
                </ng-container>
                <ng-container matColumnDef="Post">
                    <th mat-header-cell *matHeaderCellDef>
                        <!-- *hasPermission="'admin || postAndVoidPayables'" -->
                        <button *ngIf="!achPosted" mat-raised-button class="post-btn" (click)="onOpenPostedOn('ACH')">Post</button>
                        <button *ngIf="achPosted" mat-raised-button class="void-btn" (click)="voidPayableBatch('ACH')">Void</button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.postedOn && !element.voidedOn" class="post-text"> Posted </div>
                        <div *ngIf="element.voidedOn" class="void-text"> Voided </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total">
                
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="sumLastPayment">
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(achDirectDeposit, 'lastPaymentAmount') | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="sumBalance">    
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(achDirectDeposit, 'balanceToBePaid') | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="blank">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="blank2">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header-detail table-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header table-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['Total','sumLastPayment','sumBalance','blank', 'blank2']" class="table-footer"></tr>
            </table>
        </div>
    </div>
    <div class="flexColumn" *ngIf="showChecks">
        <div *ngIf="showTableName">
            <h4>Checks</h4>
        </div>
        <div class="scrollable-container">
            <table mat-table [dataSource]="checks">
                <ng-container matColumnDef="Pay to">
                    <th mat-header-cell *matHeaderCellDef>Pay to</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.payTo}}</td>
                </ng-container>
                <ng-container matColumnDef="Last Payment Amount">
                    <th mat-header-cell *matHeaderCellDef>Last Payment Amount</th>
                    <td mat-cell *matCellDef="let element">{{ getAbsoluteValue(element.lastPaymentAmount) | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="Amount Paid">
                    <th mat-header-cell *matHeaderCellDef>Amount Paid</th>
                    <td mat-cell *matCellDef="let element">
                        {{ getAbsoluteValue(element.balanceToBePaid) | currency}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="download" *ngIf="showTableName">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="!payablesChecksLink" class="layout-row">
                            <mat-spinner diameter="25"></mat-spinner> 
                            <div class="spinnerPadding"></div>
                        </div>
                        <a *ngIf="!checkReport && payablesChecksLink" target="_blank" matTooltip="Please Connect Check Report">
                            <mat-icon class="download-btn disable">download</mat-icon>
                        </a>
                        <a *ngIf="checkReport" href="{{payablesChecksLink}}" target="_blank">
                            <mat-icon class="download-btn">download</mat-icon>
                        </a>
                    </th>
                    <td mat-cell *matCellDef="let element">
                    </td>
                </ng-container>
                <ng-container matColumnDef="Post">
                    <th mat-header-cell *matHeaderCellDef>
                        <button *ngIf="!checkPosted" mat-raised-button class="post-btn" (click)="onOpenPostedOn('Check')">Post</button>
                        <button *ngIf="checkPosted" mat-raised-button class="void-btn" (click)="voidPayableBatch('Check')">Void</button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.postedOn && !element.voidedOn" class="post-text"> Posted </div>
                        <div *ngIf="element.voidedOn" class="void-text"><i>Voided</i></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total">
                
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="sumLastPayment">
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(checks, 'lastPaymentAmount') | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="sumBalance">    
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(checks, 'balanceToBePaid') | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="blank">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="blank2">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" class="table-header table-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['Total','sumLastPayment','sumBalance', 'blank', 'blank2']" class="table-footer"></tr>
            </table>
        </div>
    </div>
    <div class="flexColumn" *ngIf="showOthers">
        <div *ngIf="showTableName">
            <h4>Other</h4>
        </div>
        <div class="scrollable-container">
            <table mat-table [dataSource]="other">
                <ng-container matColumnDef="Pay to">
                    <th mat-header-cell *matHeaderCellDef>Pay to</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.payTo}}</td>
                </ng-container>
                <ng-container matColumnDef="Last Payment Amount">
                    <th mat-header-cell *matHeaderCellDef>Last Payment Amount</th>
                    <td mat-cell *matCellDef="let element">{{ getAbsoluteValue(element.lastPaymentAmount) | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="Amount Paid">
                    <th mat-header-cell *matHeaderCellDef>Amount Paid</th>
                    <td mat-cell *matCellDef="let element">
                        {{ getAbsoluteValue(element.balanceToBePaid) | currency}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="download" *ngIf="showTableName">
                    <th mat-header-cell *matHeaderCellDef>
                        <div *ngIf="!generalLedgerReportsLink" class="layout-row">
                            <mat-spinner diameter="25"></mat-spinner> 
                            <div class="spinnerPadding"></div>
                        </div>
                        <a *ngIf="!generalLedgerReports && generalLedgerReportsLink" target="_blank" matTooltip="Please Connect GL Report">
                            <mat-icon class="download-btn disable">download</mat-icon>
                        </a>
                        <a *ngIf="generalLedgerReports" href="{{generalLedgerReportsLink}}" target="_blank">
                            <mat-icon class="download-btn">download</mat-icon>
                        </a>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        
                    </td>
                </ng-container>
                <ng-container matColumnDef="Post">
                    <th mat-header-cell *matHeaderCellDef>
                        <button *ngIf="!otherPosted" mat-raised-button class="post-btn" (click)="onOpenPostedOn('Other')">Post</button>
                        <button *ngIf="otherPosted" mat-raised-button class="void-btn" (click)="voidPayableBatch('Other')">Void</button>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="element.postedOn && !element.voidedOn" class="post-text"> Posted </div>
                        <div *ngIf="element.voidedOn" class="void-text"><i>Voided</i></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Total">
                
                    <td mat-footer-cell *matFooterCellDef>Total</td>
                </ng-container>
                <ng-container matColumnDef="sumLastPayment">
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(other, 'lastPaymentAmount') | currency}}</td>
                </ng-container>
                <ng-container matColumnDef="sumBalance">    
                    <td mat-footer-cell *matFooterCellDef>{{sumAmount(other, 'balanceToBePaid') | currency}}</td>
                </ng-container>

                <ng-container matColumnDef="blank">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="blank2">    
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                <tr mat-footer-row *matFooterRowDef="['Total','sumLastPayment','sumBalance', 'blank', 'blank2']" class="table-footer"></tr>
            </table>
        </div>
    </div>
