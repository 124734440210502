import {Component, Injector, Input, ViewChild, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {FormGroup, FormBuilder, NgForm, ControlContainer} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HousingServices} from '../../housing-core/services/housing-services.service';
import {Authority, EnhancedRentTransactions, Program, ProgramModalData, UnitUiSections} from '../../housing-core/services/housing-models';
import {AccountingService} from '../../accounting/service/accounting.service';
import {UserService} from '../../../core/user/user.service';
import {CoreService} from '../../../core/service/core.service';
import {GlobalModalChild, GlobalModalParent} from '../../../kanso-common/core/service/globalmodal.service';
import {
  KansoActionResult,
  ComplexSaveResult,
  CustomAttributes,
  CustomAttributesValue,
  ICustomAttributesValue,
  CustomAttribute,
} from '../../../core/service/core-models';
import {ISubLedgerMap, AccountDefinition, AccountDefinitionType, AccountActivityRule} from '../../accounting/service/accounting-models';
import {GlobalModalService} from '../../../kanso-common/core/service/globalmodal.service';
import {ProgramsService} from 'src/app/shared/services/programs.service';
import {AccountSetupComponent} from '../account-setup/account-setup.component';
import _ from 'lodash';
import {AutoChargeConfigurationValues, ProgramModel} from 'src/app/shared/services/program.model';
import {AccountActivityRuleComponent} from '../account-activity-rule/account-activity-rule.component';
import {CustomAttributesComponent} from 'src/app/kanso-common/core/components/custom-attributes/custom-attributes.component';
import {AccountDefinitionModel} from 'src/app/shared/services/program.object';

@Component({
  selector: 'program-setup',
  templateUrl: './program-setup.component.html',
  styleUrls: ['./program-setup.component.scss'],
  providers: [{provide: ControlContainer, useExisting: NgForm}],
})
export class ProgramSetupComponent implements GlobalModalChild<ProgramModalData>, ProgramModalData, AfterViewChecked {
  @ViewChild('editProgramForm') editProgramForm;
  @Input() selectedProgram: ProgramModel;

  _program: ProgramModel;
  @Input() set program(setProgram: ProgramModel) {
    this._program = setProgram;
    this.editProgram = _.cloneDeep(setProgram);
    this.customAttributes = this.editProgram.customAttributes;
  }

  @Input() editMode: boolean;
  @Input() openPanel: UnitUiSections;
  // eslint-disable-next-line
  @Input() updateFunction: Function;

  selectedChartOfAccounts: ISubLedgerMap;
  selectedAccountDef: AccountDefinition;
  selectedAccountDefinition: AccountDefinition;
  housingAuthority: Authority;
  subLedgerMaps: ISubLedgerMap[];
  globalModalParent: GlobalModalParent;
  workOrderAutoChargeConfiguration;
  user;
  currentUserEmail;
  query = {
    filterSet: {},
  };
  selectedprogramsIndex: number;
  selectedprogramSnapshot: ProgramModel;
  chosenProgram = null;
  tenantLedgers;
  customerId: string;
  siteId: string;
  landlordLedger;
  utilityLedger;
  maintenanceLedger;
  housingauthorityLedger;
  otherLedger;
  enhancedRentsTrx: EnhancedRentTransactions[] = [];
  enhancedRentsAdj: EnhancedRentTransactions[] = [];
  passbookRateFormGroup: FormGroup;
  minTTPFormGroup: FormGroup;
  updatedProgram;
  selectedRentCalcOption;
  selectedRentCalcDetails = [];
  loading = true;
  isSaving = false;
  generalInfoPanelOpenState = true;
  certificationInfoPanelOpenState = false;
  customAttributesInfoPanelOpenState = false;
  monthlyChargeInfoPanelOpenState = false;
  accountingInfoPanelOpenState = false;
  chargeConfigurationInfoPanelOpenState = false;
  tenantLedgerPanelOpenState = false;
  landlordLedgerPanelOpenState = false;
  utilityLedgerPanelOpenState = false;
  maintenanceLedgerPanelOpenState = false;
  housingauthorityLedgerPanelOpenState = false;
  otherLedgerPanelOpenState = false;
  monthlyTransactionBehaviorPanelOpenState = false;
  customAttributesActive: boolean;
  loggedInUser;
  customAttributesTemplates: CustomAttributes[] | ICustomAttributesValue[];
  customAttributes: any[];
  attributeCount = 1;
  isNewRecord = false;
  editProgram: Program;

  programTypes: string[] = [
    'Assistance',
    'Home Ownership',
    'Lease Purchase',
    'Loan',
    'Low Rent',
    'Misc',
    'Multifamily',
    'Public Housing',
    'Rural Development',
    'Tax Credit',
  ];

  rentCalcProgramTypes: string[] = [
    'Assistance',
    'Home Ownership',
    'Lease Purchase',
    'Low Rent',
    'Public Housing',
    'Rural Development',
    'Tax Credit',
  ];

  constructor(
    protected route: ActivatedRoute,
    public housingServices: HousingServices,
    public accountingService: AccountingService,
    public programsService: ProgramsService,
    public userService: UserService,
    protected router: Router,
    public globalModalService: GlobalModalService,
    protected injector: Injector,
    public coreService: CoreService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private customAttributesComponent: CustomAttributesComponent
  ) {
    this.loading = true;
    this.userService.checkIfAuthorized().subscribe(user => {
      this.user = user;
      this.currentUserEmail = user.email;
    });
  }

  ngAfterViewInit() {
    this.editProgramForm.form.valueChanges.subscribe(this.checkValidAccountDefinitions(), this.onCheckPristineForm());
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    switch (this.openPanel) {
      case UnitUiSections.CustomAttributes:
        this.generalInfoPanelOpenState = false;
        this.customAttributesInfoPanelOpenState = true;
        break;
      default:
        break;
    }
    this.housingServices.getHousingAuthority().subscribe(
      result => {
        this.housingAuthority = result.body;
        if (!this.selectedProgram.id) {
          if (this.housingAuthority.incomeLimitAreas && this.housingAuthority.incomeLimitAreas.length > 0) {
            if (this.selectedProgram.programSettings) {
              this.selectedProgram.programSettings.incomeLimitAreaId = this.housingAuthority.incomeLimitAreas[0]._id;
            }
            if (this.selectedProgram.programType === 'Tax Credit') {
              this.selectedProgram.programSettings.taxCreditIncomeLimitAreaId = this.housingAuthority.incomeLimitAreas[0]._id;
            }
          }

          if (this.selectedProgram.programSettings) {
            this.selectedProgram.programSettings.fundingSource = this.housingAuthority.fundingSources[0]; // initialize to the first in the list
          }
        }
      },
      error => {
        console.log(error);
        this.coreService.displayError('There was an error getting Housing Authority data');
      }
    );
    if (this.editMode && this.selectedProgram.accountDefinitions.length > 0) {
      this.splitLedgerTypes();
    }
    if (this.selectedProgram.enhancedRents && this.selectedProgram.enhancedRents.length > 0) {
      this.enhancedRentsTrx = this.selectedProgram.enhancedRents.filter(t => t.adjustment == false);
      this.enhancedRentsAdj = this.selectedProgram.enhancedRents.filter(t => t.adjustment == true);
    }
    this.accountingService.getAllSubLedgerMaps().subscribe((results: KansoActionResult<any>) => {
      if (results.statusCode == 200) {
        this.subLedgerMaps = results.body;
        if (this.selectedProgram.autoChargeConfigurationValues && this.selectedProgram.autoChargeConfigurationValues.length > 0) {
          const workOrderAutoChargeConfiguration = this.selectedProgram.autoChargeConfigurationValues.find(
            config => config.type === 'Work Order Tenant Charge'
          );
          this.workOrderAutoChargeConfiguration = workOrderAutoChargeConfiguration;
          if (this.workOrderAutoChargeConfiguration) {
            this.selectedAccountDef = this.selectedProgram.accountDefinitions.find(
              accountDef => accountDef.subledgerMappingId == workOrderAutoChargeConfiguration.chartOfAccountsId
            );
            this.chartOfAccountsSelected(this.selectedAccountDef);
          }
        }
        this.loading = false;
      } else {
        console.log('an error has occured', results.message);
        this.loading = false;
      }
    });
    if (!this.selectedProgram.id) {
      // New program
      if (this.selectedProgram.programType === 'Loan') {
        delete this.selectedProgram.programSettings.calcKey;
      }
      const defaultAccountDefinition = new AccountDefinitionModel();
      this.selectedProgram.accountDefinitions.push(defaultAccountDefinition);
      this.accountingInfoPanelOpenState = true;
      this.tenantLedgerPanelOpenState = true;
      this.splitLedgerTypes();
    }
    this.loading = false;
  }

  checkValidAccountDefinitions() {
    if (this.editProgramForm) {
      if (!this.tenantLedgers.some(ad => ad.isArAccount && ad.isPrimary)) {
        this.editProgramForm.form.setErrors({invalid: true});
        return;
      }
      for (const ad of this.selectedProgram.accountDefinitions) {
        if (!ad.name || (!ad.chartOfAccounts && !ad.subledgerMappingId)) {
          this.editProgramForm.form.setErrors({invalid: true});
          return;
        }
      }
      if (this.editProgramForm.form.get('errors')) {
        this.editProgramForm.form.setErrors(null);
      }
    }
  }

  attributeChangeHandler(currentCustomAttributes: CustomAttributes[]) {
    this.attributeCount = currentCustomAttributes.length ?? 0;
  }

  programTypeSelected(event) {
    if (event.value !== null) {
      // Reset all relevant fields if the selected program type is 'Multifamily'
      if (this.selectedProgram.programType === 'Multifamily') {
        this.selectedProgram.requires58PicForm = false;
        this.selectedProgram.programSettings.observeNahasda30Percent = false;
        this.selectedProgram.programSettings.requireHotmaUpdates = false;
      }
      // this.setMinTTPFormGroup();
    }
  }

  closeDialog() {
    // this.updateFunction('cancel');
    this.globalModalParent.closePopup();
  }

  chartOfAccountsSelected(selectedAccountDefinition: AccountDefinition) {
    if (selectedAccountDefinition !== undefined) {
      this.selectedChartOfAccounts = this.subLedgerMaps.find(chart => chart._id === selectedAccountDefinition.subledgerMappingId);
    } else {
      this.clearWorkOrderAutoChargeConfiguration();
    }
  }

  splitLedgerTypes() {
    this.checkValidAccountDefinitions();
    this.tenantLedgers = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.TENANT] && !a.toDelete
    );
    this.landlordLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.LANDLORD] && !a.toDelete
    );
    this.utilityLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.UTILITY] && !a.toDelete
    );
    this.maintenanceLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.MAINTENANCE] && !a.toDelete
    );
    this.housingauthorityLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.HOUSINGAUTHORITY] && !a.toDelete
    );
    this.otherLedger = this.selectedProgram.accountDefinitions.filter(
      a => a.vendorType == AccountDefinitionType[AccountDefinitionType.OTHER] && !a.toDelete
    );
  }

  programSetupFormValid(): boolean {
    return false;
  }

  getCurrentUsersLogInCookie() {
    const cookies = document.cookie.split(';');
    let usersAuthCookie;
    for (const cookie of cookies) {
      if (cookie.includes('LastAuthUser')) {
        //found the cookie of the user
        usersAuthCookie = cookie;
      }
    }
    const arrayOfCookieStrings = usersAuthCookie.split('=');
    this.loggedInUser = arrayOfCookieStrings[1];
  }

  // addWorkOrderAutoChargeConfiguration(workOrderAutoChargeConfiguration) {
  //   if (workOrderAutoChargeConfiguration == undefined) {
  //     this.clearWorkOrderAutoChargeConfiguration();
  //   }
  // }

  private clearWorkOrderAutoChargeConfiguration() {
    this.selectedChartOfAccounts = this.subLedgerMaps.find(chart => chart._id === 'none');
    this.selectedAccountDef = undefined;
    this.workOrderAutoChargeConfiguration = undefined;
  }

  deleteAccountTemplate(account) {
    const accountIndex = this.selectedProgram.accountDefinitions.findIndex(x => x.id === account.id);
    this.selectedProgram.accountDefinitions[accountIndex].toDelete = true;
    this.splitLedgerTypes();
  }

  accountingInfoToggle() {
    this.accountingInfoPanelOpenState = !this.accountingInfoPanelOpenState;
  }

  createNewAccountDefinition(type) {
    this.globalModalService.openModal<any>(AccountSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      program: this.selectedProgram,
      accountDefinition: null,
      chartOfAccounts: this.subLedgerMaps,
      editMode: false,
      accountDefinitionType: type,
      ledgerType: AccountDefinitionType[type.toUpperCase()],
      updateFunction: this.addAccountDefinition,
    });
  }
  editAccountDefinition(a, type) {
    if (a.id && !a.accountDefinitionType) {
      this.programsService.getAccountDefinition(a.id).subscribe(result => {
        this.globalModalService.openModal<any>(AccountSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
          program: this.selectedProgram,
          accountDefinition: result[0],
          chartOfAccounts: this.subLedgerMaps,
          editMode: true,
          accountDefinitionType: type,
          ledgerType: AccountDefinitionType[type.toUpperCase()],
          updateFunction: this.updateAccountDefinition,
        });
      });
    } else {
      this.globalModalService.openModal<any>(AccountSetupComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
        program: this.selectedProgram,
        accountDefinition: a,
        chartOfAccounts: this.subLedgerMaps,
        editMode: true,
        accountDefinitionType: type,
        ledgerType: AccountDefinitionType[type.toUpperCase()],
        updateFunction: this.updateAccountDefinition,
      });
    }
  }

  editActivityRule(a, rule) {
    const filteredCOA = this.subLedgerMaps.filter(coa => coa._id == a.subledgerMappingId);
    this.globalModalService.openModal<any>(AccountActivityRuleComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      activityRule: rule,
      accountDefinitions: this.selectedProgram.accountDefinitions,
      selectedAccountDefinition: a,
      chartOfAccount: filteredCOA[0],
      allChartOfAccounts: this.subLedgerMaps,
      editMode: true,
      user: this.loggedInUser,
      program: this.selectedProgram,
      updateFunction: this.updateActivityRule,
    });
    this.editProgramForm.form.pristine = false;
  }

  newActivityRule(accountDefinition: AccountDefinition) {
    const filteredCOA = this.subLedgerMaps.filter(coa => coa._id == accountDefinition.subledgerMappingId);
    this.globalModalService.openModal<any>(AccountActivityRuleComponent, this.injector, this.globalModalService.getModalOverlayConfig(), {
      activityRule: accountDefinition.activityRules,
      accountDefinitions: this.selectedProgram.accountDefinitions,
      selectedAccountDefinition: accountDefinition,
      chartOfAccount: filteredCOA[0],
      allChartOfAccounts: this.subLedgerMaps,
      user: this.loggedInUser,
      program: this.selectedProgram,
      updateFunction: this.updateActivityRule,
    });
    this.editProgramForm.form.pristine = false;
  }

  compareWithSubCategory(x1: any, x2: any): boolean {
    return x1.subCategoryName == x2.subCategoryName;
  }

  getNonDeletedActivityRules(account) {
    return account.activityRules.filter(rule => !rule.deletedOn);
  }

  updateActivityRule = () => {
    this.onCheckPristineForm();
    this.splitLedgerTypes();
  };

  addAccountDefinition = (accountDefinition: AccountDefinition) => {
    if (this.selectedProgram.accountDefinitions.length == 0) {
      this.selectedProgram.accountDefinitions = [];
    }
    this.selectedProgram.accountDefinitions.push(accountDefinition);
    this.splitLedgerTypes();
  };

  updateAccountDefinition = (accountDefinition: AccountDefinition) => {
    const accountIndex = this.selectedProgram.accountDefinitions.findIndex(x => x.id === accountDefinition.id);
    this.selectedProgram.accountDefinitions[accountIndex] = accountDefinition;
    this.splitLedgerTypes();
  };

  onCheckPristineForm() {
    if (this.editProgramForm.valid) {
      this.editProgramForm.form.pristine = false;
    }
  }

  saveButtonText() {
    return this.isSaving ? 'Saving Program...' : 'Save Program';
  }

  enhancedRentChanged() {
    this.onCheckPristineForm();
  }

  save = () => {
    try {
      this.selectedProgram.housingAuthority = this.housingAuthority._id;
      const enhancedRentArray = [];
      for (const erA of this.enhancedRentsAdj) {
        enhancedRentArray.push(erA);
      }
      for (const erT of this.enhancedRentsTrx) {
        enhancedRentArray.push(erT);
      }
      this.selectedProgram.enhancedRents = enhancedRentArray;
      this.selectedProgram.autoChargeConfigurationValues =
        this.workOrderAutoChargeConfiguration === undefined ? [] : [this.workOrderAutoChargeConfiguration];
      if (this.customAttributes.length > 0) {
        if (this.customAttributes[0].entityAttributeId != null) {
          const attributeValues: CustomAttributesValue[] = [];
          this.customAttributes.forEach((a): void => {
            const value = new CustomAttributesValue(a);
            value.entityInstanceId = this.selectedProgram.id;
            try {
              attributeValues.push(value as CustomAttributesValue);
            } catch (error) {
              console.log(error);
            }
          });
          this.editProgram.customAttributes = attributeValues;
          this.selectedProgram.customAttributes = this.editProgram.customAttributes;
        }
      }
      this.updateFunction(this.selectedProgram);
      this.isSaving = true;
      this.closeDialog();
      this.loading = false;
    } catch (error) {
      this.isSaving = false;
      this.coreService.displayError(`Error trying to save Program and Account Definition: ${error.message}`);
    }
    this.closeDialog();
  };

  cancel() {
    this.globalModalParent.closePopup();
  }
}
