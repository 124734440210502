<mat-card class="knso-card">
    <mat-card-header>
        <mat-toolbar>
            <span>New Recurring Transaction</span>
            <button mat-icon-button (click)="closeDialog()"><mat-icon>close</mat-icon></button>
        </mat-toolbar>
    </mat-card-header>

    <mat-card-content>
        <form #vendorRecurringTransactionForm="ngForm" [ngClass]="{'disabled-form': vendorRecurringTransactionInput.archivedOn}">
            <div class="section-body">
                <div class="layout-row">
                    <mat-form-field  class="name" appearance="outline">
                        <mat-label>Name</mat-label>
                        <textarea 
                            matInput 
                            ngModel 
                            [(ngModel)]="vendorRecurringTransactionInput.name" 
                            #name="ngModel" 
                            name="name"
                            matTextareaAutosize
                            required>
                        </textarea>
                    </mat-form-field>
                    <mat-form-field class="startingOn" appearance="outline">
                        <input 
                            matInput 
                            [matDatepicker]="startingPicker" 
                            placeholder="Starting On"
                            ngModel 
                            [(ngModel)]="vendorRecurringTransactionInput.startOn" 
                            #startOn="ngModel" 
                            name="startOn"
                            required
                            [min]="minStartOnDate">
                        <mat-datepicker-toggle matSuffix [for]="startingPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startingPicker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="completedOn" appearance="outline">
                        <input 
                            matInput 
                            [matDatepicker]="endingPicker" 
                            placeholder="Ending On"
                            ngModel 
                            [(ngModel)]="vendorRecurringTransactionInput.completedOn" 
                            #completedOn="ngModel" 
                            name="completedOn"
                            [min]="vendorRecurringTransactionInput.startOn">
                        <mat-datepicker-toggle matSuffix [for]="endingPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endingPicker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="layout-row">
                    <mat-form-field class="account" appearance="outline">
                        <mat-label>Account</mat-label>
                        <mat-select
                            name="vendorAccountId"
                            id="vendorAccountId"
                            ngModel
                            [(ngModel)]="vendorRecurringTransactionInput.vendorAccountId"
                            #vendorAccountId="ngModel"
                            required>
                            <input class="select-search" matInput placeholder="Search" [formControl]="accountFilter">
                            <mat-option *ngFor="let account of filteredAccounts$ | async" 
                            [value]="account.node.id" (click)="getSubledgers(account.node.subLedgerMappingId)">
                                {{account.expectedDisplayInfo}} - {{account.node.displayName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="type" appearance="outline">
                        <mat-label>Type</mat-label>
                        <mat-select
                            name="chargeTypeId"
                            id="chargeTypeId"
                            ngModel
                            [(ngModel)]="vendorRecurringTransactionInput.chargeTypeId"
                            #chargeTypeId="ngModel"
                            required>
                            <mat-option 
                                *ngFor="let subledger of subledgers$ | async" 
                                [value]="subledger.id"
                                (click)="setChargeTypeName(subledger.name)">
                                {{subledger.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="amount" appearance="outline" *ngIf="vendorRecurringTransactionInput.portReimbursements == 'Not Applicable'">
                        <mat-label>Amount</mat-label>
                        <input 
                            matInput
                            type="number" 
                            ngModel 
                            [(ngModel)]="vendorRecurringTransactionInput.totalAmount" 
                            #totalAmount="ngModel" 
                            name="totalAmount"
                            required>
                    </mat-form-field> 
                </div>

                <div class="layout-row">
                    <mat-form-field  class="description" appearance="outline">
                        <mat-label class="flex-20">Description</mat-label>
                        <textarea 
                            matInput 
                            ngModel 
                            [(ngModel)]="vendorRecurringTransactionInput.description" 
                            #description="ngModel" 
                            name="description"
                            matTextareaAutosize>
                        </textarea>
                    </mat-form-field>
                </div>

                <div *ngIf="vendorType == 'HOUSINGAUTHORITY'">
                    <div class="layout-row" style="display: flex; align-items: center;">
                        <mat-label style="margin-left: 10px; margin-right: 50px;">Is this for Port-In or Port-Out reimbursements?</mat-label>
                        
                        <mat-radio-group 
                            [(ngModel)]="vendorRecurringTransactionInput.portReimbursements" 
                            ngModel #portReimbursements="ngModel"
                            name="portReimbursements" 
                            class="flex-50" 
                            appearance="outline" 
                            style="display: flex; flex-direction: column;"
                            (ngModelChange)="resetAmount()">
                            <mat-radio-button *ngFor="let portabilityType of portabilityTypeList" [value]="portabilityType"
                                style="margin-left: 15px;" required>
                                {{ portabilityType }}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div *ngIf="vendorRecurringTransactionInput.portReimbursements != 'Not Applicable'">
                        <div class="layout-row" style="margin-top: 20px;">
                            <mat-form-field class="tenant" appearance="outline">
                                <mat-label>Tenant</mat-label>
                                <mat-select
                                    name="householdId"
                                    id="householdId"
                                    ngModel
                                    [(ngModel)]="vendorRecurringTransactionInput.householdId"
                                    (ngModelChange)="onHouseholdSelected($event)"
                                    #householdId="ngModel">
                                    <mat-option>
                                        <input 
                                        class="select-search" 
                                        matInput 
                                        placeholder="Search" 
                                        [formControl]="householdFilter" 
                                        (input)="onSearchInput($event)"
                                        (click)="$event.stopPropagation()">
                                    </mat-option>
                                    <mat-option [value]="null">None</mat-option>
                                    <mat-option *ngFor="let member of filteredHouseholdMembers$ | async" 
                                    [value]="member.householdId">
                                       {{member.firstName}} {{member.middleName}} {{member.lastName}} {{member.suffix}} 
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <p class="text-left marg-left-1" style="margin-top: 20px;"><strong>Voucher #: &nbsp;</strong></p>
                            <p class="text-left" style="margin-top: 20px;">
                                {{ vendorRecurringTransactionInput.voucherNumber 
                                    ? vendorRecurringTransactionInput.voucherNumber : 'Not Applicable' }}</p>
                            <p class="text-left marg-left-1" style="margin-top: 20px; margin-left: 160px"><strong>Special Type: &nbsp;</strong></p>
                            <p class="text-left" style="margin-top: 20px;">
                                {{ vendorRecurringTransactionInput.voucherSpecialType 
                                    ? vendorRecurringTransactionInput.voucherSpecialType : 'Not Applicable' }}</p>
                        </div>

                        <div class="layout-row">
                            <mat-form-field class="contractRent" appearance="outline">
                                <mat-label>Contract Rent</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.contractRent" 
                                    #contractRent="ngModel" 
                                    name="contractRent">
                            </mat-form-field> 
                            <mat-form-field class="tenantPortion" appearance="outline">
                                <mat-label>Tenant Portion</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.tenantPortion" 
                                    #tenantPortion="ngModel" 
                                    name="tenantPortion">
                            </mat-form-field> 
                            <mat-form-field class="hapAmount" appearance="outline">
                                <mat-label>HAP Amount</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.hapAmount" 
                                    #hapAmount="ngModel" 
                                    name="hapAmount"
                                    required
                                    (ngModelChange)="sumTotalAmount()">
                            </mat-form-field> 
                        </div>

                        <div class="layout-row">
                            <mat-form-field class="urpAmount" appearance="outline">
                                <mat-label>URP Amount</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.urpAmount" 
                                    #urpAmount="ngModel" 
                                    name="urpAmount"
                                    required
                                    (ngModelChange)="sumTotalAmount()">
                            </mat-form-field> 
                            <mat-form-field class="adminAmount" appearance="outline">
                                <mat-label>Admin Amount</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.adminAmount" 
                                    #adminAmount="ngModel" 
                                    name="adminAmount"
                                    required
                                    (ngModelChange)="sumTotalAmount()">
                            </mat-form-field> 
                            <mat-form-field class="hardToHouseAmount" appearance="outline">
                                <mat-label>Hard to House Amount</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.hardToHouseAmount" 
                                    #hardToHouseAmount="ngModel" 
                                    name="hardToHouseAmount"
                                    (ngModelChange)="sumTotalAmount()">
                            </mat-form-field> 
                        </div>

                        <div class="layout-row">
                            <mat-form-field class="fssEscrowAmount" appearance="outline">
                                <mat-label>FSS Escrow</mat-label>
                                <input 
                                    matInput
                                    type="number" 
                                    ngModel 
                                    [(ngModel)]="vendorRecurringTransactionInput.fssEscrowAmount" 
                                    #fssEscrowAmount="ngModel" 
                                    name="fssEscrowAmount">
                            </mat-form-field> 
                            <p class="text-left marg-left-1" style="margin-top: 15px;"><strong>Monthly Amount: &nbsp;</strong></p>
                            <p class="text-left" style="margin-top: 15px;">${{ vendorRecurringTransactionInput.totalAmount }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <mat-card-actions>
                <button 
                    *ngIf="vendorRecurringTransactionInput.createdOn"
                    mat-flat-button 
                    class="hds_button"
                    id="Stop"
                    (click)="stopVendorRecurringTransaction()">
                        Stop
                </button>
                <button 
                    mat-flat-button
                    (click)="closeDialog()"
                    class="hds_button cancel"
                    id="cancelButton">
                        Cancel
                </button>
                <button 
                    mat-flat-button 
                    class="hds_button"
                    [disabled]="!vendorRecurringTransactionForm.valid || vendorRecurringTransactionForm.$pristine"
                    id="Submit"
                    (click)="saveVendorRecurringTransaction()">
                        Save
                </button>
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>
