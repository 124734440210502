import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileroomUploadButtonComponent} from './fileroom-upload-button/fileroom-upload-button.component';
import {MatIconModule} from '@angular/material/icon';
import {FileroomDashboardComponent, FileroomDashboardModule, FileroomUploaderComponent, FileroomUploaderModule} from '@hds1/fileroom';
import {UploaderHandlerComponent} from './uploader-handler/uploader-handler.component';
import {UploadButtonComponent} from './upload-button/upload-button.component';
import {MaterialModule} from '../shared/material/material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core';

@NgModule({
  declarations: [FileroomUploadButtonComponent, UploaderHandlerComponent, UploadButtonComponent],
  imports: [CommonModule, MatIconModule, FileroomDashboardModule, FileroomUploaderModule, MaterialModule, ReactiveFormsModule, CoreModule],
  exports: [FileroomUploadButtonComponent, UploadButtonComponent, UploaderHandlerComponent],
  entryComponents: [
    FileroomUploadButtonComponent,
    FileroomUploaderComponent,
    FileroomDashboardComponent,
    UploaderHandlerComponent,
    UploadButtonComponent,
  ],
})
export class FileroomModule {}
